import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {
  ScheduleDefinitionDetails,
  ScheduleDetails,
  ScheduleListOptimized,
  ScheduleRow,
  ScheduleRowSimplified
} from "../model/schedule";
import {Observable} from "rxjs";
import {Page, Pageable} from "../model/server";
import {ScheduleActionsResolverService} from "./schedule-actions-resolver.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ScheduleRestService {

  constructor(private http: HttpClient, private resolver: ScheduleActionsResolverService) { }

  prepareUrl(path: string): string {
    return environment.apiEndpoint + path;
  }

  public populateSchedule(
    schoolId: number,
    templateId: number,
    details: ScheduleDefinitionDetails
  ) : Observable<any> {
    return this.http.post(this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}/schedules`), details);
  }

  public listSchedules(
    schoolId: number,
    focusDate: number,
    templateId?: number
  ): Observable<ScheduleRowSimplified[]> {
    let params = new HttpParams().append("focusDate", focusDate.toString());
    if (templateId != null) params = params.append("templateId", templateId.toString());
    return this.http.get<ScheduleRowSimplified[]>(this.prepareUrl(`/sec/school/${schoolId}/schedules`), {params});
  }

  public listSchedulesOptimized(
    schoolId: number,
    focusDate: number,
    templateId?: number
  ): Observable<ScheduleRowSimplified[]> {
    let params = new HttpParams().append("focusDate", focusDate.toString());
    if (templateId != null) params = params.append("templateId", templateId.toString());
    return this.http.get<ScheduleListOptimized>(this.prepareUrl(`/sec/school/${schoolId}/schedules-optimized`), {params}).pipe(
      map(data => this.resolver.resolve(data))
    );
  }

  public getScheduleById(
    schoolId: number,
    scheduleId: number
  ): Observable<ScheduleRow> {
    return this.http.get<ScheduleRow>(this.prepareUrl(`/sec/school/${schoolId}/schedules/${scheduleId}`));
  }

  public updateSchedule(
    schoolId: number,
    scheduleId: number,
    details: ScheduleDetails
  ): Observable<any> {
    return this.http.put(this.prepareUrl(`/sec/school/${schoolId}/schedules/${scheduleId}`), details);
  }

  public deleteSchedule(
    schoolId: number,
    scheduleId: number,
    deleteFollowing: boolean
  ) : Observable<any> {
    const params = new HttpParams().append("deleteFollowing", deleteFollowing.toString());
    return this.http.delete(this.prepareUrl(`/sec/school/${schoolId}/schedules/${scheduleId}`), {params});
  }

  public updateScheduleState(
    schoolId: number,
    scheduleId: number,
    state: string
  ) : Observable<ScheduleRow> {
    return this.http.patch<ScheduleRow>(
      this.prepareUrl(`/sec/school/${schoolId}/schedules/${scheduleId}/state`),
      `"${state}"` ,
      {headers: new HttpHeaders("Content-Type: application/json")});
  }

}
