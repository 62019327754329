import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './service/notifications.service';
import { BrowserVersionService } from './service/browser-version.service';
import {TranslateService} from "@ngx-translate/core";

/*function getQueryParameters(location: Location): Map<string, Array<string>> {
  return (location.search.split('?')[1] || '').split('&').reduce((queryParameters, keyValuePair) => {
    let [key, value] = keyValuePair.split('=');
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);
    queryParameters.set(key, (queryParameters.get(key) || []).concat([value]));
    return queryParameters;
  }, new Map());
}*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  /*template: `<pre *ngIf="error"><code>{{error.stack}}</code></pre>
  <p *ngIf="!error && !room">Connecting to a new Room&hellip;</p>
  <p *ngIf="room && room.state === 'disconnected'">Disconnected from Room {{room.sid}}.</p>
  <p *ngIf="room && room.state !== 'disconnected'">Connected to Room {{room.sid}}.</p>`*/
})
export class AppComponent implements OnInit {
  title = 'col2-video';
  private supportedLangs: Array<string> = ['pt-BR', 'de', 'en', 'es', 'hr', 'it', 'ja', 'pl', 'ru', 'uk'];


  constructor(
    private notificationService: NotificationsService,
    private browserVersion: BrowserVersionService,
    translate: TranslateService
    ) {
    translate.addLangs(this.supportedLangs);
    const browserLang = translate.getBrowserLang();

    console.log(`${browserLang}`, translate.getBrowserCultureLang());
    const cultureLang = translate.getBrowserCultureLang();
    if (cultureLang.match(/pt-BR/)) {
      translate.use(cultureLang);
      return;
    }

    const lang = browserLang.match(/de|es|hr|it|jp|pl|ru|uk/) ? browserLang : 'en';
    translate.use(lang);
  }

  ngOnInit() {
    const audit = this.browserVersion.auditSystem();

    /*if (audit.unsupportedBrowser || audit.wrongBrowser || audit.wrongBrowserVersion) {
      this.notificationService.publishStripeNotification(
        new Notification(null, null, this.notificationService.resolveComponentFactory(BrowserStripeComponent)));
    }*/
  }

}
