<ul class="list-unstyled">
  <li class="form-row" *ngFor="let attender of progress.attenders; let i = index">
    <div class="col-2 text-center">
      <div class="form-check">
        <input class="form-check-input position-static" name="attendance-{{i}}" type="checkbox" [(ngModel)]="attender.attended" (ngModelChange)='onAttendanceChanged($event)'>
      </div>
    </div>
    <div class="col-10">{{getAttenderName(attender)}}</div>
  </li>
</ul>
