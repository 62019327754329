import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SearchingContextService} from "../../service/searching-context.service";
import {ServerRestService} from "../../service/server-rest.service";
import {switchMap,  tap} from "rxjs/operators";
import {LocalStateService} from "../../service/local-state.service";
import {Page, Pageable, RoomDef, TeachersAttendanceReport} from "../../model/server";
import {PageableDataProvider} from "../../model/internal";
import {Observable} from "rxjs";

class AttendanceRoomsQueryState {
  constructor(public teacherMail: string, public pageable: Pageable) {
  }
}

class AttendanceQueryState {
  constructor(public month: number, public duration: number) {
  }
}

@Component({
  selector: 'app-teacher-attendance-report-page',
  templateUrl: './teacher-attendance-report-page.component.html',
  styleUrls: ['./teacher-attendance-report-page.component.css']
})
export class TeacherAttendanceReportPageComponent implements OnInit {
  schoolId: number;
  private localStateAttendanceContextPath: string;
  query: AttendanceQueryState;
  reportData: TeachersAttendanceReport[];
  private localStateRoomsSearchContextPath: string;
  roomsQuery: AttendanceRoomsQueryState;
  roomsDataProvider: PageableDataProvider<RoomDef>
  totalLessonsNumber: number;
  teachers: String[];

  constructor(activatedRoute: ActivatedRoute,
              private searchContext: SearchingContextService,
              private localState: LocalStateService,
              private rest: ServerRestService) {
    activatedRoute.parent.paramMap.pipe(
      tap (params => {
        this.schoolId = Number(params.get('schoolId'));
        this.localStateAttendanceContextPath = `schools/${this.schoolId}/reports/teacher-attendance`;
        this.localStateRoomsSearchContextPath = `schools/${this.schoolId}/reports/teacher-attendance/rooms`;
      }),
      switchMap( _ => this.initializeSearchingContexts()),
      switchMap( _ => this.reloadAndStoreReportData()),
      tap( _ => this.rebuildRoomsDataProvider())
    )
      .subscribe( );
  }

  ngOnInit() {
  }

  private reloadAndStoreReportData() {
    return this.localState.set(this.localStateAttendanceContextPath, this.query).pipe(
      switchMap( _ => this.rest.getTeacherAttendanceReport(this.schoolId, this.query.month, this.query.duration)),
      tap( data => this.storeReportData(data))
    )
  }

  private initializeSearchingContexts() {
    return this.localState.get<AttendanceQueryState>(this.localStateAttendanceContextPath, () =>
      new AttendanceQueryState(0, 12)
    ).pipe(
      tap( q => this.query = q),
      switchMap( _ => this.localState.get<AttendanceRoomsQueryState>(this.localStateRoomsSearchContextPath, () =>
        new AttendanceRoomsQueryState(null, new Pageable(0, 50, "info.created,DESC"))
      )),
      tap ( (q: AttendanceRoomsQueryState) => {
        this.roomsQuery = q;
      })
    )
  }

  private storeReportData(reportData: TeachersAttendanceReport[]) {
    this.reportData = reportData;
    this.teachers = reportData.map( row => row.teacherEmail);
  }

  private rebuildRoomsDataProvider() {
    const self = this;
    this.roomsDataProvider = new class implements PageableDataProvider<RoomDef> {
      doLoad(): Observable<Page<RoomDef>> {
        return self.rest.listSchoolRooms(
          self.schoolId,
          self.roomsQuery.pageable,
          self.query.month,
          self.query.duration,
          self.roomsQuery.teacherMail
        )
      }

      load(): Observable<Page<RoomDef>> {
        return self.localState.set(self.localStateRoomsSearchContextPath, self.roomsQuery).pipe(
          switchMap( _ => this.doLoad())
        )
      }

      nextPage() {
        self.roomsQuery.pageable = self.roomsQuery.pageable.next();
      }
    }
  }

  onTimeChange() {
    this.roomsQuery.pageable.page = 0;
    this.reloadAndStoreReportData().subscribe(
      _ => this.rebuildRoomsDataProvider()
    )

  }

  onTeacherChange() {
    this.roomsQuery.pageable.page = 0;
    this.rebuildRoomsDataProvider();
  }
}
