
<div @elAppear *ngIf="hasBoxNotificaitons()" class="notificaiton-dim">

    <div @elAppear *ngIf="hasBoxNotification()" class="notificaiton-box card">
        
        <div class="card-body">
            <h5 class="card-title" *ngIf="boxNotification.title">{{boxNotification.title}}</h5>
            <p class="card-text" *ngIf="boxNotification.message" [innerHTML]="boxNotification.message"></p>
            <ng-template #boxDynamic></ng-template>
            <div class="text-right" *ngIf="!boxNotification.timeout">
                <button class="btn btn-primary" (click)="discardBoxNotification()">Close</button>
            </div>
        </div>
    </div>
</div>


<div @elCome *ngIf="hasStripeNotification()" class="notificaiton-stripe card">
        
        <div class="card-body">
                <button  *ngIf="!stripeNotification.timeout" type="button" class="close" aria-label="Close" (click)="discardStripeNotification()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                <h5 class="card-title" *ngIf="stripeNotification.title">{{stripeNotification.title}}</h5>
                <p class="card-text" *ngIf="stripeNotification.message" [innerHTML]="stripeNotification.message"></p>
                <ng-template #stripeDynamic></ng-template>
        </div>
    </div>