import { Injectable } from '@angular/core';
import { Observable, throwError , Observer, Subscription} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {ToastNotification, ToastNotificationsService} from "./toast-notifications.service";
import {ErrorBase} from "../model/server";
import {SentryService} from "./sentry.service";
import {LoggerService} from "./logger.service";
@Injectable({
  providedIn: 'root'
})
/*
  Interceptor for http request repsponsible to prepare common error structure
*/
export class HttpErrorsInterceptorService implements HttpInterceptor {

  constructor(
    private toasts: ToastNotificationsService,
    private sentry: SentryService,
    private l: LoggerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

      return next.handle(req).pipe(catchError(err => {
        let error = err.error as ErrorBase;
        if (err.status === 0 ) {
          error = new ErrorBase();
          error.errorCode = 'CONNECTION_ERROR';
          error.interactive = false;
          error.userMessage = error.message = 'Lost connection. Check your Internet connection or try again later.';
          error.developerMessage = 'Request failed. Check the connection or server status.';
        } else if (!error) {
          error = new ErrorBase();
          error.developerMessage = err.error;
          error.userMessage = error.message = 'Server Error';
          error.errorCode = 'HTTP:${err.status}';
        } else if (error && error.interactive) {
          // skip regular error processing
          return throwError(error);
        } else {
          const prev = error;
          error = new ErrorBase();
          let message = "Server Error";
          if (prev.message) message = prev.message;
          error.message = error.userMessage = message;
          error.errorCode = prev.errorCode;
          error.developerMessage = prev.developerMessage;
          error.cause = JSON.stringify(prev);
        }

        this.toasts.display(new ToastNotification("Communication Error",`${error.userMessage}`))
        this.l.i("communication error", JSON.stringify(err));
        return throwError(error);
      }));
    }
}
