import { Injectable } from '@angular/core';
import { AuthStoreImpl } from './auth.service';
import { CasaRestService } from './casa-rest.service';
import { tap, switchMap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Person, AuthorizationProfile } from '../model/casa';

/**
 * Service is listening for authorization data. After any change personal informations
 * are taken from the CASA (person instance, availabile authorization profiles)
 */
@Injectable({
  providedIn: 'root'
})
export class PersonalDataService {
  selfPerson = new ReplaySubject<Person>(1);
  authorizationProfiles = new ReplaySubject<AuthorizationProfile[]>(1);
  currentProfile = new ReplaySubject<AuthorizationProfile>(1);

  constructor(authStore: AuthStoreImpl,
              private casaRest: CasaRestService) {
    authStore.readAuthorizationData().subscribe( authData => {
      if (!authData) {
        this.clear();
      } else {
        this.loadPersonData();
      }
    });
  }

  loadPersonData() {
    this.casaRest.loadSelfPerson().pipe(
      tap( selfPerson => this.selfPerson.next(selfPerson)),
      switchMap( selfPerson => this.casaRest.listProfiles()),
      tap( authProfiles => this.authorizationProfiles.next(authProfiles)),
      switchMap( () => this.casaRest.getCurrentProfile()),
      tap( profile => this.currentProfile.next(profile)),
      tap( a => console.log(a))
    ).subscribe();
  }

  clear() {
    this.selfPerson.next(null);
    this.authorizationProfiles.next(null);
    this.currentProfile.next(null);
  }
}
