<div class="container my-5">
    <h2 class="text-center">The requested page is forbidden.</h2>
    <p class="text-center">
        The page you have requested is forbidden. 
    </p>
    <div class="text-center">
        <button class="btn btn-primary mr-2" routerLink="/">Main Page</button>
        <button class="btn btn-primary" (click)="logout()">Logout</button>
    </div>
</div>
