import {Injectable} from "@angular/core";
import {filter, take} from "rxjs/operators";
import {RoomParticipantsDataService} from "./helpers/room-participants-data-service";
import {RoomDataService} from "./helpers/room-data-service";
import {BehaviorSubject, ReplaySubject} from "rxjs";
import {RoomContextHolder} from "./helpers/RoomContextHolder";
import {SocketServiceV2} from "./socket-v2.service";

@Injectable({
  providedIn: 'root'
})
export class ShelfService {

  constructor(
    private participantsHolder: RoomParticipantsDataService,
    private selfHolder: RoomDataService,
    private socketService: SocketServiceV2,
    private contextHolder: RoomContextHolder
  ) {
    contextHolder.participantsObservable.subscribe(
      _ => this.decideIfOpenShelf()
    );
    this.contextHolder.participantConnectedEvent.subscribe(
      _ => this.decideIfOpenShelf()
    );
  }

  private shelfInitialized = false;
  private _showShelf = false;
  showShelfObservable = new BehaviorSubject(this._showShelf);
  closeIn = 0;

  getCloseInCounter(){
    return this.closeIn;
  }

  get showShelf() {
    return this._showShelf;
  }

  private decideIfOpenShelf() {
    if (this.selfHolder.amITeacher()) {
      this.decideIfOpenShelfFoTeacher();
    } else {
      this.decideIfOpenShelfForStudent();
    }
  }

  private countStudents() {
    return this.participantsHolder.getStudents().length;
  }

  private decideIfOpenShelfForStudent() {
    if (this.shelfInitialized && this.showShelf) {
      // this is the case when new student is coming but they are too many and shelf should be closed
      if (this.countStudents() > 4) {
        // REMOVED: to avoid shelf closing - should be changed
        // this.startCloseCountDown(5);
      }
    }
    if (this.showShelf || this.shelfInitialized) return;
    if (this.countStudents() > 0 ){
      this.shelfInitialized = true;
      this.openShelf();
    }
  }

  private decideIfOpenShelfFoTeacher() {
    if (this.shelfInitialized || this.showShelf) return;
    const numberOfStudents = this.participantsHolder.getParticipants().length;
    const roomInvitedStudents = this.selfHolder.selfData.roomDef.participants.filter( parti => parti.role === 'Student' && !parti.hidden).length;
    if (roomInvitedStudents === 1 && numberOfStudents === 1) {
      // wait until socket connection to be notified about student activation
      this.socketService.subscriptionsCounter.pipe(
        filter( counter => counter > 0),
        take(1)
      ).subscribe( _ => {
        // this.participantsHolder.activate(this.participantsHolder.getParticipants()[0], true);
        this.shelfInitialized = true;
        this.showShelfObservable.next(this._showShelf);
      })
      return;
    }
    if (numberOfStudents === 0) return;
    this.shelfInitialized = true;
    this.openShelf();
  }

  closeShelf() {
    this._showShelf = false;
    this.showShelfObservable.next(this._showShelf);
  }

  openShelf() {
    if (this.countStudents() <= 4 || this.selfHolder.amITeacher()) {
      this.cancelCloseCountDown();
    } else {
      // REMOVED: to avoid shelf closing - should be changed
      // this.startCloseCountDown(5);
    }
    this._showShelf = true;
    this.showShelfObservable.next(this._showShelf);

  }

  switchShelf() {
    this._showShelf = !this.showShelf;
    this.showShelfObservable.next(this._showShelf);
  }

  doNextThick() {
    if (this.closeIn <= 0) {
      this.closeShelf();
      return;
    }

    const timeToNextSecond = 1000 - new Date().getTime() % 1000;
    this.runningTimeoutId = window.setTimeout(() => {
      this.closeIn -= 1;
      this.doNextThick()
    }, timeToNextSecond);

  }

  runningTimeoutId: number = null;
  private startCloseCountDown(startValue: number) {
    if (this.runningTimeoutId) this.cancelCloseCountDown();

    this.closeIn = startValue;
    this.doNextThick();
  }

  private cancelCloseCountDown() {
    if (!this.runningTimeoutId) return;
    clearTimeout(this.runningTimeoutId);
    this.runningTimeoutId = null;
  }

  isOpened() {
    return this._showShelf;
  }
}
