import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {debounceTime, filter, switchMap, tap} from "rxjs/operators";
import {AutocompleteComponent} from "../autocomplete/autocomplete.component";


export class Adapter<T> {
  dataProvider : (string) => Observable<T[]>;
  mapper: (T) => string;
  detailsMapper: (T) => string;
}

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css']
})
export class AutocompleteInputComponent<T> implements OnInit {

  private mAdapter: Adapter<T>;
  data: T[];
  private termSubject = new Subject<string>();
  @ViewChildren("autocompleteItem")
  itemViews: QueryList<ElementRef>;

  @Input()
  openOnFocus = false;

  constructor() {
    this.termSubject.pipe(
      switchMap( term => this.mAdapter.dataProvider(term)),
      tap<T[]>( data => {
        this.data = data;
        this.autocomplete.clearFocus();
      })
    ).subscribe()
  }

  @ViewChild("autocompleteComponent", {static: true})
  autocomplete: AutocompleteComponent<T>
  mCurrentActive: T;

  set currentActive(el: T) {
    this.mCurrentActive = el;
    this.focusActive();
  }

  get currentActive(){
    return this.mCurrentActive;
  }

  @Input()
  set adapter(adapter: Adapter<T>) {
    this.mAdapter = adapter;
  }

  @Output()
  itemSelected = new EventEmitter<T>();

  ngOnInit(): void {
  }

  focusActive() {
    if (!this.currentActive) return;
    const selectedItemIndex = this.data.indexOf(this.currentActive);
    if (selectedItemIndex < 0) return;
    const itemView = this.itemViews.filter( (i, index) => index == selectedItemIndex );
    if (!itemView || itemView.length === 0) return;
    itemView[0].nativeElement.scrollIntoView({
      behavior : "smooth",
      block : "nearest"
    });
  }

  onTermUpdate(currentTerm: string) {
    this.termSubject.next(currentTerm);
  }

  getItemDescription(item: T) {
    return this.mAdapter.mapper(item);
  }

  onItemSelected(selected: T) {
    this.itemSelected.emit(selected);
  }

  getSecondaryDescription(item: T) {
    return this.mAdapter.detailsMapper(item);
  }

  reopenOnFocus(event: FocusEvent) {
    if (!this.openOnFocus) return;
    this.termSubject.next((event.target as HTMLInputElement).value);
  }
}
