import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Course, Stage} from "../model/books";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BookPageSelection} from "./book-renderer-controller.service";
import {RoomDataService} from "./helpers/room-data-service";
import {switchMap} from "rxjs/operators";
import {SocketServiceV2} from "./socket-v2.service";

@Injectable({
  providedIn: 'root'
})
export class BooksRestService {
  constructor(
    private http: HttpClient,
    private selfHolder: RoomDataService,
    protected socketService: SocketServiceV2) {
  }

  bookContentObservable = new Subject<string>();
  bookContent: string = null;

  public getCoverUrl(participantUuid: string, course: Course, stage: Stage): String {
    return this.prepareUrl(participantUuid, `/courses/${course.code}/stages/${stage.code}/cover`)
  }

  public listProducts(participantUuid: string): Observable<Course[]> {
    return this.http.get<Course[]>(this.prepareUrl(participantUuid, `/courses`))
  }

  public subscribeForBookShares(){
    this.socketService.connect().pipe(
      switchMap(context => this.socketService.topicPlain(context, '/room/' + this.selfHolder.localParticipant.roomDef.uuid + '/book-share'))
    ).subscribe(
      command => {
        if (command === 'clear') {
          this.bookContent = null;
          this.bookContentObservable.next(this.bookContent);
          return;

        }
        this.getSharedBookContent(this.selfHolder.participantUuid).subscribe(
          content => {
            this.bookContent = (content) ? content : null;
            this.bookContentObservable.next(this.bookContent);
          }
        )
      }
    )
  }

  public getBookContent(
    participantUuid: string,
    course: Course,
    stage: Stage,
    page: number,
    size: number): Observable<string> {
    const params = new HttpParams()
      .append("page", page.toString())
      .append("size", size.toString());
    return this.http.get<string>(this.prepareUrl(participantUuid, `/courses/${course.code}/stages/${stage.code}/content`),
      {
        params,
        responseType : 'text' as 'json'})
  }

  public getSharedBookContent(participantUuid: string) {
    return this.http.get<string>(this.prepareUrl(participantUuid, '/shared'), {responseType: 'text' as 'json'});
  }

  public clearBookShare(participantUuid: string): Observable<void> {
    return this.http.post<void>(this.prepareUrl(participantUuid, '/clear-share'), {});
  }

  public shareBookPage(
    participantUUid: string,
    bookPageSelection: BookPageSelection
  ): Observable<void> {
    const params = new HttpParams()
      .append('page', bookPageSelection.page.toString())
      .append('size', bookPageSelection.size.toString());
    return this.http.post<void>(this.prepareUrl(participantUUid,
      `/courses/${bookPageSelection.book.course.code}/stages/${bookPageSelection.book.stage.code}/share`), {}, {
      params
    });
  }

  private prepareUrl(participantUuid: string, url: string) {
    return `${environment.apiEndpoint}/participants/${participantUuid}/books${url}`;
  }
}
