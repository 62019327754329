import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {
  processCode(params: Params): void {
    const code = params.code;
    const state = (params.state) ? atob(params.state) : '/';
    this.auth.loginWithAuthCode(code, state);
  }

  constructor(private auth: AuthService, route: ActivatedRoute) {
    route.queryParams.subscribe( params => this.processCode(params));
  }

  ngOnInit() {
  }

}
