import { ReplaySubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class SearchingContextService {

    private contexts: { [contextId: string]: ReplaySubject<any>} = {};

    public getOrCreateContext<T>(contextId: string): Subject<T> {
        const resut = this.contexts[contextId];
        if (resut) {
            return resut;
        }
        const newCreated = new ReplaySubject<any>(1);
        this.contexts[contextId] = newCreated;
        return newCreated;
    }

    public updateContext<T>(contextId: string, data: T) {
        this.getOrCreateContext<T>(contextId).next(data);
    }
}
