import {Injectable} from "@angular/core";
import {RoomDataService} from "./helpers/room-data-service";
import {RoomContextHolder} from "./helpers/RoomContextHolder";
import {ParticipantData} from "../model/internal";
import * as Sentry from '@sentry/browser';
import {Scope, Severity} from '@sentry/browser';


@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(
    private selfHolder: RoomDataService,
    private contextHolder: RoomContextHolder
  ) {
    selfHolder.initializedObservable.subscribe( _ => {
      Sentry.setUser({id: selfHolder.participantUuid});
    });
    contextHolder.selfObservable.subscribe(selfData => this.setupUser(selfData));
  }

  private setupUser(selfData: ParticipantData) {
    if (!selfData || !selfData.participantDef) return;
    Sentry.setUser({
      id: selfData.participantDef.uuid,
      email: selfData.participantDef.mail,
      username: selfData.participantDef.name
    });

    if (selfData.roomDef) Sentry.setExtra("room-uuid", selfData.roomDef.uuid);
    Sentry.setExtra("room-sid", selfData.roomDef.sid);
    if (selfData.participant) Sentry.setExtra("sid", selfData.participant.sid);
  }

  submitMessage(title: string, feedbackMessage?: string) {
    Sentry.withScope(scope => {
      if (feedbackMessage) {
        scope.setExtra("feedback", feedbackMessage);
      }
      this.attachParticipantsInfo(scope);
      Sentry.captureMessage(title);
    })
  }

  private attachParticipantsInfo(scope: Scope) {
    if (!this.contextHolder.participants) return;
    const partis = this.contextHolder.participants.map(parti => {
      let partiData: string[] = [];
      if (parti) {
        if (parti.participantDef) {
          const def = parti.participantDef;
          partiData.push( `uuid: ${def.uuid}, mail: ${def.mail}, name: ${def.name}, role: ${def.role}, active:${def.active}, muted: ${def.muted} hidden: ${def.hidden}`);
        }
        partiData.push(`local: ${parti.local}`);
        if (parti.participant) {
          partiData.push(`sid: ${parti.participant.sid}`);
          if (parti.participant.audioTracks) partiData.push(`audioTracks: ${parti.participant.audioTracks.size}`);
          if (parti.participant.videoTracks) partiData.push(`videoTracks: ${parti.participant.videoTracks.size}`);
        }
      }
      return `{ ${partiData.join(", ")} }`;
    });
    const roomData: string[] = [];
    roomData.push(`participants: [${partis.join(",\n")}]`);
    if (this.contextHolder.selfData && this.contextHolder.selfData.videoRoom) {
      const videoRoom = this.contextHolder.selfData.videoRoom;
      roomData.push(`sid: ${videoRoom.sid}`);
    }
    scope.setExtra("room", roomData.join(",\n"));
  }

  submitDialogCaughtError(err: any) {
    Sentry.withScope(scope => {
      this.attachParticipantsInfo(scope);
      Sentry.setTag("phase", "dialog");
      Sentry.captureException(err);
    });
  }

  submitBreadcrumb(message: string) {
    Sentry.addBreadcrumb({
      category: "lifecycle",
      message,
      level: Severity.Info
    })
  }

  submitError(error: Error, phase?: string) {
    if (phase) {
      Sentry.withScope(scope => {
        this.attachParticipantsInfo(scope);
        Sentry.setTag("phase", phase);
        Sentry.captureException(error);
      })
    } else {
      Sentry.captureException(error);
    }
  }
}
