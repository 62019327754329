<div class="table-responsive-sm">
<table class="table table-sm">
    <thead>
        <tr>
            <th>Group</th>
            <th>Lessons</th>
            <th>Group time [min]</th>
            <th>Student time [min]</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of data">
            <td>{{getName(row)}}</td>
            <td>{{getRooms(row)}}</td>
            <td>{{getTime(row)}}</td>
            <td>{{getBillingTime(row)}}</td>
        </tr>
        <tr *ngIf="isEmpty(data)">
            <td colspan="4" class="text-center">No results</td>
        </tr>
        <tr *ngIf="notEmpty(data)">
            <th class="text-right">Total:</th>
            <th>{{getTotalRooms()}}</th>
            <th>{{getTotalTime()}}</th>
            <th>{{getTotalBilling()}}</th>
        </tr>
    </tbody>
</table>
</div>
