import {Injectable} from "@angular/core";
import {ShelfService} from "../../service/shelf.service";
import {BooksRestService} from "../../service/books-service";
import {ChartHostService} from "../../service/chart-host-service";
import {RoomParticipantsDataService} from "../../service/helpers/room-participants-data-service";
import {RoomContextHolder} from "../../service/helpers/RoomContextHolder";

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class StudentLayoutService {
  layoutPadding = 10;

  constructor(
    private shelfService: ShelfService,
    private bookService: BooksRestService,
    private chartHostService: ChartHostService,
    private participantsHolder: RoomParticipantsDataService,
    private contextHolder: RoomContextHolder) {
    this.listenRelayoutEvents();
  }

  public listenForResize() {
    $(window).resize(_ => this.applyLayout());
  }

  public relayout() {
    setTimeout(_ => this.applyLayout(), 100);
  }

  /*
     this function calculates a position of the line where ends a primary video area and begins
     a working area.
     function returns a position of the line relative to the longer screen dimension. It's a screen orientation
     independent.

     For a better understanding comments are for only one screen orientation and layout
     +----+----+
     |  s |  p |  s - selfie, p - primary student
     |    |    |
     +----+----+
     |         |
     |    t    |  t - teacher video
     |         |
     +---------|

     All videos are in square ratio

    */
  private calculateBaseline(w: number, h: number): number {
    const shorterDim = Math.min(w, h);
    const longerDim = Math.max(w, h);
    if (this.shelfService.showShelf) return Math.round(shorterDim / 3);

    /*
      - first case is to try a layout a selfie and primary student fitting whole screen width
      and the teacher video has to fit the width too. If it is possible we have the solution
     */
    const preferredSizeRequiredSpace = shorterDim * 1.5;
    if (longerDim > preferredSizeRequiredSpace) return Math.round(shorterDim / 2);

    /*
      Second case assumes that if we fit the teacher video starting from the bottom
      rest part of the screen on the top has enough space to display other videos
      - skip this case for now. I assume that each secondary video should have at least 1/3 of
      the width
     */

    return Math.round(shorterDim / 3);
  }

  applyLayout() {
    const w = window.innerWidth;
    const h = window.innerHeight - this.layoutPadding;

    const isHorizontal = w > h;
    const participantsSize = h / 6;
    const baseline = this.calculateBaseline(w, h);

    const secondaryVideoSize = baseline - this.layoutPadding * 1.5;
    const secondaryVideoPos = baseline + this.layoutPadding * 0.5;

    this.layoutComponent("#self-parti", false,  this.layoutPadding, this.layoutPadding, secondaryVideoSize, secondaryVideoSize);


    const workingAreaWidth = isHorizontal ? w - baseline : w;
    let workingAreHeight = isHorizontal ? h : h - baseline;
    if (this.shelfService.showShelf) {
      workingAreHeight -= participantsSize;
    }

    const teacherPrimaryVideoSize = Math.min(workingAreaWidth, workingAreHeight) - this.layoutPadding * 2;
    const teacherPrimaryVideoOffsetX = (workingAreaWidth - teacherPrimaryVideoSize) / 2;
    const teacherPrimaryVideoOffsetY = (workingAreHeight - teacherPrimaryVideoSize) / 2;

    const innerWorkingAreaWidth = isHorizontal ? workingAreaWidth - this.layoutPadding * 1.5 : workingAreaWidth - this.layoutPadding * 2;
    const innerWorkingAreaHeight = isHorizontal ? workingAreHeight - this.layoutPadding * 2 : workingAreHeight - this.layoutPadding * 1.5;

    const primaryVideoX = isHorizontal ? baseline + teacherPrimaryVideoOffsetX : teacherPrimaryVideoOffsetX;
    const primaryVideoY = isHorizontal ? teacherPrimaryVideoOffsetY : baseline + teacherPrimaryVideoOffsetY;

    const secondaryVideoX = isHorizontal ? this.layoutPadding : secondaryVideoPos;
    const secondaryVideoY = isHorizontal ? secondaryVideoPos : this.layoutPadding;

    const workingAreaX = isHorizontal ? baseline + this.layoutPadding / 2 : this.layoutPadding;
    const workingAreaY = isHorizontal ? this.layoutPadding : baseline + this.layoutPadding / 2;

    const secondaryVideoHiddenX = isHorizontal ? - secondaryVideoSize - this.layoutPadding : secondaryVideoX; // stays or comes from left
    const secondaryVideoHiddenY = isHorizontal ? secondaryVideoY : - secondaryVideoSize - this.layoutPadding ; // stays of comes from top

    const workingAreaXHidden = isHorizontal ? w + workingAreaWidth + this.layoutPadding : workingAreaX;
    const workingAreaYHidden = isHorizontal ? workingAreaY : h + workingAreHeight + this.layoutPadding * 2;

    this.layoutTeacher(primaryVideoX, primaryVideoY, teacherPrimaryVideoSize, secondaryVideoX, secondaryVideoY, secondaryVideoSize);
    this.layoutSecondary(secondaryVideoX, secondaryVideoY, secondaryVideoSize, secondaryVideoHiddenX, secondaryVideoHiddenY);
    this.layoutChart(workingAreaX, workingAreaY, innerWorkingAreaWidth, innerWorkingAreaHeight, workingAreaXHidden, workingAreaYHidden);
    this.layoutBook(workingAreaX, workingAreaY, innerWorkingAreaWidth, innerWorkingAreaHeight, workingAreaXHidden, workingAreaYHidden);

    $("#participants-shelf .participant").each( function()  {
      $(this).css("width", participantsSize + "px");
      $(this).css("height", participantsSize + "px");
    });
    $("#participants-shelf").css("height", participantsSize + 30 + "px");
    $("#participants-shelf").css("bottom", 0);
    if (this.shelfService.showShelf) {
      $("#participants-shelf").css("left", 0);
    } else {
      $("#participants-shelf").css("left", "100%");
    }

    const gotStudentSpace = secondaryVideoSize < Math.min(w, h) * 0.4; // there is a space for 3 facesc
    let logoPosX = 0;
    let logoPosY = 0;
    let logoSize = secondaryVideoSize;
    let logoHeight: number;
    if (gotStudentSpace) {
      logoPosX = isHorizontal ? secondaryVideoX : secondaryVideoX + secondaryVideoSize + this.layoutPadding;
      logoPosY = isHorizontal ? secondaryVideoY + secondaryVideoSize + this.layoutPadding : secondaryVideoY;
    } else {
      logoSize /= 2;
      logoPosX = primaryVideoX + teacherPrimaryVideoSize - logoSize;
      logoPosY = primaryVideoY;
    }
    if (this.shelfService.showShelf && isHorizontal) logoHeight = logoSize - participantsSize;
    this.layoutComponent("#logo-container", false, logoPosX, logoPosY, logoSize, logoHeight);

    // limit size of toolbox for small devices
    const toolboxWidth = Math.min(w, 390);
    $(".toolbox").each(function() {
      $(this).css("width",toolboxWidth + "px");
    })
  }

  sizeComponent(component: string, width: number, height: number){
    $(component).css("width", width + "px");
    if (height) {
      $(component).css("height", height + "px");
    } else {
      //$(component).css("height", width + "px");
    }
  }

  positionComponent(component: string, x: number, y: number) {
    $(component).css("left", x + "px");
    $(component).css("top", y + "px");
  }

  layoutComponent(component: string, hide: boolean, x: number, y: number, width: number, height?: number) {
    this.sizeComponent(component, width, height);
    this.positionComponent(component, x, y);
    if (hide) {
      $(component).css("opacity", 0);
    } else {
      $(component).css("opacity", 1);
    }
  }

  isShowChart() {
    return !!this.chartHostService.chartSelected;
  }

  isShowBook() {
    return this.bookService.bookContent && !this.chartHostService.chartSelected;
  }

  hasActiveStudent() {
    return this.participantsHolder && this.participantsHolder.hasActiveParticipant();
  }

  isShowActive() {
    return this.hasActiveStudent() && !this.isShowChart() && !this.isShowBook();
  }


  layoutTeacher(
    primaryVideoX: number,
    primaryVideoY: number,
    teacherPrimaryVideoSize: number,
    secondaryVideoX: number,
    secondaryVideoY: number,
    secondaryVideoSize: number) {
    const teacherIsFull = !this.isShowChart() && !this.isShowBook();
    if (teacherIsFull) {
      this.layoutComponent("#teacher-parti",
        false,
        primaryVideoX,
        primaryVideoY,
        teacherPrimaryVideoSize,
        teacherPrimaryVideoSize
      );
    } else {
      this.layoutComponent("#teacher-parti",
        false,
        secondaryVideoX,
        secondaryVideoY,
        secondaryVideoSize,
        secondaryVideoSize
      );
    }
  }

  layoutSecondary(
    secondaryVideoX: number,
    secondaryVideoY: number,
    secondaryVideoSize: number,
    secondaryVideoHiddenX: number,
    secondaryVideoHiddenY: number) {
    if (this.isShowActive()) {
      this.layoutComponent("#primary-parti",
        false,
        secondaryVideoX,
        secondaryVideoY,
        secondaryVideoSize,
        secondaryVideoSize);
    } else {
      this.layoutComponent("#primary-parti",
        true,
        secondaryVideoHiddenX,
        secondaryVideoHiddenY,
        secondaryVideoSize,
        secondaryVideoSize);
    }
  }

  layoutChart(
    workingAreaX: number,
    workingAreaY: number,
    innerWorkingAreaWidth: number,
    innerWorkingAreaHeight: number,
    workingAreaXHidden: number,
    workingAreaYHidden: number) {
    if (this.isShowChart() && !this.isShowBook()) {
      this.layoutComponent("#chart-container",
        false,
        workingAreaX,
        workingAreaY,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    } else {
      this.layoutComponent("#chart-container",
        true,
        workingAreaXHidden,
        workingAreaYHidden,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    }
  }

  layoutBook(
    workingAreaX: number,
    workingAreaY: number,
    innerWorkingAreaWidth: number,
    innerWorkingAreaHeight: number,
    workingAreaXHidden: number,
    workingAreaYHidden: number) {
    if (this.isShowBook()) {
      this.layoutComponent("#book-container",
        false,
        workingAreaX,
        workingAreaY,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    } else {
      this.layoutComponent("#book-container",
        true,
        workingAreaXHidden,
        workingAreaYHidden,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    }
  }

  private listenRelayoutEvents() {
    this.bookService.bookContentObservable.subscribe( _ => this.applyLayout());
    this.chartHostService.chartSelectedObservable.subscribe(_ => this.relayout());
    this.shelfService.showShelfObservable.subscribe(_ => this.relayout());
    this.listenForContextRelayoutEvents();
  }

  public listenForContextRelayoutEvents() {
    this.contextHolder.selfObservable.subscribe( _ => this.relayout());
    this.contextHolder.participantsObservable.subscribe( _ => this.relayout());
  }
}
