import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";

declare var $: any;

@Component({
  selector: 'app-modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.css']
})
export class ModalV2Component implements OnInit {


  @ViewChild("modal", {static: true})
  modal: ElementRef;
  @Input()
  scrollable = true;
  @Input()
  size: string = "md";

  state = new BehaviorSubject(false);

  isExtraLarge() {
    if (this.size === 'xl') return true;
  }

  isLarge(){
    if (this.size === 'lg') return true;
  }

  isSmall() {
    if (this.size === 'sm') return true;
  }

  constructor() { }

  public show(cancellable?: boolean) {
    const options = {};
    if (cancellable == false) {
      options['backdrop'] = 'static';
      options['keyboard'] = false;
      options['focus'] = true;
      $(this.modal.nativeElement).modal(options);
     }
    else {
      $(this.modal.nativeElement).modal('show');
    }
  }

  public hide() {
    $(this.modal.nativeElement).modal('hide');
  }

  ngOnInit() {
    $(this.modal.nativeElement).on('shown.bs.modal', () => this.state.next(true));
    $(this.modal.nativeElement).on('hidden.bs.modal', () => this.state.next(false));
  }

}
