<div #bookContainer class="book-container"
     style="overflow-y: auto; -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
     unselectable="on"
     onselectstart="return false;"
     onmousedown="return false;">
    <div #contentContainer style="display: unset" [innerHTML]="content" [ngStyle]="getZoom()">

    </div>
</div>
<app-book-controller [participantUuid]="participantUuid"></app-book-controller>
