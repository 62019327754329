import { Component, OnInit, Input } from '@angular/core';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { tap, switchMap, take, timeoutWith } from 'rxjs/operators';
import { RoomDef, ParticipantDef, Pageable, Page, RoomProgressReport } from 'src/app/model/server';
import { Observable, of } from 'rxjs';
import { RoomProgressProvider } from '../../service/helpers/progress-history-list.provider';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { Constants } from 'src/app/service/constants';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
  styleUrls: ['./room-details.component.css']
})
export class RoomDetailsComponent implements OnInit {

  room: RoomDef;
  teacherParti: ParticipantDef;
  studentsPartis: ParticipantDef[];
  historyLength = 0;
  historyDataProvider: RoomProgressProvider = null;
  progress: RoomProgressReport;

  @Input()
  set roomUuid(roomUuid: string) {
    this.loadRoomData(roomUuid);
  }

  constructor(private serverRest: ServerRestService,
              private searchingContext: SearchingContextService) { }

  loadRoomData(roomUuid: string) {
    this.serverRest.getRoom(roomUuid).pipe(
      tap( room => this.storeRoomData(room)),
      switchMap( room => this.serverRest.getRoomProgress(roomUuid)),
      tap( progress => this.progress = progress)
    ).subscribe(
      () => {
        this.restoreRoomProgressSearch();
      }
    );
  }

  restoreRoomProgressSearch() {
    const contextPath = `/rooms/${this.roomUuid}/progress-history`;
    this.searchingContext.getOrCreateContext<Pageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new Pageable(0, Constants.SmallPageSize, 'createDate,desc')))
    ).subscribe(initialPageable =>
        this.historyDataProvider = new RoomProgressProvider(
          this.room.uuid,
          contextPath,
          this.searchingContext,
          this.serverRest,
          initialPageable
        )
      );

  }

  getRoomName() {
    if (!this.room) {
      return '';
    }
    return this.room.name;
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  createLink(parti: ParticipantDef) {
    if (!parti) {
      return '';
    }
    const origin = window.location.origin;
    if (parti.role === 'Teacher') {
      return `${origin}/participants/${parti.uuid}/teacher-room`;
    } else {
      return `${origin}/participants/${parti.uuid}/student-room`;
    }
  }

  storeRoomData(room: RoomDef): void {
    this.room = room;
    this.teacherParti = room.participants.find ( p => p.role === 'Teacher');
    this.studentsPartis = room.participants.filter ( p => p.role === 'Student');
  }

  getTeacherName() {
    if (!this.teacherParti) {
      return '';
    }
    return this.teacherParti.name;
  }

  getCreateDate() {
    return this.room.createDate;
  }

  getTimerStartDate() {
    return this.room.timerStartDate;
  }

  getTotalTime() {
    return Math.round(this.room.totalTime / 60000);
  }

  getLessonStartDate() {
    return this.room.lessonStartDate;
  }

  getPCreated() {
    return this.room.provisioning.created;
  }

  getPUpdated() {
    return this.room.provisioning.updated;
  }
  getPDuration() {
    return Math.round(this.room.provisioning.duration / 60);
  }
  getPEndTime() {
    return this.room.provisioning.endTime;
  }
  getPCStatus() {
    return this.room.provisioning.status;
  }
  ngOnInit() {
  }

}
