<div class="toolbox" [ngClass]="{show: isShowToolbox()}">
  <div>
    <a class="charts-close" tabindex="" (click)="switchToolbox()"><i class="fas fa-times"></i></a>
    <ul class="nav nav-tabs justify-content-end">
      <li class="nav-item cursor-pointer">
        <a class="nav-link" tabindex="" [ngClass]="{active : isToolset('Chat')}" (click)="setToolset('Chat')" translate>room.toolbox.chat</a>
      </li>
    </ul>
  </div>
  <div [hidden]="!isToolset('Chat')" class="chat-container">
    <app-chat [visible]="isShowToolbox() && isToolset('Chat')"  *ngIf="selfData" [room]="selfData.roomDef" [participantUuid]="selfData.participantDef.uuid"></app-chat>
  </div>
</div>
