import { Injectable } from '@angular/core';
import {LoggerService} from "./logger.service";

export enum Browser {
  Opera,
  MSIE,
  Edge,
  Safari,
  Chrome,
  Firefox,
  Unknown,
  iOsSafari
}

export class SystemInfo {
  osName: string;
  browser: Browser;
  version: string;
  major: number;
  screenSize: string;
  navigatorVersion: string;
  navigatorAgent: string;
  navigatorApp: string;
  mobile: boolean;
  osVersion: string;
  appVersion: string;
}

export class BrowserAudit {
  unsupportedBrowser = false;
  wrongBrowser = false;
  supportedBrowsers: Array<Browser>;
  wrongBrowserVersion = false;
  minBrowserVersion: number;
  systemInfo: SystemInfo;
  errorDescription: string;
}



@Injectable({
  providedIn: 'root'
})
export class BrowserVersionService {

  constructor(
    private l: LoggerService
  ) { }

  public auditSystem() {
    const systemInfo = this.getBrowserInfo();
    const audit = new BrowserAudit();
    audit.systemInfo = systemInfo;
    // on iOS only Safari >= 11 is supported
    if (systemInfo.osName === 'iOS') {
      audit.supportedBrowsers = [Browser.iOsSafari, Browser.Chrome];
      if (systemInfo.browser !== Browser.Safari ) {
        if (systemInfo.browser === Browser.Chrome) {
          if (systemInfo.major < 14) {
            audit.wrongBrowserVersion = true;
            audit.errorDescription = "On iOS Chrome > 14 or Safari > 10 are supported";
          }
        } else {
          audit.wrongBrowser = true;
          audit.unsupportedBrowser = true;
          audit.errorDescription = "On iOS Chrome > 14 or Safari > 10 are supported";
        }
      } else {
        if (systemInfo.major < 11) {
          audit.wrongBrowserVersion = true;
          audit.unsupportedBrowser = true;
          audit.minBrowserVersion = 11;
          audit.errorDescription = "On iOS Chrome > 14 or Safari > 10 are supported";
        }
      }
    } else {
      // on other systems
      // chrome supported from 56
      if (systemInfo.browser === Browser.Chrome ) {
        if (systemInfo.major < 56) {
          audit.wrongBrowserVersion = true;
          audit.minBrowserVersion = 56;
          audit.errorDescription = 'Chrome verions > 55 is supported';
          audit.supportedBrowsers = [ Browser.Chrome ];
        }
      } else if (systemInfo.browser === Browser.Firefox ) {
        if ( systemInfo.major < 59 ) {
          audit.wrongBrowserVersion = true;
          audit.minBrowserVersion = 59;
          audit.errorDescription = 'Firefox version > 58 is supported';
          audit.supportedBrowsers = [ Browser.Firefox ];
        }
      } else if (systemInfo.browser === Browser.Safari ) {
        if (systemInfo.major < 11) {
          audit.wrongBrowserVersion = true;
          audit.unsupportedBrowser = true;
          audit.minBrowserVersion = 11;
          audit.errorDescription = 'Safari will not work in version < 11';
        }
      } else {
        audit.wrongBrowser = true;
        audit.supportedBrowsers = [ Browser.Chrome, Browser.Firefox ];
        audit.errorDescription = 'Your browser is not supported';
      }
    }
    return audit;
  }


  public getBrowserInfo(): SystemInfo {
    const audit = new SystemInfo();

    if (screen.width) {
      const width = (screen.width) ? screen.width : '';
      const height = (screen.height) ? screen.height : '';
      audit.screenSize = '' + width + ' x ' + height;
    }

    audit.navigatorVersion = navigator.appVersion;
    audit.navigatorAgent = navigator.userAgent;
    audit.navigatorApp = navigator.appName;
    audit.version = navigator.appVersion;
    audit.major = parseInt( String(parseFloat(audit.version)), 10);

    const nAgt = audit.navigatorAgent;

    const opera = nAgt.indexOf('Opera');
    const opr = nAgt.indexOf('OPR');
    const edge = nAgt.indexOf('Edge');
    const mise = nAgt.indexOf('MSIE');
    const chrome = nAgt.indexOf('Chrome');
    const safari = nAgt.indexOf('Safari');
    const firefox = nAgt.indexOf('Firefox');
    const trident = nAgt.indexOf('Trident/');

    if (opera >= 0) {
      audit.browser = Browser.Opera;
      audit.version = nAgt.substring( opera + 6);
      const evidentVersion = nAgt.indexOf('Version');
      if (evidentVersion >= 0) {
        audit.version = nAgt.substring(evidentVersion + 8);
      }
    } else if (opr >= 0 ) {
      audit.browser = Browser.Opera;
      audit.version = nAgt.substring(opr + 4);
    } else if (edge >= 0) {
      audit.browser = Browser.Edge;
      audit.version = nAgt.substring(edge + 5);
    } else if (mise >= 0) {
      audit.browser = Browser.MSIE;
      audit.version = nAgt.substring(mise + 5);
    } else if (audit.navigatorApp === 'Netscape' && trident >= 0) {
      audit.browser = Browser.MSIE;
      audit.version = nAgt.substring(trident + 5);
      const evidentVersion = nAgt.indexOf('rv:');
      if (evidentVersion >= 0) {
        audit.version = nAgt.substring(evidentVersion + 3);
      }
    } else if ( chrome >= 0 ) {
      audit.browser = Browser.Chrome;
      audit.version = nAgt.substring(chrome + 7);
    } else if (safari >= 0) {
      audit.browser = Browser.Safari;
      const verOffset = nAgt.indexOf('Version');
      if (verOffset !== -1) {
        audit.version = nAgt.substring(verOffset + 8);
      }

      if (nAgt.indexOf('CriOS') !== -1) {
        // Chrome on iPad spoofing Safari...correct it.
        audit.browser = Browser.Chrome;
      }
    } else if ( firefox >= 0 ) {
      audit.browser = Browser.Firefox;
      audit.version = nAgt.substring(firefox + 8);
    } else {
      audit.browser = Browser.Unknown;
      audit.version = '0.0';
    }

    let ix = audit.version.indexOf(';');
    if (ix !== -1) {
      audit.version = audit.version.substring(0, ix);
    }

    ix = audit.version.indexOf(' ');
    if (ix !== -1) {
      audit.version = audit.version.substring(0, ix);
    }

    audit.major = parseInt('' + audit.version, 10);
    if (isNaN(audit.major)) {
      audit.version  = '' + parseFloat(navigator.appVersion);
      audit.major = parseInt(navigator.appVersion, 10);
    }

    // trim version

    audit.major = parseInt('' + audit.version, 10);
    if (isNaN(audit.major)) {
      audit.version = '' + parseFloat(navigator.appVersion);
      audit.major = parseInt(navigator.appVersion, 10);
    }

    audit.mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigator.appVersion);

    let os = '-';
    const clientStrings = [
      {s: 'Windows 3.11', r: /Win16/},
      {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
      {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
      {s: 'Windows 98', r: /(Windows 98|Win98)/},
      {s: 'Windows CE', r: /Windows CE/},
      {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
      {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
      {s: 'Windows Server 2003', r: /Windows NT 5.2/},
      {s: 'Windows Vista', r: /Windows NT 6.0/},
      {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
      {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
      {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
      {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
      {s: 'Windows ME', r: /Windows ME/},
      {s: 'Android', r: /Android/},
      {s: 'Open BSD', r: /OpenBSD/},
      {s: 'Sun OS', r: /SunOS/},
      {s: 'Linux', r: /(Linux|X11)/},
      {s: 'iOS', r: /(iPhone|iPad|iPod)/},
      {s: 'Mac OS X', r: /Mac OS X/},
      {s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
      {s: 'QNX', r: /QNX/},
      {s: 'UNIX', r: /UNIX/},
      {s: 'BeOS', r: /BeOS/},
      {s: 'OS/2', r: /OS\/2/},
      {s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];

    for (const browserEntry of clientStrings) {
        if (browserEntry.r.test(nAgt)) {
            os = browserEntry.s;
            break;
        }
    }

    let osVersion = '-';

    if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (1[\d][\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        const verResult = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
        osVersion = verResult[1] + '.' + verResult[2] + '.' + (verResult[3] || '0');
        break;

    }

    audit.osName = os;
    audit.osVersion = osVersion;
    //audit.appVersion = packageInfo.version;

    return audit;
  }
  public browserDetails(): {
    browserName: string,
    fullVersion: string,
    majorVersion: number} {
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    // tslint:disable-next-line:one-variable-per-declaration
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    // tslint:disable-next-line:no-conditional-assignment
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      // tslint:disable-next-line:no-conditional-assignment
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    // tslint:disable-next-line:no-conditional-assignment
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
      // tslint:disable-next-line:no-conditional-assignment
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
      // tslint:disable-next-line:no-conditional-assignment
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      // tslint:disable-next-line:no-conditional-assignment
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    // tslint:disable-next-line:no-conditional-assignment
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
      // tslint:disable-next-line:no-conditional-assignment
    } else if ( (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      // tslint:disable-next-line:no-conditional-assignment
      (verOffset = nAgt.lastIndexOf('/')) ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    // tslint:disable-next-line:no-conditional-assignment
    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    // tslint:disable-next-line:no-conditional-assignment
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion  = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    const result = {
      browserName,
      fullVersion,
      majorVersion
    };

    this.l.i('browser:', result);
    return result;
  /*  console.log(''
  + 'Browser name  = ' + browserName + '<br>'
  + 'Full version  = ' + fullVersion + '<br>'
  + 'Major version = ' + majorVersion + '<br>'
  + 'navigator.appName = ' + navigator.appName + '<br>'
  + 'navigator.userAgent = ' + navigator.userAgent + '<br>');
*/
  }
}
