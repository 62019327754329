import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  OnDestroy,
  NgZone,
  Output,
  EventEmitter
} from '@angular/core';
import { ParticipantData } from 'src/app/model/internal';
import {LoggerService} from "../../service/logger.service";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteAudioTrack, RemoteParticipant,
  RemoteVideoTrack,
  RemoteVideoTrackPublication
} from "twilio-video";
import {interval, Subscription, tap} from "rxjs";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'app-participant-video',
  templateUrl: './participant-video.component.html',
  styleUrls: ['./participant-video.component.css']
})
export class ParticipantVideoComponent implements OnInit, AfterViewInit, OnDestroy {

  mParticipantData: ParticipantData;
  videoStarted = false;
  videoTracksAttached = [];
  audioTracksAttached = [];
  videoElement: any;
  audioElement: any;
  _showVideo = true;
  _playAudio = true;
  private guardSubscription: Subscription;

  constructor(
    private l: LoggerService,
    private ngZone: NgZone
  ) {
    this.l.d("video component created");
  }

  @Input()
  set showVideo(value: boolean) {
    this._showVideo = value;
    this.syncTracks();
  }

  @Input()
  set playAudio(value: boolean) {
    this._playAudio = value;
    this.syncTracks();
  }

  @ViewChild('videoTarget', {static: false})
  videoTarget: ElementRef;

  @Input()
  set participantData(participantData: ParticipantData) {
    this.mParticipantData = participantData;
    this.l.d(`video component assigned for ${participantData.participantDef.name}`)
    this.syncTracks();
    this.listenForTrackChanges();
  }

  @Output()
  connectionIsWorking = new EventEmitter<boolean>();

  ngAfterViewInit(): void {
    this.syncTracks();
    this.listenForTrackChanges();
  }

  private trackSubscribedEventListener = (track) => {
    this.l.d("track is comming from subscription");
    this.attachTrack(track);
  }
  private disconnectedEventListener = () => this.stop();

  listenForTrackChanges() {
    // ensure executed only once and only if all necessary components are ready
    if (this.videoStarted || !this.videoTarget || !this.mParticipantData || !this.mParticipantData.participant) {
      return;
    }

    this.mParticipantData.participant.on('trackSubscribed', this.trackSubscribedEventListener);
    this.mParticipantData.participant.on('disconnected', this.disconnectedEventListener);
    this.videoStarted = true;
  }

  getTrackPositionInAttached(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    const isVideo = (track.kind === 'video');
    return isVideo ?
    this.videoTracksAttached.findIndex ( t => t.id === (track as any).id )
    : this.audioTracksAttached.findIndex( t => t.id === (track as any).id );
  }

  removeTrackFromAttached(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    if (!track) {
      return;
    }
    const isVideo = (track.kind === 'video');
    const pos = this.getTrackPositionInAttached(track);
    if (pos < 0) {
      return null;
    }
    if (isVideo) {
      this.videoTracksAttached.splice(pos, 1);
    } else {
      this.audioTracksAttached.splice(pos, 1);
    }
    return track;
  }

  pushTrackToAttached(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    if (this.getTrackPositionInAttached(track) >= 0 ) {
      return null;
    }
    const isVideo = (track.kind === 'video');
    if (isVideo) {
      this.videoTracksAttached.push(track);
    } else {
      this.audioTracksAttached.push(track);
    }
  }

  detachTrack(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    if (!track) {
      return;
    }
    const removed = this.removeTrackFromAttached(track);
    if (removed) {
      const isVideo = (track.kind === 'video');
      this.l.i(`detaching the track for ${this.mParticipantData.participantDef.name} [isVideo=${isVideo}]`);

      this.ngZone.run(() => {
        if (isVideo) {
          this.stopTrackGuarding(track);
          track.detach(this.videoElement);
          if (this.videoElement)
            this.videoElement.remove();
        } else {
          track.detach(this.audioElement);
          if (this.audioElement) this.audioElement.remove();
        }
      });
    }
  }

  stop() {
    this.l.i('stopping the component.');
    [...this.videoTracksAttached, ...this.audioTracksAttached].forEach ( t => this.detachTrack(t));
    try {
      this.mParticipantData.participant.removeListener('disconnected', this.disconnectedEventListener);
      this.mParticipantData.participant.removeListener('trackSubscribed', this.trackSubscribedEventListener);
    } catch (e) {
      console.log(e);
    }
  }

  syncTracks() {
    if (!this.videoTarget || !this.mParticipantData || !this.mParticipantData.participant) {
      return;
    }

    if (this.mParticipantData.participantDef.muted || !this._playAudio) {
      this.mParticipantData.participant.audioTracks.forEach(( publication: any, id: string) => {
        this.detachTrack(publication.track);
      });
    } else if (!this.mParticipantData.local) {
      this.mParticipantData.participant.audioTracks.forEach(( publication: any, id: string) => {
        this.attachTrack(publication.track);
      });
    }

    if (!this._showVideo) {
      this.mParticipantData.participant.videoTracks.forEach(( publication: RemoteVideoTrackPublication | LocalVideoTrackPublication, id: string) => {
        this.detachTrack(publication.track);
      });
    } else {
      this.mParticipantData.participant.videoTracks.forEach(( publication: RemoteVideoTrackPublication | LocalVideoTrackPublication, id: string) => {
        this.attachTrack(publication.track);
      });
    }
  }

  attachTrack(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    if (!track || !track.kind) {
      return;
    }

    if (this.getTrackPositionInAttached(track) >= 0 ) {
      return;
    }

    const isVideo = (track.kind === 'video');

    if (!isVideo && this.mParticipantData.local) {
      return;
    }
    /*if (this.mParticipantData.participantDef.muted && !isVideo) {
      return;
    }*/
    if (!this._showVideo && isVideo) {
      return;
    }
    if (!this._playAudio && !isVideo) {
      return;
    }
    this.l.i(`attaching the track for ${this.mParticipantData.participantDef.name} [video=${isVideo}]`);

    let element: any;

    this.ngZone.run(() => {
      if (isVideo) {
        const rvt = track as RemoteVideoTrack
        this.guardTrack(rvt);
        element = track.attach();
        this.videoElement = element;
        this.videoTracksAttached.push(track);
      } else {
        element = track.attach();
        this.audioElement = element;
        this.audioTracksAttached.push(track);
      }

      this.videoTarget.nativeElement.appendChild(element);
    })

  }

  ngOnDestroy(): void {
    this.stopGuardRoutine();
    this.stop();
  }


  ngOnInit() {
    this.startGuardRoutine();
  }



  private stopGuardRoutine() {
    if (this.guardSubscription == null) return;
    this.guardSubscription.unsubscribe();
    this.guardSubscription = null;
  }

  private startGuardRoutine() {
    if (this.guardSubscription != null) return;
    this.guardSubscription = interval(1000).pipe(
      take(3),
      tap( tickNumber => this.l.d(`guard tick - ${tickNumber}`)),
      map( _ => this.isRescueNeeded())
    ).subscribe({
      next: rescueNeeded => {
        this.connectionIsWorking.emit(!rescueNeeded);
        },
      complete: () => {this.guardSubscription = null}
    });
  }

  private tracksGuarded: Array<RemoteVideoTrack> = [];

  private guardTrack(rvt: RemoteVideoTrack) {
    this.tracksGuarded.push(rvt);
    this.l.d(`track added. Current guarded tracks size is: ${this.tracksGuarded.length}`)
  }

  private stopTrackGuarding(track: RemoteVideoTrack | LocalVideoTrack | RemoteAudioTrack | LocalAudioTrack) {
    this.tracksGuarded.splice(this.tracksGuarded.indexOf(track as RemoteVideoTrack),1);
    this.l.d(`track removed. Current guarded tracks size is: ${this.tracksGuarded.length}`);
  }

  private isRescueNeeded(): boolean {
    return this.tracksGuarded.some(track =>
      track.mediaStreamTrack.muted
    )
  }
}
