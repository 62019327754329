<div class="container py-5">
  <div *ngIf="hasData()">
    <h1>Hi <strong>{{gePartiName()}}</strong>,</h1>
    <p class="lead">
      Your lesson is planned at
      <strong>{{getPlannedDate() | date: 'HH:mm'}}</strong>
      on
      <strong>{{getPlannedDate() | date: 'EEEE (dd/MM)'}}</strong>.
    </p>
    <p class="lead">
      Group: {{getRoomName()}}
    </p>
    <div class="text-right" *ngIf="getNote('group_note') && getNote('schedule_note')">
      <h3 class="text-danger">
        You have notes for this lesson:
      </h3>
      <p class="small">
        <span *ngIf="getNote('group_note')"><strong>Note for the group:</strong> {{getNote('group_note')}}<br/></span>
        <span *ngIf="getNote('schedule_note')"><strong>Note for the lesson:</strong> {{getNote('schedule_note')}}</span>
      </p>
    </div>
    <div *ngIf="isOffline()">
      <p class="lead text-center">
        This lesson is taking place <strong>OFFLINE</strong>.<br/>
        <span *ngIf="!negativeTime">Lesson starts in:</span>
        <span *ngIf="negativeTime">The lesson started:</span>
      </p>
      <h1 class="text-center" [ngClass]="{'text-danger': negativeTime}">
        {{getJoinTime()}} <span  *ngIf="negativeTime">ago</span>
      </h1>
      <div class="text-center my-4">
        <a class="btn btn-lg btn-primary" [routerLink]="'/participants/' + participantUuid + '/teacher-room-closed'">Open Progress Form</a>
      </div>
    </div>
    <div *ngIf="!isOffline()">
      <p class="lead text-center">
        The Online Callan Classroom will automatically open here in
      </p>
      <h1 class="text-center">
        {{getJoinTime()}}
      </h1>
    </div>
  </div>

  <div *ngIf="!isOffline()">
  <h5 class="text-center">You may plan the lesson before it starts:</h5>
  <div class="text-right">
    <small class="text-muted" *ngIf="hasDirtyState()">
      <div class="spinner-grow spinner-grow-sm text-danger" role="status">
        <span class="sr-only">sending...</span>
      </div>
    </small>
    <small class="text-muted" *ngIf="!hasDirtyState()">saved</small>
  </div>

  <app-progress-block variant="pre" [progress]="progress" (progressChanged)="progressChanged($event)"></app-progress-block>
  </div>
  <div [hidden]="historyLength === 0">
    <div  class="mb-2">
      <h6>Previous lesson history:</h6>
      <app-progress-history-list (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
    </div>
  </div>
  <div class="py-4" *ngIf="!isOffline()">
    <h5>Useful classroom hotkeys:</h5>
    <ul class="list-unstyled">
      <li><strong>J</strong> - Jump to a different page</li>
      <li><strong>R</strong> - Jump and start reading</li>
      <li><strong>D</strong> - Jump and start dictation</li>
      <li><strong>B</strong> - Back to bookmark</li>
      <li><strong>C</strong> - Chart</li>
      <li><strong>Q</strong> - Quit Chart</li>
      <li><strong>T</strong> - Text chat</li>
      <li><strong>Space bar</strong> - Prompt a random student</li>
      <li><strong>Z</strong> - Zoom on chart</li>
      <li><strong>arrow Up / Down</strong> - change between pages</li>
      <li><strong>arrow Left / Right</strong> - change between charts</li>
      <li><strong>Esc</strong> - Exit fullscreen mode or close selected tool (toolbox, chart, chat or book)</li>
    </ul>
  </div>
  <div  *ngIf="!isOffline()">
    <h3 class="mb-4">Before the lesson:</h3>
    <h5>Check the Internet connection quality quality:</h5>
    <ul>
      <li>On mobile, turn on Wi-Fi.</li>
      <li>Stay close to the router.</li>
      <li>On your laptop or desktop computer, for best results, connect to the router with an ethernet cable.</li>
      <li>Make sure that your Internet connection is fast and reliable.</li>
    </ul>
    <h5>Check your device:</h5>
    <ul class="mb-5">
      <li>User the newest version of the browser: Chrome, Safari, Firefox or Edge.</li>
      <li>On iPhones or IPads you must use Safari.</li>
      <li>Don't use default Android browsers provided by the phone manufacturer, as they don’t support the camera. On Xiaomi and Huawei phones, install Chrome internet browser. Don't open the classroom inside other apps, like Facebook, WhatsApp or Instagram.</li>
      <li>Make sure that your desktop is not overloaded. Close all other applications, windows or tabs.</li>
      <li>Your browser may ask for permission to use the camera and microphone before the lesson begins. They are necessary to join the video lesson, so make sure to give permission.</li>
    </ul>
    <h3 class="mb-4">During the lesson:</h3>
    <ul>
      <li>Don't change to or open any other windows or tabs. Make sure this is the only application open on your device.</li>
      <li>If you are having problems with your connection, hide the videos of the other students at the bottom of your screen.</li>
      <li>If you have any temporary connection problems, refresh the page.</li>
    </ul>
    <hr/>
  </div>
</div>
