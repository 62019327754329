import {Log} from "twilio-video";
import {Level} from "../app/service/helpers/LoggerLevels";

export const environment = {
  production: false,
  /*
  apiEndpoint: 'https://localhost:8443/api',
  socketEndpoint: 'https://localhost:8443/room-websocket'
  */
 apiEndpoint: 'https://api.video.stage.callanworks.co.uk/api',
 socketEndpoint: 'wss://api.video.stage.callanworks.co.uk/room-websocket',
  authClientId: 'asfdah09a8ywhefasbdcyugc893gcao0yeghfh9ps08cgbasiulw9ro0tg',
  authEndpoint: 'https://casa-dev.callanonline.com',
  serverBase: 'https://video.callanworks.co.uk',
  envName: 'stage',
  s3bucket: 'https://s3.eu-central-1.amazonaws.com/assets.video.callanonline.com',
  allowContextMenu: false,
  twilioLoggingLevel: "debug" as Log.LogLevelDesc,
  logging: Level.DEBUG
};
