import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, take, tap, timeoutWith} from "rxjs/operators";
import {GroupsDataProvider, GroupsPageable} from "../../components/room-templates-list/room-templates-list.provider";
import {of, Subject} from "rxjs";
import {Constants} from "../../service/constants";
import {SearchingContextService} from "../../service/searching-context.service";
import {ServerRestService} from "../../service/server-rest.service";
import {ListEvent} from "../../components/room-templates-list/room-templates-list.component";

@Component({
  selector: 'app-school-groups-page',
  templateUrl: './school-groups-page.component.html',
  styleUrls: ['./school-groups-page.component.css']
})
export class SchoolGroupsPageComponent implements OnInit {
  schoolId: number;
  totalGroupsNumber: number = null;
  groupsSearching = new Subject<GroupsPageable>();
  mGroupsSearch = '';
  mGroupsSorting = 'created,desc';
  groupsDataProvider: GroupsDataProvider;

  set groupsSorting(term: string) {
    this.mGroupsSorting = term;
    this.updateSearchConditions();
  }

  get groupsSorting() {
    return this.mGroupsSorting;
  }

  get groupsSearch() {
    return this.mGroupsSearch;
  }

  set groupsSearch(term: string) {
    this.mGroupsSearch = term;
    this.updateSearchConditions();
  }

  constructor(
    activatedRoute: ActivatedRoute,
    private searchingContext: SearchingContextService,
    private rest: ServerRestService,
    private router: Router
  ) {
    activatedRoute.parent.paramMap.subscribe(params => {
      this.setupSchool(Number(params.get('schoolId')));
      this.restoreGroupsSearchingConditions();
    });
    this.groupsSearching.pipe(
      debounceTime(600)
    ).subscribe( pageable =>
      this.groupsDataProvider = new GroupsDataProvider(
        `/schools/${this.schoolId}/groups`,
        pageable,
        this.searchingContext,
        this.rest,
        this.schoolId)
    );
  }

  restoreGroupsSearchingConditions() {
    const contextPath = `/schools/${this.schoolId}/groups`;
    this.searchingContext.getOrCreateContext<GroupsPageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new GroupsPageable(this.groupsSearch, 0, Constants.DefultPageSize, this.groupsSorting))),
      tap( (pageable: GroupsPageable) => {
        this.mGroupsSearch = pageable.queryTerm;
        this.mGroupsSorting = pageable.sort;
      })
    ).subscribe(
      initialPageable => this.groupsDataProvider = new GroupsDataProvider(
        contextPath,
        initialPageable,
        this.searchingContext,
        this.rest,
        this.schoolId)
    );
  }

  setupSchool(schoolId: number) {
    this.schoolId = schoolId;
  }

  updateSearchConditions() {
    this.groupsSearching.next(new GroupsPageable(this.groupsSearch, 0, Constants.DefultPageSize, this.groupsSorting));
  }

  ngOnInit() {
  }

  setGroupCount(totalElements: number) {
    this.totalGroupsNumber = totalElements;
  }

  onTemplatesEvent(event: ListEvent) {
    if (event.event === ListEvent.EVENT_ENTER) {
      this.router.navigate(['manager', this.schoolId, 'templates', event.template.id]);
    }
  }


}
