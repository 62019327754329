
<div class="container">
    <div class="row mb-4">
            <div class="col-auto">
                    <img src="/assets/img/logo.png" class="img-fluid" style="height: 64px;">
            </div>
            <div class="col">
                    <h2 class="mt-2">Callan Online Classroom</h2>
            </div>
    </div>
    <app-room-details [roomUuid]="roomUuid"></app-room-details>
    
    
</div>


