import { Component, OnInit } from '@angular/core';
import { CurrentSchoolService } from 'src/app/service/current-school.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-layout',
  templateUrl: './school-layout.component.html',
  styleUrls: ['./school-layout.component.css']
})
export class SchoolLayoutComponent implements OnInit {

  constructor(schoolIdService: CurrentSchoolService,
              activatedRoute: ActivatedRoute) {
    activatedRoute.paramMap.subscribe(params => schoolIdService.currentSchoolId.next(Number(params.get('schoolId'))));
  }

  ngOnInit() {
  }

}
