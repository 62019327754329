<app-modal-v2 #dialog2>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" translate>room.crash-report.title</h5>
      <button type="button" class="close" (click)="dialog2.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form" (ngSubmit)="submitAnIssue()">
        <div class="form-group">
          <label translate>room.crash-report.label</label>
          <textarea class="form-control"
                    name="feedback"
                    [(ngModel)]="comment"
          ></textarea>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-secondary mr-2 text-capitalize" (click)="dialog2.hide()" type="button" translate>general.cancel</button>
          <button class="btn btn-primary text-capitalize" type="submit" translate>general.send</button>
        </div>
      </form>
    </div>
  </div>
</app-modal-v2>
