<ul class="list-group data-list-group">
    <li class="list-group-item"  *ngFor="let template of templates">
        <h5>{{getName(template)}}</h5>
        <span>Teacher: {{getTeacherName(template)}}</span><br>
        <span>Students: {{getNbOfStudents(template)}}</span>
        <div class="btn-group float-right">
            <button class="btn btn-sm btn-primary " (click)="onEnter(template)">Edit & Schedule</button>
            <button *ngIf="showOpenClassroom" class="btn btn-sm btn-danger " (click)="onStart(template)">Open classroom</button>

        </div>
    </li>
    <li *ngIf="hasNext()" class="list-group-item text-center">
        <button class="btn btn-link" (click)="showMore()" ><i class="fa fa-chevron-down"></i></button>
    </li>
</ul>
<div  class="text-center my-4" *ngIf="isEmpty()">Result are emtpy for that query...</div>
<!--<table class="table">
    <thead>
        <th>Name</th>
        <th>Teacher</th>
        <th>Students</th>
        <th>Created</th>
        <th></th>
    </thead>
    <tbody>
        <tr *ngFor="let template of templates">
            <td>{{getName(template)}}</td>
            <td>{{getTeacherName(template)}}</td>
            <td>{{getNbOfStudents(template)}}</td>
            <td>{{getCreateDate(template) | date: 'dd MMM yyyy HH:mm'}}</td>
            <td>
                <button class="btn btn-sm btn-danger mx-1" (click)="onStart(template)"><i class="fa fa-play"></i></button>
                <button class="btn btn-sm btn-primary mx-1" (click)="onEnter(template)"><i class="fa fa-chevron-right"></i></button>
            </td>
        </tr>
    </tbody>
</table>
-->
