import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BooksRestService} from "../../service/books-service";
import {
  BookPageSelection,
  BookRendererControllerService,
  BookSelection
} from "../../service/book-renderer-controller.service";
import {Subscription} from "rxjs";
import {
  Bookmark, Dict,
  HotkeyEvent,
  HotkeysService, JumpTo,
  OpenChartOnFocusedBreakpoint,
  OpenFirstChart, Reading, ScaleBookFontSize,
  SwitchBookPage
} from "../../service/hotkeys.service";
import {BookmarksService, JumpKind} from "../../service/bookmarks.service";

@Component({
  selector: 'app-book-controller',
  templateUrl: './book-controller.component.html',
  styleUrls: ['./book-controller.component.css']
})
export class BookControllerComponent implements OnInit, OnDestroy {
  private lastPageRequested: number = null;

  @Input()
  participantUuid: string;

  _currentPage = 0
  bookPageChangeSubscription: Subscription;
  private selection: BookPageSelection;
  private pageShared: BookPageSelection;
  sharedPageSubscription: Subscription;
  set currentPage(value:number) {
    if (value < 0) {
      value = 0
    }
    this._currentPage = value
  }


  isPageNumberChanged() {
    return this._currentPage !== this.lastPageRequested;
  }

  get currentPage() {
    return this._currentPage
  }

  private rendererSubscription: Subscription;
  constructor(private rest: BooksRestService,
              private bookRendererControllerService: BookRendererControllerService,
              private hotkeysService: HotkeysService,
              private bookmarks: BookmarksService
              ) {
    this.bookPageChangeSubscription = bookRendererControllerService.currentBookPageSelection.subscribe(
      selection => {
        if (selection) {
          this.selection = selection;
          this.lastPageRequested = selection.page;
          if (selection.page !== this._currentPage) {
            this._currentPage = selection.page;
          }
        }
      }
    );
    this.sharedPageSubscription = this.bookRendererControllerService.bookPageShared
      .subscribe(pageShared =>
      this.pageShared = pageShared);
    this.hotkeysService.hotkeyEventSubject.subscribe( event => this.handleHotKeyEvent(event));
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.rendererSubscription) this.rendererSubscription.unsubscribe();
    if (this.bookPageChangeSubscription) this.bookPageChangeSubscription.unsubscribe();
    if (this.sharedPageSubscription) this.sharedPageSubscription.unsubscribe();
  }

  onClose() {
    this._currentPage = 0;
    this.bookRendererControllerService.close();
  }

  onClearShare() {
    this.bookRendererControllerService.clearBookShare(this.participantUuid);
  }

  hasPageRequested() {
    return this.lastPageRequested;
  }

  onMoveBreakpoint(offset: number) {
    if (!this.hasPageRequested()) return;
    if (!this.bookRendererControllerService.moveBreakpoint(offset)) {
      if (!this.findBookByPageNumberForCurrentCourse(this.lastPageRequested + offset)) return;
      if (offset > 0) this.bookRendererControllerService.moveToFirstBreakpointAfterPageLoad();
      else if (this.lastPageRequested + offset > 0) {
        this.bookRendererControllerService.moveToLastBreakpointAfterPageLoad();
      }
      this.onMovePage(offset);
    }
  }

  onMovePage(offset: number) {
    if (this.hasPageRequested() && this.lastPageRequested + offset > 0) {
      const differentBookSelection = this.prepareSelectionIfBookHasToBeChanged(this.lastPageRequested + offset);
      if (differentBookSelection) {
        this.bookRendererControllerService.openBookPage(differentBookSelection);
      } else {
        this.bookRendererControllerService.jumpToPage(this.lastPageRequested + offset);
      }
    }
  }

  private handleHotKeyEvent(event: HotkeyEvent) {
    if (event instanceof SwitchBookPage) {
      //this.onMovePage(event.offset);
      this.onMoveBreakpoint(event.offset);
    } else if (event instanceof OpenChartOnFocusedBreakpoint) {
      this.callForNextChartOnBreakpoint();
    }
  }

  hasPageShared() {
    return this.pageShared;
  }

  getSharedPageNumber() {
    return this.pageShared.page;
  }

  private callForNextChartOnBreakpoint() {
    if (!this.bookRendererControllerService.switchChartOnFocusedBreakpoint()) {
      this.hotkeysService.hotkeyEventSubject.next(new OpenFirstChart());
    }
  }

  private prepareSelectionIfBookHasToBeChanged(targetPageNumber: number) {
    const targetBook = this.findBookByPageNumberForCurrentCourse(targetPageNumber);
    if (targetBook && targetBook != this.selection.book.stage) {
      return new BookPageSelection(new BookSelection(this.selection.book.course, targetBook), targetPageNumber, 1);
    }
    return null;
  }

  private findBookByPageNumberForCurrentCourse(targetPageNumber: number) {
    const foundInCurrentBook = this.selection.book.stage["content-items"].find( item => item["page-from"] <= targetPageNumber && item["page-to"] >= targetPageNumber);

    if (foundInCurrentBook) return this.selection.book.stage;
    if (this.selection.book.stage.code.endsWith("exams")) return null;

    return this.selection.book.course.stages.find(stage => stage["content-items"].find(item => item["page-from"] <= targetPageNumber && item["page-to"] >= targetPageNumber));
  }

  isRegularBookmark() {
    return this.bookmarks.hasBookmark() && this.bookmarks.getBookmarkMode() === JumpKind.TEXT;
  }

  isActionBookmark() {
    return this.bookmarks.hasBookmark() && this.bookmarks.getBookmarkMode() !== JumpKind.TEXT;
  }

  getActionName() {
    return this.bookmarks.getBookmarkMode().actionName;
  }

  openBookmark() {
    this.hotkeysService.hotkeyEventSubject.next(new Bookmark());
  }

  getBookmarkPage() {
    return this.bookmarks.getBookmarkPage();
  }

  openJump() {
    this.hotkeysService.hotkeyEventSubject.next(new JumpTo());
  }

  openReading() {
    this.hotkeysService.hotkeyEventSubject.next(new Reading());
  }

  openDictation() {
    this.hotkeysService.hotkeyEventSubject.next(new Dict());
  }

  increaseFont() {
    this.hotkeysService.hotkeyEventSubject.next(new ScaleBookFontSize(1));
  }

  decreaseFont() {
    this.hotkeysService.hotkeyEventSubject.next(new ScaleBookFontSize(-1));
  }
}
