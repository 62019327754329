<div class="toolbox" [ngClass]="{show: isShowToolbox()}">
    <div>
        <a class="charts-close" tabindex="" (click)="switchToolbox()"><i class="fas fa-times"></i></a>
        <ul class="nav nav-tabs justify-content-end">
            <li class="nav-item cursor-pointer">
              <a class="nav-link" tabindex="" [ngClass]="{active : isToolset('Charts')}" (click)="setToolset('Charts')">Charts</a>
            </li>
            <li class="nav-item cursor-pointer">
              <a class="nav-link" tabindex="" [ngClass]="{active : isToolset('Lesson')}" (click)="setToolset('Lesson')">Lesson</a>
            </li>
            <li class="nav-item cursor-pointer">
              <a class="nav-link" tabindex="" [ngClass]="{active : isToolset('Chat')}" (click)="setToolset('Chat')">Chat</a>
            </li>
        </ul>
    </div>
    <div *ngIf="isToolset('Charts')">
        <div class="chart-select form-group">
            <select class="form-control" name="charts" [(ngModel)]="chartSet">
                    <option *ngFor="let cs of chartSets" [value]="cs.chartSetId">{{cs.chartSetName}}</option>
            </select>
        </div>
        <app-flipchart-list [chartSet]="chartSet" (selected)="selectChart($event)"></app-flipchart-list>
    </div>
    <div *ngIf="isToolset('Lesson')">
        <app-teacher-lesson-progress [participantUuid]="getSelfUuid()" short="true"></app-teacher-lesson-progress>
    </div>
    <div [hidden]="!isToolset('Chat')" class="chat-container">
      <app-chat [visible]="isShowToolbox() && isToolset('Chat')"  *ngIf="selfData" [room]="selfData.roomDef" [participantUuid]="selfData.participantDef.uuid"></app-chat>
    </div>
</div>
