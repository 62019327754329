
/**
 * Token data but without token content - for
 * security reason if app wants to know about authorization details
 * but is not engouth trusted to show the token content
 */
export class AuthorizationData {
    email: string;
    accountId: number;
    personId: number;
    expiryDate: number;
  }

export class TokenValidator {
    private static timeMargin = 1000 * 60 * 60;

    static isValid(authData: AuthorizationTokenData) {
        return authData != null && authData.expiryDate &&
        authData.expiryDate > new Date().getTime() + this.timeMargin;
      }
  }
/**
 * extendend token data - containing the token content
 */
export class AuthorizationTokenData extends AuthorizationData {
  token: string;
}
