<div class="d-flex vh-100">
  <div class="m-auto">
    <div class="text-white text-center bg-primary rounded-lg p-5 w-50-vw">
      <h4 class="mb-3">We're moving to callan.app</h4>
      <p>You'll be redirected in</p>
      <div class="d-flex justify-content-center align-items-center my-5">
        <h3>{{sec}}</h3><small class="text-info ml-2">sec.</small>
      </div>
      <button class="btn btn-outline-light rounded-pill d-flex mx-auto py-2 px-3">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m480-336 144-144-144-144-51 51 57 57H336v72h150l-57 57 51 51Zm0 240q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
        <span class="ml-2" (click)="redirect()">Go there now</span>
      </button>
    </div>
  </div>
</div>
