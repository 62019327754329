export enum ContentItemType {
  DICT = "DICT",
  EXAM = "EXAM",
  TEXT = "TEXT"
}

export class ContentItem {
  name: string;
  'page-from': number;
  'page-to': number;
  id: string;
  type: ContentItemType

}

export class Stage {
  code: string;
  name: string;
  'short-name': string;
  designation: string;
  'content-items': ContentItem[];
  continuous: boolean;
}

export class Course {
  courseName: string;
  code: string;
  stages: Stage[];
}
