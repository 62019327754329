import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {RoomTemplate, Page, TemplateParticipant} from 'src/app/model/server';
import { tap } from 'rxjs/operators';
import { GenericDataProvider } from 'src/app/model/internal';
import { GroupsDataProvider } from './room-templates-list.provider';



export class ListEvent {
  public static EVENT_ENTER = 'enter';
  public static EVENT_START = 'start';
  constructor(public event: string, public template: RoomTemplate<TemplateParticipant>) {}
}

@Component({
  selector: 'app-room-templates-list',
  templateUrl: './room-templates-list.component.html',
  styleUrls: ['./room-templates-list.component.css']
})
export class RoomTemplatesListComponent implements OnInit {
  templates: RoomTemplate<TemplateParticipant>[];
  lastPage: Page<RoomTemplate<TemplateParticipant>>;

  @Output()
  listEvent = new EventEmitter<ListEvent>();
  @Output()
  count = new EventEmitter<number>();
  mDataProvider: GroupsDataProvider;

  @Input()
  showOpenClassroom = true;

  @Input()
  set dataProvider(p: GroupsDataProvider) {
    this.mDataProvider = p;
    this.clearResults();
    if (p) {
      this.refreshData();
    }
  }

  get dataProvider() {
    return this.mDataProvider;
  }

  constructor() {
  }

  clearResults(): void {
    this.templates = [];
    this.lastPage = null;
  }

  hasNext() {
    return this.lastPage && !this.lastPage.last;
  }

  showMore() {
    if (this.hasNext()) {
      this.dataProvider.nextPage();
      this.refreshData();
    }
  }

  refreshData() {
    this.dataProvider.load().pipe(
      tap( page => this.storePage(page))
    ).subscribe();
  }

  storePage(page: Page<RoomTemplate<TemplateParticipant>>) {
    this.templates = this.templates.concat(page.content);
    this.lastPage = page;
    if (this.dataProvider.isTermEmpty() && page.totalElements === 0) {
      this.count.emit(-1);
    } else {
      this.count.emit(page.totalElements);
    }
  }

  isEmpty() {
    return this.lastPage && this.lastPage.numberOfElements === 0;
  }

  onStart(template: RoomTemplate<TemplateParticipant>) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_START, template));
  }

  onEnter(template: RoomTemplate<TemplateParticipant>) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_ENTER, template));
  }

  getName(template: RoomTemplate<TemplateParticipant>) {
    return template.details.name;
  }

  getTeacherName(template: RoomTemplate<TemplateParticipant>) {
    const teacher = template.participants.find( p => p.details.role = 'Teacher');
    let email = teacher.details.email;
    if (email == null) email = "empty"
    return `${teacher.details.name} (${email})`;
  }

  getNbOfStudents(template: RoomTemplate<TemplateParticipant>) {
    return template.participants.length - 1;
  }

  getCreateDate(template: RoomTemplate<TemplateParticipant>) {
    return template.created;
  }

  ngOnInit() {
  }

}
