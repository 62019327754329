import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ChartEntry} from "../../model/server";
import {FlipChartDefinitionsService} from "../../service/flipchart-definitions";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-flipchart-list',
  templateUrl: './flipchart-list.component.html',
  styleUrls: ['./flipchart-list.component.css']
})
export class FlipchartListComponent implements OnInit {

  charts: Array<ChartEntry>;

  constructor(private flipChartDefinitions: FlipChartDefinitionsService) {}

  mChartSet: string;

  @Input()
  set chartSet(chartSet: string) {
    this.mChartSet = chartSet;
    this.updateChartSet();
  }

  @Output()
  selected = new EventEmitter<ChartEntry>();

  updateChartSet() {
    if (!this.mChartSet) {
      return;
    }
    this.flipChartDefinitions.getChartSets().pipe(take(1)).subscribe(
      chartSets => {
        this.charts = chartSets.find ( chart => chart.chartSetId === this.mChartSet).charts;
      }
    );
  }

  getChartName(entry: ChartEntry) {
    return entry.title;
  }

  getChartImage(entry: ChartEntry) {
    if (!entry || !entry.imageName) return '';
    return `${environment.s3bucket}/default-charts/${entry.imageName}/chart.png`;
  }

  onSelected(entry: ChartEntry) {
    console.log('selected');
    this.selected.emit(entry);
  }

  ngOnInit() {
  }
}
