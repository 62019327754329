<nav *ngIf="data" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page"><a routerLink="/manager/{{schoolId}}/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item" aria-current="page"><a routerLink="/manager/{{schoolId}}/templates/{{getTemplateId()}}">Group <strong>{{getGroupName()}}</strong></a></li>
    <li class="breadcrumb-item" aria-current="page">Schedule at {{getScheduleTime() | date: 'dd MMM HH:mm'}}</li>
  </ol>
</nav>
<div class="card" *ngIf="data && isModifiable()">
  <div class="card-header">
    <button type="button" class="btn btn-link mr-1 mt-n1" (click)="onBack()">
      <i class="fa fa-chevron-left"></i>
    </button>
    Schedule for {{getScheduleName()}}
    <div class="btn-group float-right mt-n1">
      <button type="button" class="btn btn-link text-danger mr-1" (click)="showDeleteConfirm()" *ngIf="isModifiable()"><i class="fa fa-trash" title="Delete the event" data-toggle="tooltip"></i></button>
      <button type="button" class="btn btn-link mr-1" (click)="showOpenConfirm()" *ngIf="isModifiable()"><i class="fa fa-play" title="Start schedule event now" data-toggle="tooltip"></i></button>
    </div>
  </div>
  <div class="card-body">
    <div class="alert alert-warning" *ngIf="isEditForbidden()">
      <strong>You can't edit this schedule.</strong> This schedule or its group uses additional features which are supported in <strong>admin service only</strong>. Please switch to the
      <a href="https://admin.callanonline.com">admin.callanonline.com</a> service, where you will find
      all video functionality with better support.
    </div>
    <form  #f="ngForm" (submit)="f.form.valid && onSave()">
      <div class="form-row form-group">
        <label class="col-form-label col-12 col-sm-4">Planned date:</label>
        <div class="col-12 col-sm-8">
          <input
            [disabled]="!isModifiable() || isEditForbidden()"
            [(ngModel)]="startDate"
            class="form-control"
            name="scheduleStartDate"
            type="text"
            placeholder="yyyy-mm-dd HH:mm"
            #startDateInput="ngModel"
            required pattern="\d{4}-\d{2}-\d{2}[T| ]?\d{2}:\d{2}"
            [ngClass]="{ 'is-invalid': f.submitted && startDateInput.invalid}"
          >
          <div *ngIf="f.submitted && startDateInput.invalid" class="invalid-feedback">
            <div *ngIf="startDateInput.errors.required">Field is required</div>
            <div *ngIf="startDateInput.errors.pattern">Remember about the correct pattern yyyy-MM-dd HH:mm</div>
          </div>
        </div>
      </div>
      <div class="form-row form-group">
        <label class="col-form-label col-12 col-sm-4">Duration:</label>
        <div class="col-12 col-sm-8">
          <input
            [disabled]="!isModifiable() || isEditForbidden()"
            [(ngModel)]="durationMin"
            class="form-control"
            name="duration"
            type="number"
            placeholder="minutes"
          >
        </div>
      </div>
      <div class="form-row form-group">
        <label class="col-form-label col-12 col-sm-4">Classroom name:
          <small class="text-muted">(optional for offline groups)</small>
        </label>
        <div class="col-12 col-sm-8">
          <input
            [disabled]="!isModifiable() || isEditForbidden()"
            [(ngModel)]="place"
            class="form-control"
            name="classroom"
            type="text"
            placeholder="number / name"
          >
        </div>
      </div>
      <div class="mb-3">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="online-group" name="online-group" [(ngModel)]="onlineEvent"
                 [disabled]="!isModifiable() || isEditForbidden()"
          >
          <label class="custom-control-label" for="online-group" [innerHTML]="getOfflineModeDescription()"></label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12 col-sm-6">
          <input class="form-control"
                 [disabled]="!isModifiable() || isEditForbidden()"
                 name="teacher-name"
                 placeholder="Teacher name"
                 [(ngModel)]="teacher.name"
                 #teacherName="ngModel"
                 required
                 [ngClass]="{ 'is-invalid': f.submitted && teacherName.invalid}"
          >
          <div *ngIf="f.submitted && teacherName.invalid" class="invalid-feedback">
            <div *ngIf="teacherName.errors.required">Field is required</div>
          </div>
        </div>
        <div class="form-group col-12 col-sm-6">
          <input class="form-control"
                 [disabled]="!isModifiable() || isEditForbidden()"
                 name="teacher-mail" type="email" placeholder="Teacher email" [(ngModel)]="teacher.email">
        </div>
      </div>
      <div class="my-3 form-row">
        <div class="col-auto ml-1">
          Students:
        </div>

        <div class="col text-right">
          <a *ngIf="canAdd() && !isEditForbidden()" tabindex="" class="btn btn-primary btn-sm float-right" style="margin-left:15px;margin-top: -4px;" (click)="addStudent()"><i  class="fa fa-plus"></i></a>
        </div>
      </div>

      <div class="row" *ngFor="let student of students; let i = index">
        <div class="col-2 col-sm-3 col-lg-2 text-secondary pt-1 student-state-icons">
          <span *ngIf="student.offline"><i class="fa fa-school"></i></span>
          <span *ngIf="student.videoOff"><i class="fa fa-video-slash"></i></span>
          <span *ngIf="student.audioOff"><i class="fa fa-volume-mute"></i></span>
          <span *ngIf="student.passive"><i class="fa fa-hands"></i></span>
          <span *ngIf="student.chartOff"><i class="fa fa-chart-pie"></i></span>
          <span *ngIf="student.secret"><i class="fa fa-user-secret"></i></span>
          <span *ngIf="student.muteOff"><i class="fa fa-lock"></i></span>
          <span *ngIf="student.chatOff"><i class="fa fa-comment-slash"></i></span>
        </div>
        <div class="col-10 col-sm-9 col-lg-10">
          <div class="form-row">
            <div class="form-group col-12 col-lg">
              <input class="form-control"
                     [disabled]="!isModifiable() || isEditForbidden()"
                     name="student-name-{{i}}" placeholder="Student name" [(ngModel)]="student.name"
                     #studentName="ngModel"
                     required
                     [ngClass]="{ 'is-invalid': f.submitted && studentName.invalid}">
              <div *ngIf="f.submitted && studentName.invalid" class="invalid-feedback">
                <div *ngIf="studentName.errors.required">Field is required</div>
              </div>
            </div>
            <div class="form-group col">
              <input class="form-control"
                     [disabled]="!isModifiable() || isEditForbidden()"
                     name="student-mail-{{i}}" type="email" placeholder="Student email" [(ngModel)]="student.email">
            </div>
            <div class="form-group col-auto" *ngIf="isModifiable() && !isEditForbidden()">
              <div class="btn-group">
                <button *ngIf="mayDeleteStudent()" type="button" class="btn btn-link text-danger" (click)="removeStudent(student)"><i class="fa fa-trash"></i></button>
                <button type="button" class="btn btn-link text-secondary" (click)="editSettings(student)"><i class="fa fa-cog"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="saved" class="alert alert-success">
        Saved correctly.
      </div>
      <div *ngIf="hasError" class="alert alert-danger">
        Server error received: {{hasError}}
      </div>
      <div class="text-right my-2" *ngIf="!isEditForbidden()">
        <div class="btn-group">
          <button type="submit" class="btn btn-primary" *ngIf="isModifiable()">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>


<div *ngIf="data && !isModifiable()" class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-auto">
        <button class="btn btn-link mt-n1" (click)="onBack()"><i class="fa fa-chevron-left"></i> </button>
      </div>
      <div class="col">
        Schedule for {{getScheduleName()}}<br/>
        <small class="text-muted">[{{getScheduleStatus()}}]</small>
      </div>
      <div class="col-12 col-md text-right">
        <span class="text-muted">
          Planned at: {{getScheduleStartDate() | date: 'dd/MM HH:mm' }}
          <span *ngIf="durationMin"> - {{getScheduleEndDate() | date: "HH:mm"}}</span>
          <span *ngIf="place"> in {{place}}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p *ngIf="hasRoom()" class="mt-2">
      The room was created at <strong>{{getRoomCreateDate() | date: 'dd/MM HH:mm'}}</strong>.
      <span *ngIf="getRoomLessonStartDate()">
      <span> Teacher started the timer at
        {{getRoomLessonStartDate()  | date: 'dd/MM HH:mm'}}</span><span *ngIf="getRoomTotalTime()">
      and the timer summary time was {{getRoomTotalTime()}} minutes</span>.
    </span>
      <span
        *ngIf="getRoomVideoCreated()"><span> Video stream was started at {{getRoomVideoCreated()  | date: 'dd/MM HH:mm'}}</span>
    <span *ngIf="getRoomVideoFinished()"> and finished at {{getRoomVideoFinished()  | date: 'dd/MM HH:mm'}}</span>
    <span *ngIf="getRoomVideoDuration()"> in total {{getRoomVideoDuration()}} minutes</span>.</span>
      <strong *ngIf="hasRoom() && isRoomQualityReporting()">
        The audio/video quality report are registering for this room.
      </strong>
    </p>
    <hr>
    <div *ngIf="hasProgressReported()">
      <p>
        Teacher has reported the lesson progress:</p>
      <dl class="row">
        <dt class="col-4 col-sm-3">Para:</dt>
        <dd class="col-8 col-sm-9">{{getProgressReport().paragraph}}</dd>
        <dt class="col-4 col-sm-3">Last HW:</dt>
        <dd class="col-8 col-sm-9">{{getProgressReport().lastHw}}</dd>
        <dt class="col-4 col-sm-3">Reading:</dt>
        <dd class="col-8 col-sm-9">{{getProgressReport().reading}}</dd>
        <dt class="col-4 col-sm-3">Dict:</dt>
        <dd class="col-8 col-sm-9">{{getProgressReport().dictation}}</dd>
        <dt class="col-4 col-sm-3">Comment:</dt>
        <dd class="col-8 col-sm-9">{{getProgressReport().comment}}</dd>
      </dl>

    </div>
    <div class="text-center py-4" *ngIf="isComplete() && isOfflineEvent()">
      <h5>This room was opened in Offline mode.</h5>
    </div>
    <div class="text-muted text-right small" *ngIf="hasRoom()">
      <span class="text-danger">Click the icon <i class="text-primary far fa-copy"></i> to copy the invitation link for
        the participant.</span>
    </div>
    <div class="list-group" *ngIf="hasRoom()">
      <div class="list-group-item">
        <div class="d-flex w-100 justify-content-between">
          <h6>{{getPartiFullName(getRoomTeacher())}} <small class="text-muted">teacher</small></h6>
          <div class="position-relative">
            <small class="text-muted">{{getConnectionStatus(getRoomTeacher())}}</small>
            <a *ngIf="!isShowLinkCopied(createLink(getRoomTeacher()))" tabindex="" class="ml-2 cursor-pointer"
               (click)="copyToClipboard(createLink(getRoomTeacher()))"><i class="far fa-copy"></i></a>
            <span *ngIf="isShowLinkCopied(createLink(getRoomTeacher()))" class="copy-label badge badge-primary">link copied to clipboard</span>
          </div>
        </div>
        <small *ngIf="getParticipantVideoConnectionTime(getRoomTeacher())" class="text-muted">Was
          connected {{getParticipantVideoConnectionTime(getRoomTeacher())}} minutes in total.</small>
      </div>
      <div class="list-group-item" *ngFor="let student of getRoomStudents()">
        <div class="d-flex w-100 justify-content-between">
          <h6>{{getPartiFullName(student)}} </h6>
          <div class="position-relative">
            <small class="text-muted">{{getConnectionStatus(student)}}</small>
            <a *ngIf="!isShowLinkCopied(createLink(student))" tabindex="" class="ml-2 cursor-pointer"
               (click)="copyToClipboard(createLink(student))"><i class="far fa-copy"></i></a>
            <span *ngIf="isShowLinkCopied(createLink(student))" class="copy-label badge badge-primary">link copied to clipboard</span>
          </div>
        </div>
        <small *ngIf="getParticipantVideoConnectionTime(student)" class="text-muted">Was
          connected {{getParticipantVideoConnectionTime(student)}} minutes in total.</small>
        <small *ngIf="studentWasAttended(student)" class="text-success">Marked as present by the teacher.</small>
        <small *ngIf="!studentWasAttended(student)" class="text-warning">Marked as absent by the teacher.</small>
      </div>
    </div>
    <div class="text-right my-4" *ngIf="hasRoom() && !isRoomQualityReporting() && !isComplete() && !isOfflineEvent()">
      If you know about any audio/video quality issues in this room, please click the link:
      <a tabindex="" class="cursor-pointer" (click)="registerReports()">Register quality reports</a>
    </div>
    <div class="text-right mt-3">
      <a class="btn btn-outline-primary" *ngIf="hasRoom()" routerLink="/rooms/{{data.room.uuid}}/details">Show Room Details</a>
    </div>
  </div>
</div>

<app-modal-v2 #startConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Create new classroom</h5>
      <button type="button" class="close" (click)="startConfirmModal.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
      Are you sure you want to open a classroom for this event and send emails inviting the students to a new lesson?
    </div>

    <div class="modal-footer text-right">
      <button type="button" class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
      <button type="button" class="btn btn-primary" (click)="onStart()">Yes</button>
    </div>
  </div>
</app-modal-v2>


<app-modal-v2 #deleteConfirmModal>
  <div class="modal-content bg-danger text-light">
    <div class="modal-header">
      <h5 class="modal-title">Delete</h5>
      <button type="button" class="close" (click)="deleteConfirmModal.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
      Are you sure you want to delete this item?
      </div>
      <div class="form-check mt-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="deleteFollowing" >
        <label class="form-check-label" >
          Delete all following schedules too
        </label>
      </div>
    </div>
    <div class="modal-footer text-right">
      <button type="button" class="btn btn-outline-light" (click)="onDelete()">Yes, delete</button>
      <button type="button" class="btn btn-light" (click)="deleteConfirmModal.hide()">No</button>

    </div>
  </div>
</app-modal-v2>

<app-modal-v2 #settingsModal size="lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Participant settings</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="editParticipantSettings">


<!--            <div class="form-row">-->
<!--              <div class="col-1 text-muted pt-2">-->
<!--                <i class="fa fa-school"></i>-->
<!--              </div>-->
<!--              <div class="col col-form-label">-->
<!--                <label> In class-->
<!--                </label>-->
<!--                <div class="text-muted small mt-n2">-->
<!--                  If you are going to have a hybrid lesson check this switch for students in the class.-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="form-group col-auto pt-2">-->
<!--                <div class="custom-control custom-switch">  -->
<!--                  <input type="checkbox" class="custom-control-input" id="settings-offline" [(ngModel)]="editParticipantSettings.offline">-->
<!--                  <label class="custom-control-label text-hide" for="settings-offline">In class</label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-hands"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent random prompting
          </label>
          <div class="text-muted small mt-n2">
            The student will never be selected by the randomizer (prompting with the space bar).
            To prompt the student, you will need to select them manually.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-passive" [(ngModel)]="editParticipantSettings.passive">
            <label class="custom-control-label text-hide" for="settings-passive">Passive</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-chart-pie"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent chart interaction
          </label>
          <div class="text-muted small mt-n2">
            The student won’t be able to interact with the demonstration charts. <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-chart-off" [(ngModel)]="editParticipantSettings.chartOff">
            <label class="custom-control-label text-hide" for="settings-chart-off">Chart Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-comment-slash"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent chat interaction
          </label>
          <div class="text-muted small mt-n2">
            The student won’t be able to send chat messages, but will see other chat messages. <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-chat-off" [(ngModel)]="editParticipantSettings.chatOff">
            <label class="custom-control-label text-hide" for="settings-chat-off">Chat Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-user-secret"></i>
        </div>
        <div class="col col-form-label">
          <label>Observation mode
          </label>
          <div class="text-muted small mt-n2">
            The observer won’t be seen by the other participants, and won’t be able to broadcast audio or video, use the chat feature, or interact with the demonstration charts.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-secret" [(ngModel)]="editParticipantSettings.secret">
            <label class="custom-control-label text-hide" for="settings-secret">Secret</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-lock"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent muting
          </label>
          <div class="text-muted small mt-n2">
            The participant can’t be muted by the teacher and won’t be muted automatically when another student is selected. The participant can still mute themselves.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-mute-off" [(ngModel)]="editParticipantSettings.muteOff">
            <label class="custom-control-label text-hide" for="settings-mute-off">Mute Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-video-slash"></i>
        </div>
        <div class="col col-form-label">
          <label>Video Off
          </label>
          <div class="text-muted small mt-n2">
            The student’s video will be off when they join the lesson. You can turn their video back on during the lesson.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-video-off" [(ngModel)]="editParticipantSettings.videoOff">
            <label class="custom-control-label text-hide" for="settings-video-off">Video Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-volume-mute"></i>
        </div>
        <div class="col col-form-label">
          <label>Audio off
          </label>
          <div class="text-muted small mt-n2">
            The student’s audio will be off when they join the lesson. You can turn their audio back on during the lesson.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-audio-off" [(ngModel)]="editParticipantSettings.audioOff">
            <label class="custom-control-label text-hide" for="settings-audio-off">Audio Off</label>
          </div>
        </div>
      </div>
    </div>
      <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    </div>
  </div>

</app-modal-v2>
