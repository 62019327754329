import {Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Chart } from 'src/app/model/server';
import {environment} from "../../../environments/environment";
import {HotkeyEvent, HotkeysService, ZoomChart} from "../../service/hotkeys.service";
import {Subscription} from "rxjs";

export class ChartImageData {
  constructor(public x: number, public y: number, public zoom?: number) {}
}

@Component({
  selector: 'app-chart-image',
  templateUrl: './chart-image.component.html',
  styleUrls: ['./chart-image.component.css']
})
export class ChartImageComponent implements OnInit, OnDestroy {

  mChart: Chart;


  @ViewChild('img', {static: false})
  imageRef: ElementRef;
  @ViewChild('scrollContainer', {static: false})
  scrollContainer: ElementRef;
  private readonly hotKeySubscription: Subscription;

  @Input()
  set chart(chart: Chart) {
    this.mChart = chart;
  }

  @Output()
  chartDataUpdate = new EventEmitter<string>();

  constructor(
    hotKeyService: HotkeysService
  ) {
    this.hotKeySubscription = hotKeyService.hotkeyEventSubject.subscribe( event => this.handleZoomEvent(event));
  }

  getImage() {
    if (!this.mChart || !this.mChart.entryAssigned || !this.mChart.entryAssigned.imageName) return '';
    return `${environment.s3bucket}/default-charts/${this.mChart.entryAssigned.imageName}/chart.png`;
  }

  getArrowImage() {
    return `${environment.serverBase}/assets/charts/arrow-2.png`;
  }

  getPointerPositionStyle() {
    if (!this.mChart.chartData) {
      return {display: 'none'};
    }
    if (!this.imageRef) {
      return {display: 'none'};
    }

    const data: ChartImageData = JSON.parse(this.mChart.chartData);

    const nEl = this.imageRef.nativeElement;

    const w = nEl.offsetWidth;
    const h = nEl.offsetHeight;
    const imageRatio = nEl.naturalWidth / nEl.naturalHeight;
    const hostRatio = w / h;
    let imageWidth = w;
    let imageHeight = h;
    if (hostRatio > imageRatio) {
      imageWidth = h * imageRatio;
    } else {
      imageHeight = w / imageRatio;
    }

    const offsetX = (w - imageWidth) / 2;
    const offsetY = (h - imageHeight) / 2;

    const viewportWidth = this.scrollContainer.nativeElement.clientWidth;
    const viewportHeight = this.scrollContainer.nativeElement.clientHeight;

    let preferredTop = (data.y * imageHeight) + offsetY - viewportHeight / 2;
    let preferredLeft = (data.x * imageWidth) + offsetX - viewportWidth / 2;

    if (preferredTop < 0) preferredTop = 0;
    if (preferredLeft < 0) preferredLeft = 0;

    this.scrollContainer.nativeElement.scrollTop = preferredTop ;
    this.scrollContainer.nativeElement.scrollLeft = preferredLeft;

    return {
      left: ((data.x * imageWidth) + offsetX - 5) + 'px',
      top: ((data.y * imageHeight) + offsetY - 5) + 'px',
      position: 'absolute',
      transition: '0.4s',
      "pointer-events": "none",
      width: '48px'
    };
  }

  onResize(event) {
  }

  onClick(event: MouseEvent) {
    const zoom = this.mChart.chartData ? JSON.parse(this.mChart.chartData).zoom : null;
    const target: any = event.target;

    const ratio: number = target.naturalWidth / target.naturalHeight;
    const hostRatio: number = target.offsetWidth / target.offsetHeight;

    const parentWidth = target.offsetWidth;
    const parentHegiht = target.offsetHeight;

    let imageWidth: number = parentWidth;
    let imageHeight: number = parentHegiht;
    if (hostRatio > ratio) {
      // is wider than image
      imageWidth = target.offsetHeight * ratio;
      imageHeight = target.offsetHeight;
    } else {
      imageWidth = target.offsetWidth;
      imageHeight = target.offsetWidth / ratio;
    }

    const xOffset = (parentWidth - imageWidth) / 2;
    const yOffset = (parentHegiht - imageHeight) / 2;
    let clickX = event.offsetX - xOffset;
    let clickY = event.offsetY - yOffset;
    if (clickX < 0 ) {
      clickX = 0;
    }
    if (clickX > imageWidth) {
      clickX = imageWidth;
    }
    if (clickY < 0) {
      clickY = 0;
    }
    if (clickY > imageHeight) {
      clickY = imageHeight;
    }

    const chartData = JSON.stringify(new ChartImageData(
      clickX / imageWidth,
      clickY / imageHeight,
      zoom
    ));

    this.chartDataUpdate.emit(chartData);

  }

  standardImageStyle = {
    width: "100%",
    height: "100%",
    "object-fit": "contain"
  };

  ngOnInit() {
  }

  getImageStyle() {
    if (!this.mChart.chartData) {
      return this.standardImageStyle;
    }
    if (!this.imageRef) {
      return this.standardImageStyle;
    }

    const data: ChartImageData = JSON.parse(this.mChart.chartData);
    if (data.zoom == null || data.zoom === 1) {
      return this.standardImageStyle;
    }

    return {
      width: `${100*data.zoom}%`
    };
    }

  ngOnDestroy(): void {
    if (this.hotKeySubscription != null) {
      this.hotKeySubscription.unsubscribe();
    }
  }

  private handleZoomEvent(event: HotkeyEvent) {
    if (!(event instanceof ZoomChart)) return;
    if (!this.mChart.chartData || !this.imageRef) return;

    const data: ChartImageData = JSON.parse(this.mChart.chartData);
    if (!data.zoom) {
      data.zoom = 2.0;
    } else {
      data.zoom = null;
    }
    this.chartDataUpdate.emit(JSON.stringify(data));
  }

}
