import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PersonalDataService } from './personal-data.service';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthRoleGuardService implements CanActivate {
    constructor(private personData: PersonalDataService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.personData.authorizationProfiles.pipe(
            take(1),
            map( profiles =>
                profiles
                    .map( profile => this.anyRoleMatch(profile.profileRole, route.data.roles))
                    .filter( roleFound => roleFound)
                    .length > 0
                ),
            tap( hasRole => {
                if (!hasRole) {
                    this.router.navigate(['forbidden']);
                }
            })
            );
        /*
        return this.personData.currentProfile.pipe(
            take(1),
            map( profile => this.anyRoleMatch(profile.profileRole, route.data.roles))
        );*/
    }
    anyRoleMatch(profileRole: string, expectedRoles: string[]): boolean {
        return expectedRoles.findIndex( r => r === profileRole) >= 0;
    }
}
