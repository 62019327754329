import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeprecationGuardService {

  private env = environment.envName

  constructor(private router: Router) { }

  canActivate() {
    if(this.env == 'prod')
      this.router.navigate(['deprecated']).then();
    return of(true)
  }
}
