
<div class="card">
    <div class="card-body">
        <div *ngIf="!explainRoles">
            <h2 class="text-center">Selecting the school profile</h2>
            <h3 class="text-center">Please wait...</h3>    
        </div>
        <p *ngIf="explainRoles">
            Your account doesn't allow for rooms management access. Please log in as a manager.
        </p>
    </div>
</div>

