<form class="text-center" (ngSubmit)="submitForm()">


    <div *ngIf="isShowRating()" class="form-group">
        <label translate>room.survey.av-quality-label</label>
        <app-star-input [(value)]="survey.overalRating" (valueChange)="overalChanged($event)" range="5"></app-star-input>
    </div>

    <div *ngIf="isAskForSuccess()">
      <p translate>room.survey.occ-success-question</p>
      <div class="form-check  form-check-inline">
        <input class="form-check-input" type="radio" name="success" [value]="true" [(ngModel)]="survey.success" >
        <label class="form-check-label text-capitalize" translate>general.yes</label>
      </div>
      <div class="form-check  form-check-inline">
        <input class="form-check-input" type="radio" name="success" [value]="false" [(ngModel)]="survey.success">
        <label class="form-check-label text-capitalize" translate>general.no</label>
      </div>
    </div>
    <div *ngIf="isShowAudioVideoRatings()" class="mt-2">
      <div class="form-group">
        <label translate>room.survey.aq-label</label>
        <app-star-input [(value)]="survey.audioQualityRating" range="5"></app-star-input>
      </div>
      <div class="form-group">
        <label translate>room.survey.vq-label</label>
        <app-star-input [(value)]="survey.videoQualityRating" range="5"></app-star-input>
      </div>
    </div>
    <div *ngIf="askForIssues()" style="display: flex; align-content: center;">

        <div class="text-left" style="width: fit-content;">
            <p translate>room.survey.issues-label</p>
        <div class="form-check" *ngFor="let issue of issues.values">
            <input class="form-check-input" type="checkbox" [checked]="isIssueSelected(issue)" (change)="changeIssue(issue,$event)">
            <label class="form-check-label" >{{issues.getDescription(ts,issue) | async}}</label>
          </div>
        </div>
    </div>
    <div *ngIf="isShowComment()" class="form-group">
        <label translate>room.survey.tell-more-label</label>
        <textarea class="form-control" name="comment" [(ngModel)]="survey.comment"></textarea>
    </div>
    <button *ngIf="isShowSubmit()" type="submit" class="btn btn-primary text-capitalize" translate>general.submit</button>

</form>
