import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {ChartEntry, ChartSet} from "../model/server";
import {ServerRestService} from "./server-rest.service";
import {ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FlipChartDefinitionsService {

  private chartSets = new ReplaySubject<ChartSet[]>(1);
  private chartEntriesById = new ReplaySubject<{ [chartId: string]: ChartEntry}>(1);


  constructor(private restService: ServerRestService) {
  }

  loadChartSets(teacherUuid: string) {
    this.restService.listChartSets(teacherUuid)
      .pipe(
        tap(sets => this.storeData(sets))
      ).subscribe()

  }

  public initialize(sets: ChartSet[]) {
      const chartEntriesById: { [chartId: string]: ChartEntry} = {};
      for (const chartSet of sets) {
          chartSet.charts.forEach( chart => chartEntriesById[ chart.imageName ] = chart);
      }
      this.chartEntriesById.next(chartEntriesById);
  }

  private storeData(sets: ChartSet[]) {
    this.chartSets.next(sets);
    this.initialize(sets);
  }

  getChartEntriesById() {
    return this.chartEntriesById;
  }

  getChartSets() {
    return this.chartSets;
  }
}
