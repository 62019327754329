import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pageable, Page, RoomDef } from 'src/app/model/server';
import { Observable } from 'rxjs';
import {GenericDataProvider, PageableDataProvider} from 'src/app/model/internal';


@Component({
  selector: 'app-rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.css']
})
export class RoomsListComponent implements OnInit {

  rooms: RoomDef[];
  lastPage: Page<RoomDef>;
  mDataProvider: PageableDataProvider<RoomDef>;

  constructor() { }

  @Input()
  set dataProvider(provider: PageableDataProvider<RoomDef>) {
    this.mDataProvider = provider;
    this.resetData();
    if (this.mDataProvider) {
      this.loadData();
    }
  }

  @Output()
  count = new EventEmitter<number>();

  resetData() {
    this.rooms = [];
    this.lastPage = null;
    this.count.emit(0);
  }

  getName(room: RoomDef) {
    return room.name;
  }

  getCreated(room: RoomDef) {
    return room.createDate;
  }

  getStatus(room: RoomDef) {
    if (!room || !room.provisioning) {
      return null;
    }
    if (room.provisioning.status === 'PENDING') { return 'Pending'; }
    if (room.provisioning.status === 'IN_PROGRESS') { return 'In progress'; }
    if (room.provisioning.status === 'COMPLETE') { return 'Completed'; }
    return null;
  }

  getStarted(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.created) {return null; }
    return room.provisioning.created;
  }

  getFinished(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.endTime) {return null; }
    return room.provisioning.endTime;
  }

  getDuration(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.duration) {return null; }
    if (room.provisioning.duration < 60) {
      return room.provisioning.duration + ' sec.';
    }
    return Math.round(room.provisioning.duration / 60) + ' min.';
  }

  getTeacher(room: RoomDef) {
    return room.participants.find( p => p.role === 'Teacher').name;
  }

  getNbOfStudents(room: RoomDef) {
    return room.participants.filter( p => p.role === 'Student').length;
  }

  hasMore() {
    if (!this.lastPage) {return false; }
    return !this.lastPage.last;
  }

  showMore() {
    if (!this.hasMore()) {
      return;
    }
    this.mDataProvider.nextPage();
    this.loadData();
  }

  loadData() {
    this.mDataProvider.load().subscribe( page => {
      this.rooms = this.rooms.concat(page.content);
      this.lastPage = page;
      this.count.emit(page.totalElements);
    });
  }

  ngOnInit() {
  }

}
