import {Injectable} from "@angular/core";
import {ParticipantData} from "../model/internal";
import {QualityReportEntry} from "../model/server";
import {ServerRestService} from "./server-rest.service";
import {catchError, finalize} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QualityService {
  private frame: QualityReportEntry[] = [];

  constructor(private rest: ServerRestService) {
  }

  reportQuality(self: ParticipantData, participants: Array<ParticipantData>, parti: ParticipantData, stats: any) {
    if (!stats) return;
    if (self.participantDef.role !== 'Teacher') return;
    const reportEntry = new QualityReportEntry();
    reportEntry.time = new Date().getTime();
    reportEntry.uuid = parti.participantDef.uuid;
    reportEntry.stats = stats;
    this.addReportEntry(self, reportEntry);

  }

  private clearFrame() {
    this.frame = [];
  }

  private sendFrame(self: ParticipantData) {
    if (!self.roomDef.reportStats) {
      this.clearFrame();
      return;
    }
    this.rest.saveQualityReports(self.participantDef.uuid, this.frame)
      .pipe(
        catchError((err, caught) => EMPTY),
        finalize( () => this.clearFrame())
      )
      .subscribe();
  }

  private addReportEntry(self: ParticipantData, reportEntry: QualityReportEntry) {
    this.frame.push(reportEntry);  
    if (this.frame.length > 20) this.sendFrame(self);
  }
}
