import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { Subject, of, Observable, combineLatest, merge } from 'rxjs';
import { take, timeoutWith, tap, switchMap, combineAll, mergeMap, mergeAll } from 'rxjs/operators';
import { GenericSimpleDataProvider } from 'src/app/model/internal';
import {GroupTiming, RoomTemplate, TemplateParticipant} from 'src/app/model/server';
import { SearchingTerms, ReportDataProvider } from 'src/app/components/activity-report/activity-report.provider';
import { FullRoomsDataProvider, SearchTermPageable, TemplateIdSearchTermPageable } from 'src/app/service/helpers/rooms-list.provider';
import { Constants } from 'src/app/service/constants';

class ReportDataProviderWrapper extends ReportDataProvider {
  constructor(private component: SchoolActivitiesPageComponent, path: string, schoolId: number, terms: SearchingTerms) {
    super(path, schoolId, component.rest, terms, component.searchContext);
  }
  doLoad() {
    return super.doLoad().pipe(
      tap( timings => this.component.availableGroups = timings.map( row => row.group))
    );
  }
}

@Component({
  selector: 'app-school-activities-page',
  templateUrl: './school-activities-page.component.html',
  styleUrls: ['./school-activities-page.component.css']
})
export class SchoolActivitiesPageComponent implements OnInit {

  schoolId: number;
  mMonth = 0;
  mDuration = 12;
  mTemplateId = -1;
  reportData: GenericSimpleDataProvider<GroupTiming[]>;
  roomsData: FullRoomsDataProvider = null;
  availableGroups: RoomTemplate<TemplateParticipant>[];
  totalLessonsNumber: number;

  termsSubject = new Subject<SearchingTerms>();
  groupSubject = new Subject<number>();

  set month(m: number) {
    this.mMonth = m;
    this.updateDates();
  }

  get month() {
    return this.mMonth;
  }

  set duration(d: number) {
    this.mDuration = d;
    this.updateDates();
  }

  get duration() {
    return this.mDuration;
  }

  set templateId(id: number) {
    this.mTemplateId = id;
    this.groupSubject.next(id);
  }

  get templateId() {
    return this.mTemplateId;
  }

  constructor(activatedRoute: ActivatedRoute,
              public searchContext: SearchingContextService,
              public rest: ServerRestService) {
    activatedRoute.parent.paramMap.pipe(
      tap (params => this.schoolId = Number(params.get('schoolId'))),
      switchMap( () => this.restoreDates()),
      switchMap( () => this.restoreRooms())
    )
    .subscribe( );

    this.listenForTermsChangesForReports();
    this.listenForInputChangesForRooms();
  }

  listenForTermsChangesForReports() {
    this.termsSubject.pipe(
      tap( terms => {
        const contextPath = `/schools/${this.schoolId}/activity/terms`;
        this.reportData = new ReportDataProviderWrapper(this, contextPath,
          this.schoolId, terms);
      })
    ).subscribe();
  }

  listenForInputChangesForRooms() {
    merge(this.termsSubject, this.groupSubject).pipe(
      tap( () => {
        const path = `/schools/${this.schoolId}/activity/rooms`;
        this.roomsData =
          new FullRoomsDataProvider(this.schoolId, path, this.searchContext,
            this.rest,
            new TemplateIdSearchTermPageable(this.templateId,
              new SearchingTerms(this.month, this.duration), 0, Constants.DefultPageSize, 'createDate,desc'));
      })
    ).subscribe();
  }

  restoreRooms() {
    const path = `/schools/${this.schoolId}/activity/rooms`;
    return this.searchContext.getOrCreateContext<TemplateIdSearchTermPageable>(path).pipe(
      take(1),
      timeoutWith(10, of(
        new TemplateIdSearchTermPageable(this.templateId,
          new SearchingTerms(this.mMonth, this.mDuration), 0, Constants.DefultPageSize, 'createDate,desc'))),
      tap ( pageable => {
        this.mTemplateId = pageable.templateId;
        this.roomsData = new FullRoomsDataProvider(this.schoolId, path, this.searchContext, this.rest, pageable);
      })
    );
  }

  restoreDates() {
    const contextPath = `/schools/${this.schoolId}/activity/terms`;
    return this.searchContext.getOrCreateContext<SearchingTerms>(contextPath).pipe(
      take(1),
      timeoutWith(10, of(new SearchingTerms(0, 1))),
      tap (terms => {
        this.mMonth = terms.month;
        this.mDuration = terms.duration;
        this.reportData = new ReportDataProviderWrapper(this, contextPath,
          this.schoolId, terms);
      })
    );
  }

  updateDates() {
    this.mTemplateId = -1;
    this.termsSubject.next(new SearchingTerms(this.month, this.duration));
  }

  ngOnInit() {
  }

}
