<app-autocomplete
  #autocompleteComponent
  (term)="onTermUpdate($event)"
  [data]="data"
  (activeItem)="currentActive = $event"
  (itemSelected)="onItemSelected($event)"
  (inputFocused)="reopenOnFocus($event)"
>
  <ng-content></ng-content>
  <ul class="list-group" options>
    <li class="list-group-item" #autocompleteItem  [ngClass]="{'active' : currentActive == item}" *ngFor="let item of data"
        (click)="onItemSelected(item)"
    >{{getItemDescription(item)}}
      <small class="text-muted" *ngIf="getSecondaryDescription(item)"><br>( {{getSecondaryDescription(item)}})</small>
    </li>
  </ul>
</app-autocomplete>


