import {Injectable} from "@angular/core";
import {ContentItem, ContentItemType, Course, Stage} from "../model/books";
import {BookPageSelection, BookRendererControllerService, BookSelection} from "./book-renderer-controller.service";
import {BooksRestService} from "./books-service";
import {map, shareReplay, take, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {LessonProgressService} from "./lesson-progress.service";
import {RoomDataService} from "./helpers/room-data-service";

export class JumpKind{
  constructor(
    public name: string,
    public itemType: ContentItemType,
    public actionCall: string,
    public inputLabel: string,
    public actionName: string) {
  }
  public static TEXT = new JumpKind("Jump to page [J]", ContentItemType.TEXT, "Jump", "Page number", "");
  public static READ = new JumpKind("Start READING [R]", ContentItemType.TEXT, "Start", "Page number", "reading");
  public static EXAM = new JumpKind("Start EXAM", ContentItemType.EXAM, "Start", "Exam number", "exam");
  public static DICT = new JumpKind("Start DICTATION [D]", ContentItemType.DICT, "Start", "Dictation number", "dictation");
  public static vals = [JumpKind.TEXT, JumpKind.READ, JumpKind.DICT, JumpKind.EXAM ];
}

@Injectable({
  providedIn: 'root'
})
export class BookmarksService {
  private BOOKMARK_VAR_NAME = "myBookmark";
  private productsSource: Observable<Course[]>;

  private bookmarkMode = JumpKind.TEXT;
  private currentSelection: BookPageSelection;
  private pageShared: BookPageSelection;
  private jumpingPartiUuid: string;

  constructor(
    private rest: BooksRestService,
    private bookRendererController: BookRendererControllerService,
    private progressService: LessonProgressService,
    private selfHolder: RoomDataService
  ) {
    bookRendererController.currentBookPageSelection.subscribe(
      selection => {
        this.currentSelection = selection;
        this.sharePageIfNecessary();
        this.updateProgressReport(this.jumpingPartiUuid, this.bookmarkMode, selection);
      }
    );
    bookRendererController.bookPageShared.subscribe(pageShared =>
      this.pageShared = pageShared
    );
    selfHolder.initializedObservable.subscribe(
      uuid => this.initParticipantUuid(uuid)
    )
  }

  initParticipantUuid(partiUuid: string) {
    this.jumpingPartiUuid = partiUuid;
  }

  doJump(partiUuid: string, jumpType: JumpKind, selectedNumber: number): boolean {
    if (!selectedNumber) return false;
    if (!this.currentSelection) return false;
    if (!jumpType) return false;
    const isCorrectType = (el: ContentItem) =>
      el.type === jumpType.itemType || (jumpType.itemType === ContentItemType.TEXT);

    const elementPageFits = (el: ContentItem) => jumpType.itemType === ContentItemType.TEXT && el["page-from"] <= selectedNumber && el["page-to"] >= selectedNumber;
    const elementIdFits = (el: ContentItem) => el.type !== ContentItemType.TEXT && el.id === selectedNumber.toString();
    const itemFilter = (el: ContentItem) => isCorrectType(el) && (elementPageFits(el) || elementIdFits(el)) ;

    let targetStage = null;

    const currentBookFitsTheJump = !!this.currentSelection.book.stage["content-items"].find(elementPageFits);
    if (currentBookFitsTheJump) targetStage = this.currentSelection.book.stage;

    if (!targetStage) {
      const targetCandidate = this.currentSelection.book.course.stages.find(stage => stage["content-items"].find(itemFilter));
      if (!targetCandidate) return false;

      if ((jumpType === JumpKind.TEXT || jumpType === JumpKind.READ)) {
        // for text jumps allow to jump between two different continuous projects
        if (this.currentSelection.book.stage.continuous && targetCandidate.continuous) {
          targetStage = targetCandidate;
        }
      } else {
        // for other jump types products jumping is always possible
        targetStage = targetCandidate;
      }
    }
    if (!targetStage) return false;
    const targetItem = targetStage["content-items"].find(itemFilter);
    if (!targetItem) return false;

    this.saveCurrentAsBookmark(this.currentSelection);
    this.bookmarkMode = jumpType;
    this.jumpingPartiUuid = partiUuid;


    this.bookRendererController.moveToFirstBreakpointAfterPageLoad();
    const targetPageNumber = (jumpType.itemType === ContentItemType.TEXT) ? selectedNumber : targetItem["page-from"];
    const jumpTarget = new BookPageSelection(new BookSelection(this.currentSelection.book.course, targetStage), targetPageNumber, 1);
    this.updateProgressReport(partiUuid, jumpType, jumpTarget);
    this.bookRendererController.openBookPage(jumpTarget);
    return true;
  }

  private updateProgressReport(partiUuid: string, jumpType: JumpKind, jumpTarget: BookPageSelection) {
    if (!partiUuid) return;
    // error fix, sometimes jump target is null, maybee if trying to jump a missing page or after closing the book - ignore it
    if (!jumpTarget) return;
    if (jumpType !== JumpKind.READ && jumpType !== JumpKind.DICT && jumpType !== JumpKind.TEXT) return;
    this.progressService.readProgress(partiUuid).pipe(
      take(1)
    ).subscribe(progress => {
      if (jumpType === JumpKind.READ) {
        progress.details.reading = jumpTarget.page.toString();
      } else if (jumpType === JumpKind.DICT) {
        const dictation = jumpTarget.book
          .stage["content-items"]
          .find( dict =>
            dict.type === ContentItemType.DICT && dict["page-from"] <= jumpTarget.page && dict["page-to"] >= jumpTarget.page)
        if (dictation) {
          progress.details.dictation = dictation.id
        }
      } else if (jumpType === JumpKind.TEXT) {
        progress.details.paragraph = jumpTarget.page.toString();
      }
      this.progressService.progressChanged(progress.details);
    })
  }

  private saveCurrentAsBookmark(currentSelection: BookPageSelection) {
    const courseCode = currentSelection.book.course.code;
    const course = new Course();
    course.code = courseCode;
    const stageCode = currentSelection.book.stage.code;
    const stage = new Stage();
    stage.code = stageCode;
    const bookmark = new BookPageSelection(new BookSelection(course, stage), currentSelection.page, 1);

    localStorage.setItem(this.BOOKMARK_VAR_NAME, JSON.stringify(bookmark));
  }

  public hasBookmark() {
    return localStorage.getItem(this.BOOKMARK_VAR_NAME) != null;
  }

  public openBookmark(partiUuid: string): Observable<any> {
    const bookmark = JSON.parse(localStorage.getItem(this.BOOKMARK_VAR_NAME)) as BookPageSelection;
    const bookmarkMode = this.bookmarkMode;
    this.bookRendererController.clearBookShare(partiUuid);
    this.bookmarkMode = JumpKind.TEXT;
    localStorage.removeItem(this.BOOKMARK_VAR_NAME);
    return this.getProducts(partiUuid).pipe(
      map( courses => {
        const course = courses.find( course => course.code === bookmark.book.course.code);
        if (!course) return null;
        const stage = course.stages.find( st => st.code === bookmark.book.stage.code);
        if (!stage) return null;
        return new BookPageSelection(new BookSelection(course, stage), bookmark.page, 1);
      }),
      tap<BookPageSelection>(selectionToOpen => {
        if (!selectionToOpen) return;
        this.updateProgressReport(partiUuid, bookmarkMode, this.currentSelection);
        this.bookRendererController.openBookPage(selectionToOpen);
      })
    )
  }

  public getBookmarkMode() {
    return this.bookmarkMode;
  }

  private getProducts(participantUuid: string) {
    if (this.productsSource) return this.productsSource;
    this.productsSource = this.rest.listProducts(participantUuid).pipe(
      shareReplay(1)
    );
    return this.productsSource;
  }

  getBookmarkPage() {
    return (JSON.parse(localStorage.getItem(this.BOOKMARK_VAR_NAME)) as BookPageSelection).page;
  }

  private sharePageIfNecessary() {
    if (!this.currentSelection) return;
    if (this.bookmarkMode === JumpKind.READ && !this.currentSelection.equals(this.pageShared)) {
      this.bookRendererController.shareCurrentPage(this.jumpingPartiUuid);
    }
  }
}
