import {from, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

declare var mozRTCPeerConnection: any;
declare var webkitRTCPeerConnection: any;

export class CodecsChecker {
  public static supportedCodecs(): Observable<string[]> {
    const PeerConnection = typeof RTCPeerConnection !== 'undefined' ? RTCPeerConnection :
      typeof webkitRTCPeerConnection !== 'undefined' ? webkitRTCPeerConnection :
        typeof mozRTCPeerConnection !== 'undefined' ? mozRTCPeerConnection :
          null;
    if (!PeerConnection) return of([]);
    const offerOptions:any = {};
    const pc = new PeerConnection();

    try {
      pc.addTransceiver('video');
    } catch (e) {
      offerOptions.offerToReceiveVideo = true;
    }
    return from(pc.createOffer(offerOptions)).pipe(
      map((offer:any) => {
        const result:string[] = [];
        if (/^a=rtpmap:.+ H264/m.test(offer.sdp)) result.push("H264");
        if (/^a=rtpmap:.+ VP8/m.test(offer.sdp)) result.push("VP8");
        if (/^a=rtpmap:.+ VP9/m.test(offer.sdp)) result.push("VP9");
        return result;
      })
    )
  }
}
