<form *ngIf="progress" class="mb-2">
  <div class="form-row">
    <div class="col-4 my-1" *ngIf="!isPre() && !isColLesson()">
      <label *ngIf="showLabel()">{{label('para')}}</label>
      <input class="form-control" name="paragraph" type="text"
             [(ngModel)]="progress.details.paragraph"
             (ngModelChange)='onProgressChanged($event)'
             [placeholder]="placeholder('para')">
    </div>
    <div class="col-8 my-1" *ngIf="!isPre()">
      <label *ngIf="showLabel()">{{label('last-hw')}}</label>
      <input class="form-control" type="text"
             [placeholder]="placeholder('last-hw')"
             name="last-headword"
             [(ngModel)]="progress.details.lastHw"
             (ngModelChange)='onProgressChanged($event)'>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 col-sm-6 my-1">
      <label *ngIf="showLabel()">{{label('course')}}</label>
      <app-autocomplete-input #courseAutocomplete [adapter]="courseAdapter" (itemSelected)="courseSelected($event)" openOnFocus="true">
        <input class="form-control"
               name="course"
               [placeholder]="placeholder('course')"
               [disabled]="isColLesson()"
               [(ngModel)]="progress.details.course"
               (ngModelChange)='onProgressChanged($event)'
        >
      </app-autocomplete-input>
    </div>
    <div class="col-12 col-sm-6 my-1">
      <label *ngIf="showLabel()">{{label('stage')}}</label>
      <app-autocomplete-input [adapter]="stageAdapter" (itemSelected)="stageSelected($event)" openOnFocus="true">
        <input class="form-control"
               [placeholder]="placeholder('stage')"
               [disabled]="isColLesson()"
                [(ngModel)]="progress.details.stage"
                name="stage"
                (ngModelChange)='onProgressChanged($event)'
        >
      </app-autocomplete-input>
    </div>
    <div class="col-12 col-sm-6 my-1">
      <label *ngIf="showLabel()">{{label('lessonType')}}</label>
      <app-autocomplete-input [adapter]="lessonTypeAdapter" (itemSelected)="lessonTypeSelected($event)" openOnFocus="true">
        <input class="form-control"
               name="lessonType"
               [placeholder]="placeholder('lessonType')"
               [disabled]="isColLesson()"
               [(ngModel)]="progress.details.lessonType"
               (ngModelChange)='onProgressChanged($event)'
        >
      </app-autocomplete-input>
    </div>
    <div class="col-12 col-sm-6 my-1">
      <label *ngIf="showLabel()">{{label('startPage')}}</label>
      <input class="form-control"
             name="startPage"
             [placeholder]="placeholder('startPage')"
             [(ngModel)]="progress.details.startPage"
             (ngModelChange)='onProgressChanged($event)'
      >
    </div>
    <div class="col-12 col-sm-6 my-1" *ngIf="!isColLesson()">
      <label *ngIf="showLabel()">{{label('lesson')}}</label>
      <app-autocomplete-input [adapter]="lessonAdapter" (itemSelected)="lessonSelected($event)" openOnFocus="true">
        <input class="form-control"
               [placeholder]="placeholder('lesson')"
               [(ngModel)]="progress.details.readingLesson"
               name="lesson"
               (ngModelChange)='onProgressChanged($event); fixLessonPage();'
        >
      </app-autocomplete-input>
    </div>
    <div class="col-12 col-sm-6 my-1" *ngIf="isColLesson()">
      <label *ngIf="showLabel()">{{label('nwp')}}</label>
      <input class="form-control" type="text"
             [placeholder]="placeholder('nwp')"
             name="nwp"
             [(ngModel)]="progress.details.nwp"
             (ngModelChange)='onProgressChanged($event); onLessonPageChange()'>
    </div>
    <div class="col-12 col-sm-6 my-1">
      <label *ngIf="showLabel()">{{label('read')}}</label>
      <input class="form-control" type="text"
             [placeholder]="placeholder('read')"
             name="reading"
             [(ngModel)]="progress.details.reading"
             (ngModelChange)='onProgressChanged($event); onLessonPageChange()'>
    </div>

  </div>
  <div class="form-row">
    <div class="col-12 my-1" *ngIf="!isColLesson()">
      <label *ngIf="showLabel()">{{label('dict')}}</label>
      <app-autocomplete-input [adapter]="dictationsAdapter" (itemSelected)="dictationSelected($event)" openOnFocus="true">
        <input class="form-control" type="text"
               [placeholder]="placeholder('dict')"
               name="dictation"
               [(ngModel)]="progress.details.dictation"
               (ngModelChange)='onProgressChanged($event)'>
      </app-autocomplete-input>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 my-1" *ngIf="!isColLesson()">
      <label *ngIf="showLabel()">{{label('comments')}}</label>
      <textarea class="form-control"
                [placeholder]="placeholder('comments')"
                name="comments"
                [(ngModel)]="progress.details.comment"
                (ngModelChange)='onProgressChanged($event)'></textarea>
    </div>
  </div>
</form>
