import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {from, fromEvent, Observable, Subscription, throwError} from "rxjs";
import {catchError, debounceTime, finalize, map, switchMap, take, tap} from "rxjs/operators";
import {DevicesSelection} from "../capture-devices/capture-devices.component";
import { volumeFromStream } from "./volume-observable"
import {SentryService} from "../../service/sentry.service";
import {BrowserAudit, BrowserVersionService} from "../../service/browser-version.service";
import {CodecsChecker} from "./codecs-checker";
import {RoomDataService} from "../../service/helpers/room-data-service";
import {ServerRestService} from "../../service/server-rest.service";
import {RoomDef} from "../../model/server";
import {PreflightTestReport, runPreflight} from "twilio-video";
import {TwilioPreflight} from "./twilio-preflight";

enum Errors {
  CANT_ENUMERATE = 1,
  CANT_OPEN_STREAM,
  NO_MEDIA_SUPPORT,
}


@Component({
  selector: 'app-capture-devices-new',
  templateUrl: './capture-devices-new.component.html',
  styleUrls: ['./capture-devices-new.component.css']
})
export class CaptureDevicesNewComponent implements OnInit, OnDestroy {
  showTranslations = false;

  private deviceChangeSubscription: Subscription; //subscription to know about devices change
  private browserAudit: BrowserAudit;
  connectedToColVideo = false; // if rest call was successful
  private testToken: string; // token obtained on rest test
  preflightProgress: number; //0..1 value for progressbar
  preflightReport: PreflightTestReport; //final report after preflight test
  private preflightError: Error;
  preflightProgressName = "init"; //preflight progress stem name

  // issues detected on preflight
  hasRttIssues: boolean;
  hasPacketLossIssues: boolean;
  hasJitterIssues: boolean;
  private preflightSubscription: Subscription;
  private role: string;
  private uuid: string;
  copied = false;

  constructor(
    private sentryService: SentryService,
    private browserVersion: BrowserVersionService,
    private selfHolder: RoomDataService,
    private serverRest: ServerRestService
  ) {
  }

  // list of audio inputs found
  audioInputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];

  // devices currently selected
  selection = new DevicesSelection();

  // enumerator used in html - no special logic
  errors = Errors;
  ready = false;

  // if video / audio stream received
  hasPrivileges = false;

  // detected error type
  hasError: Errors;

  hasSupportedCodecs = false;

  // bars to show current volume level
  @ViewChild("vols", {static: false})
  volumes: ElementRef<HTMLDivElement>;

  @ViewChild("videoElement", {static: true})
  videoTarget: ElementRef<HTMLVideoElement>;

  @Output()
  selected = new EventEmitter<DevicesSelection>();

  // subscription for volume follow observable
  volumeSubscription: Subscription;
  preflightIsRunning = false;

  // start all tests
  doTests() {
    // clear results
    this.browserAudit = null;
    this.connectedToColVideo = false;

    this.checkCodecs();
    this.browserAudit = this.browserVersion.auditSystem();
    this.selfHolder.initializedObservable.pipe(
      take(1),
      switchMap(_ => {
        const uuid = this.selfHolder.participantUuid;
        return this.serverRest.initializeRoomByParticipant(uuid)
      }),
      map<RoomDef, string>( response => {
        const self = response.participants.find(it => it.uuid === this.selfHolder.participantUuid);
        this.role = self.role;
        this.uuid = self.uuid;
        return self && self.accessToken;
      })
    ).subscribe(
      token => {
        this.connectedToColVideo = true;
        this.testToken = token;
        //this.runPreflight(token);
      }
    )
  }

  public createLink(pageType: "room" | "error/7") {
    const host = window.location.host;
    const protocol = window.location.protocol;

    if (this.role == "Teacher") {
      return `${protocol}//${host}/participants/${this.uuid}/teacher-${pageType}`;
    } else {
      return `${protocol}//${host}/participants/${this.uuid}/student-${pageType}`;
    }
  }

  copyUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.createLink("room");
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  // used when switching a device or destroying a component
  disconnectLastStream() {
    if (this.volumeSubscription) this.volumeSubscription.unsubscribe();
    this.volumeSubscription = null;
    if (this.videoTarget && this.videoTarget.nativeElement) {
      this.videoTarget.nativeElement.srcObject = null;
    }
  }

  ngOnInit() {
    this.initialize();
    this.doTests();
  }

  private initialize() {
    this.reloadDeviceList().pipe(
      tap( _ => this.selectDefaults()),
      tap( () => this.ready = true)
    ).subscribe( devices => {
    }, error => {
      console.log('have problem with selecting the device, error:');
      console.log(error);
    });

    // listen for device list changes (like headphones connect)
    this.deviceChangeSubscription = fromEvent<Event>(navigator.mediaDevices, 'devicechange')
      .pipe(
        debounceTime(800),
        switchMap(_ => this.reloadDeviceList()),
        tap( _ => this.selectDefaults())
      ).subscribe()
  }

  private reloadDeviceList(): Observable<MediaDeviceInfo[]> {
    return this.listDevices().pipe(
      tap( devices => this.storeDevices(devices))
    )
  }

  storeDevices(devices: MediaDeviceInfo[]): void {
    this.audioInputs = devices.filter( d => d.kind === 'audioinput');
    this.videoInputs = devices.filter ( d => d.kind === 'videoinput');
  }

  selectDefaults(): void {
    if (this.audioInputs.length > 0) {
      this.selectAudio(this.audioInputs[0]);
    }
    if (this.videoInputs.length > 0) {
      this.selectVideo(this.videoInputs[0]);
    }
    this.updateDevicesPreview();
  }

  private selectVideo(mediaDeviceInfo: MediaDeviceInfo) {
    this.selection.videoInput = mediaDeviceInfo;
  }

  private selectAudio(mediaDeviceInfo: MediaDeviceInfo) {
    this.selection.audioInput = mediaDeviceInfo;
  }

  private updateDevicesPreview() {
    this.disconnectLastStream();

    // ask for stream using selected devices from the list
    const constraints: MediaStreamConstraints = {};
    if (this.selection.videoInput) {
      constraints.video = {
        deviceId: this.selection.videoInput.deviceId
      };
    }
    if (this.selection.audioInput) {
      constraints.audio = {
        deviceId: this.selection.audioInput.deviceId
      }
    }
    from(navigator.mediaDevices.getUserMedia(constraints)).subscribe(
      it =>  {
        // display devices
        this.hasPrivileges = true;
        this.videoTarget.nativeElement.srcObject = it;
        this.videoTarget.nativeElement.muted = true;
        this.volumeSubscription = volumeFromStream(it)
          .subscribe(
          it => this.updateVolume(it),
          _ => {
            this.hasError = Errors.CANT_OPEN_STREAM
          }
        )
      },
      _ => {
        this.hasError = Errors.CANT_OPEN_STREAM
      }
    )
  }

  listDevices(): Observable<Array<MediaDeviceInfo>> {
    if (navigator.mediaDevices) {
      return from(navigator.mediaDevices.enumerateDevices()).pipe(
        catchError( err => {
          this.hasError = Errors.CANT_ENUMERATE;
          return throwError(err);
        })
      );
    } else {
      this.hasError = Errors.NO_MEDIA_SUPPORT;
      return throwError(
        new Error('We have a problem with selecting a camera and/or a microphone.<br> \
        Please use a different browser or device.'));
    }
  }

  getDeviceName(device: MediaDeviceInfo) {
    if (device.label === '') {
      return 'Unknown Name';
    } else {
      return device.label;
    }
  }

  startVideo() {
    this.ready = false;
    if (this.preflightSubscription) {
      this.preflightSubscription.unsubscribe();
      this.preflightSubscription = null;
    }
    this.selected.emit(this.selection);
  }

  // react on audio device select (reconnect preview to it)
  audioChanged() {
    this.updateDevicesPreview();
  }

  // react on video device select (reconnect preview to it)
  videoChanged() {
    this.updateDevicesPreview();
  }

  // display current volume level changing bars colors
  private updateVolume(value: number) {
    if (!this.volumes || !this.volumes.nativeElement) return;
    const childCount = this.volumes.nativeElement.children.length;
    const blink = value * childCount - 1;
    for (let i = 0; i < childCount; i++) {
      const blinkElement = this.volumes.nativeElement.children[i].children[0] as HTMLDivElement;
      if (i <= blink) {
        blinkElement.classList.add("bg-danger");
      } else {
        blinkElement.classList.remove("bg-danger")
      }
    }
  }

  ngOnDestroy(): void {
    this.disconnectLastStream();
    this.deviceChangeSubscription.unsubscribe();
    if (this.preflightSubscription) {
      this.preflightSubscription.unsubscribe();
      this.preflightSubscription = null;
    }
  }

  browserCompatible() {
    return this.browserAudit
      && !this.browserAudit.wrongBrowser
      && !this.browserAudit.wrongBrowserVersion
    && !this.browserAudit.unsupportedBrowser
  }

  wrongVersion() {
    return this.browserAudit && this.browserAudit.wrongBrowserVersion;
  }

  unsupportedBrowser() {
    return this.browserAudit && (this.browserAudit.unsupportedBrowser || this.browserAudit.wrongBrowser);
  }

  getBrowserVersion() {
    return this.browserAudit && this.browserAudit.systemInfo.navigatorAgent;
  }

  private checkCodecs() {
    this.hasSupportedCodecs = false;
    CodecsChecker.supportedCodecs().subscribe(codecs => {
      this.hasSupportedCodecs = codecs.indexOf("H264") >= 0 || codecs.indexOf("VP8") >= 0;
    });
  }

  areCodecsCorrect() {
    return this.hasSupportedCodecs;
  }

  private runPreflight(token: string) {
    if (this.preflightSubscription) {
      this.preflightSubscription.unsubscribe();
      this.preflightSubscription = null;
    }
    this.preflightIsRunning = true;
    this.preflightReport = null;
    this.preflightError = null;
    this.preflightProgress = 0;
    this.preflightProgressName = "init";
    this.hasRttIssues = false;
    this.hasPacketLossIssues = false;
    this.hasJitterIssues = false;

    this.preflightSubscription = TwilioPreflight.startPreflight(token).pipe(
      finalize( () => {
        this.preflightIsRunning = false;
        this.preflightSubscription = null;
      })
    ).subscribe(([progress, progressName, report] ) => {
        this.preflightProgress = progress;
        this.preflightReport = report;
        this.preflightProgressName = progressName;
        this.analyzeReport(report);
      }, error => {
       this.preflightError = error;
      }
    );
  }

  isPreflightSuccess() {
    return !this.preflightIsRunning && this.preflightReport;
  }

  hasPreflightErrors() {
    return !this.preflightIsRunning && this.preflightIsRunning;
  }

  getPreflightProgressStyle() {
    if (!this.preflightIsRunning) return {width:'0'};
    return {width:`${this.preflightProgress*100}%`};
  }

  private analyzeReport(report: PreflightTestReport) {
    if (!this.preflightReport || !this.preflightReport.stats) return;
    const stats = this.preflightReport.stats;
    if (!stats) return;

    this.hasRttIssues = stats.rtt && (stats.rtt.average > 300 || stats.rtt.max > 300);
    this.hasPacketLossIssues = (stats.packetLoss) && (stats.packetLoss.average > 4 || stats.packetLoss.max > 6);
    this.hasJitterIssues = (stats.jitter) && (stats.jitter.average > 30 || stats.jitter.max > 30);
    if (this.hasRttIssues || this.hasPacketLossIssues || this.hasJitterIssues) {
      console.log("has issues", JSON.stringify(stats));
    }
  }

  hasConnectionIssues() {
    return this.hasRttIssues || this.hasPacketLossIssues || this.hasJitterIssues;
  }

  restartTest() {
    this.doTests();
  }

  doPreflight() {
   this.runPreflight(this.testToken);
  }
}
