import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateRoomPageComponent } from './pages/create-room-page/create-room-page.component';
import { RoomDetailsPageComponent } from './pages/room-details-page/room-details-page.component';
import { AuthGuardService } from './service/auth-guard.service';
import { AuthRoleGuardService } from './service/auth-role-guard.service';
import { OauthComponent } from './pages/oauth/oauth.component';
import { ManagerMenuLayoutComponent } from './layout/manager-menu-layout/manager-menu-layout.component';
import { ManagerDashboardPageComponent } from './pages/manager-dashboard-page/manager-dashboard-page.component';
import { MangerProfilesPageComponent } from './pages/manger-profiles-page/manger-profiles-page.component';
import { RoomTemplateDetailsPageComponent } from './pages/room-template-details-page/room-template-details-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { SchoolActivitiesPageComponent } from './pages/school-activities-page/school-activities-page.component';
import { SchoolLayoutComponent } from './layout/school-layout/school-layout.component';
import { RoomFinishedPageComponent } from './pages/room-finished-page/room-finished-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import {NewTeacherRoomPageComponent} from "./pages/new-teacher-room-page/new-teacher-room-page.component";
import {NewStudentRoomPageComponent} from "./pages/new-student-room-page/new-student-room-page.component";
import {ScheduleDetailsPageComponent} from "./pages/schedule-details-page/schedule-details-page.component";
import {RoomEnterComponent} from "./pages/room-enter/room-enter.component";
import {SchoolGroupsPageComponent} from "./pages/school-groups-page/school-groups-page.component";
import {TeacherAttendanceReportPageComponent} from "./pages/teacher-attendance-report-page/teacher-attendance-report-page.component";
import {TeacherRoomFinishedPageComponent} from "./pages/teacher-room-finished-page/teacher-room-finished-page.component";
import {TeacherRoomEnterPageComponent} from "./pages/teacher-room-enter-page/teacher-room-enter-page.component";
import {StudentRoomPageV3Component} from "./pages/student-room-page-v3/student-room-page-v3.component";
import {TeacherRoomPageV3Component} from "./pages/teacher-room-page-v3/teacher-room-page-v3.component";
import {ErrorComponent} from "./pages/error/error.component";
import {DeprecatedPageComponent} from "./pages/deprecated-page/deprecated-page.component";
import {DeprecationGuardService} from "./service/deprecation-guard.service";

const routes: Routes = [

  { path: 'oauth', component: OauthComponent },
  { path: 'forbidden', component: ForbiddenPageComponent},
  { path: 'deprecated', component: DeprecatedPageComponent },
  // manager
  {
    path: 'manager',
    component: ManagerMenuLayoutComponent,
    canActivate: [AuthGuardService, AuthRoleGuardService, DeprecationGuardService],
    // TODO: remove school teacher access
    data: { roles: ['SUPER_USER', 'SCHOOL_ADMIN', 'SCHOOL_TEACHER']},
    children: [
      {path: 'profiles', component: MangerProfilesPageComponent},
      {
        path: ':schoolId', component: SchoolLayoutComponent,
        children: [
          {path: 'dashboard', component: ManagerDashboardPageComponent},
          {path: 'templates', component: SchoolGroupsPageComponent},
          {path: 'templates/:id', component: RoomTemplateDetailsPageComponent},
          {path: 'schedules/:id', component: ScheduleDetailsPageComponent},
          {path: 'activity', component: SchoolActivitiesPageComponent},
          {path: 'teacher-attendance', component: TeacherAttendanceReportPageComponent}
        ]
      }
    ]
  },
  {
    path: 'rooms',
    children: [
      /*{
        path: 'create',
        // canActivate: [AuthGuardService, AuthRoleGuardService],
        component: CreateRoomPageComponent,
        // data: { roles: ['SCHOOL_TEACHER', 'SUPER_USER', 'SCHOOL_MANAGER']},
      },*/
      {
        path: ':roomUuid/details',
        component: RoomDetailsPageComponent
      }
    ]
  },
  {
    path: 'participants/:participantUuid',
    children: [
      {
        path: 'teacher-room',
        component: TeacherRoomEnterPageComponent,
        data: {target: 'teacher-room-video'}
      },
      {
        path: 'student-room',
        component: RoomEnterComponent,
        data: {target: 'student-room-video'}
      },
      {
        path: 'teacher-room-video',
        component: TeacherRoomPageV3Component
        //component: TeacherRoomPageComponent
      },
      {
        path: 'student-room-video',
        component: StudentRoomPageV3Component
      },
      {
        path: 'room-closed',
        component: RoomFinishedPageComponent
      },
      {
        path: 'teacher-room-closed',
        component: TeacherRoomFinishedPageComponent
      },
      {
        path: 'teacher-error/:errorCode',
        data: {role: 'teacher'},
        component: ErrorComponent
      },
      {
        path: 'student-error/:errorCode',
        data: {role: 'student'},
        component: ErrorComponent
      }
    ]
  },
  {path: '', component: MainPageComponent},
  {path: '404', component: NotFoundPageComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
