export class Region {
  constructor(public code: string, public name: string) {}
}

export class Regions {
  static regions: Region[] = [
    new Region('default', 'Default'),
    new Region('au1', 'Australia'),
    new Region('br1', 'Brazil'),
    new Region('de1', 'Germany'),
    new Region('ie1', 'Ireland'),
    new Region('in1', 'India'),
    new Region('jp1', 'Japan'),
    new Region('sh1', 'Singapore'),
    new Region('us1', 'US East Coast(Virginia)'),
    new Region('us2', 'US West Coast(Oregon)'),
  ];
}
