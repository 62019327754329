import {Component, Input, OnInit} from '@angular/core';
import {of, Subject} from "rxjs";
import {LessonProgressService} from "../../service/lesson-progress.service";
import {filter, switchMap, tap} from "rxjs/operators";
import {RoomProgressReport} from "../../model/server";
import {BooksRestService} from "../../service/books-service";
import {
  BookPageSelection,
  BookRendererControllerService,
  BookSelection
} from "../../service/book-renderer-controller.service";
import {Course} from "../../model/courses";
import {ContentItemType, Course as BookCourse} from "../../model/books";

@Component({
  selector: 'app-open-planned-book',
  templateUrl: './open-planned-book.component.html',
  styleUrls: ['./open-planned-book.component.css']
})
export class OpenPlannedBookComponent implements OnInit {

  private partiSubject = new Subject<string>();
  private progress: RoomProgressReport;
  private books: BookCourse[];
  @Input()
  set participantUuid(value: string) {
    this.partiSubject.next(value);
  }
  constructor(
    private progressService: LessonProgressService,
    private rest: BooksRestService,
    private bookRendererCtrl: BookRendererControllerService
  ) {
    this.partiSubject.pipe(
      filter(uuid => !!uuid),
      switchMap(uuid => this.loadBooks(uuid)),
      switchMap(uuid => progressService.readProgress(uuid)),
      tap<RoomProgressReport>(progress => this.progress = progress)
    ).subscribe();
  }

  ngOnInit() {
  }

  getBookPageSelection() {
    if (
      !this.progress
      || !this.books
      || !this.progress.details.course
      || !this.progress.details.startPage
    ) return null;
    const pageNumber = Number.parseInt(this.progress.details.startPage);
    if (!pageNumber) return null;
    const lessonCourse = Course.values.find( course => course.name.toLocaleLowerCase() === this.progress.details.course.trim().toLocaleLowerCase());
    if (!lessonCourse) return null;
    const bookCourse = this.books.find(bookCourse => bookCourse.code === lessonCourse.code);
    if (!bookCourse) return null;
    const stageFound = bookCourse.stages.find( stage => stage["content-items"].find(item =>
      item["page-from"] <= pageNumber
      && pageNumber <= item["page-to"]
      && (!item.type || item.type === ContentItemType.TEXT)
      )
    );
    if (!stageFound) return null;
    return new BookPageSelection(
      new BookSelection(bookCourse, stageFound), pageNumber
    );
  }

  hasPlannedJump() {
    return !!this.getBookPageSelection();
  }

  doJump(){
    this.bookRendererCtrl.openBookPage(this.getBookPageSelection());
  }

  private loadBooks(uuid: string) {
    if (this.books) return of(uuid);
    return this.rest.listProducts(uuid).pipe(
      tap( books => this.books = books),
      switchMap( _ => of(uuid))
    );
  }

  getSelectionDescription() {
    const bookPageSelection = this.getBookPageSelection();
    return `${bookPageSelection.book.course.courseName}, p. ${bookPageSelection.page}`;
  }
}
