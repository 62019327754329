<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/manager/{{schoolId}}/dashboard">Dashboard</a></li>
      <li class="breadcrumb-item" aria-current="page">Reports</li>
      <li class="breadcrumb-item active" aria-current="page">Group Usage</li>
    </ol>
  </nav>

<div class="card">
  <div class="card-body">
    Select dates range:
    <form>
      <div class="form-row">
        <div class="col-12 col-sm mb-2">
          <select name="report-month" [(ngModel)]="month" class="form-control">
            <option value="0">This month</option>
            <option value="-1">From last month</option>
            <option value="-2">2 months ago</option>
            <option value="-3">3 months ago</option>
            <option value="-6">6 months ago</option>
            <option value="-12">12 months ago</option>
          </select>
        </div>
        <div class="col mb-2">
          <select name="report-duration" [(ngModel)]="duration" class="form-control">
            <option value="1">for 1 month</option>
            <option value="2">for 2 months</option>
            <option value="3">for 3 months</option>
            <option value="6">for 6 months</option>
            <option value="12">for 12 months</option>
          </select>
        </div>
      </div>
    </form>

    <app-activity-report [dataProvider]="reportData"></app-activity-report>
    <div class="text-muted">*Refer to the <a href="https://s3.amazonaws.com/static.casa.callan.co.uk/documents/Online+Callan+Classroom+Guide.pdf" target="_blank">OCC guide</a> for more information.</div>
  </div>
</div>
<div [hidden]="!totalLessonsNumber" class="mb-4 mt-4">
  <div class="row">
    <div class="col-12 col-sm pt-2 mb-2"><h5 class="text-uppercase">Groups' lessons<span class="text-muted">({{totalLessonsNumber}})</span>:</h5></div>
    <div class="col text-right mb-2">
      <select class="form-control" name="group-id" [(ngModel)]="templateId">
        <option value="-1">All groups</option>
        <option *ngFor="let group of availableGroups" [value]="group.id">
          {{group.details.name}}
        </option>
      </select>
    </div>
  </div>
  <app-rooms-list [dataProvider]="roomsData" (count)="totalLessonsNumber = $event"></app-rooms-list>
</div>

