<div class="table-responsive-sm">
  <table class="table table-sm">
    <thead>
    <tr>
      <th>Teacher mail</th>
      <th>Lessons</th>
      <th>Duration [min]</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of getData()">
      <td>{{row.teacherEmail}}</td>
      <td>{{row.numberOfLessons}}</td>
      <td>{{calculateDuration(row)}}</td>
    </tr>
    <tr *ngIf="isEmpty()">
      <td colspan="3" class="text-center">No results</td>
    </tr>
    <tr *ngIf="notEmpty()">
      <th class="text-right">Total:</th>
      <th>{{calculateTotalLessons()}}</th>
      <th>{{calculateTotalDuration()}}</th>
    </tr>
    </tbody>
  </table>
</div>
