import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  RoomDef,
  Chart,
  Chat,
  ChatEntry,
  ClientReport,
  ParticipantSurvey,
  RoomTemplate,
  Pageable,
  Page,
  RoomProgressReport,
  ProgressDetails,
  AttendanceDetails,
  SchoolConfig,
  School,
  GroupTiming,
  RoomSettings,
  ChartSet,
  TeachersAttendanceReport,
  QualityReportEntry, IdentifiedRoomTemplate
} from '../model/server';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class UserCredentials {
  user: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class ServerRestService {
  killRoom(uuid: string): Observable<void> {
    return this.http.delete<void>(this.prepareUrl(`/participants/${uuid}/room`));
  }

  constructor(private http: HttpClient) { }

  prepareUrl(path: string): string {
    return environment.apiEndpoint + path;
  }


  // templates
  public saveOrCreateTemplate(schoolId: number, template: IdentifiedRoomTemplate) {
    return this.http.put<IdentifiedRoomTemplate>(this.prepareUrl(`/sec/school/${schoolId}/templates`), template);
  }

  public getTemplateById(schoolId: number, templateId: number) {
    return this.http.get<IdentifiedRoomTemplate>(this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}`));
  }

  public listTemplates(schoolId: number, query?: string, pageable?: Pageable) {
    let params = new HttpParams();
    if (query) {
      params = params.append('query', query);
    }
    if (pageable) {
      params = pageable.appendParams(params);
    }
    return this.http.get<Page<IdentifiedRoomTemplate>>(this.prepareUrl(`/sec/school/${schoolId}/templates`), {params});
  }

  public deleteTemplate(schoolId: number, templateId: number) {
    return this.http.delete(this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}`));
  }

  public createRoomWithTemplate(schoolId: number, templateId: number, roomSettings?: RoomSettings) {
    return this.http.put<RoomDef>(this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}/rooms`), roomSettings ? roomSettings : {});
  }

  public listSchoolRooms(schoolId: number, pageable?: Pageable, month?: number, duration?: number, teacherMail?: string) {
    let params: HttpParams = pageable ? pageable.getHttpParams() : new HttpParams();
    if (month != null) {
      params = params.append('month', month.toString());
    }
    if (duration != null) {
      params = params.append('duration', duration.toString());
    }
    if (teacherMail != null) {
      params = params.append("teacherMail", teacherMail);
    }
    return this.http.get<Page<RoomDef>>(this.prepareUrl(`/sec/school/${schoolId}/rooms`), {params});
  }

  public listSchooTemplatelRooms(schoolId: number, templateId: number, pageable?: Pageable, month?: number, duration?: number) {
    let params: HttpParams = pageable ? pageable.getHttpParams() : new HttpParams();
    if (month != null) {
      params = params.append('month', month.toString());
    }
    if (duration != null) {
      params = params.append('duration', duration.toString());
    }
    return this.http.get<any>(this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}/rooms`), {params});
  }

  public getRoom(roomUuid: string): Observable<RoomDef> {
    return this.http.get<RoomDef>(this.prepareUrl('/rooms/' + roomUuid)).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  private calculateClockShew(room: RoomDef) {
    if (room && room.serverTime) {
      room.clockSkew = new Date().getTime() - room.serverTime;
    }
    return room;
  }

  public createRoom(room: RoomDef, credentials: UserCredentials): Observable<RoomDef> {
    const auth = window.btoa(credentials.user + ':' + credentials.password);
    return this.http.put<RoomDef>(this.prepareUrl('/rooms/create'), room, {
      headers: {
        Authorization : 'Basic ' + auth
      }
    });
  }

  public createRoomSecured(room: RoomDef): Observable<RoomDef> {
    return this.http.put<RoomDef>(this.prepareUrl('/sec/create-room'), room);
  }

  public findRoomByParticipant(uuid: string): Observable<RoomDef> {
    return this.http.get<RoomDef>(this.prepareUrl('/participants/' + uuid + '/room')).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public initializeRoomByParticipant(uuid: string): Observable<RoomDef> {
    return this.http.post<RoomDef>(this.prepareUrl('/participants/' + uuid + '/room'),{}).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public listChartSets(participantUuid: string): Observable<ChartSet[]> {
    return this.http.get<ChartSet[]>(this.prepareUrl(`/participants/${participantUuid}/room/chart-sets`))
  }

  public findRoomPreviewByParticipant(uuid: string): Observable<RoomDef> {
    return this.http.get<RoomDef>(this.prepareUrl('/participants/' + uuid + '/room-preview')).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public saveQualityReports(
    participantUuid: string,
    entries: QualityReportEntry[]
    ): Observable<any> {
    return this.http.post<any>(
      this.prepareUrl(
        `/participants/${participantUuid}/quality-report?currentTime=${new Date().getTime()}`
        ),
      entries);
  }

  public startLesson(participantUuid: string): Observable<RoomDef> {
    return this.http.post<RoomDef>(this.prepareUrl('/participants/' + participantUuid + '/room/start'), null).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public stopLesson(participantUuid: string): Observable<RoomDef> {
    return this.http.post<RoomDef>(this.prepareUrl('/participants/' + participantUuid + '/room/stop'), null).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public updateChart(participantUuid: string, chart: Chart): Observable<RoomDef> {
    return this.http.put<RoomDef>(this.prepareUrl('/participants/' + participantUuid + '/room/chart'), chart).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public getChat(participantUuid: string): Observable<Chat> {
    return this.http.get<Chat>(this.prepareUrl('/participants/' + participantUuid + '/room/chat'));
  }

  public postChatMessage(participantUuid: string, message: string): Observable<ChatEntry> {
    return this.http.post<ChatEntry>(this.prepareUrl('/participants/' + participantUuid + '/room/chat'), message);
  }

  public changeStudentActivationState(participantUuid: string, studentId: string, state?: boolean): Observable<RoomDef> {
    const requestBody = (state != null) ? {state} : null;
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${participantUuid}/room/${studentId}/activation`), requestBody).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public changeRoomAudioState(participantUuid: string, state?: boolean): Observable<RoomDef> {
    const requestBody = (state != null) ? {state} : null;
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${participantUuid}/room/audio`), requestBody).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public changeStudentFocusState(participantUuid: string, studentId: string, state?: boolean): Observable<RoomDef> {
    const requestBody = (state != null) ? {state} : null;
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${participantUuid}/room/${studentId}/focus`), requestBody).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public changeStudentAudioState(participantUuid: string, studentId: string, state: boolean): Observable<RoomDef> {
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${participantUuid}/room/${studentId}/audio`), {state}).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  public changeStudentVideoState(participantUuid: string, studentId: string, state: boolean): Observable<RoomDef> {
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${participantUuid}/room/${studentId}/video`), {state}).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  changeSelfVideoState(selfUuid: string, state: boolean) {
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${selfUuid}/room/self-video`), {state}).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  changeSelfAudioState(selfUuid: string, state: boolean) {
    return this.http.post<RoomDef>(this.prepareUrl(`/participants/${selfUuid}/room/self-audio`), {state}).pipe(
      map( room => this.calculateClockShew(room))
    );
  }

  updateRoomVideoStatsReportFlag(
    schoolId: number,
    templateId: number,
    roomId: string,
    state: boolean
  ) {
    return this.http.post<any>(
      this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}/rooms/${roomId}/video-stats-report`),
      { state }
    )
  }

  public postClientReport(participantUuid: string, clientReport: ClientReport) {
    return this.http.put<void>(this.prepareUrl('/participants/' + participantUuid + '/room/client'), clientReport);
  }

  public postParticipantSurvey(participantUuid: string, survey: ParticipantSurvey) {
    return this.http.put<void>(this.prepareUrl('/participants/' + participantUuid + '/room/survey'), survey);
  }

  public getRoomProgress(roomUuid: string) {
    return this.http.get<RoomProgressReport>(this.prepareUrl(`/rooms/${roomUuid}/progress`));
  }

  public getRoomNotes(teacherUuid: string): Observable<{[key: string]: string}> {
    return this.http.get<{[key: string]: string}>(this.prepareUrl(`/participants/${teacherUuid}/room/notes`));
  }

  public getProgressDetails(participantUuid: string) {
    return this.http.get<ProgressDetails>(this.prepareUrl(`/participants/${participantUuid}/room/progress/details`));
  }

  public getRoomProgressByTeacher(participantUuid: string) {
    return this.http.get<RoomProgressReport>(this.prepareUrl(`/participants/${participantUuid}/room/progress`));
  }

  public saveRoomProgressByTeacher(participantUuid: string, details: ProgressDetails) {
    return this.http.post<RoomProgressReport>(this.prepareUrl(`/participants/${participantUuid}/room/progress`), details);
  }

  public saveRoomAttendanceByTeacher(participantUuid: string, attendance: AttendanceDetails[]) {
    return this.http.post<RoomProgressReport>(this.prepareUrl(`/participants/${participantUuid}/room/progress/attenders`), attendance);
  }

  public getRoomProgressHistoryByTeacher(participantUuid: string, pageable?: Pageable) {
    const params: HttpParams = pageable ? pageable.getHttpParams() : null;
    return this.http.get<Page<RoomProgressReport>>(this.prepareUrl(`/participants/${participantUuid}/room/progress-history`), {params});
  }

  public getRoomProgressHistory(roomUuid: string, pageable?: Pageable) {
    const params: HttpParams = pageable ? pageable.getHttpParams() : null;
    return this.http.get<Page<RoomProgressReport>>(this.prepareUrl(`/rooms/${roomUuid}/progress-history`), {params});
  }

  public getTemplateHistoryProgress(schoolId: number, templateId: number, pageable?: Pageable) {
    const params: HttpParams = pageable ? pageable.getHttpParams() : null;
    return this.http.get<Page<RoomProgressReport>>(
      this.prepareUrl(`/sec/school/${schoolId}/templates/${templateId}/progress-history`),
      {params});
  }

  public uploadLogoFile(schoolId: number, fileToUpload: File, publicAccess = false) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const req = new HttpRequest('PUT', this.prepareUrl(`/sec/school/${schoolId}/config/logo`), formData, {reportProgress: true});
    return this.http.request<SchoolConfig>(req);
  }

  public getSchoolData(schoolId: number) {
    return this.http.get<School>(this.prepareUrl(`/sec/school/${schoolId}`));
  }

  public getSchoolConfig(schoolId: number) {
    return this.http.get<SchoolConfig>(this.prepareUrl(`/sec/school/${schoolId}/config`));
  }

  public getBillingReport(schoolId: number, monthOffset: number, durationMonths: number) {
    const params = new HttpParams()
    .append('month', monthOffset.toString())
    .append('duration', durationMonths.toString());
    return this.http.get<GroupTiming[]>(this.prepareUrl(`/sec/school/${schoolId}/reports/billing`), {params});
  }

  public getTeacherAttendanceReport(schoolId: number, monthOffset: number, durationMonths: number):
    Observable<TeachersAttendanceReport[]> {
    const params = new HttpParams()
      .append('month', monthOffset.toString())
      .append('duration', durationMonths.toString());
    return this.http.get<TeachersAttendanceReport[]>(this.prepareUrl(`/sec/school/${schoolId}/reports/teachers`), {params});
  }

}
