<div class="card card-primary shadow mb-2">
    <div class="card-body">
        <div class="text-center">
            <div class="my-5">
                <h1 class="text-center">The video classroom <strong class="text-danger">{{getRoomName()}}</strong> has been created!</h1>
            </div>
            <p>The participants have been notified by email.
                Here is the list of all the links. 
                If any of the participants haven't received the email, 
                please copy and paste the relevant link below and send it to them 
                <br>(click to copy the link).</p>
            <ul class="list-unstyled">
                <li>Teacher:</li>
                <li>{{getTeacherName()}} - Link: <a tabindex="" (click)="copyToClipboard(createLink(teacherParti))" style="cursor: pointer;">{{createLink(teacherParti)}}</a></li>
                <li>Students:</li>
                <li *ngFor="let parti of studentsPartis">
                    {{parti.name}} - Link: <a tabindex="" (click)="copyToClipboard(createLink(parti))"  style="cursor: pointer;">{{createLink(parti)}}</a>
                </li>
            </ul>
            
        </div>
    </div>
</div>

<div class="card-group mt-3 mb-3">
    <div *ngIf="progress" class="card">
        <div class="card-body">
            <h5 class="card-title">Lesson progress</h5>
            <dl class="row">
                <dt class="col-sm-3">Para:</dt>
                <dd class="col-sm-9">{{progress.details.paragraph}}</dd>
                <dt class="col-sm-3">Last HW:</dt>
                <dd class="col-sm-9">{{progress.details.lastHw}}</dd>
                <dt class="col-sm-3">Reading:</dt>
                <dd class="col-sm-9">{{progress.details.reading}}</dd>
                <dt class="col-sm-3">Dict:</dt>
                <dd class="col-sm-9">{{progress.details.dictation}}</dd>
                <dt class="col-sm-3">Comment:</dt>
                <dd class="col-sm-9">{{progress.details.comment}}</dd>
            </dl>
        </div>
    </div>  
    <div class="w-100 d-md-none"><!-- wrap --></div>
    <div *ngIf="progress" class="card">
        <div class="card-body">
            <h5 class="card-title">Attendance</h5>
            <ul>
                <li *ngFor="let attender of progress.attenders" [ngClass]="{'text-success': attender.attended, 'text-danger': !attender.attended }">{{attender.participant.name}}</li>
            </ul>
        </div>
    </div>
    <div class="w-100"><!-- wrap --></div>
    <div *ngIf="room" class="card">
        <div class="card-body">
            <h5 class="card-title">Lesson time</h5>
            <p>The times reported by the teacher</p>
            <dl class="row">
                <dt class="col-sm-5">Classroom created:</dt>
                <dd class="col-sm-7">{{getCreateDate() | date: "dd MMM yyyy HH:mm"}}</dd>
                <dt *ngIf="getLessonStartDate()" class="col-sm-5">Lesson was started:</dt>
                <dd *ngIf="getLessonStartDate()" class="col-sm-7">{{getLessonStartDate() | date: "dd MMM yyyy HH:mm"}}</dd>
                <!--<dt *ngIf="getTimerStartDate()" class="col-sm-5">Timer was started at (but it wasn't stopped yet):</dt>
                <dd *ngIf="getTimerStartDate()" class="col-sm-7">{{getTimerStartDate() | date: "dd MMM yyyy HH:mm"}}</dd> -->
                <dt class="col-sm-5">Total timer count:</dt>
                <dd class="col-sm-7">{{getTotalTime()}} min</dd>
                
            </dl>
        </div>
    </div>
    <div class="w-100 d-md-none"><!-- wrap --></div>
    <div *ngIf="room" class="card">
        <div class="card-body">
            <h5 class="card-title">System details</h5>
            <p>Video connection times</p>
            <dl class="row">
                <dt *ngIf="getPCreated()" class="col-sm-6">First participant connected:</dt>
                <dd *ngIf="getPCreated()" class="col-sm-6">{{getPCreated() | date: "dd MMM yyyy HH:mm"}}</dd>
                <!--<dt *ngIf="getPUpdated()" class="col-sm-3">Updated:</dt>
                <dd *ngIf="getPUpdated()" class="col-sm-9">{{getPUpdated() | date: "dd MMM yyyy HH:mm"}}</dd>-->
                <dt *ngIf="getPEndTime()" class="col-sm-6">Classroom closed:</dt>
                <dd *ngIf="getPEndTime()" class="col-sm-6">{{getPEndTime() | date: "dd MMM yyyy HH:mm"}}</dd>
                <dt *ngIf="getPDuration()" class="col-sm-6">Video connection duration:</dt>
                <dd *ngIf="getPDuration()" class="col-sm-6">{{getPDuration()}} min</dd>
                <dt class="col-sm-6">Status:</dt>
                <dd class="col-sm-6">{{getPCStatus()}}</dd>
            </dl>
        </div>
   </div>
</div>

<div [hidden]="historyLength === 0" class="mt-3 mb-5">
    <h6>Previous lesson history:</h6>
    <app-progress-history-list (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
</div>


