import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  BookBreakpointComponent,
  BookPageSelection,
  BookRendererControllerService
} from "../../service/book-renderer-controller.service";
import {BooksRestService} from "../../service/books-service";
import {Subscription} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {map, tap} from "rxjs/operators";
import {HotkeyEvent, HotkeysService, ScaleBookFontSize} from "../../service/hotkeys.service";
import {XorEncryptor} from "../../service/helpers/xor-encryptor";

declare var $;

class BreakpointGroup {
  scrollOffset = 0;
  constructor(public items: Array<any>) {
  }
}

@Component({
  selector: 'app-book-renderer',
  templateUrl: './book-renderer.component.html',
  styleUrls: ['./book-renderer.component.css']
})
export class BookRendererComponent implements OnInit, OnDestroy, BookBreakpointComponent {
  private bookPageSubscription: Subscription;
  @Input()
  participantUuid: string;
  @ViewChild('bookContainer', {static: true})
  bookContainer: ElementRef;
  @ViewChild('contentContainer', {static: true})
  contentContainer: ElementRef;
  @Output()
  displayChart = new EventEmitter<string>();
  content: SafeHtml = "";
  zoom = 1.0;
  private currentBreakpoint = 0;
  private bookUpdateObserver: MutationObserver;
  //private breakpoints: number[];
  private breakpointGroups: Array<BreakpointGroup>;
  private chartsToDisplay: any[];
  private currentChartSelected: number = null;
  private hotKeyEventSubscription: Subscription;


  constructor(private bookRendererControllerService: BookRendererControllerService,
              private rest: BooksRestService,
              private sanitizer: DomSanitizer,
              private hotkeysService: HotkeysService
              ) {
    this.bookPageSubscription = bookRendererControllerService
      .currentBookPageSelection
      .subscribe(it => this.selectBook(it));
    bookRendererControllerService.renderer = this;
    this.hotKeyEventSubscription = hotkeysService.hotkeyEventSubject.subscribe( event => this.handleHotHeyEvent(event));
  }

  ngOnInit() {
    this.bookUpdateObserver = new MutationObserver(mutations => {
      this.updateBreakpoints();
    });
    this.bookUpdateObserver.observe(this.contentContainer.nativeElement,
      { attributes: false, childList: true, characterData: false });
  }

  ngOnDestroy(): void {
    this.bookPageSubscription.unsubscribe();
    this.bookRendererControllerService.renderer = null;
    if (this.hotKeyEventSubscription != null) {
      this.hotKeyEventSubscription.unsubscribe();
    }
  }

  private selectBook(pageRequest: BookPageSelection) {
    if (pageRequest == null) {
      return;
    }
    if (pageRequest.page == 0) {
      this.content = this.sanitizer.bypassSecurityTrustHtml("<notification>Please, put in the page number below.</notification>");
      this.hotkeysService.setShowingTheBookPage(false);
      return;
    }
    this.rest.getBookContent(
      this.participantUuid,
      pageRequest.book.course,
      pageRequest.book.stage,
      pageRequest.page,
      pageRequest.size
    )
      .pipe(
        map( encrypted => this.decrypt(encrypted)),
        tap(_ => this.scrollContainerUp())
      )
      .subscribe(
      {
        next: it => {
          this.content = this.sanitizer.bypassSecurityTrustHtml(it);
          this.hotkeysService.setShowingTheBookPage(true);
        },
        error: err => {
          this.content = this.sanitizer.bypassSecurityTrustHtml("<error>Page was not found</error>");
          this.hotkeysService.setShowingTheBookPage(false);
        }
      }
    )
  }

  private scrollContainerUp() {
    this.bookContainer.nativeElement.scrollTo(0, 0);
  }

  private updateBreakpoints() {
    if (this.bookContainer.nativeElement.childCount == 0) return;
    this.calculateJumpBreakpoints();
    //this.resolveBreakpointValue();
    this.jumpToCurrentBreakpoint();
  }

  public getCurrentBreakpoint() {
    return this.currentBreakpoint;
  }

  public jumpToBreakpoint(value: number): boolean {
    if (value < 0 || value >= this.breakpointGroups.length) return false;
    this.currentBreakpoint = value;
    this.jumpToCurrentBreakpoint();
    return true;
  }

  public setInitialBreakpointAfterPageLoad(command: number) {
    if (command !== BookRendererControllerService.JUMP_TO_THE_FIRST && command !== BookRendererControllerService.JUMP_TO_THE_LAST) {
      throw new Error("the value isn't a command. Use JUMP_TO_THE_FIRST or JUMP_TO_THE_LAST");
    }
    this.currentBreakpoint = command;
  }

  private filterByChild(keyElements: NodeList): any {
    const result = [];
    for (let i = 0 ; i < keyElements.length ; i++) {
      const element = keyElements[i] as any;
      if (element.querySelectorAll("li, p").length == 0) {
        result.push(element);
      }
    }
    return result;
  }

  private calculateJumpBreakpoints() {
    const displayHeight = this.bookContainer.nativeElement.clientHeight;
    const keyElements = this.filterByChild(this.bookContainer.nativeElement
      .querySelectorAll('page-number, qa, hwinst, hwline, postqains, preqainst, img, lessonnumber, dict-cont, chart, table.headwords-like, subhwline, idiom, er, section-title, li, p'));
    const breakpoints = [0];
    const breakpointGroups: Array<BreakpointGroup> = [];
    let previousFrameBreakpoint = 0;
    let currentFrameBreakpoint = 0;
    let elementInFrame = 0;
    let currentBreakpointGroup: Array<any> = [];
    for (let i = 0 ; i < keyElements.length ; i++) {
      const currentKeyElement = keyElements[i];
      currentFrameBreakpoint = (currentKeyElement.offsetTop + currentKeyElement.offsetHeight) * this.zoom;
      elementInFrame++;
      currentBreakpointGroup.push(currentKeyElement);
      // break if elements is more than page over the previous
      if (currentFrameBreakpoint - previousFrameBreakpoint > displayHeight) {
        if (elementInFrame > 1) {
          // typical - rewind by 1 element
          i--;
          currentBreakpointGroup.splice(currentBreakpointGroup.length - 1, 1);
          currentFrameBreakpoint = (keyElements[i].offsetTop + keyElements[i].offsetHeight) * this.zoom;
        } // other case will be handled in next if statement
        breakpoints.push(currentFrameBreakpoint);
        // this is very specific case. If element is larger than screen size we keep it on the
        if (currentBreakpointGroup.length == 1 && currentBreakpointGroup[0].offsetHeight * this.zoom > displayHeight){
          const partials = Math.ceil( (currentBreakpointGroup[0].offsetHeight * this.zoom ) /  ( displayHeight * 0.8 ) ); //keep 20% additional space
          for (let partial = 0; partial < partials; partial++) {
            const partialGroup = new BreakpointGroup(currentBreakpointGroup);
            partialGroup.scrollOffset = partial;
            breakpointGroups.push(partialGroup);
          }
        } else {
          breakpointGroups.push(new BreakpointGroup(currentBreakpointGroup));
        }
        currentBreakpointGroup = [];
        previousFrameBreakpoint = currentFrameBreakpoint;
        elementInFrame = 0;
      }
    }
    if (currentBreakpointGroup.length > 0) {
      breakpointGroups.push(new BreakpointGroup(currentBreakpointGroup));
    }
    this.breakpointGroups = breakpointGroups;
  }

  private highlightCurrentBreakpointGroup(){
    let toHighlight: Array<any> = null;
    for (let groupNb = 0 ; groupNb < this.breakpointGroups.length ; groupNb++) {
      if (groupNb === this.currentBreakpoint) {
        toHighlight = this.breakpointGroups[groupNb].items;
      } else {
        this.breakpointGroups[groupNb].items.forEach( el => el.style.opacity = '.3');
      }
    }
    if (toHighlight) {
      toHighlight.forEach(el => el.style.opacity = 'unset');
    }
  }

  private prepareChartsForCurrentBreakpointGroup() {
    const allGroupItems = this.breakpointGroups[this.currentBreakpoint];
    if (allGroupItems == null) {
      this.chartsToDisplay = null;
      return;
    }
    this.chartsToDisplay = allGroupItems.items.filter( element => element.localName === 'chart' && element.hasAttribute('ref-id'));
    this.currentChartSelected = null;
  }

  private jumpToCurrentBreakpoint() {
    const displayHeight = this.bookContainer.nativeElement.clientHeight;
    if (!this.breakpointGroups) return;
    if (this.currentBreakpoint < 0) {
      this.currentBreakpoint = 0;
      this.bookContainer.nativeElement.scrollTop = 0;
      this.highlightCurrentBreakpointGroup();
      this.prepareChartsForCurrentBreakpointGroup();
      return;
    }
    if (this.currentBreakpoint >= this.breakpointGroups.length) {
      this.currentBreakpoint = this.breakpointGroups.length - 1;
      const breakpointGroup = this.breakpointGroups[this.currentBreakpoint];
      if (breakpointGroup == null || breakpointGroup.items.length === 0) return;
      this.bookContainer.nativeElement.scrollTop = breakpointGroup.items[0].offsetTop * this.zoom;
      this.highlightCurrentBreakpointGroup();
      this.prepareChartsForCurrentBreakpointGroup();
      return;
    }

    const breakpointGroup = this.breakpointGroups[this.currentBreakpoint];
    let partialOffset = displayHeight * 0.8 * breakpointGroup.scrollOffset;
    if (partialOffset < 0) partialOffset = 0;
    $(this.bookContainer.nativeElement).animate({ scrollTop: breakpointGroup.items[0].offsetTop * this.zoom + partialOffset }, 400);
    this.highlightCurrentBreakpointGroup();
    this.prepareChartsForCurrentBreakpointGroup();
  }

  private decrypt(encrypted: string): string {
    return XorEncryptor.toString(
      new XorEncryptor(this.participantUuid, 24)
        .encrypt(
          XorEncryptor.str2ab(encrypted)
        ));
  }

  switchToNextChartOnFocusedBreakpoint(): boolean {
    if (!this.chartsToDisplay || this.chartsToDisplay.length === 0) return false;
    if (this.currentChartSelected == null) {
      this.currentChartSelected = -1;
    }
    this.currentChartSelected++;
    const chartToDisplay = this.chartsToDisplay[Math.floor(this.currentChartSelected % this.chartsToDisplay.length)];
    this.displayChart.emit(chartToDisplay.getAttribute('ref-id'));
    this.breakpointGroups
      .forEach( bpg =>
        bpg.items
          .filter( bp => bp.localName === 'chart' && bp.hasAttribute('ref-id'))
          .forEach( bp => bp.classList.remove('chart-selected')));
    chartToDisplay.classList.add('chart-selected');
    return true;
  }

  getZoom() {
    return {zoom: `${this.zoom}`};
  }

  private handleHotHeyEvent(event: HotkeyEvent) {
    if (! (event instanceof ScaleBookFontSize) ) return;
    this.scaleBook(event.factor > 0 ? 1.1 : 0.9);
  }

  private scaleBook(scaleFactor: number) {
    this.zoom = this.zoom * scaleFactor;
    if (this.zoom < 0.3) this.zoom = 0.3;
    if (this.zoom > 4) this.zoom = 4;
    this.calculateJumpBreakpoints();
    this.jumpToBreakpoint(0);
  }
}
