import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookSelection} from "../book-list/book-list.component";
import {BookSelection as ControllerBookSelection} from "../../service/book-renderer-controller.service";
import {BookPageSelection, BookRendererControllerService} from "../../service/book-renderer-controller.service";
import {ToolboxControllerService} from "../../service/toolbox-controller.service";

@Component({
  selector: 'app-teacher-book-toolbox',
  templateUrl: './teacher-book-toolbox.component.html',
  styleUrls: ['./teacher-book-toolbox.component.css']
})
export class TeacherBookToolboxComponent implements OnInit {
  @Input()
  participantUuid: String;
  @Output()
  displayChart = new EventEmitter<string>();

  private selection: BookSelection;
  private currentPageRendered: BookPageSelection;

  constructor(private bookRendererControllerService: BookRendererControllerService) {
    bookRendererControllerService.currentBookPageSelection.subscribe(
      val => this.currentPageRendered = val
    )
  }

  isPageRendered() {
    return this.currentPageRendered != null;
  }

  ngOnInit() {
  }

  openBook(selection: BookSelection) {
    this.selection = selection;
    this.bookRendererControllerService.openBook(new ControllerBookSelection(
      selection.course,
      selection.stage
    ));
  }

  onDisplayChart(chartId: string) {
    this.displayChart.emit(chartId);
  }
}
