<app-modal-v2 #modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Unexpected issue</h5>
    </div>
    <div class="modal-body">
      <p>You have been unexpectedly disconnected.</p>
      <div class="text-right">
        <button type="button" class="btn btn-danger mr-1" (click)="closeRoom.emit()">Close room</button>
        <button type="button" class="btn btn-primary" (click)="reconnect.emit()">Reconnect</button>
       </div>
    </div>
  </div>
</app-modal-v2>
