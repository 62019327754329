import {Injectable} from "@angular/core";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {AttendanceDetails, ProgressDetails, RoomProgressReport} from "../model/server";
import {ServerRestService} from "./server-rest.service";
import {debounceTime, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LessonProgressService {
  private progressSubject: Subject<RoomProgressReport> = null;
  private userUuid: string;
  private progressDetailsChangesQueue = new Subject<ProgressDetails>();
  private attendanceChangesQueue = new Subject<AttendanceDetails[]>();
  constructor(
    private rest: ServerRestService
  ) {
    this.progressDetailsChangesQueue.pipe(
      debounceTime(1000)
    ).subscribe( currentProgressDetails => this.saveProgress(currentProgressDetails))
    this.attendanceChangesQueue.pipe(
      debounceTime(1000)
    ).subscribe( attendances => this.saveAttendances(attendances));
  }

  readProgress(participantUuid: string): Observable<RoomProgressReport> {
    this.userUuid = participantUuid;
    if (!this.progressSubject) {
      this.progressSubject = new ReplaySubject<RoomProgressReport>(1);
      this.rest.getRoomProgressByTeacher(participantUuid).subscribe(
        progress => {
          this.progressSubject.next(progress);
        }
      );
    }
    return this.progressSubject;
  }

  public attendanceChanged(attendances: AttendanceDetails[]) {
    this.attendanceChangesQueue.next(attendances);
  }

  public progressChanged(currentProgressDetails: ProgressDetails){
    this.progressDetailsChangesQueue.next(currentProgressDetails);
  }

  private saveProgress(currentProgressDetails: ProgressDetails) {
    this.rest.saveRoomProgressByTeacher(this.userUuid, currentProgressDetails).pipe(
      tap(currentProgress => this.progressSubject.next(currentProgress))
    ).subscribe();
  }

  private saveAttendances(attendances: AttendanceDetails[]) {
    this.rest.saveRoomAttendanceByTeacher(this.userUuid, attendances).pipe(
      tap(currentProgress => this.progressSubject.next(currentProgress))
    ).subscribe();
  }
}
