import { Component, OnInit } from '@angular/core';
import {timer} from "rxjs";

@Component({
  selector: 'app-deprecated-page',
  templateUrl: './deprecated-page.component.html',
  styleUrls: ['./deprecated-page.component.css']
})
export class DeprecatedPageComponent implements OnInit {
  sec = 10

  constructor() {
    timer(100, 1000).subscribe(s => {
      this.sec = 10 - s
      if(this.sec == 0)
        this.redirect()
    })
  }

  ngOnInit(): void {}

  redirect() {
    window.location.href = "https://callan.app"
  }
}
