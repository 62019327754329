<app-modal-v2 #dialog>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Create a new schedule plan</h5>
      <button type="button" class="close" (click)="dialog.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
      <div class="modal-body" >
        <div *ngIf="serverReportedErrors" class="alert alert-danger">
          Check for errors:<br>
          {{serverReportedErrors | json}}
        </div>
        <div class="form-group row">
          <label class="col-12 col-sm-4 col-form-label">First lesson on: </label>
          <div class="col-12 col-sm-8">
            <input class="form-control"
                   type="datetime-local"
                   placeholder="yyyy-mm-dd HH:mm"
                   required pattern="\d{4}-\d{2}-\d{2}[T| ]?\d{2}:\d{2}"
                   [ngClass]="{ 'is-invalid': f.submitted && dateFrom.invalid}"
                   #dateFrom="ngModel"
                   [(ngModel)]="formInput.dateFrom"
                   name="dateFrom">
            <div *ngIf="f.submitted && dateFrom.invalid" class="invalid-feedback">
              <div *ngIf="dateFrom.errors.required">Field is required</div>
              <div *ngIf="dateFrom.errors.pattern">Remember about the correct pattern yyyy-MM-dd HH:mm</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-12 col-sm-4 col-form-label">Duration: </label>
          <div class="col-12 col-sm-8">
            <input class="form-control"
                   type="number"
                   placeholder="minutes"
                   [(ngModel)]="formInput.durationMin"
                   name="durationMs">
          </div>
        </div>
        <div >Then repeat on the following days:
        </div>
        <div class="row">
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-mon" [(ngModel)]="formInput.recurring['EVERY_MONDAY']" name="recurringMonday">
              <label class="form-check-label" for="rt-mon">
                Monday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-tue" [(ngModel)]="formInput.recurring['EVERY_TUESDAY']" name="recurringTuesday">
              <label class="form-check-label" for="rt-tue">
                Tuesday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-wed" [(ngModel)]="formInput.recurring['EVERY_WEDNESDAY']" name="recurringWednesday">
              <label class="form-check-label" for="rt-wed">
                Wednesday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-thu" [(ngModel)]="formInput.recurring['EVERY_THURSDAY']" name="recurringThursday">
              <label class="form-check-label" for="rt-thu">
                Thursday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-fri" [(ngModel)]="formInput.recurring['EVERY_FRIDAY']" name="recurringFriday">
              <label class="form-check-label" for="rt-fri">
                Friday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-sat" [(ngModel)]="formInput.recurring['EVERY_SATURDAY']" name="recurringSaturday">
              <label class="form-check-label" for="rt-sat">
                Saturday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-sun" [(ngModel)]="formInput.recurring['EVERY_SUNDAY']" name="recurringSunday">
              <label class="form-check-label" for="rt-sun">
                Sunday
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row mt-3" *ngIf="hasAnyRecurring()">
          <label class="col-12 col-sm-4 col-form-label">Repeat until:</label>
          <div class="col-12 col-sm-8">
            <input class="form-control"
                   type="date"
                   placeholder="yyyy-mm-dd"
                   [(ngModel)]="formInput.dateTo"
                   name="dateTo"
                   required pattern="\d{4}-\d{2}-\d{2}"
                   [ngClass]="{ 'is-invalid': f.submitted && dateTo.invalid}"
                   #dateTo="ngModel"
            >
            <div *ngIf="f.submitted && dateTo.invalid" class="invalid-feedback">
              <div *ngIf="dateTo.errors.required">Field is required</div>
              <div *ngIf="dateTo.errors.pattern">Remember about the correct pattern yyyy-MM-dd</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-right">
        <button class="btn btn-secondary" type="button" (click)="dialog.hide()">Cancel</button>
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
</app-modal-v2>
