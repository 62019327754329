
export class XorEncryptor {
  private readonly key: Uint8Array;
  constructor(keyAsString: string, private hashValue: number) {
    this.key = new TextEncoder().encode(keyAsString)
  }

  encrypt(data: Uint8Array): Uint8Array {
    const res = new Uint8Array(data.length)
    for (let i = 0 ; i < data.length ; i ++ ) {
      res[i] = data[i] ^ this.key[ Math.floor(i % this.key.length)] ^ this.hashValue;
    }
    return res;
  }

  static str2ab(input: string): Uint8Array {
    const str = atob(input);
    const buf = new Uint8Array(str.length);

    for (let i = 0; i < str.length ; i++) {
      buf[i] = str.charCodeAt(i);
    }
    return buf;
  }

  static toString(input: Uint8Array): string {
    return new TextDecoder().decode(input);
  }
}
