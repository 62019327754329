import { Injectable } from '@angular/core';
import { CanActivate,  RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthorizationTokenData, TokenValidator } from './AuthorizationTokenData';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.getCurrentAuthData().pipe(
      map( authData =>
        TokenValidator.isValid(authData)),
      tap( isAuthorised => {
        if (!isAuthorised) {
          this.auth.login(state.url);
        }
      })
    );
  }
}
