
  <div class="container py-5">
    <div *ngIf="loaded && isOffline()">
      <h3 class="text-center">This lesson is <strong>OFFLINE</strong></h3>
      <div class="mt-4">
        <dl class="row">
          <dt class="col-12 col-sm-4">Group name:</dt>
          <dd class="col-12 col-sm-8">{{getRoomName()}}</dd>
          <dt class="col-12 col-sm-4">Time: </dt>
          <dd class="col-12 col-sm-8">{{getRoomTime()  | date: 'EEEE (dd/MM) HH:mm'}}</dd>
          <dt class="col-12 col-sm-4">Teacher:</dt>
          <dd class="col-12 col-sm-8">{{getName(getTeacher())}}</dd>
          <dt class="col-12 col-sm-4" *ngIf="getNote('group_note')">Group notes:</dt>
          <dd class="col-12 col-sm-8" *ngIf="getNote('group_note')">{{getNote('group_note')}}</dd>
          <dt class="col-12 col-sm-4" *ngIf="getNote('schedule_note')">This lesson notes:</dt>
          <dd class="col-12 col-sm-8" *ngIf="getNote('schedule_note')">{{getNote('schedule_note')}}</dd>
        </dl>
      </div>
    </div>
    <h1 class="display-3 text-center" *ngIf="loaded && !isOffline()">Lesson is finished</h1>
    <h5 class="text-center" *ngIf="loaded && !isOffline()">The classroom for this lesson has been closed.
      <span *ngIf="!surveySubmitted && loaded && !isOffline()"><br/>
      Please verify or correct the lesson form:</span></h5>
      <div *ngIf="!surveySubmitted">
       <app-teacher-lesson-progress variant="post" [participantUuid]="participantUuid"></app-teacher-lesson-progress>
        <div class="text-center mt-2 mb-5">
          <button type="button" class="btn btn-primary btn-lg" (click)="surveyModal.show()" *ngIf="loaded && !isOffline()">Fill in the quality survey!</button>
        </div>
      </div>
    <div *ngIf="surveySubmitted" class="my-5">
      <h2 class="text-center">Thank you!</h2>
      <h5 class="text-center">You may close this window now.</h5>
    </div>
  </div>
  <app-modal-v2 #surveyModal>
    <div class="modal-content">
      <div class="modal-body">
        <app-survey role="Teacher" [participantUuid]="participantUuid" (surveySubmit)="onSurveySubmit()"></app-survey>
      </div>
    </div>
  </app-modal-v2>
