import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from "rxjs";
import {ParticipantData} from "../../../model/internal";
import {ServerRestService} from "../../../service/server-rest.service";

@Component({
  selector: 'app-teacher-room-timer',
  templateUrl: './teacher-room-timer.component.html',
  styleUrls: ['./teacher-room-timer.component.css']
})
export class TeacherRoomTimerComponent implements OnInit, OnDestroy {

  private mSelfData: ParticipantData;
  private timeSubscription: Subscription;

  @Input()
  private participantUuid: string;
  @Input()
  set selfData(data: ParticipantData) {
    this.mSelfData = data;
    if (data) {
      this.setupTimerTaskState(data.roomDef.lessonStartDate != null)
    }
  }

  get selfData() {
    return this.mSelfData;
  }

  constructor(private serverRest: ServerRestService,) { }

  ngOnInit() {
  }

  getTime() {
    if (!this.selfData || ! this.selfData.roomDef) {
      return '';
    }

    let totalTime: number = this.selfData.roomDef.totalTime;
    const startTime = this.selfData.roomDef.timerStartDate as any as number;
    if (startTime) {
      const clockSkew = this.selfData.roomDef.clockSkew ? this.selfData.roomDef.clockSkew : 0;
      const startTimeInLocal = startTime + clockSkew;

      totalTime += new Date().getTime() - startTime;
    }

    const date = new Date(null);
    date.setSeconds(totalTime / 1000);
    return date.toISOString().substr(11, 8);
  }

  changeTimerState() {
    if (this.isStarted()) {
      this.handleStop();
    } else {
      this.handleStart();
    }
  }

  setupTimerTaskState(runningState: boolean) {
    if (runningState && !this.timeSubscription) {
      this.timeSubscription = interval(1000).subscribe();
    } else if (!runningState && this.timeSubscription) {
      this.timeSubscription.unsubscribe();
      this.timeSubscription = null;
    }
  }


  handleStart() {
    this.serverRest.startLesson(this.participantUuid).subscribe();
    this.setupTimerTaskState(true);
  }

  handleStop() {
    this.serverRest.stopLesson(this.participantUuid).subscribe();
    this.setupTimerTaskState(false);
  }

  isStarted() {
    if (!this.selfData) {
      return false;
    }
    return (this.selfData.roomDef.timerStartDate);
  }

  ngOnDestroy() {
    this.setupTimerTaskState(false);
  }

}
