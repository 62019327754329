import {Injectable} from "@angular/core";
import {ChartHostService} from "../../service/chart-host-service";
import {ShelfService} from "../../service/shelf.service";
import {RoomContextHolder} from "../../service/helpers/RoomContextHolder";
import {RoomParticipantsDataService} from "../../service/helpers/room-participants-data-service";
import {LoggerService} from "../../service/logger.service";

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class TeacherLayoutService {
  layoutPadding = 10;

  constructor(
    private chartHostService: ChartHostService,
    private shelfService: ShelfService,
    private contextHolder: RoomContextHolder,
    private participantsHolder: RoomParticipantsDataService,
    private l: LoggerService
  ) {
    this.registerForRelayoutEvents();
  }

  public listenForResize() {
    $(window).resize(_ => this.applyLayout());
  }

  public relayout() {
    setTimeout(_ => this.applyLayout(), 100);
  }


  private sizeComponent(component: string, width: number, height?: number){
    $(component).css("width", width + "px");
    if (height) {
      $(component).css("height", height + "px");
    } else {
      $(component).css("height", width + "px");
    }
  }

  private positionComponent(component: string, x: number, y: number) {
    $(component).css("left", x + "px");
    $(component).css("top", y + "px");
  }


  private layoutComponent(component: string, hide: boolean, x: number, y: number, width: number, height?: number) {
    this.sizeComponent(component, width, height);
    this.positionComponent(component, x, y);
    if (hide) {
      $(component).css("opacity", 0);
    } else {
      $(component).css("opacity", 1);
    }
  }

  private layoutSecondary(secondaryVideoX, secondaryVideoY, secondaryVideoSize) {
    this.layoutComponent("#primary-parti",
      false,
      secondaryVideoX,
      secondaryVideoY,
      secondaryVideoSize);
  }

  private layoutBook(workingAreaX, workingAreaY, innerWorkingAreaWidth, innerWorkingAreaHeight) {
    this.layoutComponent("#book-container",
      false,
      workingAreaX,
      workingAreaY,
      innerWorkingAreaWidth, innerWorkingAreaHeight);
  }

  private isShowChart() {
    return this.chartHostService.chartSelected != null;
  }

  hasActiveStudent() {
    return this.participantsHolder && this.participantsHolder.hasActiveParticipant();
  }

  private layoutChart(workingAreaX, workingAreaY, innerWorkingAreaWidth, innerWorkingAreaHeight, workingAreaXHidden, workingAreaYHidden) {
    if (this.isShowChart()) {
      this.layoutComponent("#chart-container",
        false,
        workingAreaX,
        workingAreaY,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    } else {
      this.layoutComponent("#chart-container",
        true,
        workingAreaXHidden,
        workingAreaYHidden,
        innerWorkingAreaWidth, innerWorkingAreaHeight);
    }
  }

  public applyLayout() {
    this.l.t("teacher relayout");
    const w = window.innerWidth;
    const h = window.innerHeight - this.layoutPadding;

    const isHorizontal = w > h;
    const side = Math.round(w * 0.4);
    // self size is 1/3 of side but with full top padding and half of bottom
    const selfSize = Math.round(side / 3);
    const selfInnerSize =  selfSize - this.layoutPadding * 1.5
    // book space is rest of below the self but with both paddings
    const bookHeight = h - selfSize;
    const bookInnerHeight = bookHeight - 0.5 * this.layoutPadding;
    const workingAreaPreferredHeight =  (w - side) / 1.33;
    const activeMinHeight = h / 3;
    const maxWorkingAreaHeight = h - activeMinHeight;
    const workingAreaHeight = Math.min(workingAreaPreferredHeight, maxWorkingAreaHeight);
    const workingAreaWidth = w - side;
    const workingAreaInnerWidth = workingAreaWidth - 1.5 * this.layoutPadding;
    const activeHeight = h - workingAreaHeight;
    const activeInnerSize = activeHeight - this.layoutPadding * 1.5;
    const othersSize = (activeInnerSize) / 2;
    const participantsListHeight = othersSize + 30;
    const fullPartiMaxHeight = this.shelfService.showShelf ? h - participantsListHeight : h;
    const activeFullSize = Math.min(fullPartiMaxHeight, workingAreaWidth);
    const activeFullInnerSize = activeFullSize - this.layoutPadding * 1.5;

    this.layoutComponent("#self-parti",false, side - selfSize + this.layoutPadding, this.layoutPadding, selfInnerSize);
    const detailsWidth = side - selfSize;
    const detailsInnerWidth = detailsWidth - this.layoutPadding;
    this.layoutComponent("#room-details", false, this.layoutPadding, this.layoutPadding, detailsInnerWidth, selfInnerSize);
    this.layoutBook(this.layoutPadding, selfSize + this.layoutPadding * 0.5, side - this.layoutPadding * 1.5, bookInnerHeight);

    const showActive = this.hasActiveStudent();
    const showLarge = showActive && !this.isShowChart();
    const activeSize = showLarge ? activeFullInnerSize : activeInnerSize;
    const activeY = showActive ? this.layoutPadding : - activeSize;
    let horizontalCenter = 0;
    let verticalCenter = 0;
    if (showLarge) {
      if (fullPartiMaxHeight > workingAreaWidth) {
        // screen is limited by width, image has to be centered vertically
        verticalCenter = (fullPartiMaxHeight - activeFullSize ) / 2;
      } else {
        // screen is limited by height and has to be centered horizontally
        horizontalCenter = ( workingAreaWidth - activeFullSize) / 2;
      }
    }

    this.layoutSecondary(side +  this.layoutPadding * 0.5 + horizontalCenter, activeY + verticalCenter, activeSize);

    const shelfWidth = showActive && showLarge || !showActive ? w - side : w - side - activeInnerSize;
    const shelfLeft = showActive && showLarge || !showActive ? side + this.layoutPadding * 0.5 : side + activeInnerSize + this.layoutPadding ;
    const shelfTop = showActive && showLarge ? h - participantsListHeight : this.layoutPadding;
    const shelfHeight = participantsListHeight;


    $("#participants-shelf .participant").each( function()  {
      $(this).css("width", othersSize + "px");
      $(this).css("height", othersSize + "px");
    });

    const hideShelf = !this.shelfService.showShelf;
    const shelfLeftAfterHideApplied = this.shelfService.showShelf ? shelfLeft : w + shelfWidth;
    this.layoutComponent("#participants-shelf", hideShelf, shelfLeftAfterHideApplied, shelfTop, shelfWidth, shelfHeight);

    this.layoutChart(side + this.layoutPadding * 0.5, activeHeight + this.layoutPadding * 0.5, workingAreaWidth - this.layoutPadding, workingAreaHeight, side + this.layoutPadding * 0.5, h + workingAreaHeight );


    //layoutComponent("#logo-container", logoPosX, logoPosY, logoSize);
  }


  private registerForRelayoutEvents() {
    this.chartHostService.chartSelectedObservable.subscribe( _ => this.relayout());
    this.shelfService.showShelfObservable.subscribe( _ => this.relayout());
    this.listenForContextRelayoutEvents();
  }

  listenForContextRelayoutEvents() {
    this.contextHolder.selfObservable.subscribe( _ => this.relayout());
    this.contextHolder.participantsObservable.subscribe( _ => this.relayout());
  }
}
