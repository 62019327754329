import { Injectable } from '@angular/core';
import * as Video from 'twilio-video';
import { RoomDef, ParticipantDef } from '../model/server';
import {Observable, from, Subject, forkJoin} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {LocalTrack, Log, RemoteParticipant, Room, Track} from "twilio-video";
import * as Sentry from '@sentry/browser';
import {Severity} from "@sentry/browser";
import LogLevelDesc = Log.LogLevelDesc;
import {LoggerService} from "./logger.service";
import {setPriority} from "os";

export class VideoRenderPreferences {
  public constructor(public readonly width: number,public readonly height: number) {}

  public static Low = new VideoRenderPreferences(128, 96);
  public static Medium = new VideoRenderPreferences(512, 384);
  public static High = new VideoRenderPreferences(640, 480);
}

@Injectable({
  providedIn: 'root'
})
export class TwilioServiceService {
  private localTrackPublications: Video.LocalTrackPublication[];

  constructor(
    private l: LoggerService
  ) { }

  roomParticipantsSubject: { [sid: string]: Subject<Video.RemoteParticipant> } = {};
  roomParticipantsDisconected: {[sid: string]: Subject<Video.RemoteParticipant>} = {};
  roomsBySid: { [sid: string]: Video.Room} = {};
  rooomDefBySid: { [sid: string]: RoomDef} = {};

  setPriority(priority: Video.Track.Priority) {
    if (!this.localTrackPublications) return;
    this.l.d("setting prority to:",priority);
    this.localTrackPublications.forEach( track => track.setPriority(priority));
  }

  public connect(roomDef: RoomDef, participant: ParticipantDef, audio?: MediaDeviceInfo, video?: MediaDeviceInfo): Observable<Video.Room> {
    let width = 512;
    //const frameRate = 25;
    const localTrackParams: any = {
      audio : true,
      video : {width/*, frameRate*/},
    };
    let maxSubscriptionBitrate = 2500000;
    let maxAudioBitrate = 16000;
    let maxVideoBitrate = 160000;

    if (participant.role === "Teacher") {
      maxSubscriptionBitrate = 5000000;
      maxAudioBitrate = 32000;
      maxVideoBitrate = 1200000;
      width = 640;
    }

    if (audio && audio.deviceId !== 'default') {
      localTrackParams.audio = { deviceId: audio.deviceId , workaroundWebKitBug180748: true};
    }
    if (video) {
      localTrackParams.video = { deviceId: video.deviceId/*, frameRate */};
    }

    return from(Video.createLocalTracks(localTrackParams)).pipe(
      switchMap(localTracks => {
        return from(Video.connect(participant.accessToken, {
          video: {width/*, frameRate */ },
          bandwidthProfile: {
            video: {
              //maxSubscriptionBitrate: ,
              //maxTracks: 5,
              //mode: "presentation",
              //mode: "grid",
              mode: "collaboration",
              trackSwitchOffMode: "predicted",
              maxSubscriptionBitrate,
              clientTrackSwitchOffControl: "manual",
              contentPreferencesMode: "manual"
            }
          },
          preferredVideoCodecs: [
            { codec: 'VP8', simulcast: true }
          ],
          dominantSpeaker: true,
          enableDscp: true,
          maxAudioBitrate,
          maxVideoBitrate,
          networkQuality: {
            local : 3, remote : 3
          },
          tracks: localTracks
        })).pipe(
          tap(videoRoom => this.storeRoomData(roomDef,videoRoom)),
          tap<Video.Room>( videoRoom => {
            this.localTrackPublications = [...videoRoom.localParticipant.tracks.values()];
            this.setPriority(this.solvePriority(participant));
          })
        )
      })
    )
  }

  public unpublishLocals() {
    if (!this.localTrackPublications) return;
    this.localTrackPublications.forEach(it => it.unpublish())
    this.localTrackPublications = [];
  }

  public switchOffParticipantVideoTrack(parti: Video.RemoteParticipant): boolean {
    let anyWasSwitchedOn = false;
    let trackWasMissing = false;
    parti.videoTracks.forEach(publication => {
      if (publication && publication.track && !publication.track.isSwitchedOff) {
        anyWasSwitchedOn = true;
        publication.track.switchOff();
      }
      if (!publication || !publication.track) {
        trackWasMissing = true;
      }
    });

    // if (trackWasMissing) {
    //   Sentry.captureMessage(`track was missing while switching off for parti with sid: ${parti.sid}`);
    // }

    return anyWasSwitchedOn;
  }

  public switchOnParticipantVideoTrack(parti: Video.RemoteParticipant): boolean {
    let anyWasSwitchedOff = false;
    let trackWasMissing = false;
    parti.videoTracks.forEach(publication => {
      if (publication && publication.track && publication.track.isSwitchedOff) {
        anyWasSwitchedOff = true;
        publication.track.switchOn();
      }
      if (!publication || !publication.track) {
        trackWasMissing = true;
      }
    });

    // if (trackWasMissing) {
    //   Sentry.captureMessage(`track was missing while switching on for parti with sid: ${parti.sid}`);
    // }

    return anyWasSwitchedOff;
  }

  public setupRenderDimensions(parti: Video.RemoteParticipant, renderDimensions: VideoRenderPreferences) {
    parti.videoTracks.forEach(publication => publication && publication.track && publication.track.setContentPreferences({renderDimensions}));
  }

  getSid(room: Video.Room): string {
    return room.sid;
  }

  storeRoomData(roomDef: RoomDef, room: Video.Room): void {
    const sid = this.getSid(room);
    this.roomsBySid[sid] = room;
    this.rooomDefBySid[sid] = roomDef;
    //this.listenForRoomStateEvents(room);
  }

  private finishSubject(subject: Subject<any>) {
    if (subject) {
      subject.complete();
    }
  }

  public disconnect(room: Video.Room) {
    const sid = this.getSid(room);
    this.finishSubject(this.roomParticipantsSubject[sid]);
    this.finishSubject(this.roomParticipantsDisconected[sid]);
    delete this.roomParticipantsSubject[sid];
    delete this.roomParticipantsDisconected[sid];
    delete this.roomsBySid[sid];
    delete this.rooomDefBySid[sid];
    room.disconnect();
  }

  public localParticipant(room: Video.Room): Video.LocalParticipant {
    return room.localParticipant;
  }

  public participants(room: Video.Room): Map<Video.Participant.SID, Video.RemoteParticipant> {
    return room.participants;
  }

  public listenForDisconnectedParticipants(room: Video.Room): Observable<any> {
    const sid = this.getSid(room);
    let participantsSubject = this.roomParticipantsDisconected[sid];
    if (participantsSubject) {
      return participantsSubject;
    }
    participantsSubject = new Subject<Video.RemoteParticipant>();
    this.roomParticipantsDisconected[sid] = participantsSubject;
    room.on('participantDisconnected', participant => participantsSubject.next(participant));
    return participantsSubject;
  }

  public listenForNewParticipants(room: Video.Room): Observable<any> {
    const sid = this.getSid(room);
    let participantsSubject = this.roomParticipantsSubject[sid];
    if (participantsSubject) {
      return participantsSubject;
    }
    participantsSubject = new Subject<Video.RemoteParticipant>();
    this.roomParticipantsSubject[sid] = participantsSubject;
    room.on('participantConnected', participant => {
      participantsSubject.next(participant);
      //this.listenForParticipantStateEvents(participant);
    });
    return participantsSubject;
  }


  public solvePriority(participant: ParticipantDef): Video.Track.Priority {
    if (participant.role === "Teacher") return 'high';
    if (participant.active) return 'standard';
    return 'low';
  }

  setupLoggingLevel(twilioLoggingLevel: LogLevelDesc) {
    const Video = require('twilio-video');
    const { Logger } = Video;
    const logger = Logger.getLogger('twilio-video');
    logger.setLevel(twilioLoggingLevel);
  }

  private listenForRoomStateEvents(room: Video.Room) {
    room.on("reconnecting", error => {
      this.l.d("reconnecting event", error, room.state);
    });
    room.on("reconnected", () => {
      this.l.d("reconnected event", room.state);
    });
    room.on("disconnected", error => {
      this.l.d("disconnected event", error, room.state);
    });
    room.on("participantReconnecting", parti => {
      this.l.d("disconnected event", parti, room.state);
    });
    room.on("participantReconnected", parti => {
      this.l.d("disconnected event", parti, room.state);
    });
  }

  private listenForParticipantStateEvents(participant: RemoteParticipant) {
    participant.on("reconnecting", () => {
      this.l.d(`reconnecting ${participant.sid} with state ${participant.state}`);
    });
    participant.on("reconnected", () => {
      this.l.d(`reconnecting ${participant.sid} with state ${participant.state}`);
    });
  }
}
