import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalV2Component} from "../modal-v2/modal-v2.component";
import {RoomContextHolder} from "../../service/helpers/RoomContextHolder";
import {RoomDataService} from "../../service/helpers/room-data-service";
import {SentryService} from "../../service/sentry.service";
import {HotkeysService} from "../../service/hotkeys.service";

@Component({
  selector: 'app-crash-report',
  templateUrl: './crash-report.component.html',
  styleUrls: ['./crash-report.component.css']
})
export class CrashReportComponent implements OnInit {
  @ViewChild("dialog2", {static: true})
  modal: ModalV2Component;

  comment: string;

  constructor(
    private sentryService: SentryService,
    private hotKeyService: HotkeysService
  ) { }

  open() {
    this.modal.show(true);
  }

  ngOnInit() {
    this.modal.state.subscribe(isOpened => this.hotKeyService.setCrashReportIsOpened(isOpened));
  }

  attempt = 1;

  submitAnIssue() {
    this.sentryService.submitMessage(`user feedback (${this.attempt++})`, this.comment);
    this.modal.hide();
    this.comment = null;
  }
}
