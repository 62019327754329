import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToolboxControllerService {
  toolboxShowSubject = new BehaviorSubject(false);
  selectedToolset = new BehaviorSubject('Lesson');
}
