<div *ngIf="!hideDeviceSelection && !mContextHolder.initialized && !error &&!connecting" class="capture-devices-page-wrapper" (contextmenu)="onContextMenu()">
  <div class="modal fade show" tabindex="-1" style="display: block;">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" translate>video-preview.title</h5>
        </div>
        <div class="modal-body">
          <app-capture-devices-new (selected)="deviceSelected($event)"></app-capture-devices-new>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="connecting" class="capture-devices-page-wrapper text-light text-center py-5" (contextmenu)="onContextMenu()">
  <h2 translate>room.student.connecting</h2>
</div>
<div *ngIf="error" class="capture-devices-page-wrapper text-light text-center py-5" (contextmenu)="onContextMenu()">
  <h2>room.student.error</h2>
</div>

<div
    id="main-container"
    *ngIf="mContextHolder.initialized && participantsHolder && !connecting"
    class="v3" [ngClass]="roomLayout"
   (contextmenu)="onContextMenu()"
>
  <div id="self-parti" class="participant self">
    <app-participant-video *ngIf="selfHolder.localParticipant" [participantData]="selfHolder.localParticipant"></app-participant-video>
    <div class="status-icons pointer-events-none">
      <app-participant-signal-strength [participant]="selfHolder.localParticipant"></app-participant-signal-strength>
    </div>
    <div class="active-frame strong pointer-events-none"
         (@active-student.done)="activeStudentAnimationEnd()"
         [@active-student]="getActiveStudentAnimationState()"
    ></div>
    <div class="your-turn pointer-events-none"
         [@active-student-warn]="getActiveStudentWarnAnimationState()"
    >
      <div class="exclamation pointer-events-none">
        <div class="circle"></div>
        <div class="circle-fill"></div>
        <i class="your-turn-icon fa fa-exclamation"></i>
      </div>
    </div>
  </div>

  <div id="primary-parti" class="participant">
    <div style="display: unset" *ngIf="isShowActive()">
      <div *ngFor="let parti of participantsHolder.getActiveParticipantAsArray() ; trackBy: participantsHolder.participantsTrackBy" style="display: unset">
        <app-participant-video [participantData]="parti" [playAudio]="false" ></app-participant-video>
        <span class="participant-name">{{participantsHolder.getPartiName(parti)}}</span>
        <div class="status-icons" *ngIf="amIHidden()">
          <app-participant-signal-strength [participant]="parti"></app-participant-signal-strength>
        </div>
      </div>
    </div>
    <div class="active-frame"></div>
  </div>

  <div id="teacher-parti" class="participant">
    <div style="display: unset" *ngIf="participantsHolder.hasTeacher()">
      <div style="display: unset" *ngFor="let parti of participantsHolder.getTeacherAsArray() ; trackBy: participantsHolder.participantsTrackBy">
        <app-participant-video [participantData]="parti" (connectionIsWorking)="this.trackTeacherConnectionIsWorking($event)"></app-participant-video>
        <span class="participant-name">{{participantsHolder.getPartiName(parti)}}</span>
        <div class="status-icons" *ngIf="amIHidden()">
          <app-participant-signal-strength [participant]="parti"></app-participant-signal-strength>
        </div>
      </div>
    </div>
    <div *ngIf="!participantsHolder.hasTeacher()" class="missing text-center">
      <h2 translate>room.student.teacher-waiting</h2>
    </div>
  </div>

  <div id="logo-container">
    <img *ngIf="selfHolder.hasLogo()" [src]="selfHolder.getLogo()" >
  </div>

  <div id="chart-container">
    <app-chart-host
      *ngIf="selfHolder && selfHolder.selfData"
      [roomUuid]="selfHolder.selfData.roomDef.uuid"
      [chart]="chartHostService.chartSelected"></app-chart-host>
  </div>

  <div id="book-container">
    <app-student-book-renderer *ngIf="isShowBook()" [participantUuid]="selfHolder.participantUuid" [bookContent]="getBookContent()"></app-student-book-renderer>
  </div>

  <div id="participants-shelf">
    <button type="button" class="btn btn-danger mr-1" id="shelf-button" (click)="shelfService.closeShelf()">
      <span *ngIf="shelfService.getCloseInCounter()">({{shelfService.getCloseInCounter()}})<br/></span>
      <i class="fa fa-chevron-right"></i>

    </button>
    <div class="parti-list" >
      <div class="participant" *ngFor="let parti of participantsHolder.getStudents() ; trackBy: participantsHolder.participantsTrackBy">
        <app-participant-video [participantData]="parti"></app-participant-video>
<!--        <app-participant-video [showVideo]="showShelf" [participantData]="parti"></app-participant-video>-->
        <span class="participant-name">{{participantsHolder.getPartiName(parti)}}</span>
        <div class="status-icons" *ngIf="amIHidden()">
          <app-participant-signal-strength [participant]="parti"></app-participant-signal-strength>
        </div>
        <div class="active-frame" *ngIf="parti.participantDef.active"></div>
      </div>
    </div>
  </div>

  <div id="video-controls" class="btn-group">
    <button class="btn btn-danger" *ngIf="selfHolder.amIMuted()" (click)="participantsHolder.switchSelfAudio(selfHolder.amIMuted())"><i class="fa fa-microphone-slash fa-fw"></i></button>
    <button class="btn btn-danger" *ngIf="!selfHolder.amIMuted()" (click)="participantsHolder.switchSelfAudio(selfHolder.amIMuted())"><i class="fa fa-microphone fa-fw"></i></button>
    <button class="btn btn-danger" *ngIf="participantsHolder.isHidden(selfHolder.selfData)" (click)="participantsHolder.switchSelfVideo(participantsHolder.isHidden(selfHolder.selfData))"><i class="fa fa-video-slash fa-fw"></i></button>
    <button class="btn btn-danger" *ngIf="!participantsHolder.isHidden(selfHolder.selfData)" (click)="participantsHolder.switchSelfVideo(participantsHolder.isHidden(selfHolder.selfData))"><i class="fa fa-video fa-fw"></i></button>
    <button class="btn btn-danger" (click)="toolbox.switchToolbox()"><i class="fa fa fa-comment"></i></button>
    <div class="btn-group" role="group">
      <button class="btn btn-danger dropdown-toggle" type="button" id="settings-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa fa-ellipsis-h fa-fw"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="settings-dropdown">
        <div class="room-info text-muted p-2">
          <h6>{{selfHolder.getRoomName()}}</h6>
          <div class="text-center">
            <small><app-teacher-room-timer [participantUuid]="selfHolder.participantUuid" [selfData]="selfHolder.selfData"></app-teacher-room-timer></small>
          </div>

        </div>
        <div class="dropdown-divider"></div>
        <button type="button" class="dropdown-item" (click)="shelfService.openShelf()"><i class="fa fa-users fa-fw"></i> <span translate> room.student.menu.switch-others</span></button>
        <button type="button" class="dropdown-item d-fs-none" (click)="openFullScreen()"><i class="fa fa-expand-arrows-alt fa-fw"></i> <span translate> room.student.menu.fullscreen</span></button>
        <button type="button" class="dropdown-item d-none d-fs-block" (click)="closeFullScreen()"><i class="fa fa-compress-arrows-alt fa-fw"></i> <span translate> room.student.menu.exit-fullscreen</span></button>
        <button type="button" class="dropdown-item" (click)="crashReportComponent.open()"><i class="fa fa-bug fa-fw"></i> <span translate> room.student.menu.problem-report</span></button>
        <button type="button" class="dropdown-item mt-4" (click)="restart()"><i class="fa fa-retweet fa-fw"></i> <span > Restart</span></button>
<!--        <button type="button" class="dropdown-item mt-4" (click)="restartPartis()"><i class="fa fa-retweet fa-fw"></i> <span > Detach teacher video</span></button>-->
        <button type="button" class="dropdown-item mt-2" (click)="disconnect()"><i class="fa fa-phone-slash fa-fw"></i> <span translate> room.student.menu.disconnect</span></button>
      </div>
    </div>
  </div>

</div>

<app-student-toolbox *ngIf="selfHolder.selfData && selfHolder.selfData.participantDef"
                     #toolbox
                     [selfData]="selfHolder.selfData"
></app-student-toolbox>

<div *ngIf="isShowSurvey()">
  <app-modal>
    <app-survey role="Student" [participantUuid]="selfHolder.participantUuid" (surveySubmit)="onSurveySubmit()"></app-survey>
  </app-modal>
</div>

<div *ngIf="isShowCloseConfirmation()">
  <app-modal>
    <div style="padding:15px;">
      <h4 translate>general.are-sure</h4>
      <div class="text-right">
        <button class="btn btn-secondary mr-2 text-capitalize" (click)="closeConfirmationAnswer(false)" translate>general.no</button>
        <button class="btn btn-primary text-capitalize" (click)="closeConfirmationAnswer(true)" translate>general.yes</button>
      </div>
    </div>
  </app-modal>
</div>
<app-bad-connection
  *ngIf="mContextHolder.initialized && participantsHolder && !connecting"
  [participant]="selfHolder.localParticipant">
</app-bad-connection>
<app-crash-report #crashReportComponent ></app-crash-report>

<app-modal-v2 #unexpectedDisconnect>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" translate>room.student.unexp-disconnect.title</h5>
    </div>
    <div class="modal-body">
      <p translate>room.student.unexp-disconnect.message</p>
      <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="reconnect()" translate>room.reconnect-room-btn</button>
      </div>
    </div>
  </div>
</app-modal-v2>



