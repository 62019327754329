import { GenericDataProvider } from 'src/app/model/internal';
import { RoomDef, Pageable, Page } from 'src/app/model/server';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { Observable } from 'rxjs';
import { SearchingTerms } from '../../components/activity-report/activity-report.provider';

export class TemplateRoomsDataProvider extends GenericDataProvider<RoomDef, Pageable> {
    constructor(
                private schoolId: number,
                private templateId: number,
                searchingContextPath: string,
                searchingContext: SearchingContextService,
                private rest: ServerRestService,
                pageable: Pageable) {
      super(searchingContextPath, pageable, searchingContext);
    }
    doLoad(): Observable<Page<RoomDef>> {
      return this.rest.listSchooTemplatelRooms(this.schoolId, this.templateId, this.pageable.consumeInitial());
    }
    nextPage() {
      this.pageable = this.pageable.next();
    }
}

export class DashboardRoomsDataProvider extends GenericDataProvider<RoomDef, Pageable> {
    constructor(
                private schoolId: number,
                searchingContextPath: string,
                searchingContext: SearchingContextService,
                private rest: ServerRestService,
                pageable: Pageable) {
      super(searchingContextPath, pageable, searchingContext);
    }
    doLoad(): Observable<Page<RoomDef>> {
      return this.rest.listSchoolRooms(this.schoolId, this.pageable.consumeInitial());
    }
    nextPage() {
      this.pageable = this.pageable.next();
    }
}



export class SearchTermPageable extends Pageable {
  constructor(public term: SearchingTerms, page: number, size: number, sort?: string) {
    super(page, size, sort);
  }

  clone() {
    return new SearchTermPageable(this.term, this.page, this.size, this.sort);
  }
}

export class TemplateIdSearchTermPageable extends SearchTermPageable {
  constructor(public templateId: number, term: SearchingTerms, page: number, size: number, sort?: string) {
    super(term, page, size, sort);
  }
  clone() {
    return new TemplateIdSearchTermPageable(this.templateId, this.term, this.page, this.size, this.sort);
  }
}

export class FullRoomsDataProvider extends GenericDataProvider<RoomDef, TemplateIdSearchTermPageable> {

  constructor(private schoolId: number,
              path: string,
              context: SearchingContextService,
              private rest: ServerRestService,
              pageable: TemplateIdSearchTermPageable) {
                super(path, pageable, context);
              }

  doLoad(): Observable<Page<RoomDef>> {
    const pageable = this.pageable as TemplateIdSearchTermPageable;
    if (pageable.templateId > 0) {
      return this.rest.listSchooTemplatelRooms(this.schoolId, pageable.templateId,
        pageable.consumeInitial(),
        pageable.term.month,
        pageable.term.duration);
    } else {
      return this.rest.listSchoolRooms(this.schoolId,
        pageable.consumeInitial(),
        pageable.term.month,
        pageable.term.duration );
    }
  }
}

