import {ServerRestService} from 'src/app/service/server-rest.service';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {Chart, ParticipantDef, RoomDef} from 'src/app/model/server';
import {ParticipantData} from 'src/app/model/internal';
import {TwilioServiceService} from 'src/app/service/twilio-service.service';
import {iif, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {NotificationsService} from 'src/app/service/notifications.service';
import {FlipChartDefinitionsService} from "../flipchart-definitions";
import {QualityService} from "../quality.service";
import * as Video from 'twilio-video';
import {LocalParticipant, RemoteParticipant, RemoteVideoTrackPublication, Track} from 'twilio-video';
import {Injectable} from "@angular/core";
import {SentryService} from "../sentry.service";
import {environment} from "../../../environments/environment";
import {LoggerService} from "../logger.service";
import {VideoRoomMonitor} from "@twilio/video-room-monitor";
import {SocketServiceV2} from "../socket-v2.service";


export class UserActivationEvent {
  participantId: string;
  resultState: boolean;
  action: string;
  roomUuid: string;
}

interface RoomContextHolderI {
  /**
   * room context needs to be initialized first, field tells if is initialized
   */
  readonly initialized: boolean;
  /**
   * list of all participants
   */
  readonly participants: ParticipantData[];
  readonly participantsObservable: Observable<ParticipantData[]>;
  /**
   * self participant data
   */
  readonly selfData: ParticipantData;
  readonly selfObservable: Observable<ParticipantData>;

  /**
   * initialize the room
   * @param partiUuid self uuid
   * @param audio device
   * @param video device
   */
  init(partiUuid: string, audio: MediaDeviceInfo, video: MediaDeviceInfo): Observable<any>;

  /**
   * update room data structure with the provided chart
   * @param chart
   */
  setChart(chart: Chart): void;

  readonly roomDisconnectEvent: Observable<any>;
  readonly participantConnectedEvent: Observable<ParticipantData>;
  readonly participantDisconnectedEvent: Observable<ParticipantData>;
  readonly userActivationEvent: Observable<UserActivationEvent>;
  clear();
}

@Injectable({
  providedIn: 'root'
})
export class RoomContextHolder implements RoomContextHolderI{

    private _selfData: ParticipantData = null;
    private _selfObservable = new ReplaySubject<ParticipantData>(1);
    private _participants: Array<ParticipantData> = [];
    private _participantsObservable = new ReplaySubject<Array<ParticipantData>>(1);
    private _roomDisconnectEvent = new Subject<any>();
    private _participantConnectedEvent = new Subject<ParticipantData>();
    private _participantDisconnectedEvent = new Subject<ParticipantData>();
    private _userActivationEvent = new Subject<UserActivationEvent>();
    // array used to track connected participants between room data update event
    private registeredRoomParticipantsUuid: Array<string> = [];
    private mParticipantUuid: string;
    private _initialized = false;

    constructor(
      private serverRest: ServerRestService,
      private socketService: SocketServiceV2,
      private twilio: TwilioServiceService,
      private notificationService: NotificationsService,
      private chartsDefinitions: FlipChartDefinitionsService,
      private qualityService: QualityService,
      private l: LoggerService
    ) {}

    public init(partiUuid: string, audio: MediaDeviceInfo, video: MediaDeviceInfo): Observable<any> {
      this.mParticipantUuid = partiUuid;
      this._selfData = new ParticipantData();
      this.twilio.setupLoggingLevel(environment.twilioLoggingLevel)

      return this.serverRest.initializeRoomByParticipant(this.mParticipantUuid).pipe(
        tap( roomDef => this.updateRoomInfo(roomDef, this.mParticipantUuid)),
        switchMap( () =>  this.twilio.connect(this.selfData.roomDef, this.selfData.participantDef, audio, video)),
        switchMap( videoRoom =>
          iif( () => !!videoRoom,
            of(videoRoom),
            throwError(new Error('Browser not supported. <br> \
              It was not possible to connect to the video room. Check you browser settings.')))),
        tap( videoRoom => this.storeVideoRoom(videoRoom)),
        tap( videoRoom => this.extractLocalParticipant()),
        tap( videoRoom => this.listenForDisconnect(videoRoom)),
        tap ( videoRoom => this.resolveParticipants(videoRoom)),
        tap ( videoRoom => this.startParticipantsListening(videoRoom)),
        tap( () => this._selfObservable.next(this.selfData)),
        // initialize listeners
        tap ( roomDef => this.listenToRoomUpdates(this.selfData.roomDef.uuid)),
        tap ( _ => this._initialized = true )
      );
    }

    /*
   - socketService.disconnect()
   - this.twilio.stopListeningForDisconnectedParticipants(videoRoom)
   - this.twilio.stopListeningForNewParticipants(selfData.videoRoom)
   - for each of this.participants.participant remove vents (assigned in L301)
   - clear video room (selfData.videoRoom) events
   - clear events for this.selfData.participant (assigned in L301)
   - destroy video room (selfData.videoRoom)
   */
    clear() {
      this.socketService.disconnect().subscribe(_ => this.l.i("stompDisconnected"));
      if (this.selfData && this.selfData.videoRoom) {//bugfix
        this.selfData.videoRoom.removeAllListeners();
      }
      this.participants.forEach( parti => parti.participant.removeAllListeners());
      if (this.selfData && this.selfData.participant) {
        this.selfData.participant.removeAllListeners();
      }
      if (this.selfData && this.twilio) {
        this.twilio.disconnect(this.selfData.videoRoom);
      }

      // clear variables
      this._selfData = null;
      this._selfObservable.complete();
      this._selfObservable = new ReplaySubject<ParticipantData>(1);
      this._participants = [];
      this._participantsObservable.complete();
      this._participantsObservable = new ReplaySubject<Array<ParticipantData>>(1);
      this._roomDisconnectEvent.complete();
      this._roomDisconnectEvent = new Subject<any>();
      this._participantConnectedEvent.complete();
      this._participantConnectedEvent = new Subject<ParticipantData>();
      this._participantDisconnectedEvent.complete();
      this._participantDisconnectedEvent = new Subject<ParticipantData>();
      this._userActivationEvent.complete();
      this._userActivationEvent = new Subject<UserActivationEvent>();
      this.registeredRoomParticipantsUuid = [];
      this._initialized = false;
    }

    private listenForDisconnect(videoRoom: Video.Room) {
      videoRoom.once('disconnected', (room, error) => {
        this._roomDisconnectEvent.next(error);
      });
    }

    setChart(chart: Chart): void {
      this.selfData.roomDef.chart = chart;
      if (chart) {
        this.chartsDefinitions.getChartEntriesById().pipe(take(1)).subscribe(
          entries => {
            chart.entryAssigned = entries[chart.chartId];
            this._selfObservable.next(this.selfData);
          }
        )
      }
    }

    private updateRoomInfo(room: RoomDef, participantUuid: string): void {
      this.selfData.roomDef = room;
      this.selfData.local = true;
      this.selfData.participantDef = this.selfData.roomDef.participants.find ( p => p.uuid === participantUuid);
      if (this.selfData.roomDef.chart) {
        this.chartsDefinitions.getChartEntriesById().pipe(take(1)).subscribe(
          entries => this.selfData.roomDef.chart.entryAssigned = entries[this.selfData.roomDef.chart.chartId]
        )
      }
    }

    private listenToRoomUpdates(roomUuid: string): void {
      this.socketService.connect().pipe(
        switchMap( ctx => this.socketService.topicPlain(ctx, '/room/' + roomUuid + '/update'))
      ).subscribe(
        message => this.syncParticipationData(message)
      );
    }

    private syncParticipationData(message: any) {
      this.serverRest.findRoomByParticipant(this.mParticipantUuid).pipe(
       tap( roomDef => this.updateRoomInfo(roomDef, this.mParticipantUuid)),
       map ( _ => this.selfData.videoRoom ),
       tap<Video.Room> ( videoRoom => this.resolveParticipants(videoRoom))
      ).subscribe(
       _ => this._selfObservable.next(this.selfData)
      )

      if (message) {
        let messageObj: any = null;
        try {
          messageObj = JSON.parse(message);
        } catch (ignoreException) {}

        if (messageObj && messageObj.action === 'activation') {
          this.l.i('Its an activation message');
          this._userActivationEvent.next(messageObj as UserActivationEvent);
        }
      }
    }

    private storeVideoRoom(videoRoom: Video.Room): void {
      this.selfData.videoRoom = videoRoom;
      if (videoRoom) {
        this.l.i('got video room reference');
      } else {
        this.l.e('missing video room reference');
      }
      // todo: make it conditional
      if (environment.twilioLoggingLevel === "debug") {
        VideoRoomMonitor.registerVideoRoom(videoRoom);
        VideoRoomMonitor.openMonitor();
      }
    }

    private extractLocalParticipant(): void {
      this.selfData.participant = this.twilio.localParticipant(this.selfData.videoRoom);
      this.listenToParticipantStatsEvents(this.selfData);
    }

    private resolveParticipants(videoRoom: Video.Room): void {
      this._participants = [];

      // add online participants
      videoRoom.participants.forEach ((remoteParticipant: RemoteParticipant, sid: string ) => {
        const resolved = this.resolveParticipant(remoteParticipant);
        if (resolved.participantDef.secret) return;
        this.addParticipant(resolved);
      });

      // add offline participants
      this.selfData.roomDef.participants
        .filter( pCandidate =>
          pCandidate.offline &&
          pCandidate.uuid !== this.selfData.participantDef.uuid && pCandidate.role !== 'Teacher' &&
          !this.participants.find( p => p.participantDef.uuid === pCandidate.uuid)
        ).forEach( p =>
          this.addParticipant(this.resolveOffline(p))
      )
      this.notifyParticipantsUpdate();
    }

    private startParticipantsListening(videoRoom: Video.Room) {
      this.twilio.listenForNewParticipants(videoRoom).subscribe(
        p => {
          const resolved = this.resolveParticipant(p);
          if (resolved.participantDef.secret) return;
          this.l.i('connecting new participant');
          this.addParticipant(resolved);
          this.notifyParticipantsUpdate();
        }
      );

      this.twilio.listenForDisconnectedParticipants(videoRoom).subscribe(
        p => {
          const resolved = this.resolveParticipant(p);
          if (resolved.participantDef.secret) return;

          this.l.i('disconnecting participant');
          this.removeParticipant(resolved);
          this.notifyParticipantsUpdate();
        }
      );
    }

    private removeFromParticipantList(parti: ParticipantData): ParticipantData {
      if (parti.participantDef.offline) return;
      const participantFoundIndex = this.participants.findIndex( p => p.participantDef.uuid === parti.participantDef.uuid);
      let removed: ParticipantData = null;
      if (participantFoundIndex >= 0) {
        removed = this.participants[participantFoundIndex];
        this.l.i(`removing participant ${removed.participantDef.name}`);
        this.participants.splice(participantFoundIndex, 1);
      }
      return removed;
    }

    private removeParticipant(parti: ParticipantData): ParticipantData {
      const removed = this.removeFromParticipantList(parti);
      this.notifyParticipantsUpdate();
      if (parti != null && parti.participantDef != null && parti.participantDef.uuid != null) {
        const foundIndex = this.registeredRoomParticipantsUuid.indexOf(parti.participantDef.uuid);
        if (foundIndex >= 0) {
          this.registeredRoomParticipantsUuid.splice(foundIndex, 1);
          this._participantDisconnectedEvent.next(parti);
        }
      }
      return removed;
    }

    private addParticipant(parti: ParticipantData): void {
      let foundInRegistered = -1;
      if (parti.participantDef != null && parti.participantDef.uuid != null) {
        foundInRegistered = this.registeredRoomParticipantsUuid.indexOf(parti.participantDef.uuid);
        if (foundInRegistered < 0) {
          this._participantConnectedEvent.next(parti);
          this.listenToParticipantStatsEvents(parti);
          this.registeredRoomParticipantsUuid.push(parti.participantDef.uuid);
        }
      }
      this.removeFromParticipantList(parti);
      this.participants.push(parti);
    }

    private notifyParticipantsUpdate() {
      this._participantsObservable.next(this.participants);
    }

    private resolveParticipant(remoteParticipant: RemoteParticipant) {
      const resolvedData = new ParticipantData();
      resolvedData.participant = remoteParticipant;
      resolvedData.videoRoom = this.selfData.videoRoom;
      resolvedData.roomDef = this.selfData.roomDef;
      const uid: string = remoteParticipant.identity;
      resolvedData.participantDef = this.selfData.roomDef.participants.find( p => p.uuid === uid);

      return resolvedData;
    }

  private resolveOffline(p: ParticipantDef) {
      const data = new ParticipantData();
      data.participantDef = p;
      data.videoRoom = this.selfData.videoRoom;
      data.roomDef = this.selfData.roomDef;
      return data;
  }

  private listenToParticipantStatsEvents(parti: ParticipantData) {
    this.l.i("listening for network quality of " + parti.participantDef.name);
    parti.participant.on("networkQualityLevelChanged", (level, stats) => {
      this.qualityService.reportQuality(this.selfData, this.participants, parti, stats);
    });
  }

  get initialized() {
    return this._initialized;
  }

  get participants() {
    return this._participants;
  }

  get selfData(): ParticipantData {
    return this._selfData;
  }

  get selfObservable(): Observable<ParticipantData> {
    return this._selfObservable.asObservable();
  }

  get participantsObservable(): Observable<ParticipantData[]> {
    return this._participantsObservable.asObservable();
  }

  get roomDisconnectEvent(): Observable<any>{
      return this._roomDisconnectEvent;
  }
  get participantConnectedEvent(): Observable<ParticipantData>{
      return this._participantConnectedEvent.asObservable();
  }
  get participantDisconnectedEvent(): Observable<ParticipantData>{
      return this._participantDisconnectedEvent.asObservable();
  }
  get userActivationEvent(): Observable<UserActivationEvent>{
      return this._userActivationEvent.asObservable();
  }

  unpublishLocals() {
    this.twilio.unpublishLocals();
  }


}
