<div class="row justify-content-between w-100">
  <div class="col-auto">
    <div class="btn-group btn-group-sm mr-1 dropup" *ngIf="!isActionBookmark()">
      <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Menu
      </button>
      <div class="dropdown-menu">
        <button type="button" class="dropdown-item" (click)="openJump()">Jump [J]</button>
        <button type="button" class="dropdown-item" (click)="openReading()">Reading [R]</button>
        <button type="button" class="dropdown-item" (click)="openDictation()">Dictation [D]</button>
        <button type="button" class="dropdown-item" (click)="increaseFont()">Increase font [+]</button>
        <button type="button" class="dropdown-item" (click)="decreaseFont()">Decrease font [-]</button>
        <button type="button" class="dropdown-item" (click)="onClose()">Close Book</button>
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-warning mr-1" *ngIf="isRegularBookmark()" (click)="openBookmark()">Back to {{getBookmarkPage()}} [B]</button>
  </div>
  <div class="col" *ngIf="isActionBookmark()">
    <button type="button" class="btn btn-danger btn-sm btn-block"  (click)="openBookmark()">Finish {{getActionName()}} & Back to {{getBookmarkPage()}} [B]</button>
  </div>
  <div class="col-auto">
    <span class="page-number" [ngClass]="{shared: hasPageShared()}">[ {{currentPage}} ]</span>
  </div>
</div>

