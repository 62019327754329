import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Pageable, ParticipantDef, ProgressDetails, RoomDef, RoomProgressReport} from "../../model/server";
import {ServerRestService} from "../../service/server-rest.service";
import {RoomEnterComponent} from "../room-enter/room-enter.component";
import {LessonProgressService} from "../../service/lesson-progress.service";
import {Subscription} from "rxjs";
import {ParticipantRoomProgressProvider} from "../../service/helpers/progress-history-list.provider";
import {Constants} from "../../service/constants";
import {SearchingContextService} from "../../service/searching-context.service";
import {PingService} from "../../service/ping.service";

@Component({
  selector: 'app-teacher-room-enter-page',
  templateUrl: './teacher-room-enter-page.component.html',
  styleUrls: ['./teacher-room-enter-page.component.css']
})
export class TeacherRoomEnterPageComponent implements OnInit, OnDestroy {
  private readonly routeTarget: string;
  private room: RoomDef;
  private teacher: ParticipantDef;
  private self: ParticipantDef;
  private timer: any;
  participantUuid: string;
  progress: RoomProgressReport;
  private progressSubscription: Subscription;
  historyDataProvider: ParticipantRoomProgressProvider;
  private progressIsDirty = false;
  private roomNotes: { [key: string]: string };
  private plannedDateInLocal: number;


  constructor(activatedRoute: ActivatedRoute,
              private router: Router,
              private rest: ServerRestService,
              private progressService: LessonProgressService,
              private searchContext: SearchingContextService,
              private pingService: PingService
              ) {
    this.routeTarget = activatedRoute.snapshot.data['target'];
    activatedRoute.parent.paramMap.subscribe( params => {
      this.participantUuid = params.get('participantUuid');
      pingService.startPing(this.participantUuid);
      this.loadData();
    })
  }

  ngOnInit() {

  }

  private loadData() {
    this.rest.findRoomPreviewByParticipant(this.participantUuid).subscribe(room => {
      this.storeRoom(room);
      this.initializeRedirectWatch();
    });

    this.progressSubscription = this.progressService.readProgress(this.participantUuid).subscribe(progress => {
        this.progress = progress;
        this.progressIsDirty = false;
      }
    );
    this.setupHistoryDataProvider();
    this.readRoomNotes();

  }

  setupHistoryDataProvider(): void {
    const path = `unknown`;
    this.historyDataProvider =
      new ParticipantRoomProgressProvider(this.participantUuid, path, this.searchContext, this.rest,
        new Pageable(0, Constants.SmallPageSize, 'createDate,desc'));
  }

  private storeRoom(room: RoomDef) {
    this.room = room;
    this.plannedDateInLocal = (!room.plannedDate) ? room.plannedDate : (room.plannedDate - room.serverTime) + new Date().getTime();
    this.teacher = room.participants.find( p => p.role === 'Teacher');
    this.self = room.participants.find( p => p.uuid === this.participantUuid);
  }

  private initializeRedirectWatch() {
    if (!this.plannedDateInLocal) {
      this.navigateToRoom();
    }
    const connectionTime = this.plannedDateInLocal - new Date().getTime() - (60 * 1000);
    if (!this.isOffline() && connectionTime < 0) {
      this.navigateToRoom();
    }
    const currentTimeMs = new Date().getTime();
    const timeToWait = 1001 - currentTimeMs % 1000
    const nextThick = Math.min(connectionTime, timeToWait);
    this.timer = setTimeout(() => this.initializeRedirectWatch(), timeToWait) as any;

  }

  gePartiName() {
    return RoomEnterComponent.getName(this.self);
  }

  getRoomName() {
    if (!this.room) return '';
    return this.room.name;
  }

  getTeacherName() {
    return RoomEnterComponent.getName(this.teacher);
  }

  getPlannedDate() {
    if (!this.room) return null;
    return this.plannedDateInLocal;
  }

  secondMs = 1000;
  minuteMs = 60 * this.secondMs;
  hourMs = 60 * this.minuteMs;
  dayMs = 24 * this.hourMs;
  historyLength = 0;
  negativeTime = false;

  getJoinTime() {
    let timeDifference = this.plannedDateInLocal - new Date().getTime() - this.minuteMs;
    this.negativeTime = timeDifference < 0;
    timeDifference = Math.abs(timeDifference);

    const hours = Math.round(timeDifference / this.hourMs);
    if (hours > 24) {
      const days = Math.round(hours / 24);
      if (days === 1) return 'around one day';
      return `${days} days`;
    }
    const nbOfHours = Math.floor(timeDifference / this.hourMs);
    let timeRest = timeDifference - nbOfHours * this.hourMs;
    const nbOfMinutes = Math.floor(timeRest / this.minuteMs);
    timeRest = timeRest - nbOfMinutes * this.minuteMs;
    const nbOfSeconds = Math.floor(timeRest / this.secondMs);
    return `${this.padZeros(nbOfHours)}:${this.padZeros(nbOfMinutes)}:${this.padZeros(nbOfSeconds)}`;
  }

  padZeros(value: number) {
    const res = value != null ? value.toString() : "0";
    if (res.length < 2) return `0${res}`;
    return res;
  }

  hasData() {
    return this.room
  }

  isOffline() {
    return this.room && this.room.hybridState === 'Offline';
  }

  private navigateToRoom() {
    this.router.navigate(['participants',this.participantUuid, this.routeTarget]);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
    this.pingService.stopPing();
  }

  progressChanged($event: ProgressDetails) {
    this.progressIsDirty = true;
    this.progressService.progressChanged($event);
  }

  hasDirtyState() {
    return this.progressIsDirty;
  }

  private readRoomNotes() {
    this.rest.getRoomNotes(this.participantUuid).subscribe( it =>
      this.roomNotes = it
    );
  }

  getNote(key: string): string {
    return this.roomNotes && this.roomNotes[key];
  }
}
