import { Injectable, ComponentFactory, Inject, ComponentFactoryResolver, Type } from '@angular/core';
import { Subject } from 'rxjs';

export class Notification {
  constructor(public title: string, public message: string, public componentFactory?: ComponentFactory<any>, public timeout?: number) {}
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public boxNotifications = new Subject<Notification>();
  public stripeNotifications = new Subject<Notification>();

  constructor(@Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver) { }

  public publishBoxNotification(notification: Notification) {
    this.boxNotifications.next(notification);
  }

  public publishStripeNotification(notification: Notification) {
    this.stripeNotifications.next(notification);
  }

  public resolveComponentFactory(type: Type<unknown>): ComponentFactory<unknown> {
    return this.factoryResolver.resolveComponentFactory(type);
  }


}
