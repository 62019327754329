import {Component, Input, OnInit} from '@angular/core';
import {TeachersAttendanceReport} from "../../model/server";

@Component({
  selector: 'app-teacher-attendance-report',
  templateUrl: './teacher-attendance-report.component.html',
  styleUrls: ['./teacher-attendance-report.component.css']
})
export class TeacherAttendanceReportComponent implements OnInit {

  @Input()
  data: TeachersAttendanceReport[] = null;

  constructor() { }

  ngOnInit() {
  }

  getData(): TeachersAttendanceReport[] {
    if (!this.data) return [];
    return this.data;
  }

  isEmpty() {
    if (!this.data) return false;
    return this.data.length === 0;
  }

  notEmpty() {
    if (!this.data) return false;
    return this.data.length > 0;
  }

  calculateDuration(row: TeachersAttendanceReport) {
    return Math.round(row.totalSeconds / 60);
  }

  calculateTotalLessons() {
    return this.data.map( row => row.numberOfLessons).reduce((s, e) => s + e, 0);
  }

  calculateTotalDuration() {
    return Math.round(
      this.data.map( row => row.totalSeconds).reduce((s, e) => s + e, 0)
      / 60 );
  }
}
