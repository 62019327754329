import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ParticipantData} from "../../model/internal";

@Component({
  selector: 'app-participant-signal-strength',
  templateUrl: './participant-signal-strength.component.html',
  styleUrls: ['./participant-signal-strength.component.css']
})
export class ParticipantSignalStrengthComponent implements OnInit, OnDestroy {
  private _participant: ParticipantData;
  private _level: number = null;
  private subscribed = false;
  private _signalListener: (level, stats) => void;

  constructor() { }

  @Input()
  set participant(participantData: ParticipantData) {
    this._participant = participantData;
    this.listenForSignal();
  }

  hasValue() {
    return (this._level != null);
  }

  getValue() {
    return this._level;
  }

  ngOnInit() {
  }

  private listenForSignal() {
    if (!this._participant || this.subscribed) return;
    this.subscribed = true;
    this._signalListener = (level, stats) => {
      this._level = level;
    };
    this._participant.participant.on("networkQualityLevelChanged", this._signalListener);
  }

  ngOnDestroy(): void {
    if (this.subscribed && this._signalListener && this._participant) {
      this._participant.participant.removeListener("networkQualityLevelChanged", this._signalListener);
      this.subscribed = false;
    }
  }
}
