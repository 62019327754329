import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupTiming } from 'src/app/model/server';
import { GenericSimpleDataProvider } from 'src/app/model/internal';



@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.css']
})
export class ActivityReportComponent implements OnInit {
  data: GroupTiming[];

  constructor() { }

  @Input()
  set dataProvider(prov: GenericSimpleDataProvider<GroupTiming[]>) {
    if (prov) {
      prov.doLoad().subscribe( data => this.data = data);
    }
  }

  getName(row: GroupTiming) {
    return row.group.details.name;
  }

  getRooms(row: GroupTiming) {
    return row.roomsOpened;
  }

  getTime(row: GroupTiming) {
    return Math.round(row.roomTotalTime / 60);
  }

  getBillingTime(row: GroupTiming) {
    return row.totalStudentsTime ? Math.round(row.totalStudentsTime / 60) : '';
  }

  isEmpty(data: any[]) {
    return data && data.length === 0 ? true : false;
  }

  notEmpty(data: any[]) {
    return data && data.length > 0 ? true : false;
  }

  getTotalRooms() {
    return this.data
      .reduce((sum, current) => sum + current.roomsOpened, 0);
  }

  getTotalTime() {
    return Math.round(this.data
      .reduce((sum, current) => sum + current.roomTotalTime, 0) / 60);
  }

  getTotalBilling() {
    const result = Math.round(this.data.filter( row => row.totalStudentsTime)
    .reduce((sum, current) => sum + current.totalStudentsTime, 0) / 60);
    return result ? result : '';
  }

  ngOnInit() {
  }

}
