import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, from, ObservableInput, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

export class DevicesSelection {
  audioInput: MediaDeviceInfo;
  videoInput: MediaDeviceInfo;
  error: Error;
}

@Component({
  selector: 'app-capture-devices',
  templateUrl: './capture-devices.component.html',
  styleUrls: ['./capture-devices.component.css']
})
export class CaptureDevicesComponent implements OnInit {

  constructor() { }

  audioInputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
  selection = new DevicesSelection();
  ready = false;

  @Output()
  selected = new EventEmitter<DevicesSelection>();

  ngOnInit() {
    this.prepareInputs();
  }

  listDevices(): Observable<Array<MediaDeviceInfo>> {
    if (navigator.mediaDevices) {
      return from(navigator.mediaDevices.enumerateDevices()) as Observable<Array<MediaDeviceInfo>>;
    } else {
      return throwError(
        new Error('We have a problem with selecting a camera and/or a microphone.<br> \
        Please use a different browser or device.'));
    }
  }

  prepareInputs(): void {

    const devicesObservable = this.listDevices();
    devicesObservable.pipe(
      tap( devices => this.assignDevices(devices)),
      tap( () => this.selectDefaults()),
      tap( () => this.ready = true)
    ).subscribe( devices => {
      this.tryToStart();
      console.log('devices:');
      console.log(devices);
    }, error => {
      console.log('have problem with selecting the device, error:');
      console.log('error');
      this.returnError(error);
    });
  }
  returnError(error: Error) {
    const resultWithError = new DevicesSelection();
    resultWithError.error = error;
    this.selected.emit(resultWithError);
  }

  getDeviceName(device: MediaDeviceInfo) {
    if (device.label === '') {
      return 'Unknown Name';
    } else {
      return device.label;
    }
  }

  tryToStart(): void {
    if (this.audioInputs.length === 1 && this.videoInputs.length === 1) {
      this.startVideo();
    }
  }

  selectDefaults(): void {
    if (this.audioInputs.length > 0) {
      this.selection.audioInput = this.audioInputs[0];
    }
    if (this.videoInputs.length > 0) {
      this.selection.videoInput = this.videoInputs[0];
    }
  }

  startVideo() {
    this.ready = false;
    this.selected.emit(this.selection);
  }

  assignDevices(devices: MediaDeviceInfo[]): void {
    this.audioInputs = devices.filter( d => d.kind === 'audioinput');
    this.videoInputs = devices.filter ( d => d.kind === 'videoinput');
  }


}
