import {HostListener, Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {ToolboxControllerService} from "./toolbox-controller.service";

export class HotkeyEvent {}

export class TurnParticipantEvent extends HotkeyEvent {
  constructor(public participantNumber: number) {
    super();
  }
}

export class TurnActiveFullScreen extends HotkeyEvent {
  constructor(public toState: boolean = null) {
    super();
  }
}

export class TurnChartPage extends HotkeyEvent {
  constructor(public turnCounter: number) {
    super();
  }
}

export class RoomMute extends HotkeyEvent {}

export class SwitchToolbox extends HotkeyEvent {
  constructor(public toState: boolean) {
    super();
  }
}

export class SwitchChatEvent extends HotkeyEvent {
  constructor(public toState: boolean) {
    super();
  }
}

export class Bookmark extends HotkeyEvent{}
export class JumpTo extends HotkeyEvent{}
export class EscKey extends HotkeyEvent{}
export class Reading extends HotkeyEvent{}
export class Dict extends HotkeyEvent{}
export class OpenLessons extends HotkeyEvent{}
export class OpenChat extends  HotkeyEvent{}
export class OpenCharts extends HotkeyEvent{}
export class ActivateRandomStudent extends HotkeyEvent{}
export class OpenFirstChart extends HotkeyEvent{}
export class OpenChartOnFocusedBreakpoint extends HotkeyEvent {}
export class SwitchBookPage extends HotkeyEvent {
  constructor(public offset: number) {
    super();
  }
}

export class CloseChartEvent extends HotkeyEvent {}
export class ShareCurrentPage extends HotkeyEvent {}
export class ScaleBookFontSize extends HotkeyEvent {
  public constructor(public factor: number) {
    super();
  }
}

export class SwitchStudentFocus extends HotkeyEvent {
  static CURRENT_STUDENT = -1;
  constructor(public studentNumber:number) {
    super();
  }
}

export class ZoomChart extends HotkeyEvent {}

@Injectable({
  providedIn: 'root'
})
export class HotkeysService {
  private _showingTheBookPage: boolean = false;
  private _jumperOpened = false;
  private surveyOpened = false;
  private crashReportOpened = false;

  constructor(toolboxControllerService: ToolboxControllerService) {
    toolboxControllerService.toolboxShowSubject.subscribe( v => this._toolboxVisible = v);
    toolboxControllerService.selectedToolset.subscribe( toolset => this._activeToolset = toolset);
  }

  public hotkeyEventSubject = new Subject<HotkeyEvent>();
  private _chartSelected = false;
  private _toolboxVisible = false;
  private _activeToolset: String;


  private isChartSelected() {
    return this._chartSelected;
  }

  private isShowingBookPage() {
    return this._showingTheBookPage;
  }

  private isToolboxVisible() {
    return this._toolboxVisible;
  }

  private isChatOpened() {
    return this.isToolboxVisible() && this._activeToolset === 'Chat';
  }

  private isJumperOpened() {
    return this._jumperOpened;
  }

  private isWritingMode() {
    return this.isChatOpened() || this.isJumperOpened() || this.isLessonOpened() || this.surveyOpened || this.crashReportOpened;
  }


  public reportKeyDown(event: KeyboardEvent, roleStudent: boolean = false) {
    if (!event || !event.code) {
      return;
    }
    if (this.hasToPrevent(event)) {
      event.preventDefault();
      return;
    }

    if (roleStudent) return;

    if (this.isShowingBookPage() && !this.isWritingMode()) {
      if (event.key === "-") {
        this.hotkeyEventSubject.next(new ScaleBookFontSize(-1));
      }
      if (event.key == "+") {
        this.hotkeyEventSubject.next(new ScaleBookFontSize(1));
      }
      if (event.code === 'ArrowDown' ) {
        this.hotkeyEventSubject.next(new SwitchBookPage(1));
        event.preventDefault();
      }
      if (event.code === 'ArrowUp') {
        this.hotkeyEventSubject.next(new SwitchBookPage(-1));
        event.preventDefault();
      }
    }
    if (event.code.startsWith('Digit') && !this.isWritingMode()) {

      let personNumber = parseInt(event.code.substr(5), 0);
      if (personNumber === 0) {
        personNumber = 10;
      }
      personNumber--;
      if (event.altKey) {
        this.hotkeyEventSubject.next(new SwitchStudentFocus(personNumber));
      } else {
        this.hotkeyEventSubject.next(new TurnParticipantEvent(personNumber));
      }
      return;
    }
    if (event.code === 'KeyF' && event.altKey) {
      this.hotkeyEventSubject.next(new SwitchStudentFocus(SwitchStudentFocus.CURRENT_STUDENT));
    }
    if (!this.isWritingMode()) {
      if (event.code === 'Space') {
        this.hotkeyEventSubject.next(new ActivateRandomStudent());
      }
      if (!this.isLessonOpened() && event.code === 'KeyC') {
        //this.hotkeyEventSubject.next(new OpenFirstChart());
        this.hotkeyEventSubject.next(new OpenChartOnFocusedBreakpoint());
      }
      if (!this.isLessonOpened() && event.code === 'KeyT') {
        this.hotkeyEventSubject.next(new OpenChat());
      }
      if (!this.isLessonOpened() && event.code === 'KeyL') {
        this.hotkeyEventSubject.next(new OpenLessons());
      }
      if (event.code === 'KeyM') {
        this.hotkeyEventSubject.next(new RoomMute());
      }
      if (this.isChartSelected()) {
        if (event.code === 'ArrowLeft') {
          this.hotkeyEventSubject.next(new TurnChartPage(-1));
        }
        if (event.code === 'ArrowRight') {
          this.hotkeyEventSubject.next(new TurnChartPage(1));
        }
        if (event.code === 'KeyZ') {
          this.hotkeyEventSubject.next(new ZoomChart());
        }
      }
    }
    if (!this.isWritingMode() || this.isJumperOpened()) {
      if (event.code === 'KeyR') {
        this.hotkeyEventSubject.next(new Reading());
      }
      if (event.code === 'KeyB') {
        this.hotkeyEventSubject.next(new Bookmark());
      }
      if (event.code === 'KeyJ') {
        this.hotkeyEventSubject.next(new JumpTo());
      }
      if (event.code === 'KeyD') {
        this.hotkeyEventSubject.next(new Dict());
      }
    }

    if (!this.isWritingMode() && this.isChartSelected() && event.code === 'KeyQ') {
      this.hotkeyEventSubject.next(new CloseChartEvent());
    }

    if (event.code === 'Escape') {
      if (this.isJumperOpened()) {
        this.hotkeyEventSubject.next(new EscKey());
      } else if (this.isToolboxVisible()) {
        this.hotkeyEventSubject.next(new SwitchToolbox(false));
      } else if (this.isChatOpened()) {
        this.hotkeyEventSubject.next(new SwitchChatEvent(false));
      } else if (this.isChartSelected()) {
        this.hotkeyEventSubject.next(new CloseChartEvent());
      } else {
        this.hotkeyEventSubject.next(new TurnActiveFullScreen(false));
      }
    }
  }

  private isLessonOpened() {
    return this.isToolboxVisible() && this._activeToolset === 'Lesson';
  }

  setIsCharSelected(chartIsSelected: boolean) {
    this._chartSelected = chartIsSelected;
  }

  setJumperOpenedState(isOpened: boolean) {
    this._jumperOpened = isOpened;
  }

  setShowingTheBookPage(state: boolean) {
    this._showingTheBookPage = state;
  }

  setCrashReportIsOpened(state: boolean) {
    this.crashReportOpened = state;
  }

  private hasToPrevent(event: KeyboardEvent) {
    if(event.code === "F12") {
      return true;
    }
    if(event.ctrlKey && event.shiftKey && event.code === "KeyI") {
      return true;
    }
    if(event.ctrlKey && event.shiftKey && event.code === 'KeyC') {
      return true;
    }
    if(event.ctrlKey && event.shiftKey && event.code === 'KeyJ') {
      return true;
    }
    if(event.ctrlKey && event.code === 'KeyU') {
      return true;
    }
    return false;
  }

  setSurveyOpened(state: boolean) {
      this.surveyOpened = state;

  }
}
