import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RoomParticipantsDataService} from "../../service/helpers/room-participants-data-service";
import {RoomDataService} from "../../service/helpers/room-data-service";
import {RoomContextHolder} from "../../service/helpers/RoomContextHolder";
import {TeacherToolboxComponent} from "../../components/teacher-toolbox/teacher-toolbox.component";
import {Observable, Subject, Subscription} from "rxjs";
import {BookRendererControllerService} from "../../service/book-renderer-controller.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Notification, NotificationsService} from "../../service/notifications.service";
import {
  ActivateRandomStudent,
  CloseChartEvent,
  HotkeyEvent,
  HotkeysService,
  OpenCharts,
  OpenChat,
  OpenFirstChart,
  OpenLessons,
  RoomMute,
  ShareCurrentPage,
  SwitchStudentFocus,
  SwitchToolbox,
  TurnChartPage,
  TurnParticipantEvent
} from "../../service/hotkeys.service";
import {DevicesSelection} from "../../components/capture-devices/capture-devices.component";
import {ParticipantData} from "../../model/internal";
import {environment} from "../../../environments/environment";
import {finalize} from "rxjs/operators";
import {
  BandwidthManagerRole,
  VideoConnectionBandwidthManager
} from "../../service/helpers/VideoConnectionBandwidthManager";
import {ChartHostService} from "../../service/chart-host-service";
import {ShelfService} from "../../service/shelf.service";
import {TeacherLayoutService} from "./teacher-layout.service";
import {SentryService} from "../../service/sentry.service";
import {TeacherUnexpectedDisconnectModalComponent} from "../../components/teacher-unexpected-disconnect-modal/teacher-unexpected-disconnect-modal.component";
import {ErrorMessage, ErrorResolver} from "../error/error.component";
import {ModalV2Component} from "../../components/modal-v2/modal-v2.component";

@Component({
  selector: 'app-teacher-room-page-v3',
  templateUrl: './teacher-room-page-v3.component.html',
  styleUrls: ['./teacher-room-page-v3.component.css']
})
export class TeacherRoomPageV3Component implements OnInit, OnDestroy {
  @ViewChild('toolbox', {static: false})
  toolbox: TeacherToolboxComponent;
  @ViewChild("disconnectModal", {static: true})
  unexpectedDisconnectModal: TeacherUnexpectedDisconnectModalComponent;
  @ViewChild("notesModal", {static: true})
  notesModal: ModalV2Component

  private mCloseConfirmationObservable: Subject<boolean> = null;
  private disconnectSubscription: Subscription;
  hideDeviceSelection = false;


  constructor(
    activatedRoute: ActivatedRoute,
    protected notificationService: NotificationsService,
    private hotkeysService: HotkeysService,
    protected router: Router,
    private bookRendererControllerService: BookRendererControllerService,
    public mContextHolder: RoomContextHolder,
    public selfHolder: RoomDataService,
    public participantsHolder: RoomParticipantsDataService,
    private bandwidthManager: VideoConnectionBandwidthManager,
    public chartHostService: ChartHostService,
    public shelfService: ShelfService,
    private layoutService: TeacherLayoutService,
    private sentryService: SentryService
  // private pingService: PingService
  ) {

    this.collectInitialParameters(activatedRoute);
    window.addEventListener("pagehide", () => this.resetRoomContextHolder());
    window.addEventListener("beforeunload", () => this.resetRoomContextHolder());
  }

  private collectInitialParameters(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.paramMap.subscribe(
      params => {
        this.selfHolder.init(params.get('participantUuid'));
        // this.pingService.startPing(this.selfHolder.participantUuid);
      }
    )
  }

  restart() {
    this.hideDeviceSelection = false;
    this.resetRoomContextHolder();
  }

  resetRoomContextHolder() {
    this.mContextHolder.clear();
    this.layoutService.listenForContextRelayoutEvents();
  }


  ngOnInit() {
    this.layoutService.listenForResize();
  }

  connecting = false;
  error = false;

  init(audio: MediaDeviceInfo, video: MediaDeviceInfo) {
    if (this.mContextHolder.initialized) return;
    this.hideDeviceSelection = true;
    this.connecting = true;
    this.bandwidthManager.mode = BandwidthManagerRole.Teacher;
    this.mContextHolder.init(this.selfHolder.participantUuid, audio, video).pipe(
      finalize( () => this.connecting = false)
    ).subscribe(
      () => {
        this.subscribeToContextHolderAfterInit();
      }, err => {
        this.mContextHolder.unpublishLocals();
        const errorResolved = ErrorResolver.match(err);
        if ( errorResolved ) {
          this.showErrorPage(errorResolved);
          return;
        }
        this.sentryService.submitDialogCaughtError(err);
        this.error = true;
        this.showError(err);
      }
    );

  }

  showError(error: Error) {
    this.notificationService.publishBoxNotification(new Notification('There is an error!',
      `<p>${error.message}</p>
      <p class="text-small text-muted">
      Remember, we still are working on improving this service.<br>
      <storng>It works best on Google Chrome on desktop devices.</strong>
      </p>`));
  }

  subscribeToContextHolderAfterInit() {
    this.hotkeysService.hotkeyEventSubject.subscribe(hotkeyEvent => this.handleHotkeyEvent(hotkeyEvent));
    this.chartHostService.bookBasedChartSet.subscribe(chartSetBasedOnBook => {
      if (chartSetBasedOnBook && this.toolbox) {
        this.toolbox.selectChartSet(chartSetBasedOnBook);
      }
    });
    this.disconnectSubscription = this.mContextHolder.roomDisconnectEvent.subscribe(e => this.unexpectedDisconnect());
    this.mContextHolder.participantConnectedEvent.subscribe(p => this.welcomeParticipant(p));
    this.mContextHolder.participantDisconnectedEvent.subscribe(p => this.goodbyeParticipant(p));
  }

  deviceSelected(selection: DevicesSelection) {
    if (selection.error) {
      this.showError(selection.error);
    } else {
      this.init(selection.audioInput, selection.videoInput);
    }
  }

  ngOnDestroy() {
    this.selfHolder.disconnectRoom();
    // this.pingService.stopPing();
  }

  showCloseConfirmation(): Observable<boolean> {
    this.mCloseConfirmationObservable = new Subject<boolean>();
    this.mCloseConfirmationObservable.subscribe(() => this.mCloseConfirmationObservable = null);
    return this.mCloseConfirmationObservable;
  }

  isShowCloseConfirmation(): boolean {
    return this.mCloseConfirmationObservable !== null;
  }

  closeConfirmationAnswer(answer: boolean) {
    const sub = this.mCloseConfirmationObservable;
    sub.next(answer);
    sub.complete();
  }

  routeToRoomFinished() {
    this.router.navigate(['participants', this.selfHolder.participantUuid, 'teacher-room-closed']);
  }

  disconnect() {
    this.showCloseConfirmation().subscribe(
      a => {
        if (a) {
          try {
            this.closeRoom();
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  }

  closeRoom() {
    this.disconnectSubscription.unsubscribe();
    this.disconnectTwilioConnection();
    this.selfHolder.killRoom();
    this.routeToRoomFinished();
  }

  disconnectTwilioConnection() {
    this.mContextHolder.unpublishLocals();
    this.participantsHolder.disconnectAll();
    this.selfHolder.disconnect();
  }

  isShowWorkArea() {
    return this.chartHostService != null || this.participantsHolder.hasFocused();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.hotkeysService.reportKeyDown(event);
  }

  private handleHotkeyEvent(hotkeyEvent: HotkeyEvent) {
    if (hotkeyEvent instanceof ActivateRandomStudent) {
      this.activateRandomStudent();
    }
    if (hotkeyEvent instanceof TurnParticipantEvent) {
      if (!this.participantsHolder) return;
      const participants = this.participantsHolder.getParticipants();
      if (hotkeyEvent.participantNumber >= participants.length) {
        return;
      }
      this.participantsHolder.activate(participants[hotkeyEvent.participantNumber]);
    } else if (hotkeyEvent instanceof CloseChartEvent) {
      this.closeChart();
    } else if (hotkeyEvent instanceof SwitchToolbox) {
      this.toolbox.switchToolbox();
    } else if (hotkeyEvent instanceof RoomMute) {
      this.selfHolder.switchRoomAudioState();
    } else if (hotkeyEvent instanceof TurnChartPage) {
      this.toolbox.moveChart(hotkeyEvent.turnCounter);
    } else if (hotkeyEvent instanceof OpenChat) {
      this.openChat();
    } else if (hotkeyEvent instanceof OpenLessons) {
      this.openLessons();
    } else if (hotkeyEvent instanceof OpenCharts) {
      this.openCharts();
    } else if (hotkeyEvent instanceof OpenFirstChart) {
      this.toolbox.openFirstChart();
    } else if (hotkeyEvent instanceof ShareCurrentPage) {
      this.handleCurrentPageShare();
    } else if (hotkeyEvent instanceof SwitchStudentFocus) {
      this.switchStudentFocus(hotkeyEvent.studentNumber);
    }
  }

  closeChart() {
    if (!this.toolbox) return;
    this.toolbox.selectChart(null);
  }

  openCharts() {
    this.toolbox.setToolset('Charts');
    this.toolbox.switchToolbox(true);
  }

  openLessons() {
    this.toolbox.setToolset('Lesson');
    this.toolbox.switchToolbox(true);
  }

  openChat() {
    this.toolbox.setToolset('Chat');
    this.toolbox.switchToolbox(true);
  }

  welcomeParticipant(participant: ParticipantData) {
    this.notificationService.publishBoxNotification(
      new Notification('A new student has entered', `<strong>${participant.participantDef.name}</strong>`, null, 3000));
  }

  goodbyeParticipant(participant: ParticipantData) {
    console.log('goodbye');
    console.log(participant);
  }

  private activateRandomStudent() {
    if (!this.participantsHolder) return;
    const studentToActivate = this.participantsHolder.randomStudentByFrequency();
    if (!studentToActivate) return;
    this.participantsHolder.activate(studentToActivate, true);
  }

  private handleCurrentPageShare() {
    this.bookRendererControllerService.shareCurrentPage(this.selfHolder.participantUuid);
  }

  onContextMenu() {
    return environment.allowContextMenu;
  }

  private switchStudentFocus(studentNumber: number) {
    if (studentNumber < 0) {
      // deactivate current focused student
      const focused = this.participantsHolder.getFocusedStudent();
      if (focused && focused.length > 0) {
        this.participantsHolder.switchFocus(focused[0]);
        this.layoutService.relayout();
        return;
      }
      // else try to focus active student
      const active = this.participantsHolder.getActiveParticipantAsArray();
      if (active && active.length > 0) {
        this.participantsHolder.switchFocus(active[0], true);
        this.layoutService.relayout();
        return;
      }
      // else we don't have any candidate
      return;
    }

    // skip if out of range
    const allStudents = this.participantsHolder.getParticipants();
    if (studentNumber >= allStudents.length) {
      return;
    }

    this.participantsHolder.switchFocus(allStudents[studentNumber]);
    this.layoutService.relayout();
  }

  hasActiveStudent() {
    return this.participantsHolder && this.participantsHolder.hasActiveParticipant();
  }

  isShowChart() {
    return this.selfHolder && this.selfHolder.selfData && this.selfHolder.selfData.roomDef && this.selfHolder.selfData.roomDef.chart;
  }

  openFullScreen() {
    try {
      document.getElementsByTagName("body")[0].requestFullscreen().catch();
    } catch (ingore) {
      // full screen not supported
    }
  }

  closeFullScreen() {
    try {
      document.exitFullscreen().catch();
    } catch (ignore) {
      // full screen not supported
    }
  }

  private unexpectedDisconnect() {
    this.sentryService.submitMessage("Unexpected disconnect");
    this.unexpectedDisconnectModal.show();
  }

  refreshPage() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    window.location.href = `${protocol}//${host}/participants/${this.selfHolder.participantUuid}/teacher-room`;
  }

  private showErrorPage(error: ErrorMessage) {
    this.router.navigate(['participants', this.selfHolder.participantUuid, 'teacher-error' , error])
  }

  hasNotes() {
    return this.selfHolder.getNote("group_note") || this.selfHolder.getNote("schedule_note")
  }

  hasOpenedNotes = false;

  openedNotes() {
    return this.hasOpenedNotes;
  }

  showNotes() {
    this.hasOpenedNotes = true;
    this.notesModal.show(true);
  }

  getNote(key: string): string {
    return this.selfHolder.getNote(key) || "No notes.";
  }
}
