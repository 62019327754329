import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BooksRestService} from "../../service/books-service";
import {tap} from "rxjs/operators";
import {Course, Stage} from "../../model/books";

export class BookSelection {
  constructor(public course: Course, public stage: Stage) {}
}

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.css']
})
export class BookListComponent implements OnInit {

  @Input()
  participantUuid: string
  @Output()
  selected = new EventEmitter<BookSelection>()
  courses: Course[];

  constructor(private rest: BooksRestService) { }

  ngOnInit() {
    this.rest.listProducts(this.participantUuid).pipe(
      tap( it => this.courses = it)
    ).subscribe()
  }

  getImageUrl(course: Course, stage: Stage) {
    return this.rest.getCoverUrl(this.participantUuid, course, stage)
  }

  bookClicked(course: Course, stage: Stage) {
    this.selected.emit(new BookSelection(course, stage))
  }
}
