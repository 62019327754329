import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import {RoomDef, ChatEntry, Chat, ParticipantDef} from 'src/app/model/server';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationsService, Notification } from 'src/app/service/notifications.service';
import {SocketServiceV2} from "../../service/socket-v2.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {

  chat: Chat;
  chatEntry: ChatEntry;
  chatSubscription: Subscription;

  @Input()
  visible: boolean;

  @ViewChild('chatMessages', {static: false})
  chatMessages: ElementRef;
  hasToScroll: boolean;
  private self: ParticipantDef;

  constructor(
    private serverRest: ServerRestService,
    private socketService: SocketServiceV2,
    private notification: NotificationsService) { }

  mRoom: RoomDef;
  mParticipantUuid: string;
  initialized = false;

  chatEntryTrackBy(index: number, chatEntry: ChatEntry) {
    return index;
  }

  @Input()
  set room(room: RoomDef) {
    this.mRoom = room;
    this.initialize();
  }

  @Input()
  set participantUuid(uuid: string) {
    this.mParticipantUuid = uuid;
    this.initialize();
  }

  resolveParticipant(chatEntry: ChatEntry) {
    chatEntry.participant = this.mRoom.participants.find( p => p.uuid === chatEntry.participant.uuid);
    return chatEntry;
  }

  getEntryClass(chatEntry: ChatEntry) {
    if (!chatEntry.participant) {
      return '';
    }
    if (chatEntry.participant.role === 'Teacher') {
      return 'teacher';
    } else {
      return 'student';
    }

  }

  initialize() {
    if (!this.mRoom || !this.mParticipantUuid || this.initialized) {
      return;
    }

    this.serverRest.getChat(this.mParticipantUuid).pipe(
      tap ( chat => chat.entries = chat.entries.map(e => this.resolveParticipant(e))),
      tap ( (chat: Chat) => this.chat = chat)
    ).subscribe(
      () => this.hasToScroll = true
    );

    this.socketService.connect().subscribe(
      ctx =>
        this.chatSubscription = this.socketService.topic<ChatEntry>(ctx, '/room/' + this.mRoom.uuid + '/chat')
          .subscribe( entry => this.addEntry(entry))
    );

    this.self = this.mRoom.participants.find( p => p.uuid === this.mParticipantUuid);

    this.initialized = true;
    this.clearCurrentEntry();

  }
  scrollToBottom(): void {
    const chatWindow = this.chatMessages.nativeElement;
    chatWindow.scrollTop = chatWindow.scrollHeight;
  }

  addEntry(entry: ChatEntry): void {
    this.chat.entries.push(this.resolveParticipant(entry));
    if (entry.participant.uuid !== this.mParticipantUuid) {
      if (!this.visible) {
        this.notification.publishBoxNotification(
          new Notification('New message:', '<strong>' + entry.participant.name + ':</strong> ' + entry.message, null, 3000));
      }
    }
    this.hasToScroll = true;
  }

  onSendMessage() {
    this.serverRest.postChatMessage(this.mParticipantUuid, this.chatEntry.message).subscribe();
    this.clearCurrentEntry();
  }

  clearCurrentEntry() {
    this.chatEntry = new ChatEntry();

  }

  ngAfterViewChecked(): void {
    if (this.hasToScroll) {
      this.scrollToBottom();
      this.hasToScroll = false;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
  }

  isChatDisabled() {
    if (!this.self) return true;
    return this.self.chatOff;
  }

}
