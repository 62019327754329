<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item " aria-current="page" ><a routerLink="/manager/{{schoolId}}/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item active" aria-current="page" routerLink="/manager/{{schoolId}}/dashboard">Groups</li>
  </ol>
</nav>

<div [hidden]="totalGroupsNumber === -1" class="mb-4 mt-4">
  <button class="btn btn-primary btn-sm float-right mt-n1" routerLink="/manager/{{schoolId}}/templates/new">Create New</button>
  <h5 class="text-uppercase">Groups<span class="text-muted">({{totalGroupsNumber}})</span>:</h5>
  <form class="">
    <div class="form-row">
      <div class="col mb-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
          </div>
          <input type="text" class="form-control" name="groups-search" [(ngModel)]="groupsSearch" placeholder="Search...">
        </div>
      </div>
      <div class="col-12 d-sm-none"></div>
      <div class="col col-sm-6 col-md-4 col-lg-2">
        <select name="groups-sorting" class="form-control" [(ngModel)]="groupsSorting">
          <option value="details.name,asc">By Name</option>
          <option value="details.name,desc">By Name (descending)</option>
          <option value="created,asc">By Date</option>
          <option value="created,desc">By Date (descending)</option>
          <option value="t.details.name,asc">By Teacher</option>
          <option value="t.details.name,desc">By Teacher (descending)</option>
        </select>
      </div>
    </div>
  </form>
  <app-room-templates-list [showOpenClassroom]="false"  [dataProvider]="groupsDataProvider" (count)="setGroupCount($event)"  (listEvent)="onTemplatesEvent($event)"></app-room-templates-list>
</div>

<div *ngIf="totalGroupsNumber === -1" class="text-center my-5">
  <button routerLink="/manager/{{schoolId}}/templates/new" class="btn btn-primary btn-lg">Create a first video group</button>
</div>

