import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttendanceDetails, RoomProgressReport} from "../../../model/server";

@Component({
  selector: 'app-attendance-block',
  templateUrl: './attendance-block.component.html',
  styleUrls: ['./attendance-block.component.css']
})
export class AttendanceBlockComponent implements OnInit {

  @Input()
  progress: RoomProgressReport;
  @Output()
  attendanceChanged = new EventEmitter<AttendanceDetails[]>();


  constructor() { }

  ngOnInit() {
  }

  onAttendanceChanged($event: any) {
    this.attendanceChanged.emit(this.progress.attenders);
  }

  getAttenderName(attender: AttendanceDetails) {
    return attender.participant.name;
  }
}
