import {Component, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {ScheduleRestService} from "../../service/schedule-rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ScheduleDetails, ScheduleParticipant, ScheduleRow} from "../../model/schedule";
import {zip} from "rxjs";
import {map} from "rxjs/operators";
import {formatDate, Location} from "@angular/common";
import {HybridState, ParticipantDef} from "../../model/server";
import {ModalV2Component} from "../../components/modal-v2/modal-v2.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ServerRestService} from "../../service/server-rest.service";

declare var $: any;

@Component({
  selector: 'app-schedule-details-page',
  templateUrl: './schedule-details-page.component.html',
  styleUrls: ['./schedule-details-page.component.css']
})
export class ScheduleDetailsPageComponent implements OnInit {
  schoolId: number;
  private scheduleId: number;
  data: ScheduleRow;
  teacher: ScheduleParticipant;
  students: ScheduleParticipant[] = [];
  startDate: string;
  place: string;
  durationMin: number;
  saved = false;
  hasError: any;
  editParticipantSettings: ScheduleParticipant;
  deleteFollowing = false;

  @ViewChild("startConfirmModal", {static: true}) startConfirmModal: ModalV2Component
  @ViewChild("deleteConfirmModal", {static: true}) deleteConfirmModal: ModalV2Component
  @ViewChild("settingsModal", {static: true}) settingsModal: ModalV2Component
  private linksCopied = new Set<String>();
  onlineEvent = true;

  constructor(
    private scheduleRest: ScheduleRestService,
    private rest: ServerRestService,
    route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {
    zip(
      route.parent.paramMap.pipe(
        map( params => Number(params.get('schoolId')))
      ),
      route.paramMap.pipe(
        map( params => Number(params.get('id')))
      )
    ).subscribe(
      ([schoolId, scheduleId]) => this.setupData(schoolId, scheduleId)
    );
  }

  ngOnInit() {
  }

  isEditForbidden(){
    return this.data && !!this.data.schedule.details.participants.find( p => p.eid);
  }

  private loadData() {
    this.scheduleRest.getScheduleById(this.schoolId, this.scheduleId).subscribe(
      scheduleRow => this.storeData(scheduleRow)
    )
  }

  private setupData(schoolId: number, scheduleId: number) {
    this.schoolId = schoolId;
    this.scheduleId = scheduleId;
    this.loadData();
  }

  public hasRoom() {
    return this.data && this.data.room;
  }

  public isRoomQualityReporting() {
    if (!this.hasRoom()) return false;
    return this.data.room.reportStats;
  }

  public registerReports() {
    if (!this.hasRoom()) return;
    this.rest.updateRoomVideoStatsReportFlag(
      this.schoolId,
      this.data.template.id,
      this.data.room.uuid,
      true
    ).subscribe( _ => this.loadData())
  }

  private storeData(scheduleRow: ScheduleRow) {
    this.data = scheduleRow;
    this.onlineEvent = this.isScheduleOnline();
    if (scheduleRow.schedule.details.place) {
      this.place = scheduleRow.schedule.details.place;
    } else {
      this.place = scheduleRow.template.details.place;
    }
    this.durationMin = scheduleRow.schedule.details.durationMin;

    this.teacher = scheduleRow.schedule.details.participants.find(p => p.role === 'Teacher');
    if (this.teacher == null) {
      this.teacher = new ScheduleParticipant();
      this.teacher.role = 'Teacher';
    }
    this.students = scheduleRow.schedule.details.participants.filter( p => p.role === 'Student');
    this.startDate =  formatDate(scheduleRow.schedule.details.startDate, "yyyy-MM-dd HH:mm", this.locale);
  }

  onSave() {
    this.saved = false;
    const detailsToSave = this.prepareScheduleDetails();
    detailsToSave.durationMin = this.durationMin;

    detailsToSave.place = this.place;
    this.scheduleRest.updateSchedule(this.schoolId, this.scheduleId, detailsToSave).subscribe(
      _ => {
        this.saved = true;
        setTimeout(_ => this.saved = false, 3000);
        this.loadData();
      },
      error => {
        this.hasError = (error.error.developerMessage) ? error.error.developerMessage : error.error;
        setTimeout(_ => this.hasError = false, 5000);
      }
    );
  }

  addStudent() {
    const student = new ScheduleParticipant();
    student.role = 'Student';
    this.students.push(student);
  }

  canAdd() {
    return this.isModifiable() && this.students.length < 20;
  }

  removeStudent(student: ScheduleParticipant) {
    if (!this.mayDeleteStudent()) return;
    this.students.splice(this.students.indexOf(student),1)
  }

  getScheduleName() {
    return this.data.template.details.name;
  }

  onBack() {
    this.location.back();
  }

  showOpenConfirm() {
    this.startConfirmModal.show(true);
  }

  onDelete() {
    this.deleteConfirmModal.hide();
    this.scheduleRest.deleteSchedule(this.schoolId, this.data.schedule.id, this.deleteFollowing).subscribe( _ => {
      this.onBack();
    });
    this.deleteFollowing = false;
  }

  isModifiable() {
    return this.data && this.data.schedule.state === 'PLANED';
  }

  isOpenable() {
    return this.isModifiable();
  }

  mayDeleteStudent() {
    return this.students.length > 1;
  }

  private prepareScheduleDetails(): ScheduleDetails {
    const result = new ScheduleDetails();
    result.startDate = Date.parse(this.startDate);
    result.participants = [].concat(this.teacher, ...this.students);

    const scheduleIsOnlineFromCalculation = this.isScheduleOnline();
    if (scheduleIsOnlineFromCalculation != this.onlineEvent) {
      result.hybridState = this.onlineEvent ? HybridState.Online : HybridState.Offline;
    }
    return result;
  }

  getPartiFullName(participant: ParticipantDef) {
    if (!participant) return '';
    const email = participant.mail != null ? `(${participant.mail})` : '';
    return `${participant.name} ${email}`;
  }

  getRoomTeacher(): ParticipantDef {
    if (!this.data || !this.data.room) return null;
    return this.data.room.participants.find(p => p.role === 'Teacher');
  }

  createLink(parti: ParticipantDef): string {
    if (!parti) {
      return '';
    }
    const origin = window.location.origin;
    if (parti.role === 'Teacher') {
      return `${origin}/participants/${parti.uuid}/teacher-room`;
    } else {
      return `${origin}/participants/${parti.uuid}/student-room`;
    }
  }

  isShowLinkCopied(link: string) {
    return this.linksCopied.has(link);
  }

  copyToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.linksCopied.clear();
    this.linksCopied.add(link);
    setTimeout(() => this.linksCopied.delete(link), 3000);
  }

  getRoomStudents() {
    if (!this.data || !this.data.room) return [];
    return this.data.room.participants.filter(p => p.role === 'Student');
  }

  getScheduleStatus() {
    switch(this.data.schedule.state) {
      case 'PENDING': return 'waiting for first connection';
      case 'IN_PROGRESS': return 'in progress';
      case 'COMPLETE': return 'finished';
    }
    return this.data.schedule.state;
  }

  getConnectionStatus(roomTeacher: ParticipantDef) {
    if (roomTeacher && roomTeacher.videoInfo && roomTeacher.videoInfo.status) {
      return roomTeacher.videoInfo.status;
    } else return 'never connected';
  }

  getScheduleStartDate() {
    return this.data.schedule.details.startDate;
  }


  getRoomCreateDate() {
    if (!this.data ||!this.data.room) return null;
    return this.data.room.createDate;
  }

  getRoomLessonStartDate() {
    if (!this.data ||!this.data.room) return null;
    return this.data.room.lessonStartDate;
  }

  getRoomTotalTime() {
    if (!this.data ||!this.data.room) return null;
    return Math.round(this.data.room.totalTime / 60000);
  }

  getRoomVideoCreated() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return this.data.room.provisioning.created;
  }

  getRoomVideoFinished() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return this.data.room.provisioning.endTime;
  }

  getRoomVideoDuration() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return Math.round(this.data.room.provisioning.duration / 60);
  }

  hasProgressReported() {
    return this.data
      && this.data.room
      && this.data.room.progress
      && this.data.room.progress.details
      && this.data.room.progress.details.paragraph
      && this.data.room.progress.details.paragraph.length > 0;

  }

  getProgressReport() {
    return this.data.room.progress.details;
  }

  getParticipantVideoConnectionTime(student: ParticipantDef) {
    if (!student || !student.videoInfo) return 0;
    return Math.round(student.videoInfo.duration / 60);
  }


  studentWasAttended(student: ParticipantDef) {
    if (!student) return false;
    if (!this.data || !this.data.room || !this.data.room.progress || !this.data.room.progress.attenders) return false;
    return this.data.room.progress.attenders.find( a => a.attended === true && a.participant.uuid === student.uuid)
  }

  onStart() {
    this.scheduleRest.updateScheduleState(this.schoolId, this.data.schedule.id, "PENDING").subscribe( row => {
      this.loadData();
      this.startConfirmModal.hide();
    })
  }

  showDeleteConfirm() {
    this.deleteConfirmModal.show(true);
  }

  getGroupName() {
    if (!this.data || !this.data.template) return '';
    return this.data.template.details.name;
  }

  getTemplateId() {
    if (!this.data || !this.data.template) return '';
    return this.data.template.id;
  }

  getScheduleTime() {
    return this.data.schedule.details.startDate;
  }

  getScheduleEndDate() {
    return this.data.schedule.details.startDate + this.durationMin * 60 * 1000;
  }

  editSettings(student: ScheduleParticipant) {
    this.editParticipantSettings = student;
    this.settingsModal.show();
  }

  hasFlags(student: ScheduleParticipant) {
    return this.isOffline(student);
  }

  isOffline(student: ScheduleParticipant) {
    if (!student) return false;
    return student.offline;
  }

  getOfflineModeDescription() {
    return this.onlineEvent ?
      this.sanitizer.bypassSecurityTrustHtml("The lesson is online")
      : this.sanitizer.bypassSecurityTrustHtml("The lesson is offline (in a physical classroom <i class=\"fa fa-school\"></i>)");
  }

  private isScheduleOnline() {
    if (this.data.schedule.details.hybridState === HybridState.Offline) return false;
    if (this.data.schedule.details.hybridState === HybridState.Online) return true;
    return this.data.template.details.hybridState !== HybridState.Offline;

  }

  isComplete() {
    return this.data.schedule.state === 'COMPLETE';
  }

  isOfflineEvent() {
    return !this.onlineEvent;
  }
}
