import {TwilioServiceService, VideoRenderPreferences} from "../twilio-service.service";
import {ParticipantData} from "../../model/internal";
import * as Video from "twilio-video";
import {Injectable} from "@angular/core";
import {RoomContextHolder, UserActivationEvent} from "./RoomContextHolder";
import {ShelfService} from "../shelf.service";
import {RoomDataService} from "./room-data-service";
import {BooksRestService} from "../books-service";
import {RoomParticipantsDataService} from "./room-participants-data-service";
import {LoggerService} from "../logger.service";


export enum BandwidthManagerRole {
  Teacher, Student
}

@Injectable({
  providedIn: 'root'
})
export class VideoConnectionBandwidthManager {

  mode = BandwidthManagerRole.Student;
  chartOpened = false;
  bookOpened = false;
  shelfOpened = false;

  constructor(
      private twilio: TwilioServiceService,
      private contextHolder: RoomContextHolder,
      private shelfService: ShelfService,
      private selfHolder: RoomDataService,
      private bookService: BooksRestService,
      private partiHolder: RoomParticipantsDataService,
      private l: LoggerService
  ) {

    contextHolder.selfObservable.subscribe( self => {
      this.chartOpened = self.roomDef.chart != null;
      this.updatePartiesBandwidthSettings();
    });

    shelfService.showShelfObservable.subscribe(value => {
      this.shelfOpened = value;
      this.updatePartiesBandwidthSettings();
    });

    bookService.bookContentObservable.subscribe( bookContent => {
      this.bookOpened = bookContent != null;
    });
  }

  public updatePartiesBandwidthSettings() {
    this.updateStreamSettings(this.contextHolder.participants);
  }

  private updateStreamSettings(parties: Array<ParticipantData>) {
    parties.forEach(parti => {
      if (parti.local) {
        return;
      }
      if (this.isVisible(parti)) {
        if (this.twilio.switchOnParticipantVideoTrack(parti.participant as Video.RemoteParticipant)){
         this.l.i(`${parti.participantDef.name} video is switched on`);
        }
      } else {
        if (this.twilio.switchOffParticipantVideoTrack(parti.participant as Video.RemoteParticipant)) {
          this.l.i(`${parti.participantDef.name} video is switched off`);
        }
      }

      const renderDimensions = this.resolveDimensions(parti);
      this.twilio.setupRenderDimensions(parti.participant as Video.RemoteParticipant, renderDimensions);
      //console.log(`${parti.participantDef.name} video dimensions requested`, renderDimensions);
    });
    this.updateSelfPriority();
  }

  private resolveDimensions(parti: ParticipantData): VideoRenderPreferences {
    if (this.mode === BandwidthManagerRole.Student) {
      if (this.partiHolder.isTeacher(parti)) {
        if (this.isWorkingAreaOccupied()) return VideoRenderPreferences.Low;
        else return VideoRenderPreferences.High;
      } else {
        if (this.partiHolder.isActive(parti)) return VideoRenderPreferences.Medium;
        return VideoRenderPreferences.Low;
      }
    }
    if (this.mode === BandwidthManagerRole.Teacher) {
      if (this.partiHolder.isActive(parti) && !this.isWorkingAreaOccupied()) {
        return VideoRenderPreferences.Medium;
      }
      else {
        return VideoRenderPreferences.Low;
      }
    }
  }

  private isVisible(parti: ParticipantData) {
    if (this.partiHolder.isHidden(parti)) return false;
    if (this.partiHolder.isTeacher(parti)) return true;
    if (this.partiHolder.isActive(parti) && !this.isWorkingAreaOccupied()) return true;
    if (this.shelfIsOpened()) return true;
    return false;
  }

  private shelfIsOpened() {
    return this.shelfOpened;
  }

  private isWorkingAreaOccupied() {
    return this.chartOpened || this.bookOpened;
  }

  private updateSelfPriority() {
    if (this.selfHolder.amITeacher()) {
      this.l.i("[H]");
      this.twilio.setPriority('high');
      return;
    }
    if (this.selfHolder.amIActive()) {
      this.l.i("[S]");
      this.twilio.setPriority("standard");
    } else {
      this.l.i("[L]");
      this.twilio.setPriority("low");
    }
  }
}
