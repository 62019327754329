import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Person, AuthorizationProfile } from 'src/app/model/casa';
import { tap, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { CasaRestService } from 'src/app/service/casa-rest.service';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import { CurrentSchoolService } from 'src/app/service/current-school.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manager-menu-layout',
  templateUrl: './manager-menu-layout.component.html',
  styleUrls: ['./manager-menu-layout.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({
        right: '0px',
      })),
      state('hide', style({
        right: '-260px',
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.2s ease-in' )
      ]),
      transition('hide => show', [
        style({display: 'block'}),
        animate('0.2s ease-out'),
      ])
    ]),
    trigger('showDim', [
      state('show', style({
        opacity: 1,
      })),
      state('hide', style({
        opacity: 0,
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.2s ease-in')
      ]),
      transition('hide => show', [
        style({display: 'block'}),
        animate('0.2s ease-out')
      ])
    ])
  ]
})
export class ManagerMenuLayoutComponent implements OnInit, OnDestroy {


  menuOpened = false;
  person: Person;
  CALLAN_ONLINE_LU_ID = 1;
  colProfile: AuthorizationProfile;
  PAY_LABEL = 'buyeBooks';
  hasPayAccess = false;
  availableProfiles: AuthorizationProfile[];
  selfPersonSubscription: Subscription;
  authProfilesSubscription: Subscription;
  schoolId: number;

  constructor(private authService: AuthService,
              private casaRest: CasaRestService,
              personalData: PersonalDataService,
              currentSchoolService: CurrentSchoolService) {
    this.selfPersonSubscription = personalData.selfPerson.subscribe( person => this.person = person);
    this.authProfilesSubscription = personalData.authorizationProfiles.pipe(
      tap( profiles => this.availableProfiles = profiles),
      tap( profiles => this.searchForColProfile(profiles)),
      tap( profiles => this.searchForPayAccess(profiles))
    ).subscribe();

    currentSchoolService.currentSchoolId.subscribe(schoolId => this.schoolId = schoolId);
  }

  ngOnDestroy(): void {
    if (this.selfPersonSubscription) {
      this.selfPersonSubscription.unsubscribe();
    }
    if (this.authProfilesSubscription) {
      this.authProfilesSubscription.unsubscribe();
    }
  }

  hasCol() {
    return this.colProfile != null;
  }

  hasPay() {
    return this.hasPayAccess;
  }

  goToCol() {
    this.casaRest.authInCol(this.colProfile).subscribe(code => {
      const target = code.redirectUrl + '?code=' + code.codeValue + '&state=Lw%3D%3D';
      window.open(target, '_blank');
    });
  }

  goToPay() {
    this.casaRest.authInPay().subscribe(code => {
      const target = code.redirectUrl + '?code=' + code.codeValue;
      window.open(target, '_blank');
    });
  }

  private searchForPayAccess(profiles: AuthorizationProfile[]): void {
    if (!profiles) {
      this.hasPayAccess = false;
      return;
    }
    if (profiles.find( p => p.learningUnitLabels && p.learningUnitLabels.indexOf(this.PAY_LABEL) >= 0)) {
      this.hasPayAccess = true;
    }
  }

  private searchForColProfile(profiles: AuthorizationProfile[]) {
    if (!profiles) {
      this.colProfile = null;
      return;
    }
    this.colProfile = profiles.find( p =>
        p.learningUnitId === this.CALLAN_ONLINE_LU_ID
        && p.profileRole === 'STUDENT'
      );
  }

  searchForActiveProfile() {
    return this.casaRest.getCurrentProfile();
  }

  ngOnInit() {
  }

  getName() {
    if (!this.person) {
      return '';
    }
    const res = [];
    if (this.person.name) {
      res.push(this.person.name);
    }
    if (this.person.surname) {
      res.push(this.person.surname);
    }
    return res.join(' ');
  }

  getEmail() {
    if (!this.person) {
      return '';
    }
    return this.person.registrationEmail;
  }

  logout() {
    this.authService.logout();
  }

  showMenu() {
    this.menuOpened = true;
  }

  hideMenu() {
    this.menuOpened = false;
  }

  isShowMenu() {
    return this.menuOpened;
  }
}
