import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthorizationTokenData, TokenValidator } from './AuthorizationTokenData';

/**
 * Auth response class
 */
class AuthResponse {
  // tslint:disable-next-line:variable-name
  access_token: string;
  // tslint:disable-next-line:variable-name
  expiries_in: number;
  // tslint:disable-next-line:variable-name
  refresh_token: string;
  // tslint:disable-next-line:variable-name
  token_type: string;
}

/**
 * token store container interface for persiting the data
 */
export interface AuthStore {
  getCurrentAuthorizationData(): Observable<AuthorizationTokenData>;
  storeAuthorizationToken(token: string): Observable<AuthorizationTokenData>;
  /**
   * listen for authorization changes
   */
  readAuthorizationData(): Observable<AuthorizationTokenData>;
  clear();
}

/**
 * authorization service interface
 */
export interface Auth {
  /**
   * allow to read auth data i.e. to display them in the app
   */
  getCurrentAuthData(): Observable<AuthorizationTokenData>;
  /**
   * start the login. Exit the app and return with given state after the auth.
   * @param returnState return state
   */
  login(returnState: string): void;
  /**
   * Authorize with the authorization code. It may be used by login authorization to finish
   * it with the code returned by the auth server or just during the redirect - for other
   * application intgration (like by iframe to avoid double authorization)
   * @param authCode authorization code
   * @param state return state
   */
  loginWithAuthCode(authCode: string, state: string): void;

  loginWithPlainToken(token: string, state: string);
}


/**
 * token store using local storage
 */
@Injectable({
  providedIn: 'root'
})
export class AuthStoreImpl implements AuthStore {

  private static tokenEntryLocation = 'cspaAuthTokenData';
  private currentAuthorizationData = new ReplaySubject<AuthorizationTokenData>(1);
  constructor() {

    // try to read auth data from the local storage
    this.getCurrentAuthorizationData().subscribe( authData => {
      if (TokenValidator.isValid(authData)) {
        this.currentAuthorizationData.next(authData);
      }
    });
  }

  readAuthorizationData(): Observable<AuthorizationTokenData> {
     return this.currentAuthorizationData;
  }

  getCurrentAuthorizationData(): Observable<AuthorizationTokenData> {
    return of(JSON.parse(localStorage.getItem(AuthStoreImpl.tokenEntryLocation)) as AuthorizationTokenData);
    //return of(this.localStorage.get(AuthStoreImpl.tokenEntryLocation) as AuthorizationTokenData);
  }

  clear() {
    localStorage.removeItem(AuthStoreImpl.tokenEntryLocation);
    //this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
    this.currentAuthorizationData.next(null);
  }

  storeAuthorizationToken(token: string): Observable<AuthorizationTokenData> {
    //this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
    localStorage.removeItem(AuthStoreImpl.tokenEntryLocation);
    const tokenData = this.parseToken(token);
    localStorage.setItem(AuthStoreImpl.tokenEntryLocation, JSON.stringify(tokenData))
    //this.localStorage.set(AuthStoreImpl.tokenEntryLocation, tokenData);
    this.currentAuthorizationData.next(tokenData);
    return of(tokenData);
  }

  parseToken(token: string): AuthorizationTokenData {
    const splitted = token.split('.');
    const data = JSON.parse(atob(splitted[1]));
    const tokenData = new AuthorizationTokenData();
    tokenData.email = data.userEmail;
    tokenData.personId = data.personId;
    tokenData.accountId = data.userId;
    tokenData.expiryDate = new Date(data.exp * 1000).getTime();
    tokenData.token = token;
    return tokenData;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements Auth {

  constructor(private authStore: AuthStoreImpl,
              private http: HttpClient,
              private router: Router) { }

  getCurrentAuthData(): Observable<AuthorizationTokenData> {
    return this.authStore.getCurrentAuthorizationData();
  }

  logout() {
    this.authStore.clear();
    const logoutUrl = environment.authEndpoint + '/oauth/logout?redirect_uri=' + encodeURIComponent(environment.serverBase );
    window.location.href = logoutUrl;
    // this.router.navigate(['/']);
  }

  login(returnState: string): void {
    const oauth = environment.authEndpoint
      + '/oauth/school/1/login?' + this.constructOauth(returnState);
    window.location.href = oauth;
  }

  loginWithAuthCode(authCode: string, state: string): void {
    this.askForAccessToken(authCode)
    .pipe(
      flatMap(tokenResponse => this.authStore.storeAuthorizationToken(tokenResponse.access_token))
    ).subscribe( _ => this.openState(state));
  }

  loginWithPlainToken(token: string, state: string) {
    this.authStore.clear();
    this.authStore.storeAuthorizationToken(token)
    .subscribe( _ => this.openState(state));
  }

  openState(state: string): void {
    this.router.navigateByUrl(state);
  }

  askForAccessToken(code: string): Observable<AuthResponse> {
    const codeRequest = {
      code,
      client_id: environment.authClientId,
      redirect_uri: environment.serverBase + '/oauth'
      };

    return this.http.post<AuthResponse>(environment.authEndpoint + '/oauth/token', codeRequest);
  }

  private constructOauth(stateUrl: string) {
    const stateStr = encodeURIComponent(btoa(stateUrl));
    const clientId = environment.authClientId;
    const redirectUrl = encodeURIComponent(environment.serverBase + '/oauth');

    const queryPart = 'response_type=code&chooseProfile=1&client_id='
    + clientId
    + '&state=' + stateStr
    + '&redirect_uri=' + redirectUrl;
    return queryPart;
  }

}
