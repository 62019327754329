import {Injectable} from "@angular/core";
import {
  ScheduleParticipantBase,
  ScheduleRowSimplified
} from "../model/schedule";
import {DateUtils} from "./helpers/date-utils";
import {RoomTemplateDetailsBase, RoomTemplateBase} from "../model/server";

export class ConflictPair {
  constructor(public left: ScheduleRowSimplified, public right: ScheduleRowSimplified) {
  }
}

export class ConflictResults {
  constructor(
    public byClass: ConflictPair[],
    public byTeacher: ConflictPair[],
    public conflictedTeacherEmails: string[],
    public conflictedClassRooms: string[]){}
}

export class SearchQuery {
  static BY_ALL = "all";
  static BY_GROUP = "group";
  static BY_TEACHER = "teacher";
  static BY_STUDENT = "student";
  static BY_CLASS = "class";

  static SEARCH_TYPES = [SearchQuery.BY_ALL, SearchQuery.BY_GROUP, SearchQuery.BY_TEACHER, SearchQuery.BY_STUDENT, SearchQuery.BY_CLASS];

  constructor(public searchType: string, public queryTerm: string) {
  }
}

export class ScheduleTableDisplay {
  constructor(
    public groupsSorted: RoomTemplateBase<RoomTemplateDetailsBase>[],
    public daysSorted: number[],
    public rowsByDayByGroupId: Map<number, Map<number, ScheduleRowSimplified[]>>
  ) {
  }
}

export class ScheduleCalendarDisplay {
  constructor(
    public daysSorted: number[],
    public rowsByDay: Map<number, ScheduleRowSimplified[]>
  ) {
  }
}

export class SearchResults {
  constructor(
    public results: ScheduleRowSimplified[],
    public foundGroups: RoomTemplateBase<RoomTemplateDetailsBase>[],
    public foundTeachers: ScheduleParticipantBase[],
    public foundStudents: ScheduleParticipantBase[],
    public foundClasses : string[]
  ) {}

  static empty() {
    return new SearchResults([],[],[],[],[]);
  }
  static all(dataSet: ScheduleRowSimplified[]) {
    return new SearchResults(dataSet, [], [], [], []);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleSearchService {

  public search(dataSet: ScheduleRowSimplified[], query: SearchQuery) : SearchResults {
    if (!dataSet) return null;
    if (!query || query.queryTerm.trim() === "") return SearchResults.all(dataSet);

    const allGroups: Map<string,RoomTemplateBase<RoomTemplateDetailsBase>> = this.findDistinctGroups(dataSet);
    const allTeachers: Map<string,ScheduleParticipantBase> = this.findDistinctTeachers(dataSet);
    const allStudents: Map<string,ScheduleParticipantBase> = this.findDistinctStudents(dataSet);
    const allClasses: Set<string> = this.findDistinctClasses(dataSet);

    const queryTerms: string[] = this.prepareQueryTerm(query.queryTerm);

    const isAll = query.searchType === SearchQuery.BY_ALL;
    const groups: Map<string,RoomTemplateBase<RoomTemplateDetailsBase>> = (isAll || query.searchType === SearchQuery.BY_GROUP)
      ? this.filterByHash(allGroups, queryTerms)
      : new Map();
    const teachers: Map<string,ScheduleParticipantBase> = (isAll || query.searchType === SearchQuery.BY_TEACHER)
      ? this.filterByHash(allTeachers, queryTerms)
      : new Map();
    const students: Map<string,ScheduleParticipantBase> = (isAll || query.searchType === SearchQuery.BY_STUDENT)
      ? this.filterByHash(allStudents, queryTerms)
      : new Map();
    const classes: Set<string> = (isAll || query.searchType === SearchQuery.BY_CLASS)
      ? this.filterSetByHash(allClasses, queryTerms)
      : new Set();


    const results = dataSet.filter(
      row => {
        let scheduleClass = row.schedule.details.place;
        if (scheduleClass) scheduleClass = scheduleClass.trim();
        if (!scheduleClass) scheduleClass = row.template.details.place;
        return groups.has(this.hashGroup(row.template))
        || row.schedule.details.participants.find(p => teachers.has(this.hashParticipant(p)))
        || row.schedule.details.participants.find(p => students.has(this.hashParticipant(p)))
        || classes.has(scheduleClass)
      }
    )

    return new SearchResults(
      results,
      Array.from(groups.values()),
      Array.from(teachers.values()),
      Array.from(students.values()),
      Array.from(classes)
    );
  }

  sortEventsByDate(dataSet: ScheduleRowSimplified[]) {
    return dataSet.sort((l, r) => l.schedule.details.startDate - r.schedule.details.startDate);
  }

  findInTimeRange(dataSet: ScheduleRowSimplified[], startingPos: number, timeFrameStart: number, timeFrameEnd: number) {
    const result = [];
    let index = startingPos;
    while(index < dataSet.length) {
        const element = dataSet[index];
        const elementStartDate = element.schedule.details.startDate;
        let elementDuration = element.schedule.details.durationMin;
        if (!elementDuration) elementDuration = 25;
        elementDuration *= 60 * 1000;
        const elementFinishDate = elementStartDate + elementDuration;
        if (elementStartDate > timeFrameEnd) return result;

        /* expression below is tricky because it uses the
        because it uses the result of the previous expression.

        In previous we are checking if element is not after window at
        all. In the condition below we are checking if starts
        or at least ends in the frame.
         */

        if (elementStartDate > timeFrameStart
          || elementFinishDate > timeFrameStart
        ) {
          result.push(element);
        }
        index++;
    }
    return result;
  }

  groupByHash<T>(dataRows: T[], hashExtractor: (T) => string): Map<string, T[]> {
    return dataRows.reduce((result: Map<string, T[]>, row: T) => {
      const rowHash = hashExtractor(row);
      if (!rowHash || rowHash.length === 0) return result;
      let hashElements = result.get(rowHash);
      if (!hashElements) {
        hashElements = [];
        result.set(rowHash, hashElements);
      }
      hashElements.push(row);
      return result;
    }, new Map<string, T[]>()) as Map<string, T[]>;
  }

  teacherMailExtractor = (row: ScheduleRowSimplified) => {
    const teacher = row.schedule.details.participants.find(p => p.role === 'Teacher');
    if (!teacher) return "";
    const teacherMail = teacher.email;
    if (teacherMail) {
      return teacherMail.trim().toLocaleLowerCase();
    }
    return "";
  }

  searchForConflicts(dataSorted: ScheduleRowSimplified[]) {

    const byTeacher = this.groupByHash(dataSorted, this.teacherMailExtractor);

    const byRoom = this.groupByHash(dataSorted, (row: ScheduleRowSimplified) => {
      let classroom = row.schedule.details.place;
      if (!classroom) classroom = row.template.details.place;
      if (classroom) classroom = classroom.trim().toLocaleLowerCase();
      else  classroom = "";
      return classroom;
    });

    const conflictsByTeacher: ConflictPair[] = [];
    const conflictedTeachers: string[] = [];


    byTeacher.forEach((rows, teacherMail) => {
      const conflicts = this.findConflicts(rows);
      if (!conflicts || conflicts.length === 0) return;
      conflictsByTeacher.push(...conflicts);
      conflictedTeachers.push(teacherMail);
    });

    const conflictsByRoom: ConflictPair[] = [];
    const conflictedRooms: string[] = [];

    byRoom.forEach((rows, roomName) => {
      const conflicts = this.findConflicts(rows);
      if (!conflicts || conflicts.length === 0) return;
        conflictsByRoom.push(...conflicts);
        conflictedRooms.push(roomName);
     });

    return new ConflictResults(
      conflictsByRoom,
      conflictsByTeacher,
      conflictedTeachers,
      conflictedRooms
    );
  }

  findConflicts(dataRowsSorted: ScheduleRowSimplified[]) {
    const conflicts: ConflictPair[] = [];
    for (let i = 0 ; i < dataRowsSorted.length ; i++) {
      const element = dataRowsSorted[i];
      const elementStart = element.schedule.details.startDate;
      let elementDuration = element.schedule.details.durationMin;
      if (!elementDuration) elementDuration = 25;
      elementDuration *= 60000;
      const elementEnd = elementStart + elementDuration;
      const conflicted = this.findInTimeRange(dataRowsSorted, i + 1, elementStart, elementEnd);
      if (conflicted.length > 0) {
        conflicts.push(
          ...conflicted.map( it => new ConflictPair(element, it))
        )
      }
      i += conflicted.length;
    }
    return conflicts;
  }

  public prepareDisplayCalendar(sortedDataSet: ScheduleRowSimplified[]) {
    const dates = new Set<number>();
    const rowsByDay = sortedDataSet.reduce((result: Map<number, ScheduleRowSimplified[]>, element) => {
      const dayDate = DateUtils.getDayStartDate(new Date(element.schedule.details.startDate)).getTime();
      dates.add(dayDate);
      let dayEvents = result.get(dayDate);
      if (!dayEvents) {
        dayEvents = [];
        result.set(dayDate, dayEvents);
      }
      dayEvents.push(element);
      return result;
    }, new Map<number,ScheduleRowSimplified[]>());

    return new ScheduleCalendarDisplay(
      Array.from(dates).sort(),
      rowsByDay
    )

  }

  public prepareDisplay(dataSet: ScheduleRowSimplified[], sortingDay?: number): ScheduleTableDisplay {
    const groupById = dataSet.reduce((result: Map<number, RoomTemplateBase<RoomTemplateDetailsBase>>, currentValue) => {
      result.set(currentValue.template.id, currentValue.template);
      return result;
    }, new Map<number, RoomTemplateBase<RoomTemplateDetailsBase>>());

    const dates = new Set<number>();
    const rowsByDayByGroup = dataSet.reduce((result: Map<number, Map<number, ScheduleRowSimplified[]>>, current) => {
      const groupId = current.template.id;
      const dayDate = DateUtils.getDayStartDate(new Date(current.schedule.details.startDate));
      const dayDateMs = dayDate.getTime();
      dates.add(dayDate.getTime());
      let groupSchedulesByDayDate = result.get(groupId);
      if (!groupSchedulesByDayDate) {
        groupSchedulesByDayDate = new Map<number, ScheduleRowSimplified[]>();
        result.set(groupId, groupSchedulesByDayDate);
      }
      let daySchedules = groupSchedulesByDayDate.get(dayDateMs);
      if (!daySchedules) {
        daySchedules = [];
        groupSchedulesByDayDate.set(dayDateMs, daySchedules);
      }
      daySchedules.push(current);
      return result;
    }, new Map<number, Map<number, ScheduleRowSimplified[]>>());

    let groups = Array.from(groupById.values()).sort((l, r) => l.details.name.localeCompare(r.details.name))
    const days = Array.from(dates).sort((l,r) => l.valueOf() - r.valueOf());

    for (const group of groups) {
      const rowsByDay = rowsByDayByGroup.get(group.id);
      if (!rowsByDay) continue;
      for (const day of days) {
        const dayRows = rowsByDay.get(day);
        if (!dayRows) continue;
        rowsByDay.set(day, dayRows.sort((l,r) => l.schedule.details.startDate - r.schedule.details.startDate))
      }
    }
    if (days.indexOf(sortingDay) >= 0) {
      // change sorting by day
      groups = this.sortGroupsByDay(groups, rowsByDayByGroup, sortingDay);
    }
    return new ScheduleTableDisplay(
      groups,
      days,
      rowsByDayByGroup
    );
  }

  public hashParticipant(p: ScheduleParticipantBase) {
    const hash = [];
    if (p.name) hash.push(p.name);
    if (p.email) hash.push(p.email);
    return hash.join(" ");
  }

  public hashGroup(template: RoomTemplateBase<RoomTemplateDetailsBase>) {
    return `${template.id} ${template.details.name}`;
  }

  private findDistinctGroups(dataSet: ScheduleRowSimplified[]) {
    return dataSet.reduce((result, current) => {
      const groupHash = this.hashGroup(current.template);
      result.set(groupHash, current.template);
      return result;
    }, new Map<string, RoomTemplateBase<RoomTemplateDetailsBase>>());
  }

  private findDistinctTeachers(dataSet: ScheduleRowSimplified[]) {
    return ([] as ScheduleParticipantBase[])
        .concat(...dataSet.map( row => row.schedule.details.participants.filter( parti => parti.role === 'Teacher')))
        .reduce((result, current) => {
          const participantHash = this.hashParticipant(current);
          result.set(participantHash, current);
          return result;
        }, new Map<string, ScheduleParticipantBase>());
  }

  private findDistinctStudents(dataSet: ScheduleRowSimplified[]) {
    return ([] as ScheduleParticipantBase[])
      .concat(...dataSet.map( row => row.schedule.details.participants.filter( parti => parti.role === 'Student')))
      .reduce((result, current) => {
        const participantHash = this.hashParticipant(current);
        result.set(participantHash, current);
        return result;
      }, new Map<string, ScheduleParticipantBase>());
  }

  private findDistinctClasses(dataSet: ScheduleRowSimplified[]) {
    return new Set(dataSet
      .map( row => {
        let schedulePlace = row.schedule.details.place;
        if (schedulePlace) schedulePlace = schedulePlace.trim();
        return schedulePlace ? schedulePlace : row.template.details.place
      }  )
      .filter( place => !!place)
      .map( place => place.trim())
      .filter( place => place.length > 0));
  }

  private prepareQueryTerm(queryTerm: string) {
    return queryTerm.trim().split(/\s+/)
      .map( t => t.toLocaleLowerCase());
  }


  private filterByHash<T>(toFilter: Map<string, T>, queryTerms: string[]): Map<string, T> {
    const result = new Map<string, T>();
    toFilter.forEach((v,k) => {
      if (this.match(k, queryTerms)) {
        result.set(k, v);
      }
    });
    return result;
  }

  private filterSetByHash(toFilter: Set<string>, queryTerms: string[]): Set<string> {
    return new Set(Array.from(toFilter).filter( el => this.match(el, queryTerms)))
  }

  private match(text: string, queryTerms: string[]) {
    const k = text.toLocaleLowerCase();
    let lastFoundEndingIndex = -1;
    for (const term of queryTerms) {
      const foundPos = k.indexOf(term, lastFoundEndingIndex + 1);
      if (foundPos <= lastFoundEndingIndex) return false;
      lastFoundEndingIndex = foundPos + term.length;
    }
    return true;
  }

  private sortGroupsByDay(groups: RoomTemplateBase<RoomTemplateDetailsBase>[], rowsByDayByGroup: Map<Number, Map<Number, ScheduleRowSimplified[]>>, sortingDay: number) {
    return groups.sort((l,r) => {
      const leftValue = this.getScheduleDateValue(l, rowsByDayByGroup, sortingDay);
      const rightValue = this.getScheduleDateValue(r, rowsByDayByGroup, sortingDay);
      return leftValue - rightValue;
    })
  }

  private getScheduleDateValue(group: RoomTemplateBase<RoomTemplateDetailsBase>, rowsByDayByGroup: Map<Number, Map<Number, ScheduleRowSimplified[]>>, sortingDay: number) {
    const groupRowsByDay = rowsByDayByGroup.get(group.id);
    if (!groupRowsByDay) return Number.MAX_VALUE;
    const daySchedules = groupRowsByDay.get(sortingDay);
    if (!daySchedules || daySchedules.length === 0) return Number.MAX_VALUE;
    return daySchedules[0].schedule.details.startDate;
  }
}
