<div *ngIf="showTranslations || (!hasPrivileges && !hasError)">
  <h3 class="text-center text-danger my-5" translate>video-preview.dialogs.permissions.title</h3>
  <p translate>video-preview.dialogs.permissions.message</p>
</div>

<div *ngIf="showTranslations || (hasError === errors.CANT_ENUMERATE)">
  <h3 class="text-center text-danger my-5" translate>video-preview.dialogs.enumerate-failed.title</h3>
  <p translate>video-preview.dialogs.enumerate-failed.message</p>
</div>

<div *ngIf="showTranslations || (hasError === errors.CANT_OPEN_STREAM)">
  <h3 class="text-center text-danger my-5" translate>video-preview.dialogs.stream-failed.title</h3>
  <p translate>video-preview.dialogs.stream-failed.message</p>
</div>

<div *ngIf="showTranslations || (hasError === errors.NO_MEDIA_SUPPORT)">
<h3 class="text-center text-danger my-5" translate>video-preview.dialogs.no-media-support.title</h3>
<p translate>video-preview.dialogs.no-media-support.message</p>
</div>
<form #inputs="ngForm" class="form" (ngSubmit)="inputs.form.valid && startVideo()">
<div class="row">
  <div class="col-12 col-md-6 col-lg-5">
    <div [hidden]="!hasPrivileges">
      <div class="embed-responsive embed-responsive-1by1 bg-light video-target">
        <div class="video-placeholder bg-light text-muted" translate>video-preview.video-placeholder</div>
        <video #videoElement muted playsinline autoplay style="object-fit: cover"></video>
      </div>
        <div *ngIf="ready" class="mt-2">
        <div class="form-group mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" style="width:40px;" ><i class="fas fa-video"></i></span>
            </div>
            <select required class="form-control" name="video" [(ngModel)]="selection.videoInput" (change)="videoChanged()">
              <option *ngFor="let device of videoInputs" [ngValue]="device">{{getDeviceName(device)}}</option>
            </select>
          </div>
        </div>
        <div class="form-group mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" style="width:40px;"><i class="fas fa-microphone"></i></span>
            </div>
            <select required class="form-control" name="mic" [(ngModel)]="selection.audioInput" (change)="audioChanged()">
              <option *ngFor="let device of audioInputs" [ngValue]="device">{{getDeviceName(device)}}</option>
            </select>
          </div>
        </div>
        <div class="form-group mt-3 mb-4">
          <div class="row no-gutters m-n1" #vols>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
            <div class="col-2 px-1" ><div class="py-1 border border-secondary w-100"></div></div>
          </div>
        </div>
        </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-7 pt-2">
    <h4 translate>video-preview.verification.title</h4>
    <p translate>video-preview.verification.description</p>
    <dl class="row mt-2">
      <dt class="col-8" translate>video-preview.verification.browser-compatibility.label</dt>
      <dd class="col-4 text-center" *ngIf="browserCompatible()"><i class="fa fa-check-circle text-success"></i></dd>
      <dd class="col-4 text-center" *ngIf="!browserCompatible()"><i class="fa fa-times-circle text-danger"></i></dd>
      <dd *ngIf="showTranslations || (!browserCompatible() && wrongVersion())" class="col-12 small text-left text-danger" translate>video-preview.verification.browser-compatibility.error.wrong-version</dd>
      <dd *ngIf="showTranslations || (!browserCompatible() && unsupportedBrowser())" class="col-12 small text-left text-danger" [innerHTML]="'video-preview.verification.browser-compatibility.error.unsupported' | translate"></dd>
      <dd *ngIf="showTranslations || !browserCompatible() || wrongVersion() || unsupportedBrowser()" class="col-12 small">
        <button type="button" class="btn btn-link btn-sm position-relative" (click)="copyUrl()"><span translate>video-preview.verification.copy-url </span> <span *ngIf="copied"  class="position-absolute top-0 translate-middle badge rounded-pill bg-danger text-white" style="left: 0" translate>errors.link-copied</span></button>
      </dd>
      <dt class="col-8" translate>video-preview.verification.codecs.label</dt>
      <dd class="col-4 text-center" *ngIf="areCodecsCorrect()"><i class="fa fa-check-circle text-success"></i></dd>
      <dd class="col-4 text-center" *ngIf="!areCodecsCorrect()"><i class="fa fa-times-circle text-danger"></i></dd>
      <dd *ngIf="showTranslations || !areCodecsCorrect()" class="col-12 small text-left text-danger" translate>video-preview.verification.codecs.error.unsupported</dd>
      <dd *ngIf="showTranslations || !areCodecsCorrect()" class="col-12 small">
        <button type="button" class="btn btn-link btn-sm position-relative" (click)="copyUrl()"><span translate>video-preview.verification.copy-url </span> <span *ngIf="copied"  class="position-absolute top-0 translate-middle badge rounded-pill bg-danger text-white" style="left: 0" translate>errors.link-copied</span></button>
      </dd>
      <dt class="col-8" translate>video-preview.verification.connection.label</dt>
      <dd class="col-4 text-center" *ngIf="connectedToColVideo"><i class="fa fa-check-circle text-success"></i></dd>
      <dd class="col-4 text-center" *ngIf="!connectedToColVideo"><i class="fa fa-times-circle text-danger"></i></dd>
      <dd *ngIf="showTranslations || !connectedToColVideo" class="col-12 small text-left text-danger" translate>video-preview.verification.connection.error</dd>
      <dd *ngIf="showTranslations || !connectedToColVideo" class="col-12 small">
        <button type="button" class="btn btn-link btn-sm position-relative" (click)="restartTest()" translate>video-preview.verification.reload</button>
      </dd>
      <dt class="col-8" translate>video-preview.verification.video-connection.label</dt>
      <dd class="col-4 text-center" *ngIf="preflightIsRunning">
        <div class="spinner-grow text-warning spinner-grow-sm" role="status">
          <span class="sr-only" translate>video-preview.verification.video-connection.info.testing</span>
        </div>
      </dd>
      <dd class="col-4 text-center" *ngIf="!preflightIsRunning && (!isPreflightSuccess() && !hasConnectionIssues())">
        <button type="button" class="btn btn-sm btn-outline-info" (click)="doPreflight()">Test!</button>
      </dd>
      <dd class="col-4 text-center" *ngIf="isPreflightSuccess() && !hasConnectionIssues()">
       <a class="btn-link btn text-success" (click)="doPreflight()"> <i class="fa fa-check-circle text-success" ></i></a>
      </dd>
      <dd class="col-4 text-center" *ngIf="isPreflightSuccess() && hasConnectionIssues()">
        <button type="button" class="btn btn-sm btn-outline-warning" (click)="doPreflight()"><i class="fa fa-question-circle text-warning"></i> Test!</button>
      </dd>
      <dd class="col-4 text-center" *ngIf="hasPreflightErrors()">
        <button type="button" class="btn btn-sm btn-outline-danger" (click)="doPreflight()"><i class="fa fa-times-circle text-danger"></i> Test!</button>
      </dd>
      <dd class="col-11 pt-2 px-4">
        <div class="progress" style="height: 5px;" *ngIf="preflightIsRunning">
          <div class="progress-bar bg-warning progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [ngStyle]="getPreflightProgressStyle()"></div>
        </div>
        <div class="text-center small text-muted" *ngIf="showTranslations || preflightIsRunning">
          <span translate>video-preview.verification.video-connection.info.testing-connection </span> {{preflightProgressName}}
        </div>
      </dd>
      <dd class="col-12 small text-danger" *ngIf="showTranslations || (!preflightIsRunning && hasPreflightErrors())">
        <span translate>video-preview.verification.video-connection.info.failed-on </span> {{preflightProgressName}}
      </dd>
      <dd class="col-10 offset-1 small text-danger" *ngIf="showTranslations || (isPreflightSuccess() && hasRttIssues && !hasPacketLossIssues && !hasJitterIssues)" [innerHTML]="'video-preview.verification.video-connection.issues.network' | translate"></dd>
      <dd class="col-10 offset-1 small text-danger" *ngIf="showTranslations || (isPreflightSuccess() && (hasPacketLossIssues || hasJitterIssues))" [innerHTML]="'video-preview.verification.video-connection.issues.performance' | translate">
        </dd>
      <dd *ngIf="showTranslations || (isPreflightSuccess() && (hasRttIssues || hasPacketLossIssues || hasJitterIssues))" class="col-12 small">
        <a type="button" [href]="createLink('error/7')" target="_blank" translate>video-preview.verification.show-con-qa-error</a>
      </dd>
      <dd class="col-8" *ngIf="showTranslations || (!hasPrivileges || hasError)">
        <span *ngIf="showTranslations || (!hasPrivileges && !hasError)">
          <strong class="text-danger" translate>video-preview.dialogs.permissions.title</strong><br/>
          <small translate>video-preview.dialogs.permissions.message</small><br/>
        </span>

        <span *ngIf="showTranslations || (hasError === errors.CANT_ENUMERATE)">
          <strong class="text-danger" translate>video-preview.dialogs.enumerate-failed.title</strong><br/>
          <small translate>video-preview.dialogs.enumerate-failed.message</small><br/>
        </span>

        <span *ngIf="showTranslations || (hasError === errors.CANT_OPEN_STREAM)">
          <strong class="text-danger" translate>video-preview.dialogs.stream-failed.title</strong><br/>
          <small translate>video-preview.dialogs.stream-failed.message</small><br/>
        </span>

        <span *ngIf="showTranslations || (hasError === errors.NO_MEDIA_SUPPORT)">
          <strong class="text-danger" translate>video-preview.dialogs.no-media-support.title</strong><br/>
          <small translate>video-preview.dialogs.no-media-support.message</small><br/>
        </span>
      </dd>
      <dd class="col-4 text-center" *ngIf="showTranslations || (!hasPrivileges || hasError)"><i class="fa fa-times-circle text-danger"></i></dd>
    </dl>
  </div>
</div>
  <div class="form-group text-right">
    <button type="submit" class="btn btn-block btn-lg btn-primary" [disabled]="hasError">Start</button>
  </div>
  <dl>
    <dt class="col-8" translate>video-preview.verification.browser-version.label</dt>
    <dd class="col-12">{{getBrowserVersion()}}</dd>
  </dl>
</form>



