<div class="chat">
    <ul #chatMessages >
        <div *ngIf="chat">
        <li  *ngFor="let entry of chat.entries ; trackBy: chatEntryTrackBy" [ngClass]="getEntryClass(entry)">
            <div class="header">
                <span>{{entry.date | date: 'HH:mm'}}</span> -
                <span>{{entry.participant.name}}</span>
            </div>
            <div class="body">
                <span>{{entry.message}}</span>
            </div>
        </li>
        </div>
    </ul>

    <form #chatForm="ngForm" (ngSubmit)="onSendMessage()">

        <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'room.chat.message-placeholder' | translate}}" name="message" [(ngModel)]="chatEntry.message"
            [disabled]="isChatDisabled()"
            >
            <div class="input-group-append">
                <button class="btn btn-outline-primary" type="submit"
                        [disabled]="isChatDisabled()"
                translate>room.chat.send</button>
            </div>
        </div>
    </form>
</div>
