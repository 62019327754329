import {Component, Input, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ServerRestService} from 'src/app/service/server-rest.service';
import {AttendanceDetails, Pageable, RoomProgressReport, Type} from 'src/app/model/server';
import {tap} from 'rxjs/operators';
import {NotificationsService} from 'src/app/service/notifications.service';
import {ParticipantRoomProgressProvider} from '../../service/helpers/progress-history-list.provider';
import {SearchingContextService} from 'src/app/service/searching-context.service';
import {Constants} from 'src/app/service/constants';
import {LessonProgressService} from "../../service/lesson-progress.service";



@Component({
  selector: 'app-teacher-lesson-progress',
  templateUrl: './teacher-lesson-progress.component.html',
  styleUrls: ['./teacher-lesson-progress.component.css']
})
export class TeacherLessonProgressComponent implements OnInit {

  participantUuidSub = new ReplaySubject<string>(1);
  mParticipantUuid: string;
  progress: RoomProgressReport;
  historyLength = 0;
  historyDataProvider: ParticipantRoomProgressProvider = null;
  private formIsDirty = false;
  @Input()
  variant = "mid";
  @Input()
  short = false;

  @Input()
  set participantUuid(partiUuid: string) {
    this.participantUuidSub.next(partiUuid);
    if (partiUuid) this.listenToProgressChanges(partiUuid);
  }

  progressChanged($event) {
    this.formIsDirty = true;
    this.progressService.progressChanged(this.progress.details);
  }

  getAttenderName(attender: AttendanceDetails) {
    return attender.participant.name;
  }

  attendanceChanged($event) {
    this.formIsDirty = true;
    this.progressService.attendanceChanged(this.progress.attenders);
  }

  constructor(private rest: ServerRestService,
              private notifSevice: NotificationsService,
              private searchContext: SearchingContextService,
              private progressService: LessonProgressService) {
    this.participantUuidSub.pipe(
      tap( uuid => this.mParticipantUuid = uuid),
      // order is important - progress may be crated in lazy way during loading
      tap ( uuid => this.setupHistoryDataProvider()),
    ).subscribe();
  }

  setupHistoryDataProvider(): void {
    const path = `unknown`;
    this.historyDataProvider =
      new ParticipantRoomProgressProvider(this.mParticipantUuid, path, this.searchContext, this.rest,
        new Pageable(0, Constants.SmallPageSize, 'createDate,desc'));
  }

  ngOnInit() {
  }

  private listenToProgressChanges(partiUuid: string) {
    this.progressService.readProgress(partiUuid).subscribe(
      newProgress => {
        this.progress = newProgress;
        this.formIsDirty = false;
      }
    )
  }

  hasDirtyState() {
    return this.formIsDirty;
  }

  isColLesson() {
    return this.progress.details.type == Type.Col
  }
}
