import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProgressDetails, RoomProgressReport, Type} from "../../../model/server";
import {Course, LessonType, Stage} from "../../../model/courses";
import {Adapter} from "../../autocomplete-input/autocomplete-input.component";
import {of} from "rxjs";
import {ContentItem, ContentItemType, Course as BookCourse} from "../../../model/books";

import {BooksRestService} from "../../../service/books-service";

@Component({
  selector: 'app-progress-block',
  templateUrl: './progress-block.component.html',
  styleUrls: ['./progress-block.component.css']
})
export class ProgressBlockComponent implements OnInit {
  private products: BookCourse[];

  @Input()
  set progress(value: RoomProgressReport) {this._progress = value; this.loadProducts();}
  get progress() {return this._progress;}
  _progress: RoomProgressReport;

  @Input()
  variant = "mid";

  @Output()
  progressChanged = new EventEmitter<ProgressDetails>()

  lessonTypeAdapter: Adapter<LessonType> = {
    detailsMapper: _ => null,
    mapper: lessonType => lessonType.name,
    dataProvider: input => of(LessonType.values.filter( lessonType => lessonType.name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0))
  };

  courseAdapter: Adapter<Course> = {
    detailsMapper: _ => null,
    mapper: course => course.name,
    dataProvider: input => of(Course.values.filter( course => course.name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0))
  };

  stageAdapter: Adapter<Stage> = {
    detailsMapper: _ => null,
    mapper: stage => stage.name,
    dataProvider: input => {
      const course = this.findSelectedCourse();
      if (!course) return of([]);
      return of(Stage.forCourse(course).filter(stage => stage.name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0));
    }
  }

  private findSelectedCourse(): Course {
    return Course.values.find(c => c.name === this.progress.details.course);
  }
  private findBookCourse(course: Course): BookCourse {
    if (!course) return null;
    if (!this.products) return null;
    return this.products.find(p => p.code === course.code);
  }
  private allCourseLessons(bookCourse: BookCourse): ContentItem[] {
    if (!bookCourse) return [];
    return [].concat(...bookCourse.stages.map( stage => stage["content-items"].filter(item => item.type === ContentItemType.TEXT || item.type == null)));
  }
  private allCourseDictations(bookCourse: BookCourse): ContentItem[] {
    if (!bookCourse) return [];
    return [].concat(...bookCourse.stages.map( stage => stage["content-items"].filter(item => item.type === ContentItemType.DICT)));
  }

  lessonAdapter: Adapter<ContentItem> = {
    detailsMapper: _ => null,
    mapper: item => item.name,
    dataProvider: input => {
      return of(this.allCourseLessons(
        this.findBookCourse(
          this.findSelectedCourse()))
        .filter( it => it.name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0));
    }
  }

  dictationsAdapter: Adapter<ContentItem> = {
    detailsMapper: _ => null,
    mapper: item => item.name,
    dataProvider: input => {
      return of(this.allCourseDictations(
        this.findBookCourse(
          this.findSelectedCourse()))
        .filter( it => it.name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0));
    }
  }

  constructor(private rest: BooksRestService) {
  }

  ngOnInit() {
  }

  onProgressChanged($event: any) {
    this.progressChanged.emit(this.progress.details);
  }

  label(value: string) {
    if (value === 'para') {
      return "NWP";
    } else if (value === 'last-hw') {
      return "Last word taught";
    } else if (value === 'read') {
      if (this.isPre()) return "Reading start page";
      return "Reading finished";
    } else if (value === 'dict') {
      return "Dictation";
    } else if (value === 'comments') {
      return "Comments";
    } else if (value === 'course') {
      return "Course";
    } else if (value === 'stage') {
      return "Stage";
    } else if (value === 'lesson') {
      return 'Reading lesson';
    } else if (value === 'lessonType') {
      return 'Lesson type';
    } else if (value === 'startPage') {
      return 'Start page';
    } else if (value === 'nwp') {
      return 'New work page';
    }
  }

  placeholder(value: string) {
    if (this.variant === "mid") return this.label(value); else return "";
  }

  showLabel() {
    return this.variant !== "mid";
  }

  isPre() {
    return this.variant === 'pre';
  }

  getCourses() {
    return Course.values;
  }

  getStages(courseCode: string) {
    const course = Course.byCode(courseCode);
    console.log(course);
    if (!course) return [];
    const stages = Stage.forCourse(course);
    console.log(stages);
    return stages;
  }

  courseSelected(selected: Course) {
    this.progress.details.course = selected.name;
    this.onProgressChanged(null);
  }

  stageSelected(selected: Stage) {
    this.progress.details.stage = selected.name;
    this.onProgressChanged(null);
  }

  private loadProducts() {
    if (!this.progress || !this.progress.teacher.uuid || this.products) return;
    this.rest.listProducts(this.progress.teacher.uuid).subscribe( prods => this.products = prods);
  }

  lessonSelected(selected: ContentItem) {
    this.progress.details.readingLesson = selected.name;
    this.onProgressChanged(null);
    this.fixLessonPage();
  }

  onLessonPageChange() {
    this.fixLessonNumber();
  }

  fixLessonPage() {
    const lessonFoundArray: ContentItem[] = this.allCourseLessons(this.findBookCourse(this.findSelectedCourse()))
      .filter( item => item.name === this.progress.details.readingLesson)
    if (!lessonFoundArray || lessonFoundArray.length !== 1) return;
    const lesson = lessonFoundArray[0];
    const page = this.getPage();
    if (page == null || page < lesson["page-from"] || page > lesson["page-to"]) {
      this.progress.details.reading = lesson["page-from"]? lesson["page-from"].toString() : null;
    }
  }

  private getPage() {
    if (!this.progress) return null;
    const pageWritten = this.progress.details.reading;
    if (!pageWritten || pageWritten.trim().length == 0) return null;
    return Number.parseInt(pageWritten);
  }


  private fixLessonNumber() {
    const page = this.getPage();
    if (page == null) return;
    const lessonFoundArray: ContentItem[] = this.allCourseLessons(this.findBookCourse(this.findSelectedCourse()))
      .filter( lesson => lesson["page-from"] <= page && page <= lesson["page-to"]);
    if (!lessonFoundArray || lessonFoundArray.length !== 1) return;
    this.progress.details.readingLesson = lessonFoundArray[0].name;
  }

  lessonTypeSelected(item: LessonType) {
    this.progress.details.lessonType = item.name;
    this.onProgressChanged(null);
  }

  dictationSelected(selected: ContentItem) {
    this.progress.details.dictation = selected.name;
    this.onProgressChanged(null);
  }

  isColLesson() {
    return this.progress.details.type == Type.Col
  }
}
