import { GenericSimpleDataProvider } from 'src/app/model/internal';
import { GroupTiming } from 'src/app/model/server';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { Observable } from 'rxjs';

export class SearchingTerms {
    constructor(public month: number, public duration: number) { }
  }

export class ReportDataProvider implements GenericSimpleDataProvider<GroupTiming[]> {
constructor(
            private searchContextPath: string,
            private schoolId: number,
            private rest: ServerRestService,
            private terms: SearchingTerms,
            private searchingContext: SearchingContextService
            ) {}
    doLoad(): Observable<GroupTiming[]> {
        this.searchingContext.updateContext<SearchingTerms>(this.searchContextPath, this.terms);
        return this.rest.getBillingReport(this.schoolId, this.terms.month, this.terms.duration);
    }
}
