import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ParticipantData} from "../../model/internal";
import {Subscription} from "rxjs";
import {ToolboxControllerService} from "../../service/toolbox-controller.service";

@Component({
  selector: 'app-student-toolbox',
  templateUrl: './student-toolbox.component.html',
  styleUrls: ['./student-toolbox.component.css']
})
export class StudentToolboxComponent implements OnInit, OnDestroy {

  private showToolbox = false;
  @Input()
  selfData: ParticipantData;
  toolset = 'Chat';
  private toolboxStateSubscription: Subscription;

  setToolset(name: string) {
    this.toolset = name;
  }

  isToolset(name: string) {
    return this.toolset === name;
  }

  public switchToolbox() {
    this.toolboxControllerService.toolboxShowSubject.next(!this.showToolbox);
  }

  constructor(private toolboxControllerService: ToolboxControllerService) { }

  ngOnInit() {
    this.toolboxStateSubscription = this.toolboxControllerService.toolboxShowSubject.subscribe(
      state => this.showToolbox = state
    )
  }

  isShowToolbox() {
    return this.showToolbox
  }

  ngOnDestroy(): void {
    if (this.toolboxStateSubscription) {
      this.toolboxStateSubscription.unsubscribe();
    }
  }

  getSelfUuid() {
    return this.selfData.participantDef.uuid;
  }

}
