
<ul class="flipchart-list">
    <li>
        <a class="entry blank" (click)="onSelected(null)">
            <span class="name">Blank</span>
        </a>
    </li>
    <li *ngFor="let chartEntry of charts">
        <a class="entry" (click)="onSelected(chartEntry)">
            <img  [src]="getChartImage(chartEntry)">
            <span class="name">{{getChartName(chartEntry)}}</span>
        </a>
    </li>
</ul>
<div class="clearfix"> </div>
