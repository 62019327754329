<app-modal-v2 #modal>
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Navigator</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="doJump()">
        <div class="form-group">
          <label>Action:</label>
          <select class="form-control my-1" [(ngModel)]="selectedJumType" name="jump-type" (change)="jumpTypeSwitched()">
            <option *ngFor="let kind of jumpKinds" [ngValue]="kind" >{{kind.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{selectedJumType.inputLabel}}:</label>
          <input #numberInput class="form-control my-1" type="number" name="jump-value" [(ngModel)]="selectedNumber">
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-warning mr-1" *ngIf="isRegularBookmark()" (click)="openBookmark()">Back to {{getBookmarkPage()}} [B]</button>
          <button type="button" class="btn btn-danger mr-1" *ngIf="isActionBookmark()" (click)="openBookmark()">Finish {{getActionName()}} & Back to {{getBookmarkPage()}} [B]</button>
          <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal" (click)="hide()">Close</button>
          <button type="submit" class="btn btn-primary">{{selectedJumType.actionCall}}</button>
        </div>
      </form>
    </div>
  </div>
</app-modal-v2>
