<div [@loading]="isLoading ? 'loading' : 'loaded'" class="progress mb-1" style="height: 4px">
  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
</div>
<div *ngIf="dataRows?.length > 0" class="schedule-list row no-gutters mx-n1 mb-2">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 px-1" *ngFor="let row of dataRows">
    <div class="schedule h-100 row no-gutters">
      <div class="col-4 d-flex flex-column align-content-stretch cursor-pointer" (click)="onOpen(row)" [ngClass]="getStatusColorClass(row)" >
        <div class="flex-grow-1 pt-2">
          <h5 class="text-center">
            {{getScheduleTime(row) | date: 'HH:mm'}}
          </h5>
        </div>
        <div class="text-center flex-grow-0">
          <small>{{getScheduleTime(row) | date: 'EEEE'}}</small>
        </div>
        <div class="text-center flex-grow-0 pb-1">
          <small>{{getScheduleTime(row) | date: 'dd MMM'}}</small>
        </div>
      </div>
      <div class="col-8 d-flex flex-column align-content-stretch p-1">
        <div class="flex-grow-1 text-right">
          <div *ngIf="showTemplateName" class="cursor-pointer" (click)="onOpen(row)">
            {{getTemplateName(row)}}
          </div>
          <div class="teacher-name cursor-pointer" (click)="onOpen(row)">
            {{getTeacherName(row)}}
          </div>
          <div *ngIf="getTeacherEmail(row)">
            <small class="text-muted">{{getTeacherEmail(row)}}</small>
          </div>
        </div>
        <div class="flex-grow-0 row no-gutters m-n1 py-1">
          <div class="col-auto px-1">
            <small class="text-muted" *ngIf="countActiveStudents(row)">
              ({{countActiveStudents(row)}}) <i class="fas fa-users"></i>
            </small>
            <small class="text-muted" *ngIf="countOfflineStudents(row)">
               ({{countOfflineStudents(row)}})  <i class="fas fa-school"></i>
            </small>
          </div>
          <div class="col text-right px-1">
            <small class="text-muted">
              {{getScheduleStatus(row)}}
            </small>
          </div>
          <div class="col-auto px-1">
            <a tabindex="" *ngIf="mayDelete(row)" class="text-danger mr-2 cursor-pointer small" (click)="onDelete(row)"><i class="fas fa-trash"></i></a>
            <a tabindex="" *ngIf="mayStart(row)"  class="cursor-pointer small" (click)="onStart(row)"><i class="fas fa-play"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div  class="text-center my-4" *ngIf="isEmpty()">No results.</div>
