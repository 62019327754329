import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ServerRestService} from "../../service/server-rest.service";
import {PingService} from "../../service/ping.service";
import {ParticipantDef, RoomDef} from "../../model/server";
import {RoomEnterComponent} from "../room-enter/room-enter.component";
import {take} from "rxjs/operators";
import {BrowserAudit, BrowserVersionService, SystemInfo} from "../../service/browser-version.service";
import {TranslateService} from "@ngx-translate/core";


export enum ErrorMessage {
  RoomNotFount = 1, // room is closed and can't be opened
  VideoConnectionProblems = 2, // can't establish twilio infrastructure connection
  UnableToConnect = 3, // unable to connect but probably problem is on Twilio side
  UnableToEstablishWebRtc = 4, // client is unsupported
  NotAllowedError = 5, // no permissions
  CouldNotStartMedia = 6, // video source is not readable
  LowNetworkProfile = 7

}
export enum TwilioErrors {
  RoomNotFound = 53106,

  SignalingConnectionError = 53000,
  SignalingDisconnected = 53001,
  SignalingTimeOut = 53002,

  UnableToConnect = 53104,

  UnableToApplyLocalMediaDescriptor = 53400,
  ServerUnableToApplyLocalMediaDescriptor = 53401,
  UnableToApplyRemoteMediaDescriptor = 53402,
  ServerUnableToApplyRemoteMediaDescriptor = 53403,
  NoSupportedCodec = 53404,

  MediaConnectionFailed = 53405
}

export class ErrorResolver {
  public static match(err: any): ErrorMessage {
    if (err.name === 'NotAllowedError') {
      return ErrorMessage.NotAllowedError;
    }
    if (err.name === 'NotReadableError') {
      return ErrorMessage.CouldNotStartMedia;
    }
    if (err.name === 'TwilioError'){
      const errorCode = err.code;
      if (errorCode === TwilioErrors.RoomNotFound) {
        return ErrorMessage.RoomNotFount;
      }
      if (
        errorCode === TwilioErrors.SignalingConnectionError ||
        errorCode === TwilioErrors.SignalingDisconnected ||
        errorCode === TwilioErrors.SignalingTimeOut
      ) return ErrorMessage.VideoConnectionProblems;

      if (
        errorCode === TwilioErrors.UnableToConnect
      ) return ErrorMessage.UnableToConnect;

      if (
        errorCode === TwilioErrors.UnableToApplyLocalMediaDescriptor ||
        errorCode === TwilioErrors.ServerUnableToApplyLocalMediaDescriptor ||
        errorCode === TwilioErrors.UnableToApplyRemoteMediaDescriptor ||
        errorCode === TwilioErrors.ServerUnableToApplyRemoteMediaDescriptor ||
        errorCode === TwilioErrors.NoSupportedCodec
      ) return ErrorMessage.UnableToEstablishWebRtc;

      if (
        errorCode === TwilioErrors.MediaConnectionFailed
      ) return ErrorMessage.CouldNotStartMedia
    }
  }
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  private participantUuid: string;
  private room: RoomDef;
  private teacher: ParticipantDef;
  private self: ParticipantDef;
  private role: string;
  private errorCode: number;
  private browserInfo: SystemInfo;
  private audit: BrowserAudit;
  errors = ErrorMessage;
  private time = new Date();

  constructor(activatedRoute: ActivatedRoute,
              private router: Router,
              private rest: ServerRestService,
              private browserInfoService: BrowserVersionService,
              private translate: TranslateService) {
    this.browserInfo = browserInfoService.getBrowserInfo();
    this.audit = browserInfoService.auditSystem();
    activatedRoute.data.pipe(take(1)).subscribe(data => this.role = data.role)
    activatedRoute.paramMap.subscribe(params => {
      this.participantUuid = params.get('participantUuid');
      this.errorCode = Number(params.get('errorCode'));
      this.loadData();
    })
  }

  public is(errorMessage: ErrorMessage) {
    return this.errorCode === errorMessage;
  }

  private loadData() {
    this.rest.findRoomPreviewByParticipant(this.participantUuid).subscribe(room => {
      this.storeRoom(room);
    })
  }

  showReopenLink() {
    return this.errorCode === ErrorMessage.VideoConnectionProblems ||
        this.errorCode === ErrorMessage.NotAllowedError ||
      this.errorCode === ErrorMessage.CouldNotStartMedia ||
      this.errorCode === ErrorMessage.UnableToConnect;
  }

  private storeRoom(room: RoomDef) {
    this.room = room;
    this.teacher = room.participants.find( p => p.role === 'Teacher');
    this.self = room.participants.find( p => p.uuid === this.participantUuid);
  }

  ngOnInit() {
  }

  gePartiName() {
    return RoomEnterComponent.getName(this.self);
  }

  static getName(parti: ParticipantDef) {
    if (!parti) return '';
    return parti.name;
  }

  getRoomName() {
    if (!this.room) return '';
    return this.room.name;
  }

  getTeacherName() {
    return RoomEnterComponent.getName(this.teacher);
  }

   getPlannedDate() {
    if (!this.room) return null;
    return this.room.plannedDate;
  }

  copied = false;

  hasData() {
    return this.room
  }

  createLink() {
    const host = window.location.host;
    const protocol = window.location.protocol;

    if (this.role == "teacher") {
      return `${protocol}//${host}/participants/${this.participantUuid}/teacher-room`;
    } else {
      return `${protocol}//${host}/participants/${this.participantUuid}/student-room`;
    }
  }

  openAgain() {
    window.location.href = this.createLink();
  }

  copyUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.createLink();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  isBrowserCompatible() {
    return this.audit &&
      !this.audit.wrongBrowser &&
      !this.audit.unsupportedBrowser &&
      !this.audit.wrongBrowserVersion
  }

  getAgentInfo() {
    return this.browserInfoService && this.browserInfoService.getBrowserInfo().navigatorAgent;
  }

  getCompatibilityStatus() {
    return this.translate.get('errors.compatibility-status.' + (this.isBrowserCompatible() ? 'compatible' : 'incompatible') );
  }

  getTime() {
    return this.time;
  }

  showCopyLink() {
    return this.errorCode === ErrorMessage.VideoConnectionProblems ||
      this.errorCode === ErrorMessage.UnableToConnect ||
      this.errorCode === ErrorMessage.UnableToEstablishWebRtc ||
      this.errorCode === ErrorMessage.NotAllowedError ||
      this.errorCode === ErrorMessage.CouldNotStartMedia;
  }
}
