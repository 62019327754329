import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import {  Chart } from 'src/app/model/server';
import { take} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {FlipChartDefinitionsService} from "../../service/flipchart-definitions";
import {ChartHostService} from "../../service/chart-host-service";
import {SocketServiceV2} from "../../service/socket-v2.service";


@Component({
  selector: 'app-chart-host',
  templateUrl: './chart-host.component.html',
  styleUrls: ['./chart-host.component.css']
})
export class ChartHostComponent implements OnInit, OnDestroy {

  @Input()
  chart: Chart;
  mRoomUuid: string = null;
  chartSubscription: Subscription;

  @Input()
  set roomUuid(uuid: string) {
    if (!this.mRoomUuid && uuid) {
      this.mRoomUuid = uuid;
      this.socketService.connect()
      .subscribe(
        ctx => this.chartSubscription =
          this.socketService.topic<Chart>(ctx, '/room/' + uuid + '/chart')
          .subscribe( chart => this.setupChart(chart))
        );
    }
  }

  setupChart(chart: Chart): void {
    if ((chart as any as string) === 'null') {
      chart = null;
    }

    this.chart = chart;
    if (chart == null) {
      //this.chartUpdate.emit(null);
      this.chartHostService.applyIncomingChartUpdate(null);
      return;
    }
    this.chartSetDefinitions.getChartEntriesById().pipe(take(1)).subscribe(
      entries => {
        this.chart.entryAssigned = entries[this.chart.chartId];
        //this.chartUpdate.emit(this.chart);
        this.chartHostService.applyIncomingChartUpdate(this.chart);
      }
    )
  }

  onChartDataUdate(chartData: string) {
    //this.chartDataUpdate.emit(chartData);
    this.chartHostService.populateChartDataUpdate(chartData);
  }

  constructor(
    private socketService: SocketServiceV2,
    private chartSetDefinitions: FlipChartDefinitionsService,
    private chartHostService: ChartHostService) {
   // socketService.connect().subscribe( context => )
  }

  ngOnDestroy(): void {
    if (this.chartSubscription) {
      this.chartSubscription.unsubscribe();
    }
  }

  ngOnInit() {
  }
}
