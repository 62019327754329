<p class="text-danger">Any changes to this list will <strong>affect all future planned lessons</strong>.</p>
<form *ngIf="template" #nrForm="ngForm" (submit)="!isEditForbidden() && onSave()" >
    <div class="alert alert-warning" *ngIf="isEditForbidden()">
      <strong>You can't edit this group.</strong> This group uses additional features which are supported in <strong>admin service only</strong>. Please switch to the
      <a href="https://admin.callanonline.com">admin.callanonline.com</a> service, where you will find
      all video functionality with better support.
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-sm-6">
          <label>Group name</label>
          <input class="form-control"
          [(ngModel)]="template.details.name"
          placeholder="Group name"
          name="room-name"
          [disabled]="isEditForbidden()"
          >
      </div>
      <div class="form-group col-12 col-sm-6">
        <label>Classroom name <small>(optional for offline groups)</small></label>
        <input class="form-control"
               [(ngModel)]="template.details.place"
               placeholder="classroom number / name"
               [disabled]="isEditForbidden()"
               name="room-number">
      </div>
    </div>
    <div class="mb-3">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="online-group" name="online-group" [(ngModel)]="onlineGroup"
               [disabled]="isEditForbidden()">
        <label class="custom-control-label" for="online-group" [innerHTML]="getOfflineModeDescription()"></label>
      </div>
    </div>
    <div>Default teacher:</div>
    <div class="form-row">
        <div class="form-group col-12 col-sm-6">
            <input class="form-control" name="teacher-name" placeholder="Teacher's name" [(ngModel)]="teacher.details.name"
                   [disabled]="isEditForbidden()">
        </div>
        <div class="form-group col-12 col-sm-6">
            <input class="form-control" name="teacher-mail" type="email" placeholder="Teacher's email" [(ngModel)]="teacher.details.email"
                   [disabled]="isEditForbidden()">
        </div>
<!--        <div class="form-group col-auto">-->
<!--          <button type="button" class="btn btn-link" (click)="editSettings(teacher)"><i class="fa fa-cog"></i></button>-->
<!--        </div>-->
    </div>
    <div class="row">
        <div class="col-auto mb-2">
            Students:
        </div>
        <div class="col text-right" *ngIf="!isEditForbidden()">
            <button type="button" *ngIf="canAdd()" class="btn btn-primary btn-sm float-right mt-n1" (click)="addStudent()"><i  class="fa fa-plus"></i></button>
        </div>
    </div>
    <div class="row" *ngFor="let student of students; let i = index">
      <div class="col-2 col-sm-3 col-lg-2 text-secondary pt-1 student-state-icons">
        <span *ngIf="student.details.offline"><i class="fa fa-school"></i></span>
        <span *ngIf="student.details.videoOff"><i class="fa fa-video-slash"></i></span>
        <span *ngIf="student.details.audioOff"><i class="fa fa-volume-mute"></i></span>
        <span *ngIf="student.details.passive"><i class="fa fa-hands"></i></span>
        <span *ngIf="student.details.chartOff"><i class="fa fa-chart-pie"></i></span>
        <span *ngIf="student.details.secret"><i class="fa fa-user-secret"></i></span>
        <span *ngIf="student.details.muteOff"><i class="fa fa-lock"></i></span>
        <span *ngIf="student.details.chatOff"><i class="fa fa-comment-slash"></i></span>
      </div>
      <div class="col-10 col-sm-9 col-lg-10">
        <div class="form-row" >
          <div class="form-group col-12 col-lg">
            <input class="form-control" name="student-name-{{i}}" placeholder="Student's name" [(ngModel)]="student.details.name"
                   [disabled]="isEditForbidden()">
          </div>
          <div class="form-group col">
            <input class="form-control" name="student-mail-{{i}}" type="email" placeholder="Student's email" [(ngModel)]="student.details.email"
                   [disabled]="isEditForbidden()">
          </div>
          <div class="form-group col-auto text-right" *ngIf="!isEditForbidden()">
            <div class="btn-group">
              <button type="button" class="btn btn-link text-danger" (click)="removeStudent(student)"><i class="fa fa-trash"></i></button>
              <button type="button" class="btn btn-link text-secondary" (click)="editSettings(student)"><i class="fa fa-cog"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-md-3 col-lg-2">
            <label class="col-form-label">Select a region:</label>
        </div>
        <div class="form-group col">
            <select class="form-control" [(ngModel)]="template.details.region" name="room-region"
                    [disabled]="isEditForbidden()">
                <option *ngFor="let region of regions" [value]="region.code">{{region.name}}</option>
            </select>
            <small id="passwordHelpBlock" class="form-text text-danger">
                Choose the region closest to your location for improved connection quality.
            </small>
        </div>
    </div>
    <div class="text-right my-2" *ngIf="!isEditForbidden()">
        <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary" (click)="onCancel()">Clear</button>
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
</form>

<app-modal-v2 #settingsModal size="lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Participant settings</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="editParticipantSettings">

<!--      <div class="form-row">-->
<!--        <div class="col-1 text-muted pt-2">-->
<!--          <i class="fa fa-school"></i>-->
<!--        </div>-->
<!--        <div class="col col-form-label">-->
<!--          <label> In class-->
<!--          </label>-->
<!--          <div class="text-muted small mt-n2">-->
<!--            If you are going to have a hybrid lesson check this switch for students in the class.-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group col-auto pt-2">-->
<!--          <div class="custom-control custom-switch">-->
<!--            <input type="checkbox" class="custom-control-input" id="settings-offline" [(ngModel)]="editParticipantSettings.details.offline">-->
<!--            <label class="custom-control-label text-hide" for="settings-offline">In class</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-hands"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent random prompting
          </label>
          <div class="text-muted small mt-n2">
            The student will never be selected by the randomizer (prompting with the space bar).
            To prompt the student, you will need to select them manually.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-passive" [(ngModel)]="editParticipantSettings.details.passive">
            <label class="custom-control-label text-hide" for="settings-passive">Passive</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-chart-pie"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent chart interaction
          </label>
          <div class="text-muted small mt-n2">
            The student won’t be able to interact with the demonstration charts. <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-chart-off" [(ngModel)]="editParticipantSettings.details.chartOff">
            <label class="custom-control-label text-hide" for="settings-chart-off">Chart Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-comment-slash"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent chat interaction
          </label>
          <div class="text-muted small mt-n2">
            The student won’t be able to send chat messages, but will see other chat messages. <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-chat-off" [(ngModel)]="editParticipantSettings.details.chatOff">
            <label class="custom-control-label text-hide" for="settings-chat-off">Chat Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-user-secret"></i>
        </div>
        <div class="col col-form-label">
          <label>Observation mode
          </label>
          <div class="text-muted small mt-n2">
            The observer won’t be seen by the other participants, and won’t be able to broadcast audio or video, use the chat feature, or interact with the demonstration charts.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-secret" [(ngModel)]="editParticipantSettings.details.secret">
            <label class="custom-control-label text-hide" for="settings-secret">Secret</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-lock"></i>
        </div>
        <div class="col col-form-label">
          <label>Prevent muting
          </label>
          <div class="text-muted small mt-n2">
            The participant can’t be muted by the teacher and won’t be muted automatically when another student is selected. The participant can still mute themselves.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-mute-off" [(ngModel)]="editParticipantSettings.details.muteOff">
            <label class="custom-control-label text-hide" for="settings-mute-off">Mute Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-video-slash"></i>
        </div>
        <div class="col col-form-label">
          <label>Video Off
          </label>
          <div class="text-muted small mt-n2">
            The student’s video will be off when they join the lesson. You can turn their video back on during the lesson.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-video-off" [(ngModel)]="editParticipantSettings.details.videoOff">
            <label class="custom-control-label text-hide" for="settings-video-off">Video Off</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-1 text-muted pt-2">
          <i class="fa fa-volume-mute"></i>
        </div>
        <div class="col col-form-label">
          <label>Audio off
          </label>
          <div class="text-muted small mt-n2">
            The student’s audio will be off when they join the lesson. You can turn their audio back on during the lesson.
          </div>
        </div>
        <div class="form-group col-auto pt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="settings-audio-off" [(ngModel)]="editParticipantSettings.details.audioOff">
            <label class="custom-control-label text-hide" for="settings-audio-off">Audio Off</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    </div>
  </div>

</app-modal-v2>
