import {Observable} from "rxjs";


export function volumeFromStream(stream: MediaStream): Observable<number> {
  return new Observable<number>(observer => {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;
    audioSource.connect(analyser);
    const volumes = new Uint8Array(analyser.frequencyBinCount);
    const volumeCallback = () => {
      analyser.getByteFrequencyData(volumes);
      let volumeSum = 0;
      for (const volume of volumes)
        volumeSum += volume;
      const averageVolume = volumeSum / volumes.length;
      // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
      observer.next(averageVolume / 128);
    }
    const intervalId = setInterval(volumeCallback, 100);

    return () => {
      clearInterval(intervalId);
      audioSource.disconnect();
    };
  });
}
