import {ParticipantData} from "../../model/internal";
import {ServerRestService} from "../server-rest.service";
import {LessonProgressService} from "../lesson-progress.service";
import {take} from "rxjs/operators";
import {RoomContextHolder} from "./RoomContextHolder";
import {Injectable} from "@angular/core";
import {RoomDataService} from "./room-data-service";

interface RoomParticipantsDataServiceI {
  getParticipants(): ParticipantData[];
  participantsTrackBy(index: number, item: ParticipantData);
  disconnectAll();
  getActiveParticipantAsArray(): ParticipantData[];
  hasActiveParticipant(): boolean;
  activate(parti: ParticipantData, state?: boolean);
  isActive(parti: ParticipantData): boolean;
  isMuted(parti: ParticipantData): boolean;
  isHidden(parti: ParticipantData): boolean;
  isFocused(parti: ParticipantData): boolean;
  getPartiCounterColor(participant: ParticipantData): any;
  switchVideo(parti: ParticipantData, state: boolean);
  switchSelfVideo(state: boolean);
  switchSelfAudio(state: boolean);
  switchAudio(parti: ParticipantData, state: boolean) ;
  switchFocus(parti: ParticipantData, state?: boolean) ;
  getFocusedStudent(): ParticipantData[] ;
  hasFocused(): boolean ;
  getPartiNameWithCounter(parti: ParticipantData): string ;
  getPartiName(parti: ParticipantData): string ;
  getTeacherAsArray(): ParticipantData[];
  hasTeacher(): boolean;
  getStudents(): ParticipantData[];
  randomStudentByFrequency():ParticipantData;
  isOnline(parti: ParticipantData): boolean;
  isTeacher(parti: ParticipantData): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoomParticipantsDataService implements RoomParticipantsDataServiceI{
    hidePartis = false;

    constructor(
        private serverRest: ServerRestService,
        private progressService: LessonProgressService,
        private contextHolder: RoomContextHolder,
        private selfHolder: RoomDataService
        ) {}

    private get selfUuid() {
      return this.selfHolder.participantUuid;
    }

    private get participants() {
      if (this.hidePartis) return [];
      return this.getParticipants();
    }

    getParticipants(): ParticipantData[] {
      if (this.hidePartis) return [];
      if (!this.contextHolder.participants) return [];
      return this.contextHolder.participants;
    }

    participantsTrackBy(index: number, item: ParticipantData) {
        return item.participantDef.uuid;
    }

    disconnectAll() {
        // if (this._participants) {
        //     this._participants.forEach(p => {
        //       const parti = p.participant;
        //         try {
        //             p.participant.disconnect();
        //         } catch (e) {
        //
        //         }
        //     });
        //     this.participants = [];
        // }
    }

    getActiveParticipantAsArray() {
      if (this.hidePartis) return [];
      const res = this.participants.find(p => p.participantDef.active);
      if (!res) return [];
      return [res];
    }

    hasActiveParticipant() {
      if (this.hidePartis) return false;
      return this.getActiveParticipantAsArray().length > 0;
    }

    activate(parti: ParticipantData, state?: boolean) {
        this.serverRest.changeStudentActivationState(this.selfUuid, parti.participantDef.uuid, state).subscribe();
        this.progressService.readProgress(this.selfUuid).pipe(
          take(1)
        ).subscribe(
          progress => {
            const absent = progress.attenders.find( attender => attender.participant.uuid === parti.participantDef.uuid && !attender.attended);
            if (absent) {
              absent.attended = true;
              this.progressService.attendanceChanged(progress.attenders);
            }
          }
        )
    }

    isActive(parti: ParticipantData) {
        if (!parti) return false;
        return parti.participantDef.active;
    }

    isMuted(parti: ParticipantData) {
        if (!parti) return false;
        return parti.participantDef.muted;
    }

    isHidden(parti: ParticipantData) {
        if (!parti) return false;
        return parti.participantDef.hidden;
    }

    isFocused(parti: ParticipantData) {
      if (!parti) return false;
      return parti.participantDef.focused;
    }

    getPartiCounterColor(participant: ParticipantData) {
        const values = this.participants.filter(p => p.participantDef.role = 'Student').map(p => p.participantDef.activationsNb);
        const maxValue = Math.max(...values);
        const minValue = Math.min(...values);

        // value in  range 0-1, 1 means that was highest frequency
        let partiValue = 0;
        if (maxValue > minValue) {
            partiValue = (participant.participantDef.activationsNb - minValue) / (maxValue - minValue);
        }

        const lValue = Math.round(50 + partiValue * 50);
        return {'background-color': `hsl(0, 100%, ${lValue}%)`};
    }

    switchVideo(parti: ParticipantData, state: boolean) {
        this.serverRest.changeStudentVideoState(this.selfUuid, parti.participantDef.uuid, state).subscribe();
    }
    switchSelfVideo(state: boolean) {
      this.serverRest.changeSelfVideoState(this.selfUuid, state).subscribe();
    }

    switchSelfAudio(state: boolean) {
      this.serverRest.changeSelfAudioState(this.selfUuid, state).subscribe();
    }

    switchAudio(parti: ParticipantData, state: boolean) {
        this.serverRest.changeStudentAudioState(this.selfUuid, parti.participantDef.uuid, state).subscribe();
    }

    switchFocus(parti: ParticipantData, state?: boolean) {
      this.serverRest.changeStudentFocusState(this.selfUuid, parti.participantDef.uuid, state).subscribe();
    }

    getFocusedStudent(): ParticipantData[] {
      if (this.hidePartis) return [];
      const result = this.participants.find( p => p.participantDef.focused);
      if (!result) return [];
      return [result];
    }

    hasFocused(): boolean {
      return this.getFocusedStudent().length > 0;
    }

    getPartiNameWithCounter(parti: ParticipantData): string {
      return `${this.getPartiName(parti)}(${parti.participantDef.activationsNb})`;
    }

    getPartiName(parti: ParticipantData): string {
        if (!parti || !parti.participantDef || !parti.participantDef.name) {
            return '';
        }
        return parti.participantDef.name;
    }

  getTeacherAsArray() {
    if (this.hidePartis) return [];
    const result = this.participants.find(p => p && p.participantDef && p.participantDef.role === 'Teacher');
    if (!result) return [];
    return [result];
  }

  hasTeacher() {
    if (this.hidePartis) return false;
    return this.getTeacherAsArray().length > 0;
  }

  getStudents(): ParticipantData[] {
    if (this.hidePartis) return [];
    return this.participants.filter( p => p.participantDef.role === 'Student');
  }

  randomStudentByFrequency():ParticipantData {
    return this.randomStudentBySet();
  }

  lastRandomizedUuid: string = null;
  lastRandomizedActivations: number = 0;

  private randomStudentByProbability():ParticipantData {
    const participants = this.getParticipants();
    if (participants.length === 0) return null;
    const activeIndex = participants.findIndex(p => p.participantDef.active);

    const activations = participants.map(p => p.participantDef.activationsNb);
    const maxActivations = activations.reduce((prev, current) => (prev > current) ? prev : current);
    const minActivations = activations.reduce((prev, current) => (prev > current) ? current : prev);
    const activationsRange = (maxActivations - minActivations + 1);
    const probabilityRanges = activations.map(e => activationsRange - (e - minActivations));
    if (activeIndex >= 0) {
      const minProbability = probabilityRanges.reduce((prev, current) => (prev > current) ? current : prev);
      const previousActivations = activeIndex != null && participants[activeIndex].participantDef.uuid === this.lastRandomizedUuid ? this.lastRandomizedActivations : 0;
      const activeProbability = minProbability / Math.pow(2, previousActivations + 1);
      probabilityRanges[activeIndex] = activeProbability;
    }
    const probabilitySum = probabilityRanges.reduce((prev, current) => prev + current, 0);
    const randomValue = Math.random() * probabilitySum;
    let currentSum = 0;
    for (let i = 0; i < probabilityRanges.length; i++) {
      currentSum += probabilityRanges[i];
      if (randomValue < currentSum) return participants[i];
    }
    const result = participants[participants.length - 1];
    if (this.lastRandomizedUuid === result.participantDef.uuid) {
      this.lastRandomizedActivations++;
    } else {
      this.lastRandomizedUuid = result.participantDef.uuid;
      this.lastRandomizedActivations = 1;
    }
    return result;
  }

  private randomizeSet: number[] = [];

  private randomStudentBySet():ParticipantData {
    let participants = this.getParticipants();
    if (!participants) return null;
    participants = participants.filter( p => !p.participantDef.passive);
    if (participants.length < 1) return null;

    let resultParticipant :ParticipantData = null;

    while(!resultParticipant) {
      if (!this.randomizeSet || this.randomizeSet.length === 0) {
        this.randomizeSet = [...Array(participants.length).keys()];
      }

      const nextRandomizeSetIndex = Math.floor(Math.random() * this.randomizeSet.length);
      const nextStudentIndex = this.randomizeSet[nextRandomizeSetIndex];
      this.randomizeSet.splice(nextRandomizeSetIndex, 1);

      if (nextStudentIndex >= participants.length) continue;
      resultParticipant = participants[nextStudentIndex];
    }

    return resultParticipant;
  }

  isOnline(parti: ParticipantData) {
    return parti && parti.participantDef && !parti.participantDef.offline;
  }

  isTeacher(parti: ParticipantData) {
    return parti && parti.participantDef && parti.participantDef.role === 'Teacher';
  }
}
