<div class="row">
  <div class="col-12" *ngIf="progress">

    <div class="row mt-4">
      <div class="col">
        <h6>Current lesson progress:</h6>
      </div>
      <div class="col-auto text-right">
        <small class="text-muted" *ngIf="hasDirtyState()">
          <div class="spinner-grow spinner-grow-sm text-danger" role="status">
            <span class="sr-only">sending...</span>
          </div>
        </small>
        <small class="text-muted" *ngIf="!hasDirtyState()">saved</small>
      </div>
    </div>
    <app-progress-block [variant]="variant" [progress]="progress" (progressChanged)="progressChanged($event)"></app-progress-block>
  </div>
  <div class="col-12" *ngIf="progress && !isColLesson()" >
    <h6 >Attendance:</h6>
    <app-attendance-block [progress]="progress" (attendanceChanged)="attendanceChanged($event)"></app-attendance-block>
  </div>
  <div class="col-12" [hidden]="historyLength === 0">
    <div  class="mb-2">
        <h6>Previous lesson history:</h6>
        <app-progress-history-list [short]="short" (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
    </div>
  </div>
</div>

