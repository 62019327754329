import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ParticipantData} from "../../model/internal";

@Component({
  selector: 'app-bad-connection',
  templateUrl: './bad-connection.component.html',
  styleUrls: ['./bad-connection.component.css']
})
export class BadConnectionComponent implements OnInit, OnDestroy {
  private _participant: ParticipantData;

  constructor() { }
  private signalListener = (level, stats) => {
    this.showWarning = level <= 2;
  }
  showWarning = false;

  @Input()
  set participant(parti: ParticipantData) {
    this.unregisterListener();
    this._participant = parti;
    this.listenForSignal();
  }

  ngOnInit() {
  }

  getConnectionQualityErrorPageUrl() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    if (!this._participant || !this._participant.participantDef || !this._participant.participantDef.uuid) {
      return "";
    }
    const rolePart = this._participant.participantDef.role === 'Teacher' ? 'teacher-error' : 'student-error';
    return `${protocol}//${host}/participants/${this._participant.participantDef.uuid}/${rolePart}/7`;
  }

  positionWindow() {
    const w = window.innerWidth;
    const alertW = Math.min(Math.max(Math.min(600, 0.6 * w), 400), w);
    // 0.6 of with but not more than 600 but not less than 400 or just screen width
    return {
      'position': 'fixed',
      'left': `${((w - alertW)/2)}px`,
      'top': '15px',
      'width':`${alertW}px`
    };
  }

  private listenForSignal() {
    if (this._participant && this._participant.participant) {
      this._participant.participant.on("networkQualityLevelChanged", this.signalListener);
    }
  }

  ngOnDestroy(): void {
    this.unregisterListener();
  }

  private unregisterListener() {
    this.showWarning = false;
    if (this._participant && this._participant.participant) {
      this._participant.participant.removeListener("networkQualityLevelChanged", this.signalListener);
    }
  }
}

