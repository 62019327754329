import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ModalV2Component} from "../../components/modal-v2/modal-v2.component";
import {ServerRestService} from "../../service/server-rest.service";
import {finalize, map, switchMap, tap} from "rxjs/operators";
import {ParticipantDef, RoomDef} from "../../model/server";

@Component({
  selector: 'app-teacher-room-finished-page',
  templateUrl: './teacher-room-finished-page.component.html',
  styleUrls: ['./teacher-room-finished-page.component.css']
})
export class TeacherRoomFinishedPageComponent implements OnInit {
  participantUuid: string;
  @ViewChild("surveyModal", {static: true})
  surveyModal: ModalV2Component
  surveySubmitted = false;
  loaded = false;
  roomData: RoomDef;
  private notes: {[id: string]: string};

  constructor(
    activatedRoute: ActivatedRoute,
    private rest: ServerRestService
  ) {
    activatedRoute.paramMap.pipe(
      map( params => params.get("participantUuid")),
      tap<string>( uuid => this.participantUuid = uuid),
      switchMap( uuid => this.rest.findRoomByParticipant(uuid)),
      tap<RoomDef>( room => this.roomData = room),
      switchMap( _ => this.rest.getRoomNotes(this.participantUuid)),
      tap<{[id: string]:string}>( notes => this.notes = notes),
      tap( _ => this.loaded = true)
    ).
    subscribe();
  }

  ngOnInit() {
  }

  onSurveySubmit() {
    this.surveySubmitted = true;
    this.surveyModal.hide();
  }

  isOffline() {
    return this.roomData && this.roomData.hybridState === 'Offline';
  }

  getRoomName() {
    return this.roomData && this.roomData.name;
  }

  getRoomTime() {
    return this.roomData && this.roomData.plannedDate;
  }

  getTeacher() {
    return this.roomData && this.roomData.participants.find( it => it.role === 'Teacher');
  }

  getName(parti: ParticipantDef) {
    return parti && parti.name;
  }

  getNote(key: string) {
    return this.notes && this.notes[key];
  }
}
