import {EventEmitter, Injectable} from "@angular/core";
import {Course, Stage} from "../model/books";
import {BehaviorSubject, Subject} from "rxjs";
import {BooksRestService} from "./books-service";

export interface BookBreakpointComponent {
  getCurrentBreakpoint(): number;
  jumpToBreakpoint(number: number): boolean;
  setInitialBreakpointAfterPageLoad(command: number): void;
  // return true if any chart was found
  switchToNextChartOnFocusedBreakpoint(): boolean;
}

export class BookSelection {
  constructor(public course: Course,
              public stage: Stage) {
  }
}

export class BookPageSelection {
  constructor(
    public book: BookSelection,
    public page: number,
    public size: number = 1) {
  }

  equals(to: BookPageSelection): boolean {
    if (!to) return false;
    return to.size === this.size
      && to.page === this.page
      && to.book.stage.code === this.book.stage.code
      && to.book.course.code === this.book.course.code;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BookRendererControllerService {
  public currentBookPageSelection = new BehaviorSubject<BookPageSelection>(null);
  public bookPageShared = new BehaviorSubject<BookPageSelection>(null);
  public currentBook: BookSelection;
  public static JUMP_TO_THE_FIRST = -Number.MAX_SAFE_INTEGER;
  public static JUMP_TO_THE_LAST = Number.MAX_SAFE_INTEGER;

  renderer: BookBreakpointComponent;
  private _currentPage: BookPageSelection;
  private _sharedPage: BookPageSelection;

  constructor(private bookRestService: BooksRestService) {
    this.currentBookPageSelection.subscribe(currentPage => this._currentPage = currentPage);
    this.bookPageShared.subscribe( sharedPage => this._sharedPage = sharedPage);
  }

  openBookPage(bookPageSelection: BookPageSelection) {
    this.currentBook = bookPageSelection.book;
    this.currentBookPageSelection.next(bookPageSelection);
  }

  openBook(book: BookSelection) {
    this.currentBook = book;
    const startingPage = book.stage["content-items"][0]["page-from"];
    this.currentBookPageSelection.next(new BookPageSelection(book, startingPage, 1));
  }

  close() {
    this.currentBook = null;
    this.currentBookPageSelection.next(null);
  }

  jumpToPage(pageNumber: number) {
    if (this.currentBook == null) return;
    this.currentBookPageSelection.next(new BookPageSelection(this.currentBook, pageNumber,1));
  }

  moveBreakpoint(offset: number): boolean {
    return this.renderer.jumpToBreakpoint(this.renderer.getCurrentBreakpoint() + offset);
  }

  moveToFirstBreakpointAfterPageLoad() {
    this.renderer.setInitialBreakpointAfterPageLoad(BookRendererControllerService.JUMP_TO_THE_FIRST);
  }

  moveToLastBreakpointAfterPageLoad() {
    this.renderer.setInitialBreakpointAfterPageLoad(BookRendererControllerService.JUMP_TO_THE_LAST);
  }

  shareCurrentPage(participantUuid: string) {
    if (!this._currentPage || this._currentPage.equals(this._sharedPage)) {
      this.clearBookShare(participantUuid);
    } else {
      this.bookRestService.shareBookPage(participantUuid, this._currentPage).subscribe( _ =>
        this.bookPageShared.next(this._currentPage)
      )
    }
  }

  clearBookShare(participantUuid: string) {
    this.bookRestService.clearBookShare(participantUuid)
      .subscribe( _ => this.bookPageShared.next(null))
  }

  switchChartOnFocusedBreakpoint(): boolean {
    return this.renderer.switchToNextChartOnFocusedBreakpoint();
  }
}
