import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalV2Component} from "../modal-v2/modal-v2.component";

@Component({
  selector: 'app-teacher-unexpected-disconnect-modal',
  templateUrl: './teacher-unexpected-disconnect-modal.component.html',
  styleUrls: ['./teacher-unexpected-disconnect-modal.component.css']
})
export class TeacherUnexpectedDisconnectModalComponent implements OnInit {

  @ViewChild("modal", {static: true})
  modal: ModalV2Component;

  constructor() { }

  @Output()
  reconnect = new EventEmitter();
  @Output()
  closeRoom = new EventEmitter();

  ngOnInit() {
  }

  public show() {
    this.modal.show(false);
  }

  public hide() {
    this.modal.hide();
  }
}
