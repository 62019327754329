
<div class="star-input">
    <div class="star-repeater" *ngFor="let n of values" (click)="doChangeValue(n)">
        <div class="star-wrapper" *ngIf="n.active">
                <i  class="fas fa-star"></i>
                
        </div>
        <div class="star-wrapper" *ngIf="!n.active">
                <i  class="far fa-star"></i>
        </div>
        
    </div>
    
</div>