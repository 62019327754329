<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page"><a routerLink="/manager/{{schoolId}}/dashboard">Dashboard</a></li>
      <li class="breadcrumb-item" aria-current="page" ><a routerLink="/manager/{{schoolId}}/templates">Groups</a></li>
      <li class="breadcrumb-item" aria-current="page">Group <strong *ngIf="template">{{template.details.name}}</strong></li>
    </ol>
</nav>
<div  *ngIf="templateId && template">
  <div class="my-4">
    <div class="float-right">
      <div *ngIf="hasScheduleQueryArgs()" class="btn-group mr-2">
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(-1)">&lt;</button>
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleToNow()"><strong>{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</strong></button>
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(1)">&gt;</button>
      </div>
      <button class="btn btn-primary btn-sm" (click)="createNewSchedule()">Schedule lessons</button>
    </div>
    <h5 class="text-uppercase">Weekly Planned Lessons <span class="text-muted">({{schedulesCount}})</span></h5>
    <app-schedules-list (dataSet)="onSchedulesDataSetUpdate($event)" (loading)="onSchedulesLoading($event)" [showTemplateName]="false" #schedulesListComponent [dataProvider]="getSchedulesDataProvider()"  (listEvent)="onSchedulesEvent($event)"></app-schedules-list>
  </div>
</div>
<div class="card mb-2">
  <div class="card-header">
    <button class="btn btn-link mr-1 mt-n1" routerLink="/manager/{{schoolId}}/dashboard"><i class="fa fa-chevron-left"></i> </button> {{getGroupName()}}
    <div class="btn-group float-right mt-n1" *ngIf="templateId">
      <button class="btn btn-link text-danger mr-1" (click)="deleteConfirmModal.show(true)"><i class="fa fa-trash" title="Delete the group" data-toggle="tooltip"></i></button>
      <button class="btn btn-link mr-1" (click)="startConfirmModal.show(true)"><i class="fa fa-play" title="Open classroom now" data-toggle="tooltip"></i> </button>
    </div>
  </div>
    <div class="card-body">

        <app-room-template-edit [template]="template" (formEvent)="onEditEvent($event)"></app-room-template-edit>
        <div *ngIf="savedCorrectly" class="alert alert-success">
          Saved correctly
        </div>
        <div *ngIf="saveError" class="alert alert-danger">
          Saving error
        </div>

    </div>
</div>
<div  *ngIf="templateId">
  <div class="mb-2 mt-4" [hidden]="historyLength === 0">
        <h5 class="text-uppercase">Group's lessons progress history<span class="text-muted">({{historyLength}})</span>:</h5>
        <app-progress-history-list (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
    </div>
    <div class="mb-4 mt-4" [hidden]="lessonsLength === 0">
        <h5 class="text-uppercase">This group's lessons<span class="text-muted">({{lessonsLength}})</span>:</h5>
        <app-rooms-list (count)="lessonsLength = $event" [dataProvider]="roomsDataProvider"></app-rooms-list>
    </div>
</div>

<app-modal-v2 #startScheduleConfirmModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
            <button type="button" class="close" (click)="startScheduleConfirmModal.hide()"  aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body" *ngIf="scheduleToStart">
          Are you sure you want to open a classroom for the group <strong>{{scheduleToStart.template.details.name}}</strong> and send emails inviting the students to a new lesson?
        </div>

      <div class="modal-footer text-right">
            <button class="btn btn-secondary" (click)="startScheduleConfirmModal.hide()">No</button>
            <button class="btn btn-primary" (click)="onStartSchedule()">Yes</button>
        </div>
    </div>
</app-modal-v2>

<app-modal-v2 #startConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
      <button type="button" class="close" (click)="startConfirmModal.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body" *ngIf="template">
      <p>
        Are you sure you want to open a classroom for the group <strong>{{template.details.name}}</strong> and send emails inviting the students to a new lesson?
      </p>
      <p>
        <strong class="text-danger">IMPORTANT:</strong> You are opening this Classroom outside the Scheduler. This lesson will not will not appear on the Planned lessons list.
      </p>
    </div>

    <div class="modal-footer text-right">
      <button class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
      <button class="btn btn-primary" (click)="onStart()">Yes</button>
    </div>
  </div>
</app-modal-v2>


<app-modal-v2 #deleteConfirmModal>
    <div class="modal-content bg-danger text-light">
        <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Delete</h5>
            <button type="button" class="close" (click)="cancelDelete()"  aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" *ngIf="template">
            <div>
              <strong>Are you sure you want to delete this item?</strong>
            </div>
          <div class="form-check mt-4" *ngIf="scheduleToDelete">
            <input class="form-check-input" type="checkbox" [(ngModel)]="deleteFollowing" >
            <label class="form-check-label" >
              Delete all following schedules too
            </label>
          </div>
        </div>
        <div class="modal-footer text-right">
          <button class="btn btn-outline-light" (click)="onDelete()">Yes, delete</button>
          <button class="btn btn-light" (click)="cancelDelete()">No</button>
        </div>
    </div>
</app-modal-v2>

<app-create-schedule #createScheduleComponent [schoolId]="schoolId" [templateId]="templateId" (finish)="refreshSchedules()"></app-create-schedule>
