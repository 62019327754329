import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { ParticipantSurvey, Issues } from 'src/app/model/server';
import {HotkeysService} from "../../service/hotkeys.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit, OnDestroy {

  constructor(
    private serverRest: ServerRestService,
    private hotKey: HotkeysService,
    public ts: TranslateService
    ) { }

  @Input()
  role = 'Teacher';
  issues = Issues;
  overalHasChanged = false;

  @Input()
  participantUuid: string;

  survey = new ParticipantSurvey(2);

  @Output()
  surveySubmit = new EventEmitter<void>();

  ngOnInit() {
    this.hotKey.setSurveyOpened(true);
  }

  ngOnDestroy() {
    this.hotKey.setSurveyOpened(false);
  }

  isShowRating() {
    return this.role === 'Student';
  }

  isShowAudioVideoRatings() {
    return this.survey.success;
  }

  isAskForSuccess() {
    return this.role === 'Teacher';
  }

  askForIssues() {
    return this.survey.success === false;
  }

  isShowSubmit() {
    if (this.role === 'Student' && this.overalHasChanged) {
      return true;
    }
    if (this.role === 'Teacher' && this.survey.success !== undefined) {
      return true;
    }
    return false;
  }


  isShowComment() {
    if (this.role === 'Student' && this.survey.overalRating < 2) {
      return true;
    }
    if (this.role === 'Teacher' && this.survey.success !== undefined) {
      return true;
    }
    return false;
  }

  overalChanged(n: number) {
    this.overalHasChanged = true;
    this.postSurvey().subscribe(
    );
  }

  isIssueSelected(issueId: string) {
    return this.survey.issues && this.survey.issues.indexOf(issueId) >= 0;
  }

  changeIssue(issueId: string, event: any) {
    if (!this.survey.issues) {
      this.survey.issues = [];
    }
    const index = this.survey.issues.indexOf(issueId);
    if (index >= 0) {
      this.survey.issues.splice(index, 1);
    } else {
      this.survey.issues.push(issueId);
    }
  }

  submitForm() {
    this.postSurvey().subscribe(() => this.surveySubmit.emit());
  }

  postSurvey() {
    return this.serverRest.postParticipantSurvey(this.participantUuid, this.survey);
  }
}
