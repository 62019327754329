import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

class StarEntry {
  constructor(public n: number, public active: boolean) {}
}

@Component({
  selector: 'app-star-input',
  templateUrl: './star-input.component.html',
  styleUrls: ['./star-input.component.css']
})
export class StarInputComponent implements OnInit {
  values: Array<StarEntry>;

  constructor() {
    this.prepareValues();
  }

  mRange = 5;

  mValue = 2;
  @Output()
  valueChange = new EventEmitter<number>();

  get value() {
    return this.mValue;
  }

  @Input()
  set value(v: number) {
    this.mValue = v;
    this.prepareValues();
  }

  doChangeValue(n: StarEntry) {
    for (let i = 0 ; i < this.values.length ; i++ ) {
      if (i <= n.n) {
        this.values[i].active = true;
      } else {
        this.values[i].active = false;
      }
    }
    this.mValue = n.n;
    this.valueChange.emit(n.n);
  }

  @Input()
  set range(r: number) {
    this.mRange = r;
    this.prepareValues();
  }

  prepareValues() {
    this.values = [] ;
    for (let i = 0; i < this.mRange ; i ++ ) {
      this.values.push(new StarEntry(i, i <= this.mValue));
    }
  }

  ngOnInit() {
  }

}
