export class Course {
  constructor(public readonly code, public readonly name: string) {}

  public static readonly en = new Course("en", "General English [en]");
  public static readonly enRev3 = new Course("en3", "Callan English 3rd edition [en3]");
  public static readonly en_bus = new Course("en-bs", "Callan for Business [en-bs]");
  public static readonly es = new Course("es", "Callan Español [es]");
  public static readonly en_kids = new Course("en-ch", "Callan for Kids [en-kid]");

  public static readonly values = [Course.enRev3, Course.en, Course.en_bus, Course.es, Course.en_kids];

  public static byCode(code: string) {
    return this.values.find(it => it.code === code);
  }
}

export class LessonType {
  constructor(public readonly code, public readonly name: string) {
  }

  public static readonly std = new LessonType("std",   "Standard [std]"             );
  public static readonly rev = new LessonType("rev",   "Full Stage Revision [rev]"  );
  public static readonly qsr = new LessonType("qsr",   "Quick Stage Revision [qsr]" );
  public static readonly exam = new LessonType("exam",  "Exam [exam]"               );
  public static readonly prova = new LessonType("prova", "Prova [prova]"            );
  public static readonly test = new LessonType("test",  "Test [test]"               );
  public static readonly conv = new LessonType("conv",  "Conversation [conv]"      );

  public static readonly values = [LessonType.std, LessonType.rev, LessonType.qsr, LessonType.exam, LessonType.prova, LessonType.test, LessonType.conv];
}

export class Stage {
  constructor(public readonly code: string, public readonly name: string,public readonly course: Course) {}

  public static readonly enS1  = new Stage("en_1",  "Stage 1 [en.1]",  Course.en);
  public static readonly enS2  = new Stage("en_2",  "Stage 2 [en.2]",  Course.en);
  public static readonly enS3  = new Stage("en_3",  "Stage 3 [en.3]",  Course.en);
  public static readonly enS4  = new Stage("en_4",  "Stage 4 [en.4]",  Course.en);
  public static readonly enS5  = new Stage("en_5",  "Stage 5 [en.5]",  Course.en);
  public static readonly enS6  = new Stage("en_6",  "Stage 6 [en.6]",  Course.en);
  public static readonly enS7  = new Stage("en_7",  "Stage 7 [en.7]",  Course.en);
  public static readonly enS8  = new Stage("en_8",  "Stage 8 [en.8]",  Course.en);
  public static readonly enS9  = new Stage("en_9",  "Stage 9 [en.9]",  Course.en);
  public static readonly enS10 = new Stage("en_10", "Stage 10 [en.10]", Course.en);
  public static readonly enS11 = new Stage("en_11", "Stage 11 [en.11]", Course.en);
  public static readonly enS12 = new Stage("en_12", "Stage 12 [en.12]", Course.en);

  public static readonly en3S1  = new Stage("en3_1",  "Stage 1 [en3.1]",  Course.enRev3);
  public static readonly en3S2  = new Stage("en3_2",  "Stage 2 [en3.2]",  Course.enRev3);
  public static readonly en3S3  = new Stage("en3_3",  "Stage 3 [en3.3]",  Course.enRev3);
  public static readonly en3S4  = new Stage("en3_4",  "Stage 4 [en3.4]",  Course.enRev3);
  public static readonly en3S5  = new Stage("en3_5",  "Stage 5 [en3.5]",  Course.enRev3);
  public static readonly en3S6  = new Stage("en3_6",  "Stage 6 [en3.6]",  Course.enRev3);
  public static readonly en3S7  = new Stage("en3_7",  "Stage 7 [en3.7]",  Course.enRev3);
  public static readonly en3S8  = new Stage("en3_8",  "Stage 8 [en3.8]",  Course.enRev3);
  public static readonly en3S9  = new Stage("en3_9",  "Stage 9 [en3.9]",  Course.enRev3);
  public static readonly en3S10 = new Stage("en3_10", "Stage 10 [en3.10]", Course.enRev3);
  public static readonly en3S11 = new Stage("en3_11", "Stage 11 [en3.11]", Course.enRev3);
  public static readonly en3S12 = new Stage("en3_12", "Stage 12 [en3.12]", Course.enRev3);

  public static readonly enBsS1  = new Stage("en-bus_1",  "Business English [en-bs.1]", Course.en_bus);

  public static readonly esS1  = new Stage("es_1",  "Etapa 1 [es.1]", Course.es);
  public static readonly esS2  = new Stage("es_2",  "Etapa 2 [es.2]", Course.es);
  public static readonly esS3  = new Stage("es_3",  "Etapa 3 [es.3]", Course.es);
  public static readonly esS4  = new Stage("es_4",  "Etapa 4 [es.4]", Course.es);
  public static readonly esS5  = new Stage("es_5",  "Etapa 5 [es.5]", Course.es);
  public static readonly esS6  = new Stage("es_6",  "Etapa 6 [es.6]", Course.es);

  public static readonly enKidsS1  = new Stage("en_cfk_1",  "Stage 1 [en-kid.1]", Course.en_kids);
  public static readonly enKidsS2  = new Stage("en_cfk_2",  "Stage 2 [en-kid.2]", Course.en_kids);
  public static readonly enKidsS3  = new Stage("en_cfk_3",  "Stage 3 [en-kid.3]", Course.en_kids);
  public static readonly enKidsS4  = new Stage("en_cfk_4",  "Stage 4 [en-kid.4]", Course.en_kids);
  public static readonly enKidsS5  = new Stage("en_cfk_5",  "Stage 5 [en-kid.5]", Course.en_kids);
  public static readonly enKidsS6  = new Stage("en_cfk_6",  "Stage 6 [en-kid.6]", Course.en_kids);

  public static readonly values = [
    Stage.enS1,
    Stage.enS2,
    Stage.enS3,
    Stage.enS4,
    Stage.enS5,
    Stage.enS6,
    Stage.enS7,
    Stage.enS8,
    Stage.enS9,
    Stage.enS10,
    Stage.enS11,
    Stage.enS12,
    Stage.en3S1,
    Stage.en3S2,
    Stage.en3S3,
    Stage.en3S4,
    Stage.en3S5,
    Stage.en3S6,
    Stage.en3S7,
    Stage.en3S8,
    Stage.en3S9,
    Stage.en3S10,
    Stage.en3S11,
    Stage.en3S12,
    Stage.enBsS1,
    Stage.esS1,
    Stage.esS2,
    Stage.esS3,
    Stage.esS4,
    Stage.esS5,
    Stage.esS6,
    Stage.enKidsS1,
    Stage.enKidsS2,
    Stage.enKidsS3,
    Stage.enKidsS4,
    Stage.enKidsS5,
    Stage.enKidsS6
  ];

  public static byCode(code: string) {
    return this.values.find(it => it.code === code);
  }

  public static forCourse(course: Course) {
    return this.values.filter( it => it.course.code === course.code)
  }
}
