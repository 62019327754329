import {ServerRestService} from "../server-rest.service";
import {TwilioServiceService} from "../twilio-service.service";
import {ParticipantData} from "../../model/internal";
import {BrowserVersionService, SystemInfo} from "../browser-version.service";
import {Chart, ClientReport} from "../../model/server";
import {Injectable} from "@angular/core";
import {RoomContextHolder} from "./RoomContextHolder";
import {ReplaySubject} from "rxjs";
import {LoggerService} from "../logger.service";

interface RoomDataServiceI {
  readonly participantUuid: string;
  readonly selfData: ParticipantData;
  /** @deprecated - self data is the same **/
  readonly localParticipant: ParticipantData;
  init(participantUuid: string);
  amIMuted(): boolean;
  amIHidden(): boolean;
  readChart(): Chart;
  hasLogo(): boolean;
  getLogo(): string;
  reportClient(systemInfo: SystemInfo);
  disconnectRoom();
  getRoomName(): string;
  switchRoomAudioState();
  getSelfName(): string;
  amIActive(): boolean;
  amITeacher(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoomDataService implements RoomDataServiceI {
  private notes: { [key: string]: string };
  private notesLoaded: boolean;
    constructor(
        private serverRest: ServerRestService,
        private twilio: TwilioServiceService,
        private roomContext: RoomContextHolder,
        private browserVersion: BrowserVersionService,
        private l: LoggerService
        ) {
      this.initRoomContext();
    }

    private initRoomContext() {
      if (!this.roomContext) return;
      this.roomContext.selfObservable.subscribe(selfData => {
        this.setupHideBasedOnParticipantReceivedData();
        this.setupMuteBasedOnParticipantReceivedData();
        if (selfData.participantDef.role === 'Teacher') this.loadRoomNotes();
      });
    }

    public initializedObservable = new ReplaySubject<string>(1);

  /**
   * TO REMOVE!!!! just for backward compatibility for old layout TS classes
   * @param roomContext
   */
  public switchRoomContext(roomContext: RoomContextHolder) {
      this.roomContext = roomContext;
      this.initRoomContext();
    }

    private _participantUuid: string;

    private hidden = false;
    private muted = false;

    public get participantUuid() {
      return this._participantUuid;
    }

    public get selfData() {
      return this.roomContext.selfData;
    }

    public get localParticipant() {
      return this.roomContext.selfData;
    }

    public init(partiUuid: string) {
      this._participantUuid = partiUuid;
      this.reportClient(this.browserVersion.getBrowserInfo());
      this.initializedObservable.next(partiUuid);
      this.notesLoaded = false;
    }

    public amIMuted() {
        return this.muted;
    }

    public amIHidden() {
        return this.hidden;
    }

    public readChart() {
        return this.selfData.roomDef.chart;
    }

    private setupHideBasedOnParticipantReceivedData() {
        if (this.hidden !== this.selfData.participantDef.hidden) {
            this.l.i(`switching self hide state to ${this.selfData.participantDef.muted}`);
        }
        this.hidden = this.selfData.participantDef.hidden;
        this.applyHideOnTrack();
    }

    private setupMuteBasedOnParticipantReceivedData() {
        if (this.muted !== this.selfData.participantDef.muted) {
            this.l.i(`switching self mute state to ${this.selfData.participantDef.muted}`);
        }
        this.muted = this.selfData.participantDef.muted;
        this.applyMuteOnTrack();
    }

    private applyHideOnTrack() {
        if (!this.selfData || !this.selfData.participant) {
            return;
        }
        const tracks = this.selfData.participant.videoTracks;
        if (!tracks) {
            return;
        }
        if (this.amIHidden()) {
          if (this.isAnyTrackEnabled(tracks)) {
            this.l.i('disabling self video track');
            tracks.forEach((pub: any, _: string) => pub.track.disable());
          }
        } else {
          if (this.isAnyTrackDisabled(tracks)) {
            this.l.i('enabling self video track');
            tracks.forEach((pub: any, _: string) => pub.track.enable());
          }
        }
    }

    private isAnyTrackDisabled(publications: Map<string, any>) {
      return !!Array.from(publications.values()).find(publication => !publication.track.isEnabled);
    }

    private isAnyTrackEnabled(publications: Map<string, any>) {
      return !!Array.from(publications.values()).find(publication => publication.track.isEnabled);
    }

    private applyMuteOnTrack() {
        if (!this.selfData || !this.selfData.participant) {
            return;
        }
        const tracks = this.selfData.participant.audioTracks;
        if (!tracks) {
            return;
        }
        if (this.amIMuted()) {
          if (this.isAnyTrackEnabled(tracks)) {
            this.l.i('disabling self audio track');
            tracks.forEach((pub: any, id: string) => pub.track.disable());
          }
        } else {
          if (this.isAnyTrackDisabled(tracks)) {
            this.l.i('enabling self audio track');
            tracks.forEach((pub: any, id: string) => pub.track.enable());
          }
        }
    }

    hasLogo() {
        return this.selfData != null && this.selfData.roomDef != null;
    }

    getLogo() {
        if (!this.hasLogo()) {
            return null;
        } else if (!this.selfData.roomDef.logo) {
            return '/assets/callan_logo_sm.png';
        }
        return this.selfData.roomDef.logo;
    }

    disconnectRoom() {
        if (this.selfData && this.selfData.videoRoom) {
            this.twilio.disconnect(this.selfData.videoRoom);
        }
    }

    reportClient(systemInfo: SystemInfo) {
        this.serverRest.postClientReport(
            this._participantUuid,
            new ClientReport(systemInfo))
            .subscribe();
    }

    disconnect() {
        if (this.localParticipant && this.localParticipant.participant) {
            try {
                //this.localParticipant.participant.disconnect();
            } catch (e) {
            }
        }

        this.disconnectRoom();
        // this._localParticipant = null;
    }

    killRoom() {
      if (this.amITeacher()) {
        this.serverRest.killRoom(this.selfData.participantDef.uuid).subscribe();
      }
    }

    getRoomName() {
        if (!this.selfData || !this.selfData.roomDef) {
            return '';
        }
        return this.selfData.roomDef.name;
    }


    switchRoomAudioState() {
        this.serverRest.changeRoomAudioState(this.selfData.participantDef.uuid).subscribe();
    }

  getSelfName() {
    if (!this.selfData || !this.selfData.participantDef || !this.selfData.participantDef.name) return '';
    return this.selfData.participantDef.name;
  }

  amIActive() {
    if (!this.selfData || !this.selfData.participantDef) return false;
    return this.selfData.participantDef.active;
  }

  amITeacher() {
    if (!this.selfData || !this.selfData.participantDef) return false;
    return this.selfData.participantDef.role === 'Teacher';
  }

  private loadRoomNotes() {
      if (this.notesLoaded) return;
    this.serverRest.getRoomNotes(this._participantUuid).subscribe(it => {
        this.notes = it;
        this.notesLoaded = true;
      }
    );
  }

  public getNote(key: string): string {
      return this.notes && this.notes[key];
  }
}
