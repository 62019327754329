import {Pageable, RoomTemplate, Page, TemplateParticipant} from 'src/app/model/server';
import { GenericDataProvider } from 'src/app/model/internal';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { ServerRestService } from 'src/app/service/server-rest.service';
import { Observable } from 'rxjs';

export class GroupsPageable extends Pageable {
    constructor(public queryTerm: string, page: number, size: number, sort?: string) {
      super(page, size, sort);
    }
    clone() {
      return new GroupsPageable(this.queryTerm, this.page, this.size, this.sort);
    }
  }

export class GroupsDataProvider extends GenericDataProvider<RoomTemplate<TemplateParticipant>, GroupsPageable> {
    constructor(searchingContextPath: string,
                pageable: GroupsPageable,
                searchContextService: SearchingContextService,
                private rest: ServerRestService,
                private schoolId: number) {
      super(searchingContextPath, pageable, searchContextService);
    }
    isTermEmpty() {
      return !this.pageable.queryTerm;
    }
    doLoad(): Observable<Page<RoomTemplate<TemplateParticipant>>> {
     return this.rest.listTemplates(this.schoolId, this.pageable.queryTerm, this.pageable.consumeInitial());
    }
}

