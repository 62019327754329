import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ServerRestService} from "../../service/server-rest.service";
import {ParticipantDef, RoomDef} from "../../model/server";
import {PingService} from "../../service/ping.service";

@Component({
  selector: 'app-room-enter',
  templateUrl: './room-enter.component.html',
  styleUrls: ['./room-enter.component.css']
})
export class RoomEnterComponent implements OnInit, OnDestroy {
  private readonly routeTarget: string;
  private participantUuid: string;
  private room: RoomDef;
  private teacher: ParticipantDef;
  private self: ParticipantDef;
  private timer: any;
  timeIsNegative = false;
  private plannedDateInLocal: number;

  constructor(activatedRoute: ActivatedRoute,
              private router: Router,
              private rest: ServerRestService,
              private pingService: PingService) {
    this.routeTarget = activatedRoute.snapshot.data['target'];
    activatedRoute.parent.paramMap.subscribe( params => {
      this.participantUuid = params.get('participantUuid');
      pingService.startPing(this.participantUuid);
      this.loadData();
    })
  }

  ngOnInit() {

  }

  private loadData() {
    this.rest.findRoomPreviewByParticipant(this.participantUuid).subscribe(room => {
      this.storeRoom(room);
      this.initializeRedirectWatch();
    })
  }

  private storeRoom(room: RoomDef) {
    this.room = room;
    this.plannedDateInLocal = (!room.plannedDate) ? room.plannedDate : (room.plannedDate - room.serverTime) + new Date().getTime();
    this.teacher = room.participants.find( p => p.role === 'Teacher');
    this.self = room.participants.find( p => p.uuid === this.participantUuid);
  }

  private initializeRedirectWatch() {
    if (!this.plannedDateInLocal) {
      this.navigateToRoom();
    }
    const connectionTime = this.plannedDateInLocal - new Date().getTime() - (60 * 1000);
    if (connectionTime < 0 && !this.isOffline()) this.navigateToRoom();
    const currentTimeMs = new Date().getTime();
    const timeToWait = 1001 - currentTimeMs % 1000
    const nextThick = Math.min(connectionTime, timeToWait);
    this.timer = setTimeout(() => this.initializeRedirectWatch(), nextThick) as any;

  }

  gePartiName() {
    return RoomEnterComponent.getName(this.self);
  }

  static getName(parti: ParticipantDef) {
    if (!parti) return '';
    return parti.name;
  }

  getRoomName() {
    if (!this.room) return '';
    return this.room.name;
  }

  isOffline() {
    return this.room && this.room.hybridState === 'Offline';
  }

  getTeacherName() {
    return RoomEnterComponent.getName(this.teacher);
  }

  getPlannedDate() {
    if (!this.room) return null;
    return this.plannedDateInLocal;
  }

  secondMs = 1000;
  minuteMs = 60 * this.secondMs;
  hourMs = 60 * this.minuteMs;
  dayMs = 24 * this.hourMs;

  getJoinTime() {
    let timeDifference = this.plannedDateInLocal - new Date().getTime() - this.minuteMs;
    this.timeIsNegative = timeDifference < 0;

    timeDifference = Math.abs(timeDifference)
    const hours = Math.round(timeDifference / this.hourMs);
    if (hours > 24) {
      const days = Math.round(hours / 24);
      if (days === 1) return 'around one day';
      return `${days} days`;
    }
    const nbOfHours = Math.floor(timeDifference / this.hourMs);
    let timeRest = timeDifference - nbOfHours * this.hourMs;
    const nbOfMinutes = Math.floor(timeRest / this.minuteMs);
    timeRest = timeRest - nbOfMinutes * this.minuteMs;
    const nbOfSeconds = Math.floor(timeRest / this.secondMs);
    return `${this.padZeros(nbOfHours)}:${this.padZeros(nbOfMinutes)}:${this.padZeros(nbOfSeconds)}`;
  }

  padZeros(value: number) {
    const res = value != null ? value.toString() : "0";
    if (res.length < 2) return `0${res}`;
    return res;
  }

  hasData() {
    return this.room
  }

  private navigateToRoom() {
    this.router.navigate(['participants',this.participantUuid, this.routeTarget]);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.pingService.stopPing();
  }
}
