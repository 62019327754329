import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Chart, ChartEntry, ChartSet} from 'src/app/model/server';
import {ToolboxControllerService} from "../../service/toolbox-controller.service";
import {Subscription} from "rxjs";
import {ParticipantData} from "../../model/internal";
import {FlipChartDefinitionsService} from "../../service/flipchart-definitions";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-teacher-toolbox',
  templateUrl: './teacher-toolbox.component.html',
  styleUrls: ['./teacher-toolbox.component.css']
})
export class TeacherToolboxComponent implements OnInit, OnDestroy {
  private showToolbox = false;
  chartSet: string;
  chartSets: ChartSet[];
  @Input()
  chartSelected: Chart;
  @Output()
  chartSelect = new EventEmitter<Chart>();
  @Input()
  selfData: ParticipantData;
  _toolset: string;
  private toolboxStateSubscription: Subscription;
  private toolsetSubscription: Subscription;

  setToolset(name: string) {
    this.toolboxControllerService.selectedToolset.next(name);
  }

  isToolset(name: string) {
    return this._toolset === name;
  }

  public switchToolbox(toState: boolean = null) {
    if (toState != null) {
      this.toolboxControllerService.toolboxShowSubject.next(toState);
    }
    else this.toolboxControllerService.toolboxShowSubject.next(!this.showToolbox);
  }

  selectChart(entry: ChartEntry) {
    this.toolboxControllerService.toolboxShowSubject.next(false);

    let chart: Chart = null;
    if (entry) {
      chart = new Chart();
      chart.chartId = entry.imageName;
    }

    this.chartSelect.emit(chart);
  }

  moveChart(step: number) {
    if (!this.chartSelected || !this.chartSet) {
      return;
    }
    const currentChartSet = this.chartSets.find( c => c.chartSetId === this.chartSet);
    if (!currentChartSet) {
      return;
    }
    const selectedChartIndex = currentChartSet.charts.findIndex(c => c.imageName === this.chartSelected.entryAssigned.imageName);
    if (selectedChartIndex < 0) {
      return;
    }
    const newChartIndex = selectedChartIndex + step;
    if (newChartIndex < 0 || newChartIndex >= currentChartSet.charts.length) {
      return;
    }
    this.selectChart(currentChartSet.charts[newChartIndex]);
  }

  constructor(
    private toolboxControllerService: ToolboxControllerService,
    private chartsDefinitions: FlipChartDefinitionsService) {
    this.toolsetSubscription = toolboxControllerService.selectedToolset.subscribe( toolset => this._toolset = toolset);
  }

  ngOnInit() {
    this.toolboxStateSubscription = this.toolboxControllerService.toolboxShowSubject.subscribe(
      state => {
        this.showToolbox = state;
      }
    )
    this.chartsDefinitions.getChartSets().pipe(take(1)).subscribe(
      chartSets => {
        this.chartSets = chartSets;
        this.chartSet = this.chartSets[0].chartSetId
      }
    )
  }

  isShowToolbox() {
    return this.showToolbox
  }

  ngOnDestroy(): void {
    if (this.toolboxStateSubscription) {
      this.toolboxStateSubscription.unsubscribe();
    }
    if (this.toolboxStateSubscription) {
      this.toolsetSubscription.unsubscribe();
    }
  }

  getSelfUuid() {
    return this.selfData.participantDef.uuid;
  }

  selectChartSet(chartSetCandidate: string) {
    this.chartSet = chartSetCandidate;
  }

  openFirstChart() {
    if (!this.chartSet) return;
    const chartSet = this.chartSets.find( set => set.chartSetId === this.chartSet);
    if (!chartSet) return;
    this.selectChart(chartSet.charts[0]);
  }
}
