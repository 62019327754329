import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-signal-strength',
  templateUrl: './signal-strength.component.html',
  styleUrls: ['./signal-strength.component.css']
})
export class SignalStrengthComponent implements OnInit {

  @Input()
  value: number;

  getWidth() {
    if (!this.value) return {"width": "0rem"};
    return {
      "width": `${this.value * 2 / 10}rem`
    };
  }

  constructor() { }

  ngOnInit() {
  }

}
