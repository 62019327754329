<div *ngIf="!hideDeviceSelection && !mContextHolder.initialized && !error &&!connecting" class="capture-devices-page-wrapper" (contextmenu)="onContextMenu()">
  <div class="modal fade show" tabindex="-1" style="display: block;">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" translate>video-preview.title</h5>
        </div>
        <div class="modal-body">
          <app-capture-devices-new (selected)="deviceSelected($event)"></app-capture-devices-new>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="connecting" class="capture-devices-page-wrapper text-light text-center py-5" (contextmenu)="onContextMenu()">
  <h2>Connecting, please wait...</h2>
</div>
<div *ngIf="error" class="capture-devices-page-wrapper text-light text-center py-5" (contextmenu)="onContextMenu()">
  <h2>Connection Error</h2>
</div>
<div
  id="main-container"
  class="v3"
  *ngIf="mContextHolder.initialized && participantsHolder && !connecting"
  (contextmenu)="onContextMenu()"
>

  <div id="room-details" class="room-details">
    <div class="room-title-bar">
      <img *ngIf="selfHolder.hasLogo()" class="room-logo" [src]="selfHolder.getLogo()" (click)="selfHolder.switchRoomAudioState()">
      <h2 class="text-center pt-2">
        <app-teacher-room-timer #timer [participantUuid]="selfHolder.participantUuid" [selfData]="selfHolder.selfData"></app-teacher-room-timer>
      </h2>
    </div>
    <div class="room-controls text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" *ngIf="selfHolder.amIMuted()" (click)="participantsHolder.switchSelfAudio(selfHolder.amIMuted())"><i class="fa fa-microphone-slash"></i></button>
        <button class="btn btn-secondary" *ngIf="!selfHolder.amIMuted()" (click)="participantsHolder.switchSelfAudio(selfHolder.amIMuted())"><i class="fa fa-microphone"></i></button>
        <button class="btn btn-secondary" *ngIf="participantsHolder.isHidden(selfHolder.selfData)" (click)="participantsHolder.switchSelfVideo(participantsHolder.isHidden(selfHolder.selfData))"><i class="fa fa-video-slash"></i></button>
        <button class="btn btn-secondary" *ngIf="!participantsHolder.isHidden(selfHolder.selfData)" (click)="participantsHolder.switchSelfVideo(participantsHolder.isHidden(selfHolder.selfData))"><i class="fa fa-video"></i></button>
        <button class="btn btn-danger" type="button" *ngIf="hasNotes() && !openedNotes()" (click)="showNotes()">
          <i class="fa fa-fw fa-exclamation"></i>
        </button>
        <div class="btn-group" role="group">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="settings-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa fa-ellipsis-h"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="settings-dropdown">
            <div class="room-info text-muted p-2">
              <h6>{{selfHolder.getRoomName()}}</h6>
              <div class="text-center">
                <small><app-teacher-room-timer [participantUuid]="selfHolder.participantUuid" [selfData]="selfHolder.selfData"></app-teacher-room-timer></small>
              </div>

            </div>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" (click)="openCharts()"><i class="fa fa-image fa-fw"></i> Charts [C]</button>
            <button type="button" class="dropdown-item" (click)="openChat()"><i class="fa fa-comment fa-fw"></i> Text chat [T]</button>
            <button type="button" class="dropdown-item" (click)="openLessons()"><i class="fa fa-server fa-fw"></i> Lesson details [L]</button>
            <button type="button" class="dropdown-item" (click)="selfHolder.switchRoomAudioState()"><i class="fa fa-volume-off fa-fw"></i> (Un)mute all [M]</button>
            <button type="button" class="dropdown-item" (click)="shelfService.switchShelf()"><i class="fa fa-users fa-fw"></i> Show / hide students</button>
            <button type="button" class="dropdown-item" (click)="timer.changeTimerState()"><i class="fa fa-clock fa-fw"></i> Timer on / off</button>
            <button type="button" class="dropdown-item d-fs-none" (click)="openFullScreen()"><i class="fa fa-expand-arrows-alt fa-fw"></i> Full-screen</button>
            <button type="button" class="dropdown-item d-none d-fs-block" (click)="closeFullScreen()"><i class="fa fa-compress-arrows-alt fa-fw"></i> Exit full-screen</button>
            <button type="button" class="dropdown-item" *ngIf="isShowChart()" (click)="closeChart()"><i class="fa fa-eye-slash fa-fw"></i> Quit Chart [Q]</button>
            <button type="button" class="dropdown-item" (click)="showNotes()" *ngIf="hasNotes()"><i class="fa fa-info fa-fw"></i> Group notes</button>
            <button type="button" class="dropdown-item" (click)="crashReportComponent.open()"><i class="fa fa-bug fa-fw"></i> Report a problem</button>
            <button type="button" class="dropdown-item mt-4" (click)="restart()"><i class="fa fa-retweet fa-fw"></i> <span > Restart</span></button>
            <button type="button" class="dropdown-item text-danger mt-2" (click)="disconnect()"><i class="fa fa-phone-slash fa-fw"></i> End lesson</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="self-parti" class="participant self">
    <app-participant-video *ngIf="selfHolder.localParticipant" [participantData]="selfHolder.localParticipant"></app-participant-video>
    <div class="status-icons">
      <app-participant-signal-strength [participant]="selfHolder.localParticipant"></app-participant-signal-strength>
    </div>
  </div>

  <div id="primary-parti" class="participant">
    <div style="display: unset" *ngIf="participantsHolder.hasActiveParticipant()">
      <div style="display: unset" *ngFor="let parti of participantsHolder.getActiveParticipantAsArray() ; trackBy: participantsHolder.participantsTrackBy">
        <a (click)="participantsHolder.activate(parti, true)">
          <app-participant-video [participantData]="parti" [playAudio]="false"></app-participant-video>
        </a>
        <span class="participant-name">{{participantsHolder.getPartiNameWithCounter(parti)}}</span>
        <div class="status-icons">
          <app-participant-signal-strength [participant]="parti"></app-participant-signal-strength>
        </div>
      </div>
    </div>
  </div>

  <div id="book-container" class="book-container overflow-y">
    <app-teacher-book-toolbox [participantUuid]="selfHolder.participantUuid" (displayChart)="chartHostService.switchToNewChartByChartId($event)"></app-teacher-book-toolbox>
  </div>

  <div id="chart-container">
    <app-chart-host *ngIf="selfHolder.selfData" [roomUuid]="selfHolder.selfData.roomDef.uuid" [chart]="chartHostService.chartSelected"></app-chart-host>
  </div>

  <div id="participants-shelf">
    <div class="parti-list" >
      <div class="participant" *ngFor="let parti of participantsHolder.getParticipants() ; trackBy: participantsHolder.participantsTrackBy">
        <a (click)="participantsHolder.activate(parti)">
<!--          <app-participant-video *ngIf="participantsHolder.isOnline(parti)" [showVideo]="showShelf" [participantData]="parti"></app-participant-video>-->
          <app-participant-video *ngIf="participantsHolder.isOnline(parti)" [participantData]="parti"></app-participant-video>
        </a>
        <span class="participant-name">{{participantsHolder.getPartiNameWithCounter(parti)}}</span>
        <div class="parti-counter" [ngStyle]="participantsHolder.getPartiCounterColor(parti)"></div>
        <div class="status-icons">
          <app-participant-signal-strength [participant]="parti"></app-participant-signal-strength>
          <button class="btn btn-outline-danger btn-video" *ngIf="participantsHolder.isMuted(parti)" (click)="participantsHolder.switchAudio(parti, participantsHolder.isMuted(parti))"><i class="fa fa-microphone-slash fa-fw"></i></button>
          <button class="btn btn-outline-success btn-video" *ngIf="!participantsHolder.isMuted(parti)" (click)="participantsHolder.switchAudio(parti, participantsHolder.isMuted(parti))"><i class="fa fa-microphone fa-fw"></i></button>
          <button class="btn btn-outline-danger btn-video" *ngIf="participantsHolder.isHidden(parti)" (click)="participantsHolder.switchVideo(parti, participantsHolder.isHidden(parti))"><i class="fa fa-video-slash fa-fw"></i></button>
          <button class="btn btn-outline-success btn-video" *ngIf="!participantsHolder.isHidden(parti)" (click)="participantsHolder.switchVideo(parti, participantsHolder.isHidden(parti))"><i class="fa fa-video fa-fw"></i></button>
        </div>
        <div class="active-frame" *ngIf="parti.participantDef.active"></div>
      </div>
    </div>
  </div>
</div>

<app-teacher-toolbox *ngIf="selfHolder.selfData && selfHolder.selfData.participantDef"
                     #toolbox
                     [selfData]="selfHolder.selfData"
                     [chartSelected]="chartHostService.chartSelected"
                     (chartSelect)="chartHostService.switchToNewChart($event)"
></app-teacher-toolbox>

<app-jumper [participantUuid]="selfHolder.participantUuid"></app-jumper>

<div *ngIf="isShowCloseConfirmation()">
  <app-modal>
    <div style="padding:15px;">
      <h4>Are you sure ?</h4>
      <div class="text-right">
        <button class="btn btn-secondary mr-2" (click)="closeConfirmationAnswer(false)">No</button>
        <button class="btn btn-primary" (click)="closeConfirmationAnswer(true)">Yes</button>
      </div>
    </div>
  </app-modal>
</div>

<app-bad-connection
  *ngIf="mContextHolder.initialized && participantsHolder && !connecting"
  [participant]="selfHolder.localParticipant">
</app-bad-connection>

<app-crash-report #crashReportComponent ></app-crash-report>
<app-teacher-unexpected-disconnect-modal #disconnectModal (closeRoom)="disconnectModal.hide(); closeRoom();" (reconnect)="refreshPage();"></app-teacher-unexpected-disconnect-modal>

<app-modal-v2 #notesModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Group notes</h5>
      <button type="button" class="close" (click)="notesModal.hide()"  aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="getNote('group_note')"><strong>This group note:</strong> {{getNote('group_note')}}
      </p>
      <p *ngIf="getNote('schedule_note')">
        {{getNote('schedule_note')}}
      </p>
      <div class="form-group text-right">
        <button class="btn btn-primary" (click)="notesModal.hide()" type="button">Close</button>
      </div>
    </div>
  </div>
</app-modal-v2>
