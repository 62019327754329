import {Injectable} from "@angular/core";
import {FlipChartDefinitionsService} from "./flipchart-definitions";
import {RoomDataService} from "./helpers/room-data-service";
import {BookPageSelection, BookRendererControllerService} from "./book-renderer-controller.service";
import {ReplaySubject, Subject} from "rxjs";
import {RoomContextHolder} from "./helpers/RoomContextHolder";
import {Chart} from "../model/server";
import {HotkeysService} from "./hotkeys.service";
import {ServerRestService} from "./server-rest.service";
import {LoggerService} from "./logger.service";


interface ChartHostServiceI {
  /**
   * Called by chart host when receive incoming signal with chart update.
   * Updates current chart data and sends an update notification event to the rest of the app
   * @param chart - chart updated
   */
  applyIncomingChartUpdate(chart: Chart);

  /**
   * Called by chart host component when chart data is updated locally and should be
   * sent to the server to update other sites
   * @param chartData
   */
  populateChartDataUpdate(chartData: string);

  /**
   * Call to force chart switching as a Teacher
   * @param chart
   */
  switchToNewChart(chart: Chart);

  /**
   * switch to a new chart using id only
   * @param chartId
   */
  switchToNewChartByChartId(chartId: string);
}

@Injectable({
  providedIn: 'root'
})
export class ChartHostService implements ChartHostServiceI {

  public bookBasedChartSet = new Subject<string>();
  public chartSelectedObservable = new ReplaySubject<Chart>(1);
  private lastChartSetSelected: string;
  chartSelected: Chart;


  private bookToChartSetMapping: { [key: string]: string } = {
    'en': 'english',
    'es': 'spanish',
    'en-bs': 'englishBusiness',
    'en-ch': 'englishKids'
  };

  constructor(
    private chartsDefinitions: FlipChartDefinitionsService,
    private selfHolder: RoomDataService,
    bookRendererControllerService: BookRendererControllerService,
    private roomContextService: RoomContextHolder,
    private hotkeysService: HotkeysService,
    private serverRest: ServerRestService,
    private l: LoggerService
  ) {
    bookRendererControllerService.currentBookPageSelection.subscribe(bookPageSelection => {
      this.selectChartsSetAccordingToBook(bookPageSelection);
    });
    roomContextService.selfObservable.subscribe(self => {
      this.extractChart();
    });
    selfHolder.initializedObservable.subscribe( uuid => {
      this.init();
    });
  }

  private extractChart() {
    this.chartSelected = this.selfHolder.readChart();
    this.hotkeysService.setIsCharSelected(this.chartSelected != null);
    this.chartSelectedObservable.next(this.chartSelected);
  }

  applyIncomingChartUpdate(chart: Chart) {
    this.chartSelected = chart;
    this.chartSelectedObservable.next(chart);
  }

  public populateChartDataUpdate(chartData: string) {
    if (!this.chartSelected) {
      return;
    }

    if (!this.selfHolder.amITeacher()
       && (!this.selfHolder.amIActive() || this.selfHolder.selfData.participantDef.chartOff)
    ) {
      return;
    }

    const chart = JSON.parse(JSON.stringify(this.chartSelected));
    chart.chartData = chartData;

    this.serverRest.updateChart(this.selfHolder.participantUuid, chart)
      .subscribe(room => {
        this.roomContextService.setChart(room.chart);
      });
  }

  public switchToNewChart(chart: Chart) {
    this.serverRest.updateChart(this.selfHolder.participantUuid, chart)
      .subscribe(room => {
        this.roomContextService.setChart(chart);
        this.chartSelected = chart;
        this.chartSelectedObservable.next(chart);
      });
  }

  switchToNewChartByChartId(chartId: string) {
    let chart = new Chart();
    chart.chartId = chartId;
    this.switchToNewChart(chart);
  }

  private selectChartsSetAccordingToBook(bookPage: BookPageSelection) {
    if (!bookPage || !bookPage.book || !bookPage.book.course || !bookPage.book.course.code) {
      this.l.i(`no book selected, cancelling auto chart set selection.`);
      return;
    }

    if (this.lastChartSetSelected === bookPage.book.course.code) return;
    const chartSetCandidate = this.bookToChartSetMapping[bookPage.book.course.code];
    this.bookBasedChartSet.next(chartSetCandidate);
    this.lastChartSetSelected = bookPage.book.course.code;
  }

  private init(){
    this.chartsDefinitions.loadChartSets(this.selfHolder.participantUuid);
  }
}
