import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {BehaviorSubject, from, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {map, switchMap, take} from "rxjs/operators";
import {LoggerService} from "./logger.service";
import {Client, StompSubscription} from "@stomp/stompjs";

export class SocketServiceContext {
  client: Client;
  subscriptions: Array<StompSubscription> = [];
}

export class SocketServiceSubscription {

}


@Injectable({
  providedIn: 'root'
})
export class SocketServiceV2 {

  constructor(private l: LoggerService) { }
  connectionSubject: ReplaySubject<SocketServiceContext> = null;
  subscriptionsCounter = new BehaviorSubject<number>(0);

  connect(): Observable<SocketServiceContext> {

    if (this.connectionSubject) {
      return this.connectionSubject;
    }

    this.connectionSubject = new ReplaySubject<SocketServiceContext>(1);

    const context = new SocketServiceContext();
    context.client = new Client({
      brokerURL: environment.socketEndpoint,
      debug: str => this.l.d(str),
      reconnectDelay: 10000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,

    })

    context.client.onConnect = frame => {
      this.l.d("stomp connected")
      this.connectionSubject.next(context);
    }
    context.client.onDisconnect = frame => {
      this.l.d("stomp disconnected")
    }
    context.client.onStompError = frame => {
      this.l.d("stomp error", frame)
    }
    context.client.activate()

    return this.connectionSubject;
  }

  send(context: SocketServiceContext, topic: string, message: string) {
    context.client.publish({
      destination: topic,
      body: message
    })
  }

  private unsubscribe(context: SocketServiceContext, subscription: StompSubscription){
    const subscriptionIndex = context.subscriptions.findIndex(el => el.id === subscription.id)
    if (subscriptionIndex < 0) {
      return;
    }
    context.subscriptions.splice(subscriptionIndex,1);
    context.client.unsubscribe(subscription.id);
  }

  private unsubscribeAll(context: SocketServiceContext) {
    context.subscriptions.forEach( it => {
      context.client.unsubscribe(it.id)
    });
    context.subscriptions.splice(0, context.subscriptions.length);
  }

  subscriptions = 0;
  topicPlain(context: SocketServiceContext, topic: string): Observable<string> {
    return new Observable<any>(
      (observer) => {
        const subscription = context.client.subscribe( topic, message => observer.next(message.body));
        context.subscriptions.push(subscription);
        this.subscriptions++;
        this.subscriptionsCounter.next(this.subscriptions);
        return () =>
           {
             this.unsubscribe(context, subscription);
            this.subscriptions--;
            this.subscriptionsCounter.next(this.subscriptions);
          };
      }
    );
  }

  disconnect(): Observable<any> {
    if (this.connectionSubject) {
      return this.connectionSubject.pipe(
        take(1),
        switchMap<SocketServiceContext,any>(ctx =>
          new Observable(observer => {
            this.unsubscribeAll(ctx);
            ctx.client.forceDisconnect();
            this.l.d("stomp disconnected finally");
            observer.complete();
            this.connectionSubject = null;
            // ctx.client.deactivate({force: true}).finally(() => {
            //   this.l.d("stomp disconnected finally");
            //   observer.complete();
            //   this.connectionSubject = null;
            // });
          })
        )
      );
    }
    return of(null);
  }

  topic<T>(context: SocketServiceContext, topic: string): Observable<T> {
    return this.topicPlain(context, topic).pipe(
      map(txt => JSON.parse(txt) as T)
    )
  }

  handleRoomMessage(message: any) {

  }


}
