<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ol>
</nav>

<div *ngIf="getSchoolName()" class="mb-2 card">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-8 col-xl-9">
        <div class="float-left logo-container"  appFileDrop (click)="fileInput.click()" (fileDrop)="uploadFile($event)">
          <img class="school-logo" *ngIf="getLogoUrl()" src="{{getLogoUrl()}}">
          <span *ngIf="!getLogoUrl()">
                        <i  class="fa fa-question-circle logo-placeholder"></i>
                    </span>
          <div class="logo-cover"></div>
          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
          <div *ngIf="uploadProgress" class="progress" style="height: 2px;">
            <div class="progress-bar" role="progressbar" [ngStyle]="getUploadProgressStyle()" ></div>
          </div>
        </div>
        <h4 class="mt-4">{{getSchoolName()}}</h4>
        <p class="text-muted">
          To upload the logo, click on the placeholder or drop a file there.<br>
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 pt-4">
        <a href="https://s3.amazonaws.com/static.casa.callan.co.uk/documents/Online+Callan+Classroom+Guide.pdf" target="_blank" class="btn btn-block btn-primary"><i class="fa fa-question"></i> Instructions & FAQ</a>
        <a routerLink="/manager/{{schoolId}}/activity" class="btn btn-block btn-warning"><i class="fa fa-dollar-sign"></i> Usage information</a>
      </div>
    </div>
  </div>
</div>

<div class="my-4">
  <div *ngIf="hasScheduleQueryArgs()" class="btn-group float-right">
    <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(-1)">&lt;</button>
    <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleToNow()"><strong>{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</strong></button>
    <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(1)">&gt;</button>
  </div>
  <h5 class="text-uppercase">Weekly upcoming lessons <span class="text-muted">({{getSchedulesCount()}}) </span>
  </h5>
  <div class="mt-n2 text-muted" *ngIf="scheduleDataSet?.length">
    <small>Below are shown all lessons in progress and the most immediate upcoming lessons. For the full weekly schedule see the table below.</small>
  </div>
  <app-schedules-list (loading)="onScheduleLoading($event)" (dataSet)="onScheduleDataSet($event)" [showUpcomingOnly]="true" [showDelete]="false" #schedulesListComponent [dataProvider]="getSchedulesDataProvider()"
                      (listEvent)="onSchedulesEvent($event)"></app-schedules-list>
  <div *ngIf="getSchedulesCount()">
    <small class="text-muted">All weekly lessons</small>
    <app-schedules-week-summary [schoolId]="schoolId" [dataSet]="scheduleDataSet" ></app-schedules-week-summary>
  </div>

</div>

<app-modal-v2 #startConfirmModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
            <button type="button" class="close" (click)="startConfirmModal.hide()"  aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
      <div class="modal-body" *ngIf="scheduleToStart">
        Are you sure you want to open a classroom for the group <strong>{{scheduleToStart.template.details.name}}</strong> and send emails inviting the students to a new lesson?
      </div>
        <div class="modal-footer text-right">
            <button class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
            <button class="btn btn-primary" (click)="startPlannedTemplate()">Yes</button>
        </div>
    </div>
</app-modal-v2>
