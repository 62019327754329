import {Observable} from "rxjs";
import {PreflightTestReport, runPreflight} from "twilio-video";


export class TwilioPreflight {
  private static progressValues = ['mediaAcquired', 'connected', 'mediaSubscribed', 'iceConnected' , 'dtlsConnected' , 'peerConnectionConnected', 'mediaStarted'];

  private static calculateProgress(value: string): number {
    let index = this.progressValues.indexOf(value);
    if (index < 0) index = 0;
    return (index + 1) / (this.progressValues.length + 1);
  }

  public static startPreflight(token: string): Observable<[number, string, PreflightTestReport]> {
    return new Observable<[number, string, PreflightTestReport]>(observer => {
      let stopped = false;
      const preflightTest = runPreflight(token);

      preflightTest.on('progress', (progress: string) => {
        if (stopped) return;
        observer.next([this.calculateProgress(progress), progress, null]);
      });

      preflightTest.on('failed', (error: Error) => {
        if (stopped) return;
        observer.error(error);
      });

      preflightTest.on('completed', (report: PreflightTestReport) => {
        if (stopped) return;
        observer.next([1, 'complete', report]);
        observer.complete();
      });

      return () => {
        stopped = true;
        preflightTest.stop();
      }
    });
  }
}
