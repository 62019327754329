import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {ScheduleRestService} from "../../service/schedule-rest.service";
import {ScheduleDefinitionDetails} from "../../model/schedule";
import {DateUtils} from "../../service/helpers/date-utils";
import {ModalV2Component} from "../modal-v2/modal-v2.component";


class CreateScheduleForm {
  dateFrom: string;
  dateTo: string;
  durationMin: number;
  recurring: {[recurringType: string]: boolean} = {};
}

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.css']
})
export class CreateScheduleComponent implements OnInit {
  @ViewChild('dialog', {static: true})
  dialog: ModalV2Component;
  @Input()
  schoolId: number;
  @Input()
  templateId: number;

  @Output()
  finish = new EventEmitter<any>()

  formInput = new CreateScheduleForm();
  serverReportedErrors: any = null;

  constructor(
    private scheduleRest: ScheduleRestService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
  }

  show() {
    this.dialog.show(true)
  }

  onSubmit() {
    const def = new ScheduleDefinitionDetails();
    def.startDate = Date.parse(this.formInput.dateFrom);
    def.cycleFinish = this.formInput.dateTo != null ? DateUtils.getDayEndDate(new Date(Date.parse(this.formInput.dateTo))).getTime() : null;
    def.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    def.durationMin = this.formInput.durationMin;
    const resultRecurrings: string[] = [];
    const recurrings = this.formInput.recurring;
    for (const recType in recurrings) {
      if (recurrings[recType]) {
        resultRecurrings.push(recType);
      }
    }
    def.scheduleCycle = resultRecurrings;

    this.serverReportedErrors = null;
    this.scheduleRest.populateSchedule(this.schoolId, this.templateId, def).subscribe( _ => {
      this.formInput = new CreateScheduleForm();
      this.dialog.hide();
      this.finish.emit();
    },error => {
      if (!error || !error.error) {
        this.serverReportedErrors = "missing error message";
        return;
      }
      this.serverReportedErrors = error.error['developerMessage'] ?  error.error['developerMessage'] : error
    });
  }

  hasAnyRecurring() {
    const recurring = this.formInput.recurring;
    for (const recType in recurring) {
      if (recurring[recType] === true) return true;
    }
    return false;
  }
}
