import {GenericSimpleDataProvider} from "../../model/internal";
import { ScheduleRowSimplified} from "../../model/schedule";
import {Observable} from "rxjs";
import {SearchingContextService} from "../../service/searching-context.service";
import {ScheduleRestService} from "../../service/schedule-rest.service";
import {map} from "rxjs/operators";
import {DateUtils} from "../../service/helpers/date-utils";

export class ScheduleListQuery {
  constructor(public weekOffset:number, public templateId: number) {
  }
  public filterCurrentWeek = true;
}

export class SchedulesListProvider implements GenericSimpleDataProvider<ScheduleRowSimplified[]> {
  constructor(
    private searchingContextPath: string,
    private searchingContextService: SearchingContextService,
    private rest: ScheduleRestService,
    public schoolId: number,
    public queryArgs: ScheduleListQuery
  ) {}

  doLoad(): Observable<ScheduleRowSimplified[]> {
    const queryDateMs = DateUtils.queryDate(this.queryArgs.weekOffset);
    const weekStart = DateUtils.weekStart(queryDateMs);
    const weekEnd = DateUtils.weekEnd(queryDateMs);

    this.searchingContextService.updateContext(this.searchingContextPath, this.queryArgs);
      return this.rest.listSchedulesOptimized(this.schoolId, queryDateMs, this.queryArgs.templateId)/*.subscribe(
        data => console.log(data)
      );*/
      /*return this.rest.listSchedules(this.schoolId, queryDateMs, this.queryArgs.templateId)*/.pipe(
        map(rows => {
          return rows.filter(row => {
            if (this.queryArgs.filterCurrentWeek) {
              if (row.schedule.details.startDate < weekStart) return false;
              if (row.schedule.details.startDate > weekEnd) return false;
            }
            return true;
          });
        })
      )
  }
}
