<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page"><a routerLink="/manager/{{schoolId}}/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item" aria-current="page">Reports</li>
    <li class="breadcrumb-item active" aria-current="page">Teacher times</li>
  </ol>
</nav>

<div class="card">
  <div class="card-body">
    Select dates range:
    <form class="mb-2">
      <div class="form-row">
        <div class="col-12 col-sm mb-2">
          <select name="report-month" (change)="onTimeChange()" [(ngModel)]="query.month" class="form-control">
            <option value="0">This month</option>
            <option value="-1">From last month</option>
            <option value="-2">2 months ago</option>
            <option value="-3">3 months ago</option>
            <option value="-6">6 months ago</option>
            <option value="-12">12 months ago</option>
          </select>
        </div>
        <div class="col mb-2">
          <select name="report-duration" (change)="onTimeChange()" [(ngModel)]="query.duration" class="form-control">
            <option value="1">for 1 month</option>
            <option value="2">for 2 months</option>
            <option value="3">for 3 months</option>
            <option value="6">for 6 months</option>
            <option value="12">for 12 months</option>
          </select>
        </div>
      </div>
    </form>

    <app-teacher-attendance-report [data]="reportData"></app-teacher-attendance-report>
  </div>
</div>
<div class="mb-4 mt-4">
  <div class="row">
    <div class="col-12 col-sm pt-2 mb-2"><h5 class="text-uppercase">Teachers' lessons<span class="text-muted">({{totalLessonsNumber}})</span>:</h5></div>
    <div class="col text-right mb-2">
      <select class="form-control" (change)="onTeacherChange()" name="group-id" [(ngModel)]="roomsQuery.teacherMail">
        <option [ngValue]="null" >All Teachers</option>
        <option *ngFor="let teacher of teachers" [value]="teacher">
          {{teacher}}
        </option>
      </select>
    </div>
  </div>
  <app-rooms-list [dataProvider]="roomsDataProvider" (count)="totalLessonsNumber = $event"></app-rooms-list>
</div>
