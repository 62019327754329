import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewChecked } from '@angular/core';
import { NotificationsService, Notification } from 'src/app/service/notifications.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  animations: [
    trigger('elAppear', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.3s', style({opacity: 1}))
      ]),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ]),
    trigger('elCome', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('0.3s', style({transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        animate('0.3s', style({ transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class NotificationsComponent implements OnInit, AfterViewChecked {


  @ViewChild('stripeDynamic', { read: ViewContainerRef, static: false }) stripeDynamic: ViewContainerRef;
  @ViewChild('boxDynamic', { read: ViewContainerRef, static: false }) boxDynamic: ViewContainerRef;

  boxNotifications: Array<Notification> = [];
  stripeNotifications: Array<Notification> = [];

  boxNotification: Notification = null;
  stripeNotification: Notification = null;

  hasBoxNotificaitons(): boolean {
    if (!this.boxNotifications) {
      return false;
    }
    return this.boxNotifications.length > 0;
  }

  hasBoxNotification(): boolean {
    return (this.boxNotification) ? true : false;
  }

  popNextBoxNotification() {
    if (!this.hasBoxNotificaitons()) {
      return;
    }
    if (!this.boxNotification) {
      this.boxNotification = this.boxNotifications[0];
      if (this.boxNotification.timeout) {
        setTimeout( () => this.discardBoxNotification(), this.boxNotification.timeout);
      }
    }
  }

  discardBoxNotification() {
    this.boxNotification = null;
    this.boxNotifications.splice(0, 1);
    if (this.boxDynamic) {
      this.boxDynamic.clear();
    }
    setTimeout(() => this.popNextBoxNotification(), 500);
  }

  discardStripeNotification() {
    this.stripeNotification = null;
    this.stripeNotifications.splice(0, 1);
    if (this.stripeDynamic) {
      this.stripeDynamic.clear();
    }
    setTimeout(() => this.popNextStripeNotification(), 500);
  }

  hasStripeNotificaitons(): boolean {
    return this.stripeNotifications.length > 0;
  }

  hasStripeNotification(): boolean {
    return (this.stripeNotification) ? true : false;
  }

  popNextStripeNotification() {
    if (!this.hasStripeNotificaitons()) {
      return;
    }
    if (!this.stripeNotification) {
      this.stripeNotification = this.stripeNotifications[0];

      if (this.stripeNotification.timeout) {
        setTimeout( () => this.discardStripeNotification(), this.stripeNotification.timeout);
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.stripeDynamic && this.stripeNotification) {
      if (this.stripeDynamic.length === 0 && this.stripeNotification.componentFactory) {
        const component = this.stripeNotification.componentFactory.create(this.stripeDynamic.parentInjector);
        this.stripeDynamic.insert(component.hostView);
      }
    }
    if (this.boxDynamic && this.boxNotification) {
      if (this.boxDynamic.length === 0 && this.boxNotification.componentFactory) {
        const component = this.boxNotification.componentFactory.create(this.boxDynamic.parentInjector);
        this.boxDynamic.insert(component.hostView);
      }
    }
  }

  constructor(notificaitonService: NotificationsService) {
    notificaitonService.boxNotifications.subscribe( notification => {
      this.boxNotifications.push(notification);
      this.popNextBoxNotification();
    });
    notificaitonService.stripeNotifications.subscribe( notification => {
      this.stripeNotifications.push(notification);
      this.popNextStripeNotification();
    });
  }

  ngOnInit() {
  }



}
