import {
  Component,
  HostListener,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';
import {TeacherToolboxComponent} from "../../components/teacher-toolbox/teacher-toolbox.component";
import {RoomDataService} from "../../service/helpers/room-data-service";
import {RoomContextHolder, UserActivationEvent} from "../../service/helpers/RoomContextHolder";
import {RoomParticipantsDataService} from "../../service/helpers/room-participants-data-service";
import {Observable, Subject} from "rxjs";
import {ServerRestService} from "../../service/server-rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Notification, NotificationsService} from "../../service/notifications.service";
import {BooksRestService} from "../../service/books-service";
import {HotkeysService} from "../../service/hotkeys.service";
import {finalize, take, throttleTime} from "rxjs/operators";
import {DevicesSelection} from "../../components/capture-devices/capture-devices.component";
import {ParticipantData} from "../../model/internal";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {environment} from "../../../environments/environment";
import {VideoConnectionBandwidthManager} from "../../service/helpers/VideoConnectionBandwidthManager";
import {ChartHostService} from "../../service/chart-host-service";
import {ShelfService} from "../../service/shelf.service";
import {StudentLayoutService} from "./student-layout.service";
import {SentryService} from "../../service/sentry.service";
import {ErrorMessage, ErrorResolver} from "../error/error.component";
import {ModalV2Component} from "../../components/modal-v2/modal-v2.component";
import {BadConnectionComponent} from "../../components/bad-connection/bad-connection.component";

@Component({
  selector: 'app-student-room-page-v3',
  templateUrl: './student-room-page-v3.component.html',
  styleUrls: ['./student-room-page-v3.component.css'],
  animations: [
    trigger('active-student', [
      state('default', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),

      transition('default <=> active', [
        animate('0.2s')
      ])
    ]),
    trigger('active-student-warn',[
      state('default', style({
        opacity: 0
      })),
      state('warn', style({
        opacity: 1
      })),
      transition('default <=> warn', [
        animate('0.2s'
        )
      ])
    ])
  ]
})
export class StudentRoomPageV3Component implements OnInit, OnDestroy {

  @ViewChild('toolbox', {static: false})
  toolbox: TeacherToolboxComponent;
  @ViewChild('unexpectedDisconnect', {static: true})
  disconnectModal: ModalV2Component;
  connecting = false;
  error = false;

  private mCloseConfirmationObservable: Subject<boolean> = null;
  private surveySubmitted = false;
  private mShowSurvey = false;
  private blink: boolean = false;
  private showExclamation = false;
  private exclamationRequests = new Subject<any>();
  hideDeviceSelection = false;
  // audio device select last time
  private lastSelectedAudio: MediaDeviceInfo;
  // video device selected last time
  private lastSelectedVideo: MediaDeviceInfo;

  constructor(
    protected serverRest: ServerRestService,
    activatedRoute: ActivatedRoute,
    protected notificationService: NotificationsService,
    protected router: Router,
    private bookService: BooksRestService,
    private hotkeysService: HotkeysService,
    public mContextHolder: RoomContextHolder,
    public selfHolder: RoomDataService,
    public participantsHolder: RoomParticipantsDataService,
    private bandwidthManager: VideoConnectionBandwidthManager,
    public chartHostService: ChartHostService,
    public shelfService: ShelfService,
    private studentLayoutService: StudentLayoutService,
    private sentryService: SentryService
  ) {
    this.collectInitialParameters(activatedRoute);
    window.addEventListener("pagehide", () => this.resetRoomContextHolder());
    window.addEventListener("beforeunload", () => this.resetRoomContextHolder());
  }

  restart() {
    this.hideDeviceSelection = false;
    this.resetRoomContextHolder();
  }



  private resetRoomContextHolder() {
    this.mContextHolder.clear();
    this.studentLayoutService.listenForContextRelayoutEvents();
  }

  ngOnInit() {
    this.registerExclamationRequestHandler();
    this.studentLayoutService.listenForResize();
  }

  private collectInitialParameters(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.paramMap.subscribe(
      params => {
        this.selfHolder.init(params.get('participantUuid'));
        // this.pingService.startPing(this.selfHolder.participantUuid);
      }
    )
  }

  restartPartis() {
    this.participantsHolder.hidePartis = true;
    setTimeout(() => this.participantsHolder.hidePartis = false, 1000);
  }

  init(audio: MediaDeviceInfo, video: MediaDeviceInfo) {
    if (this.mContextHolder.initialized) return;
    this.hideDeviceSelection = true;
    this.connecting = true;
    this.lastSelectedAudio = audio;
    this.lastSelectedVideo = video;
    this.mContextHolder.init(this.selfHolder.participantUuid, audio, video).pipe(
      finalize( () => this.connecting = false)
    ).subscribe(
      () => {
        this.subscribeToContextHolderAfterInit();
        this.bookService.subscribeForBookShares();
      }, err => {
        this.mContextHolder.unpublishLocals();
        const errorResolved = ErrorResolver.match(err);
        if ( errorResolved ) {
          this.showErrorPage(errorResolved);
          return;
        }
        this.error = true;
        this.sentryService.submitDialogCaughtError(err);
        this.showError(err);
      }
    );
  }

  subscribeToContextHolderAfterInit() {
    this.mContextHolder.selfObservable.pipe(take(1)).subscribe(_ => {
      this.listenForDisconnect();
      this.setupRoomLayout();
    });
    this.mContextHolder.participantConnectedEvent.subscribe( p => this.welcomeParticipant(p));
    this.mContextHolder.userActivationEvent.subscribe( activation => {
      if (activation.participantId === this.selfHolder.participantUuid) {
        this.startActivationAnimation(activation);
      }
      if (
        activation.participantId === this.selfHolder.participantUuid
        && this.selfHolder.amIActive()
        && activation.resultState === true
      ) {
        this.blink = true;
      }
    });
  }

  private registerExclamationRequestHandler() {
    this.exclamationRequests.pipe(
      throttleTime(2000)
    ).subscribe(
      _ => {
        this.showExclamation = true;
        setTimeout(_ => this.showExclamation = false, 1900)
      }
    );
  }

  private startActivationAnimation(activation: UserActivationEvent) {
    if (this.selfHolder.amIActive() && activation.resultState === true) {
      this.blink = true;
    }
    if (activation.resultState === true) {
      // blink the exclamation if it's activation
      this.exclamationRequests.next(null);
    }
  }

  showError(error: Error) {
    this.notificationService.publishBoxNotification(new Notification('There is an error!',
      `<p>${error.message}</p>
      <p class="text-small text-muted">
      Remember, we still are working on improving this service.<br>
      <storng>It works best on Google Chrome on desktop devices.</strong>
      </p>`));
  }

  listenForDisconnect() {
    this.mContextHolder.roomDisconnectEvent
      .subscribe(e => {
        if (e) {
          this.sentryService.submitError(e, "student-unexpected-disconnect");
          this.showRestartWindow();
          this.disconnectRoomParticipants();
        } else {
          this.closeClassroom();
        }
      });
  }

  deviceSelected(selection: DevicesSelection) {
    if (selection.error) {
      this.showError(selection.error);
    } else {
      this.init(selection.audioInput, selection.videoInput);
    }
  }

  ngOnDestroy() {
    this.selfHolder.disconnectRoom();
    // this.pingService.stopPing();
  }

  amIHidden(){
    if (!this.selfHolder
      || !this.selfHolder.selfData
      || !this.selfHolder.selfData.participantDef
    ) return false;
    return this.selfHolder.selfData.participantDef.hidden;
  }

  @HostListener('window:beforeunload', ['$event'])
  handleSurveyShow($event) {
    if (!$event) {
      return;
    }
    if (this.isShowSurvey()) {
      $event.returnValue = true;
      event.preventDefault();
    }
  }

  showCloseConfirmation(): Observable<boolean> {
    this.mCloseConfirmationObservable = new Subject<boolean>();
    this.mCloseConfirmationObservable.subscribe( resp => {
      this.mCloseConfirmationObservable = null;
    });
    return this.mCloseConfirmationObservable;
  }

  isShowCloseConfirmation(): boolean {
    return this.mCloseConfirmationObservable !== null;
  }

  closeConfirmationAnswer(answer: boolean) {
    const sub = this.mCloseConfirmationObservable;
    sub.next(answer);
    sub.complete();
  }

  isShowSurvey() {
    return !this.surveySubmitted && this.mShowSurvey;
  }

  onSurveySubmit() {
    this.surveySubmitted = true;
    this.mShowSurvey = false;
    this.routeToRoomFinished();
  }

  routeToRoomFinished() {
    this.router.navigate([ 'participants', this.selfHolder.participantUuid, 'room-closed']);
  }

  disconnect() {
    this.showCloseConfirmation().subscribe(
      a => {
        if (a) {
          //this.closeFullScreen();
          this.closeClassroom();
        }
      }
    );
  }

  closeClassroom() {
    this.disconnectRoomParticipants();
    this.showSurvey();
  }

  disconnectRoomParticipants() {
    this.participantsHolder.disconnectAll();
    this.selfHolder.disconnect();
  }

  private showSurvey() {
    if (!this.surveySubmitted) {
      this.mShowSurvey = true;
    }
  }

  public isShowWorkArea(): boolean {
    return this.chartHostService.chartSelected != null || this.bookService.bookContent != null || this.participantsHolder.hasFocused();
  }

  welcomeParticipant(participant: ParticipantData) {
    if (participant.participantDef.role !== 'Teacher') {
      return;
    }
    this.notificationService.publishBoxNotification(
      new Notification('The teacher has entered', `<strong>${participant.participantDef.name}</strong>`, null, 3000));
    }

  getActiveStudentAnimationState() {
    if (this.blink) {
      return 'default';
    }
    return this.selfHolder.amIActive() ? 'active' : 'default'
  }

  activeStudentAnimationEnd() {
    this.blink = false;
  }

  isShowBook() {
    return this.bookService.bookContent && !this.chartHostService.chartSelected;
  }

  getBookContent() {
    return this.bookService.bookContent;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.hotkeysService.reportKeyDown(event, true);
  }

  onContextMenu() {
    return !environment.production;
  }

  getActiveStudentWarnAnimationState() {
    return this.showExclamation ? 'warn' : 'default';
  }

  isShowChart() {
    return !!this.chartHostService.chartSelected;
  }

  hasActiveStudent() {
    return this.participantsHolder.hasActiveParticipant();
  }

  isShowActive() {
    return this.hasActiveStudent() && !this.isShowChart() && !this.isShowBook();
  }

  openFullScreen() {
    try {
      document.getElementsByTagName("body")[0].requestFullscreen();
    } catch (ingore) {
      // full screen not supported
    }
  }

  closeFullScreen() {
    try {
      document.exitFullscreen();
    } catch (ignore) {
      // full screen not supported
    }
  }

  roomLayout = "";

  private setupRoomLayout() {
    this.serverRest.getProgressDetails(this.selfHolder.participantUuid).subscribe(
      roomProgress => {
        if (roomProgress && roomProgress.course && roomProgress.course.endsWith("[en-kid]")) {
          this.roomLayout = "kids";
        }
      }
    )
  }

  private showErrorPage(error: ErrorMessage) {
    this.router.navigate(['participants', this.selfHolder.participantUuid, 'student-error' , error])
  }

  private showRestartWindow() {

    this.disconnectModal.show(false);
  }

  reconnect() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    window.location.href = `${protocol}//${host}/participants/${this.selfHolder.participantUuid}/student-room`;
  }

  getConnectionQualityErrorPageUrl() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    return `${protocol}//${host}/participants/${this.selfHolder.participantUuid}/student-error/7`;
  }

  private rescueReconnectsNumber = 0;

  rescueReconnect() {
    if (this.rescueReconnectsNumber >= 3) return;
    this.rescueReconnectsNumber++;
    this.resetRoomContextHolder();
    this.init(this.lastSelectedAudio, this.lastSelectedVideo);
  }

  private teacherConnectionIsWorking = false;

  trackTeacherConnectionIsWorking(isWorking: boolean) {
    if (this.teacherConnectionIsWorking) return;
    this.teacherConnectionIsWorking = isWorking;
    console.log(`teacher connection status: ${isWorking}`);
    if (this.teacherConnectionIsWorking) return;
    this.rescueReconnect();
  }
}
