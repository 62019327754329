import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { CreateRoomPageComponent } from './pages/create-room-page/create-room-page.component';
import { CreateRoomComponent } from './components/create-room/create-room.component';
import { RoomDetailsComponent } from './components/room-details/room-details.component';
import { RoomDetailsPageComponent } from './pages/room-details-page/room-details-page.component';
import { ParticipantVideoComponent } from './components/participant-video/participant-video.component';
import { FlipchartListComponent } from './components/flipchart-list/flipchart-list.component';
import { ChartHostComponent } from './components/chart-host/chart-host.component';
import { ChartImageComponent } from './components/chart-image/chart-image.component';
import { ChatComponent } from './components/chat/chat.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserStripeComponent } from './components/browser-stripe/browser-stripe.component';
import { CaptureDevicesComponent } from './components/capture-devices/capture-devices.component';
import { StarInputComponent } from './components/star-input/star-input.component';
import { SurveyComponent } from './components/survey/survey.component';
import { ModalComponent } from './components/modal/modal.component';
import { JwtInterceptorService } from './service/jwt-interceptor.service';
import { OauthComponent } from './pages/oauth/oauth.component';
import { ManagerMenuLayoutComponent } from './layout/manager-menu-layout/manager-menu-layout.component';
import { ManagerDashboardPageComponent } from './pages/manager-dashboard-page/manager-dashboard-page.component';
import { MangerProfilesPageComponent } from './pages/manger-profiles-page/manger-profiles-page.component';
import { RoomTemplatesListComponent } from './components/room-templates-list/room-templates-list.component';
import { RoomTemplateEditComponent } from './components/room-template-edit/room-template-edit.component';
import { RoomTemplateDetailsPageComponent } from './pages/room-template-details-page/room-template-details-page.component';
import { RoomsListComponent } from './components/rooms-list/rooms-list.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { TeacherToolboxComponent } from './components/teacher-toolbox/teacher-toolbox.component';
import { TeacherLessonProgressComponent } from './components/teacher-lesson-progress/teacher-lesson-progress.component';
import { ProgressHistoryListComponent } from './components/progress-history-list/progress-history-list.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { SchoolActivitiesPageComponent } from './pages/school-activities-page/school-activities-page.component';
import { ActivityReportComponent } from './components/activity-report/activity-report.component';
import { SchoolLayoutComponent } from './layout/school-layout/school-layout.component';
import { RoomFinishedPageComponent } from './pages/room-finished-page/room-finished-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { TeacherBookToolboxComponent } from './components/teacher-book-toolbox/teacher-book-toolbox.component';
import { BookListComponent } from './components/book-list/book-list.component';
import { BookControllerComponent } from './components/book-controller/book-controller.component';
import { BookRendererComponent } from './components/book-renderer/book-renderer.component';
import { NewTeacherRoomPageComponent } from './pages/new-teacher-room-page/new-teacher-room-page.component';
import { TeacherRoomTimerComponent } from './components/teacher/teacher-room-timer/teacher-room-timer.component';
import { NewStudentRoomPageComponent } from './pages/new-student-room-page/new-student-room-page.component';
import { StudentToolboxComponent } from './components/student-toolbox/student-toolbox.component';
import { StudentBookRendererComponent } from './components/student-book-renderer/student-book-renderer.component';
import { SchedulesListComponent } from './components/schedules-list/schedules-list.component';
import { CreateScheduleComponent } from './components/create-schedule/create-schedule.component';
import { ScheduleDetailsPageComponent } from './pages/schedule-details-page/schedule-details-page.component';
import { RoomEnterComponent } from './pages/room-enter/room-enter.component';
import { SchedulesWeekSummaryComponent } from './components/schedules-week-summary/schedules-week-summary.component';
import { ModalV2Component } from './components/modal-v2/modal-v2.component';
import { ToastNotificationsComponent } from './components/toast-notifications/toast-notifications.component';
import {HttpErrorsInterceptorService} from "./service/http-errors-interceptor.service";
import { SchoolGroupsPageComponent } from './pages/school-groups-page/school-groups-page.component';
import { TeacherAttendanceReportPageComponent } from './pages/teacher-attendance-report-page/teacher-attendance-report-page.component';
import { TeacherAttendanceReportComponent } from './components/teacher-attendance-report/teacher-attendance-report.component';
import { SignalStrengthComponent } from './components/signal-strength/signal-strength.component';
import { ParticipantSignalStrengthComponent } from './components/participant-signal-strength/participant-signal-strength.component';
import { JumperComponent } from './components/jumper/jumper.component';
import { TeacherRoomFinishedPageComponent } from './pages/teacher-room-finished-page/teacher-room-finished-page.component';
import { TeacherRoomEnterPageComponent } from './pages/teacher-room-enter-page/teacher-room-enter-page.component';
import { ProgressBlockComponent } from './components/teacher-lesson-progress/progress-block/progress-block.component';
import { AttendanceBlockComponent } from './components/teacher-lesson-progress/attendance-block/attendance-block.component';
import { StudentRoomPageV3Component } from './pages/student-room-page-v3/student-room-page-v3.component';
import { TeacherRoomPageV3Component } from './pages/teacher-room-page-v3/teacher-room-page-v3.component';
import {AutocompleteComponent} from "./components/autocomplete/autocomplete.component";
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { OpenPlannedBookComponent } from './components/open-planned-book/open-planned-book.component';
import { CrashReportComponent } from './components/crash-report/crash-report.component';
import { TeacherUnexpectedDisconnectModalComponent } from './components/teacher-unexpected-disconnect-modal/teacher-unexpected-disconnect-modal.component';
import { ErrorComponent } from './pages/error/error.component';
import { BadConnectionComponent } from './components/bad-connection/bad-connection.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { CaptureDevicesNewComponent } from './components/capture-devices-new/capture-devices-new.component';
import { DeprecatedPageComponent } from './pages/deprecated-page/deprecated-page.component';


Sentry.init({
  dsn: 'https://b3ea2933b7bd498b90f96462b814b04c@sentry.io/5175729',
  environment: environment.envName,
  beforeSend(event, hint) {
    return event;
}
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const e  = error as Error;
    if (e.message && e.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }
    const eventId = Sentry.captureException(error.originalError || error);
    console.log(error);
    // Sentry.showReportDialog({ eventId });
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CreateRoomPageComponent,
    CreateRoomComponent,
    RoomDetailsComponent,
    RoomDetailsPageComponent,
    ParticipantVideoComponent,
    FlipchartListComponent,
    ChartHostComponent,
    ChartImageComponent,
    ChatComponent,
    NotificationsComponent,
    BrowserStripeComponent,
    CaptureDevicesComponent,
    StarInputComponent,
    SurveyComponent,
    ModalComponent,
    OauthComponent,
    ManagerMenuLayoutComponent,
    ManagerDashboardPageComponent,
    MangerProfilesPageComponent,
    RoomTemplatesListComponent,
    RoomTemplateEditComponent,
    RoomTemplateDetailsPageComponent,
    RoomsListComponent,
    MainPageComponent,
    ForbiddenPageComponent,
    TeacherToolboxComponent,
    TeacherLessonProgressComponent,
    ProgressHistoryListComponent,
    FileDropDirective,
    SchoolActivitiesPageComponent,
    ActivityReportComponent,
    SchoolLayoutComponent,
    RoomFinishedPageComponent,
    NotFoundPageComponent,
    TeacherBookToolboxComponent,
    BookListComponent,
    BookControllerComponent,
    BookRendererComponent,
    NewTeacherRoomPageComponent,
    TeacherRoomTimerComponent,
    NewStudentRoomPageComponent,
    StudentToolboxComponent,
    StudentBookRendererComponent,
    SchedulesListComponent,
    CreateScheduleComponent,
    ScheduleDetailsPageComponent,
    RoomEnterComponent,
    SchedulesWeekSummaryComponent,
    ModalV2Component,
    ToastNotificationsComponent,
    SchoolGroupsPageComponent,
    TeacherAttendanceReportPageComponent,
    TeacherAttendanceReportComponent,
    SignalStrengthComponent,
    ParticipantSignalStrengthComponent,
    JumperComponent,
    TeacherRoomFinishedPageComponent,
    TeacherRoomEnterPageComponent,
    ProgressBlockComponent,
    AttendanceBlockComponent,
    StudentRoomPageV3Component,
    TeacherRoomPageV3Component,
    AutocompleteComponent,
    AutocompleteInputComponent,
    OpenPlannedBookComponent,
    CrashReportComponent,
    TeacherUnexpectedDisconnectModalComponent,
    ErrorComponent,
    BadConnectionComponent,
    CaptureDevicesNewComponent,
    DeprecatedPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }

    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptorService, multi: true},
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent],
  entryComponents: [BrowserStripeComponent]
})
export class AppModule { }
