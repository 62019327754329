import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BooksRestService} from "../../service/books-service";
import {take, tap} from "rxjs/operators";
import {BookPageSelection, BookRendererControllerService,} from "../../service/book-renderer-controller.service";
import {ModalV2Component} from "../modal-v2/modal-v2.component";
import {Bookmark, Dict, EscKey, HotkeyEvent, HotkeysService, JumpTo, Reading} from "../../service/hotkeys.service";
import {BookmarksService, JumpKind} from "../../service/bookmarks.service";
import {LessonProgressService} from "../../service/lesson-progress.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-jumper',
  templateUrl: './jumper.component.html',
  styleUrls: ['./jumper.component.css']
})
export class JumperComponent implements OnInit, OnDestroy {
  private currentSelection: BookPageSelection;
  jumpKinds = JumpKind.vals;

  @ViewChild("modal", {static: true})
  modal: ModalV2Component
  @ViewChild("numberInput", {static: true})
  numberInput: ElementRef
  private modalSubscription: Subscription;

  constructor(
    private rest: BooksRestService,
    private bookRendererController: BookRendererControllerService,
    private hotkeys: HotkeysService,
    private bookmarks: BookmarksService,
    private progress: LessonProgressService
  ) {
    bookRendererController.currentBookPageSelection.pipe(
      tap( selection => this.currentSelection = selection)
    ).subscribe();

    hotkeys.hotkeyEventSubject.subscribe( it => this.handleHotkeyEvent(it));
  }

  @Input()
  participantUuid: string;
  selectedJumType = JumpKind.TEXT;
  selectedNumber: number;

  ngOnInit() {
    this.modalSubscription = this.modal.state.subscribe(state => this.hotkeys.setJumperOpenedState(state));
  }

  show(kind: JumpKind) {
    const allowModalOpen = !this.bookmarks.hasBookmark() || this.bookmarks.getBookmarkMode() === JumpKind.TEXT
    if (!allowModalOpen) return;
    this.switchJumpType(kind);
    this.modal.show(false);
    setTimeout(() => this.focus(), 800)
  }

  private handleHotkeyEvent(event: HotkeyEvent) {
    if (event instanceof JumpTo && this.currentSelection) {
      this.show(JumpKind.TEXT);
    } else if (event instanceof Dict && this.currentSelection) {
      this.show(JumpKind.DICT);
    } else if (event instanceof Reading && this.currentSelection) {
      this.show(JumpKind.READ);
    } else if (event instanceof Bookmark && this.bookmarks.hasBookmark()) {
      this.openBookmark();
    } else if (event instanceof EscKey) {
      this.hide();
      return;
    }

  }

  hide() {
    this.modal.hide();
  }

  private focus() {
    this.numberInput.nativeElement.focus();
    this.numberInput.nativeElement.select();
  }

  doJump() {
    if (
    this.bookmarks.doJump(
      this.participantUuid, this.selectedJumType, this.selectedNumber)) {
      this.hide();
    }

  }

  public openBookmark() {
    this.bookmarks.openBookmark(this.participantUuid).subscribe(
      _ => this.hide()
    );
  }

  getBookmarkPage() {
    return this.bookmarks.getBookmarkPage();
  }

  isRegularBookmark() {
    return this.bookmarks.hasBookmark() && this.bookmarks.getBookmarkMode() === JumpKind.TEXT;
  }

  isActionBookmark() {
    return this.bookmarks.hasBookmark() && this.bookmarks.getBookmarkMode() !== JumpKind.TEXT;
  }

  getActionName() {
    return this.bookmarks.getBookmarkMode().actionName;
  }

  jumpTypeSwitched() {
    this.progress.readProgress(this.participantUuid).pipe(
      take(1)
    ).subscribe(
      progress => {
        if (progress.details.reading && this.selectedJumType === JumpKind.READ) {
          this.selectedNumber = Number(progress.details.reading);
        } else if (progress.details.dictation && this.selectedJumType === JumpKind.DICT) {
          const plainNumberTryValue = Number.parseInt(progress.details.dictation);
          this.selectedNumber = plainNumberTryValue
          if (!plainNumberTryValue) {
            try {
              this.selectedNumber = Number.parseInt(progress.details.dictation.split(" ")[1])
            } catch (anyEx){}
          }

        } else {
          this.selectedNumber = null;
        }
      }
    );
  }

  private switchJumpType(newType: JumpKind) {
    this.selectedJumType = newType;
    this.jumpTypeSwitched();
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }
}
