import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {XorEncryptor} from "../../service/helpers/xor-encryptor";

@Component({
  selector: 'app-student-book-renderer',
  templateUrl: './student-book-renderer.component.html',
  styleUrls: ['./student-book-renderer.component.css']
})
export class StudentBookRendererComponent implements OnInit {
  private _bookContent: SafeHtml;
  @ViewChild('bookContainer', {static: true})
  bookContainer: ElementRef;
  @Input()
  participantUuid: string
  @Input()
  set bookContent(value: string) {
    this._bookContent = this.sanitizer.bypassSecurityTrustHtml(this.decrypt(value));
    this.scrollContainerUp();
  };
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  private decrypt(encrypted: string): string {
    if (encrypted == null) return null;
    return XorEncryptor.toString(
      new XorEncryptor(this.participantUuid, 24)
        .encrypt(
          XorEncryptor.str2ab(encrypted)
        ));
  }

  getContent() {
    return this._bookContent;
  }

  private scrollContainerUp() {
    this.bookContainer.nativeElement.scrollTo(0, 0);
  }
}
