<div class="screen-container text-light pt-5">
  <div class="container" *ngIf="hasData()">
    <h1><span translate>general.hi </span> <strong>{{gePartiName()}}</strong>,</h1>
    <p class="lead">
      <span translate>enter.student.start-at </span>
      <strong>{{getPlannedDate() | date: 'HH:mm'}}</strong>
      <span translate> enter.student.start-at.on </span>
      <strong>{{getPlannedDate() | date: 'EEEE (dd/MM)'}}</strong>.
      <strong *ngIf="isOffline()"><br> <span translate>enter.student.offline.description</span></strong>
    </p>
    <p class="lead">
      <span translate>general.group-label</span>: {{getRoomName()}}<br>
      <span translate>general.teacher-label</span>: {{getTeacherName()}}<br>
    </p>
    <div *ngIf="!isOffline()">
      <p class="lead text-center" translate>enter.student.classroom-opens-in</p>
      <h1 class="text-center" *ngIf="!timeIsNegative">
        {{getJoinTime()}}
      </h1>
    </div>
    <div class="mt-4 mb-5" *ngIf="!isOffline()">
      <h3 class="mb-4" translate>how-prepare.checklist.intro</h3>
      <h5 translate>how-prepare.iq.text</h5>
      <ul>
        <li translate>how-prepare.iq.wifi-for-mobile</li>
        <li translate>how-prepare.iq.close-to-wifi</li>
        <li translate>how-prepare.iq.by-wire</li>
        <li translate>how-prepare.iq.fast</li>
      </ul>
      <h5 translate>how-prepare.dev.text</h5>
      <ul class="mb-5">
        <li translate>how-prepare.dev.browser</li>
        <li translate>how-prepare.dev.ios-browser</li>
        <li translate>how-prepare.dev.avoid-manufacturer-browser</li>
        <li translate>how-prepare.dev.close-apps</li>
        <li translate>how-prepare.dev.permissions</li>
      </ul>
      <h3 class="mb-4" translate>how-prepare.during.text</h3>
      <ul>
        <li translate>how-prepare.during.dont-switch-windows</li>
        <li translate>how-prepare.during.hide-students</li>
        <li translate>how-prepare.during.refresh-page</li>
      </ul>
      <hr/>
    </div>
  </div>

</div>
