import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-room-details-page',
  templateUrl: './room-details-page.component.html',
  styleUrls: ['./room-details-page.component.css']
})
export class RoomDetailsPageComponent implements OnInit {
  roomUuid: string;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.paramMap.subscribe( params => this.roomUuid = params.get('roomUuid'));
  }

  ngOnInit() {
  }

}
