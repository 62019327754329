import {Injectable} from "@angular/core";
import {take} from "rxjs/operators";
import {SocketServiceContext, SocketServiceV2} from "./socket-v2.service";

@Injectable({
  providedIn: 'root'
})
export class PingService {
  private websocketContext: SocketServiceContext;
  private uuid: string;
  private pingTimeout: any = null;

  constructor(private socketService: SocketServiceV2) {
    socketService.connect().pipe(take(1)).subscribe(
      context => this.websocketContext = context
    );
  }


  startPing(uuid: string) {

    this.uuid = uuid;

    if (this.isRunning()) return;

    const timeoutRoutine = () => {
      this.pingServer();
      this.pingTimeout = setTimeout(timeoutRoutine, 30000)
    };
    this.pingTimeout = setTimeout(timeoutRoutine, 5000);
  }

  private isRunning() {
    return this.pingTimeout != null;
  }

  stopPing(){
    if (!this.isRunning()) return;
    clearTimeout(this.pingTimeout);
    this.pingTimeout = null;
  }

  private pingServer() {
    if (!this.websocketContext || !this.uuid) return;
    this.socketService.send(this.websocketContext, `/app/participant/${this.uuid}/ping`,"ping")
  }

}
