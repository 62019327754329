<div class="form-row">
  <div class="col-auto mb-2">
    <select class="form-control form-control-sm"
            [(ngModel)]="searchQuery.searchType"
    (change)="doSearch()">
      <option value="all">Search All</option>
      <option value="group">By group name</option>
      <option value="teacher">By teacher</option>
      <option value="student">By student</option>
      <option value="class">By class</option>
    </select>
  </div>
  <div class="col mb-2">
    <input class="form-control form-control-sm" placeholder="Search ..." [(ngModel)]="searchQuery.queryTerm" (change)="doSearch()">
  </div>
  <div class="col-12 text-right col-sm-auto">
    <div class="btn-group btn-group-sm">
      <button type="button"
              class="btn btn-light"
              (click)="showAsTable()"
              [ngClass]="{'btn-link': isTableDisplay()}"
              data-toggle="tooltip"
              title="List View"
      ><i class="fa fa-th-list"></i> </button>
      <button type="button"
              class="btn btn-light"
              [ngClass]="{'btn-link': isCalendarDisplay()}"
              (click)="showAsCalendar()"
              data-toggle="tooltip"
              title="Calendar View"
      ><i class="fa fa-calendar-alt"></i> </button>
      <button type="button"
              class="btn btn-light"
              [ngClass]="{'text-black-25': isPlainTextDisplay() || !plainTextDisplayAvailable()}"
              (click)="showAsPlainText()"
              data-toggle="tooltip"
              title="Email view - type teacher's name first"
      ><i class="fa fa-envelope-open-text"></i> </button>
    </div>
  </div>
</div>
<div class="small text-muted" *ngIf="hasAnyMatch()">
  Matched with:<br>
  <span *ngIf="getGroupsMatched().length"><strong>groups: </strong>
    <a tabindex="" class="mr-1 cursor-pointer"
       (click)="searchForGroup(group)"
       *ngFor="let group of first(getGroupsMatched(),probeSize)"
    >
      {{getGroupName(group)}}
    </a>
    <span class="mr-1" *ngIf="countMore(getGroupsMatched(),probeSize)">and {{countMore(getGroupsMatched(),probeSize)}} more</span>
    <br/>
  </span>
  <span *ngIf="getTeachersMatched().length"><strong>teachers: </strong>

    <a tabindex="" class="mr-1 cursor-pointer"
       *ngFor="let teacher of first(getTeachersMatched(),probeSize)"
       (click)="searchTeacherGroup(teacher)"
    >
      {{getPersonName(teacher)}}
    </a>
    <span class="mr-1" *ngIf="countMore(getTeachersMatched(),probeSize)">and {{countMore(getTeachersMatched(),probeSize)}} more</span>
    <br/>
  </span>
  <span *ngIf="getStudentsMatched().length"><strong>students: </strong>

    <a tabindex="" class="mr-1 cursor-pointer"
       *ngFor="let student of first(getStudentsMatched(),probeSize)"
       (click)="searchForStudent(student)"
    >
      {{getPersonName(student)}}
    </a>
    <span class="mr-1" *ngIf="countMore(getStudentsMatched(),probeSize)">and {{countMore(getStudentsMatched(),probeSize)}} more</span>
    <br/>
  </span>
  <span *ngIf="getClassesMatched().length"><strong>classes: </strong>

    <a tabindex="" class="mr-1 cursor-pointer"
       *ngFor="let className of first(getClassesMatched(),probeSize)"
       (click)="searchForClass(className)"
    >
      {{className}}
    </a>
    <span class="mr-1" *ngIf="countMore(getClassesMatched(),1)">and {{countMore(getClassesMatched(),probeSize)}} more</span>
    <br/>
  </span>

</div>
<div class="alert alert-danger " *ngIf="hasConflicts()">
  <div *ngIf="conflicts.byTeacher.length > 0"><strong>Teachers have conflicts: </strong>
  <a *ngFor="let teacher of conflicts.conflictedTeacherEmails" class="mr-1 cursor-pointer" tabindex="" (click)="searchTeacherMail(teacher)">{{teacher}}</a>
  </div>
  <div *ngIf="conflicts.byClass.length > 0"><strong>classes have conflicts: </strong>
    <a *ngFor="let room of conflicts.conflictedClassRooms" class="mr-1 cursor-pointer" tabindex="" (click)="searchClassroomName(room)">{{room}}</a>
  </div>
</div>
<div class="text-center" *ngIf="dataIsEmpty()">
  <strong>no results</strong>
</div>
<div class="text-center py-5" *ngIf="!dataIsEmpty() && isPlainTextDisplay()">
  <div *ngFor="let day of toShow.daysSorted">
    <h5>{{day | date: "dd MMM, EEEE"}}</h5>
    <div class="mb-2" *ngFor="let schedule of getCalendarDisplayViewDay(day)">
      {{schedule.schedule.details.startDate | date: 'HH:mm'}}
      <span *ngIf="getEndDate(schedule)">- {{getEndDate(schedule) | date: "HH:mm"}} </span>
      <span class="text-muted small" *ngIf="getPlace(schedule)">[{{getPlace(schedule)}}] </span>-
      <span>{{getGroupName(schedule.template)}}</span>
      <span *ngIf="isOffline(schedule)"> <i class="fa fa-school ml-2"></i> </span>
    </div>
  </div>
  <div class="text-right">
    <a class="btn btn-primary" [href]="preparePlainViewEmailLink()">Open as email</a>
  </div>
</div>
<div class="table-responsive" *ngIf="!dataIsEmpty() && isCalendarDisplay()">
  <table class="table table-sm table-bordered">
    <thead class="thead-dark text-center">
      <tr>
        <th *ngFor="let dayDate of toShow.daysSorted">
          {{dayDate | date: "EEE"}} <span *ngIf="searchQuery.sortBy === dayDate"><i class="fa fa-sort-down"></i> </span><br>
          <small>{{dayDate | date: "d"}}</small>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let dayDate of toShow.daysSorted" class="text-center">
          <div class="calendar-entry" *ngFor="let schedule of getCalendarDisplayViewDay(dayDate)">
            <a class="time" [ngClass]="getTextColorClassByStatus(schedule)"
               routerLink="/manager/{{schoolId}}/schedules/{{schedule.schedule.id}}">
              {{schedule.schedule.details.startDate | date: 'HH:mm'}}
              <span *ngIf="getEndDate(schedule)">- {{getEndDate(schedule) | date: "HH:mm"}}</span>
              <span class="text-muted class">{{getPlace(schedule)}}</span>
              <span *ngIf="hasAnyOffline(schedule)" class="small text-muted"><i class="fa fa-school ml-1"></i></span>
            </a><br/>
            <a class="group" routerLink="/manager/{{schoolId}}/templates/{{schedule.template.id}}">
              {{getGroupName(schedule.template)}}
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-responsive" *ngIf="!dataIsEmpty() && isTableDisplay()">
  <table class="table table-sm table-bordered">
    <thead class="thead-dark text-center">
      <tr>
        <th (click)="sort(null)" class="cursor-pointer"><span *ngIf="searchQuery.sortBy == null"><i class="fa fa-sort-down"></i> </span></th>
        <th *ngFor="let dayDate of toShow.daysSorted"
            (click)="sort(dayDate)" class="cursor-pointer"
        >
          {{dayDate | date: "EEE"}} <span *ngIf="searchQuery.sortBy === dayDate"><i class="fa fa-sort-down"></i> </span><br>
          <small>{{dayDate | date: "d"}}</small>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of getTableViewGroupsSorted()">
        <td [ngClass]="{'table-active' : searchQuery.sortBy == null }">
          <small>
            <a routerLink="/manager/{{schoolId}}/templates/{{group.id}}">{{getGroupName(group)}}</a>
          </small>
        </td>
        <td *ngFor="let dayDate of toShow.daysSorted"
            [ngClass]="{'table-active' : searchQuery.sortBy === dayDate }">
          <a [ngClass]="getTextColorClassByStatus(schedule)"
             routerLink="/manager/{{schoolId}}/schedules/{{schedule.schedule.id}}"
             *ngFor="let schedule of getGroupDaySchedules(group, dayDate)">
            {{schedule.schedule.details.startDate | date: 'HH:mm'}}
            <span *ngIf="getEndDate(schedule)">- {{getEndDate(schedule) | date: "HH:mm"}}</span>
            <small class="text-muted">{{getPlace(schedule)}}</small>
            <span *ngIf="hasAnyOffline(schedule)" class="small text-muted"><i class="fa fa-school ml-1"></i></span>
            <br>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
