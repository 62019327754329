<ul class="list-group data-list-group">
    <li class="list-group-item"  *ngFor="let room of rooms">
        <small class="text-muted float-right" *ngIf="getStatus(room)">Status: {{getStatus(room)}}</small>
        <h5>{{getName(room)}}</h5>
        Teacher: {{getTeacher(room)}}, students: {{getNbOfStudents(room)}} <br>
        Classroom opened at {{getCreated(room) | date: "HH:mm (dd MMM)"}} 
        <span *ngIf="getStarted(room)">, lesson started: {{getStarted(room) | date: "HH:mm (dd MMM)"}}</span><span *ngIf="getFinished(room)">, lesson finished: {{getFinished(room) | date: "HH:mm"}}</span><span *ngIf="getDuration(room)">, duration: {{getDuration(room)}}</span>
        <button class="btn btn-sm btn-primary float-right" routerLink="/rooms/{{room.uuid}}/details">Details</button>
    </li>
    <li class="list-group-item text-center" *ngIf="hasMore()">
        <button class="btn btn-link" (click)="showMore()" ><i class="fa fa-chevron-down"></i></button>
    </li>
</ul>


<!--<table class="table">
    <thead>
        <th>Name</th>
        <th>Teacher</th>
        <th>Students</th>
        <th>Created</th>
        <th>Started</th>
        <th>Lesson Started</th>
        <th>Total Time</th>
    </thead>
    <tbody>
        <tr *ngFor="let room of rooms" routerLink="/rooms/{{room.uuid}}/details">
            <td>{{getName(room)}}</td>
            <td>{{getTeacher(room)}}</td>
            <td>{{getNbOfStudents(room)}}</td>
            <td>{{getCreated(room) | date: "dd MMM yyyy HH:mm"}}</td>
            <td>{{getStarted(room) | date: "dd MMM yyyy HH:mm"}}</td>
            <td>{{getLessonStarted(room) | date: "dd MMM yyyy HH:mm"}}</td>
            <td>{{getTotalTime(room)}} s</td>
        </tr>
        <tr>
            <td colspan="7" class="text-center" *ngIf="hasMore()">
                <button class="btn btn-link" (click)="showMore()"><i class="fa fa-chevron-down"></i></button>
            </td>
        </tr>
    </tbody>
</table>
-->