import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input()
  visible = true;
  cancellable = false;

  cancelSubject = new Subject<any>();
  constructor() { }

  dimmClick() {
    if (this.cancellable) {
      this.cancel();
    }
  }

  public show(cancellable?: boolean) {
    this.visible = true;
    if (cancellable !== undefined && cancellable != null) {
      this.cancellable = cancellable;
    }
  }

  preventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  public hide() {
    this.visible = false;
  }

  public cancel() {
    this.hide();
    this.cancelSubject.next(null);
  }

  ngOnInit() {
  }

}
