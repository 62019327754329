
<div class="error-screen-container text-light pt-5">
  <div class="container" *ngIf="hasData()">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2>{{'general.dear' | translate }} <strong>{{gePartiName()}}</strong>,</h2>
        <p class="lead">
          {{'errors.lead' | translate}}
        </p>
      </div>
      <div class="col-12 col-md-6 text-right">
        <p>
          <span translate>general.group-label</span>: {{getRoomName()}}<br>
          <span *ngIf="getPlannedDate()"><span translate>errors.start-date-label</span>: {{getPlannedDate() | date: 'dd MMM HH:mm'}}<br/></span>
          <span translate>general.teacher-label</span>: {{getTeacherName()}}<br>
        </p>
      </div>
    </div>

    <dl class="row">
      <dt class="col-4 col-md-3 col-lg-2"><span translate>errors.time-label</span>:</dt>
      <dd class="col-8 col-md-9 col-lg-10">{{getTime() | date: 'dd MMM HH:mm'}}</dd>
      <dt class="col-4 col-md-3 col-lg-2" [ngClass]="{'text-success': isBrowserCompatible(), 'text-danger': !isBrowserCompatible()}"><span translate>errors.browser-label</span>:</dt>
      <dd class="col-8 col-md-9 col-lg-10" [ngClass]="{'text-success': isBrowserCompatible(), 'text-danger': !isBrowserCompatible()}">
        {{(getCompatibilityStatus()) | async }}
        <p *ngIf="!isBrowserCompatible()" translate>errors.browser-compatibility-comment</p>
      </dd>
      <dt class="col-4 col-md-3 col-lg-2"><span translate>errors.room-link-label</span>:</dt>
      <dd class="col-8 col-md-9 col-lg-10 text-break"><a href="{{createLink()}}">{{createLink()}}</a></dd>
      <dt class="col-4 col-md-3 col-lg-2"><span translate>errors.browser-details-label</span>:</dt>
      <dd class="col-8 col-md-9 col-lg-10">{{getAgentInfo()}}</dd>
    </dl>

    <div *ngIf="is(errors.RoomNotFount)">
      <h3 translate>errors.room-closed.title</h3>
      <p translate>errors.room-closed.description</p>
    </div>
    <div *ngIf="is(errors.VideoConnectionProblems)">
      <h3 translate>errors.vce.title</h3>
      <p translate>errors.vce.description</p>
      <h4 translate>errors.solution-title</h4>
      <ul>
        <li translate>errors.vce.solution.try-once-again</li>
        <li><span translate>errors.vce.solution.improve-net</span>
          <ul>
            <li translate>errors.vce.solution.check-net</li>
            <li translate>errors.vce.solution.check-range</li>
            <li translate>errors.vce.solution.try-wire</li>
            <li translate>errors.vce.solution.free-resources</li>
            <li translate>errors.vce.solution.check-router</li>
          </ul>
        </li>
      </ul>
      <hr/>
    </div>
    <div *ngIf="is(errors.UnableToConnect)">
      <h3 translate>errors.cant-connect.title</h3>
      <p translate>errors.cant-connect.description</p>
      <hr/>
    </div>
    <div *ngIf="is(errors.UnableToEstablishWebRtc)">
      <h3 translate>errors.bad-browser.title</h3>
      <p translate>errors.bad-browser.description</p>
      <h4 translate>errors.solution-title</h4>
      <p><span translate>errors.bad-browser.solution</span>
      <strong translate>errors.bad-browser.solution-comment</strong>
      <hr/>
    </div>
    <div *ngIf="is(errors.NotAllowedError)">
      <h3 translate>errors.no-perms.title</h3>
      <p translate>errors.no-perms.description</p>
      <h4 translate>errors.solution-title</h4>
      <p translate>errors.no-perms.solution</p>
      <hr/>
    </div>
    <div *ngIf="is(errors.LowNetworkProfile)">
      <h3 translate>errors.bad-con.title</h3>
      <p translate>errors.bad-con.description</p>
      <h4 translate>errors.solution-title</h4>
      <ul>
        <li translate>errors.bad-con.solution.wifi-on-mobile</li>
        <li translate>errors.bad-con.solution.reduce-traffic</li>
        <li translate>errors.bad-con.solution.check-range</li>
        <li translate>errors.bad-con.solution.free-resources</li>
        <li translate>errors.bad-con.solution.close-others</li>
      </ul>
      <hr/>
    </div>
    <div *ngIf="is(errors.CouldNotStartMedia)">
      <h3>errors.res-lock.title</h3>
      <p>errors.res-lock.description</p>
      <h4>errors.solution-title</h4>
      <p>errors.res-lock.solution</p>
    </div>

    <div class="mb-5 row justify-content-end no-gutters mx-n1">
      <div class="col-auto mx-1" *ngIf="is(errors.UnableToConnect)">
        <a  class="btn btn-danger btn-sm mb-2" href="mailto:help@callan.co.uk?subject=OCC connection error" translate>general.email-us</a>
      </div>
      <div class="col-auto mx-1" *ngIf="showReopenLink()">
        <button class="btn btn-sm btn-primary mb-2" type="button" (click)="openAgain()" translate>errors.open-again-btn</button>
      </div>
      <div class="col-auto mx-1" *ngIf="showCopyLink()">
        <button class="btn btn-sm mb-2" [ngClass]="{'btn-primary':!copied, 'btn-secondary':copied}" type="button" (click)="copyUrl()">
          <span translate>errors.copy-link</span>
          <span *ngIf="copied" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" translate>errors.link-copied</span>
        </button>
      </div>
    </div>
    <div>
      <h3 translate>how-prepare.title</h3>
      <p translate>how-prepare.intro</p>
      <h3 translate>how-prepare.checklist.intro</h3>
      <ul>
        <li><span translate>how-prepare.iq.text</span>
          <ul>
            <li translate>how-prepare.iq.wifi-for-mobile</li>
            <li translate>how-prepare.iq.close-to-wifi</li>
            <li translate>how-prepare.iq.by-wire</li>
            <li translate>how-prepare.iq.fast</li>
          </ul>
        </li>
        <li><span translate>how-prepare.dev.text</span>
          <ul>
            <li translate>how-prepare.dev.browser</li>
            <li translate>how-prepare.dev.ios-browser</li>
            <li translate>how-prepare.dev.avoid-manufacturer-browser</li>
            <li translate>how-prepare.dev.avoid-in-app</li>
            <li translate>how-prepare.dev.close-apps</li>
            <li translate>how-prepare.dev.permissions</li>
          </ul>
        </li>
        <li translate>how-prepare.dont-switch-windows</li>
        <li translate>how-prepare.close-shelf</li>
      </ul>
      <hr/>
    </div>
  </div>
</div>
