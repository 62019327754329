import { Component, OnInit } from '@angular/core';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import {  filter, take, timeoutWith } from 'rxjs/operators';
import { zip, of } from 'rxjs';
import { AuthorizationProfile } from 'src/app/model/casa';
import { Router } from '@angular/router';
import { CurrentSchoolService } from 'src/app/service/current-school.service';

@Component({
  selector: 'app-manger-profiles-page',
  templateUrl: './manger-profiles-page.component.html',
  styleUrls: ['./manger-profiles-page.component.css']
})
export class MangerProfilesPageComponent implements OnInit {
  explainRoles = false;

  constructor(personData: PersonalDataService,
              private router: Router,
              private currentSchoolService: CurrentSchoolService) {
    personData.currentProfile.pipe(
      filter( currentProfile => currentProfile != null),
      take(1)
    );
    personData.authorizationProfiles.pipe(
      filter( profiles => profiles != null),
      take(1)
    );
    zip(
      personData.currentProfile.pipe(
        filter( currentProfile => currentProfile != null),
        timeoutWith(1000, of(null)),
        take(1)
      ),
      personData.authorizationProfiles.pipe(
        filter( profiles => profiles != null),
        take(1)
      )
    ).subscribe(
      ([currentProfile, allProfiles ]) => {
        this.selectTheSchool(currentProfile, allProfiles);
      }
    );
  }

  /*
    1) check if current profile is a school manager
    2) check if any profile is a school manager
    3) check if the current profile has a school id and is a super user
    4) check if any profile has a school id and is super user
  */
  selectTheSchool(currentProfile: AuthorizationProfile, allProfiles: AuthorizationProfile[])  {
    const currentProfileAsManager = currentProfile && currentProfile.asSchoolAdmin && currentProfile.learningUnitId ? currentProfile : null;

    const otherProfileAsManger = allProfiles.find( p => p.asSchoolAdmin && p.learningUnitId);

    const isSuperUser = allProfiles.findIndex( p => p.asSuperUser) >= 0;

    const currentProfileAsSuperUser = isSuperUser && currentProfile && currentProfile.learningUnitId ? currentProfile : null;
    const otherProfileAsSuperUser = isSuperUser ? allProfiles.find( p => p.learningUnitId) : null;

    const currentProfileAsTeacher = currentProfile
      && (currentProfile.asTeacher || currentProfile.profileRole === 'SCHOOL_TEACHER')
      && currentProfile.learningUnitId ? currentProfile : null;
    const otherProfileAsTeacher = allProfiles.find( p => (p.asTeacher || p.profileRole === 'SCHOOL_TEACHER') && p.learningUnitId);

    if (currentProfileAsManager) {
      this.openDashboard(currentProfileAsManager.learningUnitId);
    } else if (otherProfileAsManger) {
      this.openDashboard(otherProfileAsManger.learningUnitId);
    } else if (currentProfileAsSuperUser) {
      this.openDashboard(currentProfileAsSuperUser.learningUnitId);
    } else if (otherProfileAsSuperUser) {
      this.openDashboard(otherProfileAsSuperUser.learningUnitId);
    } else if (currentProfileAsTeacher) {
      this.openDashboard(currentProfileAsTeacher.learningUnitId);
    } else if (otherProfileAsTeacher) {
      this.openDashboard(otherProfileAsTeacher.learningUnitId);
    } else {
      this.explainRoles = true;
    }
  }
  openDashboard(learningUnitId: number) {
    this.currentSchoolService.currentSchoolId.next(learningUnitId);
    this.router.navigate(['manager', learningUnitId, 'dashboard']);
  }


  ngOnInit() {
  }

}
