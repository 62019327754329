import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {
  HybridState,
  IdentifiedRoomTemplate, IdentifiedTemplateParticipant,
  RoomTemplate,
  TemplateParticipant,
  TemplateParticipantDetailsBase
} from 'src/app/model/server';
import {Regions} from "../../service/helpers/regions";
import {ModalV2Component} from "../modal-v2/modal-v2.component";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-room-template-edit',
  templateUrl: './room-template-edit.component.html',
  styleUrls: ['./room-template-edit.component.css']
})
export class RoomTemplateEditComponent implements OnInit {

  public static EVENT_SAVE = 'save';
  public static EVENT_CANCEL = 'cancel';

  @ViewChild("settingsModal", {static: true})
  settingsModal: ModalV2Component;

  mTemplate: IdentifiedRoomTemplate;
  teacher: IdentifiedTemplateParticipant;
  students: IdentifiedTemplateParticipant[];
  regions = Regions.regions;
  editParticipantSettings: IdentifiedTemplateParticipant;
  onlineGroup = true;

  @Input()
  set template(template: IdentifiedRoomTemplate) {
    this.mTemplate = template;
    this.decomposeData();
  }
  get template() {return this.mTemplate; }

  @Output()
  formEvent = new EventEmitter<string>();

  canAdd() {
    if (!this.template) { return false; }
    return this.template.participants.length <= 20;
  }

  isEditForbidden(){
    return this.mTemplate && !!this.mTemplate.participants.find( p => p.eid);
  }

  addStudent() {
    if (!this.canAdd()) {
      return;
    }
    const student = new IdentifiedTemplateParticipant();
    student.details = new TemplateParticipantDetailsBase();
    student.details.role = 'Student';
    this.template.participants.push(student);
    this.students.push(student);
  }

  removeStudent(participant: IdentifiedTemplateParticipant) {
    this.students.splice(this.students.indexOf(participant), 1);
    this.template.participants.splice(this.template.participants.indexOf(participant), 1);
  }

  onSave() {
    this.template.details.hybridState = (this.onlineGroup) ? null : HybridState.Offline;
    this.formEvent.emit(RoomTemplateEditComponent.EVENT_SAVE);
  }

  onCancel() {
    this.formEvent.emit(RoomTemplateEditComponent.EVENT_CANCEL);
  }

  decomposeData() {
    if (!this.template) {return; }
    this.teacher = this.template.participants.find( p => p.details.role === 'Teacher');
    this.students = this.template.participants.filter ( p => p.details.role === 'Student');
    this.onlineGroup = this.template.details.hybridState !== HybridState.Offline;
  }


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  editSettings(student: IdentifiedTemplateParticipant) {
    this.editParticipantSettings = student;
    this.settingsModal.show();
  }

  hasFlags(student: TemplateParticipant) {
    return this.isOffline(student);
  }

  isOffline(student: TemplateParticipant) {
    if (!student) return false;
    return student.details.offline;
  }

  getOfflineModeDescription() {
    return this.onlineGroup ?
      this.sanitizer.bypassSecurityTrustHtml("The group is online")
      : this.sanitizer.bypassSecurityTrustHtml("The group is offline (in a physical classroom <i class=\"fa fa-school\"></i>)");
  }
}

