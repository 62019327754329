import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { zip,  of } from 'rxjs';
import { map, take, timeoutWith } from 'rxjs/operators';
import { ServerRestService } from 'src/app/service/server-rest.service';
import {
  TemplateDetails,
  TemplateParticipantDetailsBase,
  Pageable,
  Page,
  RoomSettings,
  IdentifiedRoomTemplate,
  IdentifiedTemplateParticipant
} from 'src/app/model/server';
import { RoomTemplateEditComponent } from 'src/app/components/room-template-edit/room-template-edit.component';
import { SearchingContextService } from 'src/app/service/searching-context.service';
import { TemplateRoomsDataProvider } from 'src/app/service/helpers/rooms-list.provider';
import { ProgressDataProvider } from 'src/app/service/helpers/progress-history-list.provider';
import { Constants } from 'src/app/service/constants';
import {
  ListEvent as ScheduleListEvent,
  ListEvent,
  SchedulesListComponent
} from "../../components/schedules-list/schedules-list.component";
import {ScheduleListQuery, SchedulesListProvider} from "../../components/schedules-list/schedules-list.provider";
import {ScheduleRestService} from "../../service/schedule-rest.service";
import {ScheduleRowSimplified} from "../../model/schedule";
import {CreateScheduleComponent} from "../../components/create-schedule/create-schedule.component";
import {DateUtils} from "../../service/helpers/date-utils";
import {ModalV2Component} from "../../components/modal-v2/modal-v2.component";

declare var $: any;

@Component({
  selector: 'app-room-template-details-page',
  templateUrl: './room-template-details-page.component.html',
  styleUrls: ['./room-template-details-page.component.css']
})
export class RoomTemplateDetailsPageComponent implements OnInit {

  schoolId: number;
  templateId: number = null;
  template: IdentifiedRoomTemplate;
  historyLength = 0;
  lessonsLength = 0;
  historyDataProvider: ProgressDataProvider = null;
  @ViewChild('startConfirmModal', {static: true}) startConfirmModal: ModalV2Component;
  @ViewChild('deleteConfirmModal', {static: true}) deleteConfirmModal: ModalV2Component;
  @ViewChild('schedulesListComponent', {static: false}) schedulesListComponent: SchedulesListComponent;
  @ViewChild('createScheduleComponent', {static: true}) createScheduleComponent: CreateScheduleComponent;
  @ViewChild('startScheduleConfirmModal', {static: true}) startScheduleConfirmModal: ModalV2Component;
  roomsDataProvider: TemplateRoomsDataProvider;
  private schedulesDataProvider: SchedulesListProvider;
  scheduleToDelete: ScheduleRowSimplified;
  scheduleToStart: ScheduleRowSimplified;
  isScheduleLoading = false;
  schedulesCount: number = 0;
  savedCorrectly = false;
  saveError = false;
  deleteFollowing = false;

  constructor(route: ActivatedRoute,
              private rest: ServerRestService,
              private router: Router,
              private searchingContext: SearchingContextService,
              private schedulesRest: ScheduleRestService
    ) {
    zip(
      route.parent.paramMap.pipe(
        map( params => Number(params.get('schoolId')))
      ),
      route.paramMap.pipe(
        map( params => params.get('id'))
      )
    ).subscribe(
      ([schoolId, templateId]) => this.setupData(schoolId, templateId)
    );
  }

  setupData(schoolId: number, templateId: string): void {
    this.schoolId = schoolId;
    if (templateId === 'new') {
      this.createNewTemplate();
    } else {
      this.templateId = Number(templateId);
      this.reloadTemplate();
      this.restoreRoomsSearching();
      this.restoreHistorySearching();
      this.restoreScheduleSearching();
    }
  }

  private restoreScheduleSearching() {
    const contextPath = `/schools/${this.schoolId}/templates/${this.templateId}/schedules`;
    this.searchingContext.getOrCreateContext<ScheduleListQuery>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new ScheduleListQuery(0 , this.templateId)))
    ).subscribe( initialQuery => {
      this.schedulesDataProvider = new SchedulesListProvider(contextPath, this.searchingContext, this.schedulesRest, this.schoolId, initialQuery);
    })
  }

  restoreRoomsSearching() {
    const contextPath = `/schools/${this.schoolId}/template/${this.templateId}/rooms`;
    this.searchingContext.getOrCreateContext<Pageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new Pageable(0, Constants.DefultPageSize, 'createDate,desc')))
    ).subscribe( initialPageable =>
      this.roomsDataProvider = new TemplateRoomsDataProvider(this.schoolId,
        this.templateId,
        contextPath, this.searchingContext, this.rest, initialPageable));
  }

  restoreHistorySearching() {
    const contextPath = `/schools/${this.schoolId}/template/${this.templateId}/progress-history`;
    this.searchingContext.getOrCreateContext<Pageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new Pageable(0, Constants.SmallPageSize, 'createDate,desc')))
    ).subscribe( initialPageable =>
      this.historyDataProvider = new ProgressDataProvider(
        this.schoolId,
        this.templateId,
        contextPath,
        this.searchingContext,
        this.rest,
        initialPageable
      )
    );
  }

  reloadTemplate() {
    this.rest.getTemplateById(this.schoolId, this.templateId).subscribe(
      template => this.template = template
    );
  }

  createNewTemplate() {
    this.template = new IdentifiedRoomTemplate();
    this.template.details = new TemplateDetails();
    const emptyTeacher = new IdentifiedTemplateParticipant();
    emptyTeacher.details = new TemplateParticipantDetailsBase();
    emptyTeacher.details.role = 'Teacher';
    const emptyStudent = new IdentifiedTemplateParticipant();
    emptyStudent.details = new TemplateParticipantDetailsBase();
    emptyStudent.details.role = 'Student';
    this.template.participants = [emptyTeacher, emptyStudent];
  }

  onEditEvent(event: string) {
    if (event === RoomTemplateEditComponent.EVENT_CANCEL) {
      if (this.templateId == null) {
        this.createNewTemplate();
      } else {
        this.reloadTemplate();
      }
    } else if (event === RoomTemplateEditComponent.EVENT_SAVE) {
      this.saveTemplate();
    }
  }

  onDelete() {
    this.deleteConfirmModal.hide();
    if (this.scheduleToDelete != null) {
      this.schedulesRest.deleteSchedule(this.schoolId, this.scheduleToDelete.schedule.id, this.deleteFollowing).subscribe( _ => {
        this.refreshSchedules();
      });
      this.deleteFollowing = false;
      this.scheduleToDelete = null;
    } else {
      this.rest.deleteTemplate(this.schoolId, this.templateId)
        .subscribe(_ => this.router.navigate(['manager', this.schoolId, 'dashboard']));
    }
  }

  onStart() {
    this.startConfirmModal.hide();
      const roomSettings = new RoomSettings();
      this.rest.createRoomWithTemplate(this.schoolId, this.templateId, roomSettings)
        .subscribe(room => this.router.navigate(['rooms', room.uuid, 'details']));
  }

  saveTemplate() {
    this.savedCorrectly = false;
    this.saveError = false;

    this.rest.saveOrCreateTemplate(this.schoolId, this.template).subscribe(
      saved => {
        this.savedCorrectly = true;
        setTimeout(() => this.savedCorrectly = false, 3000);
        this.template = saved;
        if (!this.templateId) {
          this.templateId = saved.id;
          this.router.navigate(['manager', this.schoolId, 'templates', this.templateId]);
        }
        //this.router.navigate(['manager', this.schoolId, 'dashboard']);
      }
    , err => {
        this.saveError = true;
        setTimeout(() => this.saveError = false, 3000);
      });
  }

  ngOnInit() {
    $( () => $('[data-toggle="tooltip"]').tooltip());
  }

  getSchedulesDataProvider() {
    return this.schedulesDataProvider;
  }

  onSchedulesEvent(event: ListEvent) {
    if (event.event === ListEvent.EVENT_START) {
      this.scheduleToStart = event.item;
      this.startScheduleConfirmModal.show(true)
    } else if (event.event === ListEvent.EVENT_DELETE) {
      this.scheduleToDelete = event.item;
      this.deleteConfirmModal.show(true)
    } else if (event.event === ScheduleListEvent.EVENT_OPEN) {
      this.router.navigate(["manager", this.schoolId, "schedules", event.item.schedule.id])
    }

  }

  createNewSchedule() {
    this.createScheduleComponent.show();
  }

  refreshSchedules() {
    this.schedulesListComponent.refreshData();
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.schedulesListComponent.refreshData();
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  onSchedulesDataSetUpdate(dataSet: ScheduleRowSimplified[]) {
    this.schedulesCount = dataSet.length;
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider && this.schedulesDataProvider.queryArgs && this.schedulesDataProvider.queryArgs.weekOffset != null;
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.schedulesListComponent.refreshData();
  }

  onStartSchedule() {
    this.startScheduleConfirmModal.hide();
    if (this.scheduleToStart!=null) {
      this.schedulesRest.updateScheduleState(this.schoolId, this.scheduleToStart.schedule.id, "PENDING").subscribe(row =>
        {
          this.scheduleToStart = null;
          this.refreshSchedules();
        }
      )
    }
  }

  cancelDelete() {
    this.deleteConfirmModal.hide();
    this.scheduleToDelete = null;
  }

    getGroupName() {
        if (this.template && this.template.details.name && this.template.details.name.length > 0) return this.template.details.name;
        return "Group details";
    }
}
