<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/manager/{{schoolId}}/dashboard">
    <img src="/assets/img/logo.png"
         style="height: 36px;margin-top: 6px;"
         class="d-inline-block align-top" alt="" loading="lazy">
    Online Callan Classroom
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link cursor-pointer" routerLink="/manager/{{schoolId}}/templates">Groups</a>
      </li>
      <li class="nav-item dropdown">
        <button type="button" class="btn btn-link nav-link dropdown-toggle" data-toggle="dropdown">Reports</button>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item cursor-pointer" routerLink="/manager/{{schoolId}}/activity">Group usage</a>
            <a class="dropdown-item cursor-pointer" routerLink="/manager/{{schoolId}}/teacher-attendance">Teacher times</a>
          </div>
      </li>
      <li class="nav-item d-block d-lg-none text-right">
        <a class="nav-link cursor-pointer" (click)="logout()" tabindex="">Logout</a>
        <small class="text-muted mt-2">{{getName()}} <br/> ({{getEmail()}})</small>
      </li>
    </ul>
  </div>
  <div class="d-none d-lg-block dropdown">
    <button type="button" class="btn btn-sm btn-link nav-link" data-toggle="dropdown"><i class="fa fa-cog"></i> </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
      <a *ngIf="hasCol()" tabindex="" class="dropdown-item cursor-pointer" (click)="goToCol()">Open Callan Online</a>
      <a *ngIf="hasPay()" tabindex="" class="dropdown-item cursor-pointer" (click)="goToPay()">Buy Books</a>
      <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
      <div class="p-2 small text-muted text-right">
        {{getName()}} <br/> ({{getEmail()}})
      </div>
    </div>
  </div>

</nav>


<div class="container pt-5 mt-3">
  <router-outlet></router-outlet>
</div>
