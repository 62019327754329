import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Level} from "./helpers/LoggerLevels";


@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private level = environment.logging

  public t(...data: any[]){
    if (this.level > Level.TRACE) return;
    console.log(...data);
  }

  public d(...data: any[]){
    if (this.level > Level.DEBUG) return;
    console.log(...data);
  }

  public i(...data: any[]){
    if (this.level > Level.INFO) return;
    console.log(...data);
  }

  e(...data: any[]) {
    console.error(...data);
  }
}
